import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { UserContext } from '../../../contexts/user-context';
import { searchObjectsInCache, getUserParametersForView, updateUserParametersForView, getTypesFromCache } from '../../../utils/caching';
import { transformObjForReactSelect, transformSelectedObjBeforeSaving } from '../../../utils/data-processing';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './view-filters-form.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadConfig from '../../../utils/configLoader';
const config = loadConfig('general');

const ViewFiltersForm = ({ viewId, viewFilters, hideFiltersForm }) => {

  const { userParameters, setUserParameters, theme } = useContext(UserContext); 
  const selectedFilters = getUserParametersForView(userParameters, viewId, 'filters') || {};

  const [filters, setFilters] = useState({
    dateChip: selectedFilters.dateChip || 'Any',
    startDate: selectedFilters.startDate || null,
    endDate: selectedFilters.endDate || null,
    collections: selectedFilters.collections || ['Any'],
    types: selectedFilters.types || ['Any'],
    templatesOnly: selectedFilters.templatesOnly || false,
    favoritesOnly: selectedFilters.favoritesOnly || false,
    archivedOnly: selectedFilters.archivedOnly || false,
    linksOnly: selectedFilters.linksOnly || false,
    tagsOnly: selectedFilters.tagsOnly || false,
    text: selectedFilters.text || '',
    keywords: selectedFilters.keywords || '',
  });

  const [typeValues, setTypeValues] = useState([{ label: 'Any', value: 'Any' }]);

    // Load data needed in the form (e.g. values for dropdowns)
  // Instructions: adapt as needed depending on what is needed in your form
  const dateRangeValues = ['Any', 'Today', 'This Week', 'Last Week', 'This Month', 'Last Month'];

  const collectionValues = [
    { label: 'Any', value: 'Any' },
  ];
  config.data.collections.forEach(collection => {
    if(!viewFilters.collections || viewFilters.collections.length === 0 || viewFilters.collections?.includes(collection.value)) {
      collectionValues.push(collection);
    }
    // else {
    //   collectionValues.push(collection);
    // }
  });

    // Asynchronous function to load type values based on selected collections
    const loadTypeValues = async () => {
      let newTypeValues = [{ label: 'Any', value: 'Any' }];
      if (!filters.collections || filters.collections?.includes("Any")) {
        for (const collection of viewFilters.collections) {            
          const rawTypes = await searchObjectsInCache("possible-values", { fieldCollection: collection, fieldName: "type" });
          const types = rawTypes ? rawTypes.map((obj) => transformObjForReactSelect(obj)) : [];
          newTypeValues = [...newTypeValues, ...types];
        }
      } else {
        for (const collection of filters.collections) {            
          const rawTypes = await searchObjectsInCache("possible-values", { fieldCollection: collection, fieldName: "type" });
          const types = rawTypes ? rawTypes.map((obj) => transformObjForReactSelect(obj)) : [];
          newTypeValues = [...newTypeValues, ...types];
        }
      }
      setTypeValues(newTypeValues);
    };
  
    useEffect(() => {
      loadTypeValues(); // Call this function when the component mounts or when filters.collections changes
    }, [filters.collections]);

  // Functions to handle changes
  const handleFilterChange = (key, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleMultiSelect = (key, value) => {
    setFilters(prevFilters => {
      if (value === "Any") {
        // If "Any" is selected, replace the current filter with just ["Any"]
        return {
          ...prevFilters,
          [key]: ["Any"],
        };
      }
  
      // Get updated values, either removing or adding the selected value
      const updatedValues = prevFilters[key]?.includes(value)
        ? prevFilters[key].filter(item => item !== value) // Remove the value if it's already selected
        : [...prevFilters[key], value]; // Add the value if it's not selected
  
      // If no values are selected, default back to "Any"
      if (updatedValues.length === 0) {
        return {
          ...prevFilters,
          [key]: ["Any"], // Reset to "Any" when the array is empty
        };
      }
  
      // Ensure "Any" is removed if any other values are selected
      return {
        ...prevFilters,
        [key]: updatedValues.filter(item => item !== "Any"),
      };
    });
  };  

  const handleDateChipChange = (chip) => {
    let startDate = null;
    let endDate = null;

    switch (chip) {
      case 'Today':
        startDate = moment().startOf('day').toDate();
        endDate = moment().endOf('day').toDate();
        break;
      case 'This Week':
        startDate = moment().startOf('isoWeek').toDate();
        endDate = moment().endOf('isoWeek').toDate();
        break;
      case 'Last Week':
        startDate = moment().startOf('isoWeek').subtract(1, 'isoWeek').toDate();
        endDate = moment().endOf('isoWeek').subtract(1, 'isoWeek').toDate();
        break;
      case 'This Month':
        startDate = moment().startOf('month').toDate();
        endDate = moment().endOf('month').toDate();
        break;
      case 'Last Month':
        startDate = moment().startOf('month').subtract(1, 'month').toDate();
        endDate = moment().endOf('month').subtract(1, 'month').toDate();
        break;
      default:
        break;
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      dateChip: chip,
      startDate,
      endDate,
    }));
  };

  const clearAll = () => {
    setFilters({
      dateChip: 'Any',
      collections: ['Any'],
      types: ['Any'],
    });
  };

  const handleSave = () => {
    updateUserParametersForView(setUserParameters, viewId, 'filters', filters);
    hideFiltersForm();
  };

  return (
    <Modal dialogClassName={`modal-fullscreen-lg-down modal-lg modal-dialog-scrollable ${theme}`} show={true} onHide={hideFiltersForm}>
      <Modal.Header closeButton>
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='filters-form'>
          {/* Date Range Shortcuts */}
          <div className='row filters-row'>
            <label htmlFor="chipDate" className="filter-name">Time Window</label>
            <label htmlFor="chipDate" className="filter-label">Select a value to automatically set start and end date</label>
            <div className='filter-content-scrollable'>
              <Stack direction="row" spacing={1}>
                {dateRangeValues.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    className={`filter-chip ${filters.dateChip !== value ? 'outlined' : ''} ${filters.dateChip === value ? 'selected' : ''}`}
                    variant={filters.dateChip !== value ? 'outlined' : undefined}
                    onClick={() => handleDateChipChange(value)}
                  />
                ))}
              </Stack>
            </div>
          </div>

          {/* Date Range Selection */}
          <div className='row filters-row'>
            <label htmlFor="dateRange" className="filter-name">Date Range</label>
            <label htmlFor="dateRange" className="filter-label">Start a start and end date to apply custom date range</label>
            <div className='col filters-col-left'>
              <label htmlFor="startDate" className="filter-label">Start Date</label>
              <input 
                name="startDate"
                type="date"
                className="form-control mb-1"
                value={filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''} 
                onChange={(e) => handleFilterChange('startDate', moment(e.target.value).startOf('day').toDate())} 
              />
            </div>
            <div className='col filters-col-right'>
              <label htmlFor="endDate" className="filter-label">End Date</label>
              <input
                name="endDate"
                type="date"
                className="form-control mb-1"
                value={filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''}
                onChange={(e) => handleFilterChange('endDate', moment(e.target.value).endOf('day').toDate())}
              />
            </div>
          </div>

          {/* Collection Selection */}
          {viewFilters.collections.length > 1 && 
            <div className='row filters-row'>
              <label htmlFor="collections" className="filter-name">Collections</label>
              <label htmlFor="collections" className="filter-label"></label>
              <div className='filter-content-scrollable'>
                <Stack direction="row" spacing={1}>
                  {collectionValues.map((data) => (
                    <Chip
                      key={data.value}
                      label={data.label}
                      className={`filter-chip ${!filters.collections?.includes(data.value) ? 'outlined' : ''} ${filters.collections?.includes(data.value) ? 'selected' : ''}`}
                      variant={!filters.collections?.includes(data.value) ? 'outlined' : undefined}
                      onClick={() => handleMultiSelect('collections', data.value)}
                    />
                  ))}
                </Stack>
              </div>
            </div>
          }

          {/* Type Selection */}
          {(typeValues.length > 0) && 
            <div className='row filters-row'> 
              <label htmlFor="types" className="filter-name">Type</label>
              <label htmlFor="types" className="filter-label"></label>
              <div className='filter-content-block'>
                <Stack spacing={{ xs: 1, sm: 1 }} direction="row" useFlexGap flexWrap="wrap">
                  {typeValues.map((data, index) => ( 
                    <Chip
                      key={data.value}
                      label={data.label}
                      className={`filter-chip ${!filters.types?.includes(data.value) ? 'outlined' : ''} ${filters.types?.includes(data.value) ? 'selected' : ''}`}
                      variant={!filters.types?.includes(data.value) ? 'outlined' : undefined}
                      onClick={() => handleMultiSelect('types', data.value)}
                    />
                  ))}
                </Stack>
              </div>
            </div>
          }

          {/* Focus Section */}
          {/* <div className='row filters-row'>
            <label className="filter-name">Others</label>
            <div className="container">
              <div className="row">
                <div className="col">
                  <FormControlLabel
                    label="Favorites"
                    control={
                      <Checkbox
                        className='filter-checkbox'
                        checked={filters.favoritesOnly || false}
                        onChange={() => handleFilterChange('favoritesOnly', !filters.favoritesOnly)}
                      />
                    }
                  />
                </div>
                <div className="col">
                  <FormControlLabel
                    label="Templates"
                    control={
                      <Checkbox
                        className='filter-checkbox'
                        checked={filters.templatesOnly || false}
                        onChange={() => handleFilterChange('templatesOnly', !filters.templatesOnly)}
                      />
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FormControlLabel
                    label="Links"
                    control={
                      <Checkbox
                        className='filter-checkbox'
                        checked={filters.linksOnly || false}
                        onChange={() => handleFilterChange('linksOnly', !filters.linksOnly)}
                      />
                    }
                  />
                </div>
                <div className="col">
                  <FormControlLabel
                    label="Tags"
                    control={
                      <Checkbox
                        className='filter-checkbox'
                        checked={filters.tagsOnly || false}
                        onChange={() => handleFilterChange('tagsOnly', !filters.tagsOnly)}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div> */}

          {/* Other Filters - Archived */}
          {/* <div className='row filters-row'>
            <label className="filter-name">Other Filters</label>
            <div className="container">
              <div className="row">
                <div className="col">
                  <FormControlLabel
                    label="Archived"
                    control={
                      <Checkbox
                        className='filter-checkbox'
                        checked={filters.archivedOnly || false}
                        onChange={() => handleFilterChange('archivedOnly', !filters.archivedOnly)}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div> */}

          {/* Seacrch */}
          <div className='row filters-row'>
            <label htmlFor="search" className="filter-name">Search</label>
            <label htmlFor="search" className="filter-label"></label>
            <div className='col-12 col-md-6 mb-3'>
              <label htmlFor="text" className="filter-label">Text (exact match)</label>
              <input
                name="text"
                type="search"
                className="form-control mb-1"
                value={filters.text || ''}
                onChange={(e) => handleFilterChange('text', e.target.value)}
              />
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <label htmlFor="keywords" className="filter-label">Keywords (separated by comma)</label>
              <input
                name="keywords"
                type="search"
                className="form-control mb-1"
                value={filters.keywords || ''}
                onChange={(e) => handleFilterChange('keywords', e.target.value)}
              />
            </div>
          </div>

        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={clearAll}>
          Clear All
        </Button>
        <Button variant='primary' onClick={handleSave}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewFiltersForm;
