import SingleViewLayout from '../../../layouts/view/single-view';
import SplitViewResponsiveLayout from '../../../layouts/view/split-view-responsive';
import Content from '../components/content';
import Panel from '../components/panel';
import useLoadingIndicator from '../../../hooks/use-loading-indicator';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const Spinner = loadModule("./components/loading/spinner.js");

const Main = () => {
  
  const isLoading = useLoadingIndicator();

  return (
    <div className="centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <SingleViewLayout 
          content={<Content />}
          maxWidth={"var(--view-narrow-width)"}
        />
      )}
    </div>
    
  );
};

export default Main;
