import React from 'react';
import FocusModal from './focus-modal';
import useFocusMode from '../hooks/use-focus-mode';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import "./text-area-with-focus.css";

export const TextAreaWithFocus = ({ fieldName, fieldValue, onChange, handleGenericChange }) => {

  const { isOpen, openModal, closeModal, modalContent, setModalContent } = useFocusMode();

  const setAndOpenModal = () => {
    setModalContent(fieldValue);
    openModal();
  }

  return (
    <div className="text-area-with-focus">
      <div className='label-container'>
        <label htmlFor="description" className="form-label">
          Content
        </label>
        <div className="open-in-full-button" onClick={() => setAndOpenModal()}>
          <VisibilitySharpIcon style={{ fontSize: '20px' }} />
        </div>
      </div>

      <textarea
        placeholder="Content.."
        className="form-control flex-grow-1 custom-textarea"
        name={fieldName}
        value={fieldValue}
        onChange={onChange}
      ></textarea>

      <FocusModal
        isOpen={isOpen}
        closeModal={closeModal}
        modalContent={modalContent}
        setModalContent={setModalContent}
        fieldName={fieldName}
        handleGenericChange={handleGenericChange}
      />
    </div>
  );
};
