import { useState } from 'react';
import { login, googleAuth } from '../services/auth-service';

export const useLoginForm = () => {
  const [form, setForm] = useState({
    email: "",
    username: "",
    password: "",
  });

  const updateForm = (value) => {
    setForm((prev) => ({ ...prev, ...value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(form);
      window.location.href = "/app/home";
    } catch (error) {
      alert("Invalid email or password, please try again.\nError: " + error);
      window.location.href = "/login";
    }
  };

  return {
    form,
    updateForm,
    handleSubmit,
    googleAuth
  };
};