// TBD: could use a form layout for harmonization
import { Link } from 'react-router-dom';
import { useSignupForm } from '../hooks/use-signup-form';
import './login.css';

const SignupForm = () => {
  const {
    form,
    updateForm,
    handleSubmit,
    handleSubmitRegistrationCode,
    googleAuth,
    agreeTerms,
    setAgreeTerms,
    validRegistrationCode,
    config,
  } = useSignupForm();

  return (
    <div className='form-container'>
      <div className="form-signin w-100 m-auto">
        {config.waitlistMode && !validRegistrationCode && (
          <form onSubmit={handleSubmitRegistrationCode}>
            <div>
              <div className="mb-3">
                <label
                  htmlFor="registrationCode"
                  className="form-label"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  Registration Code*
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  id="registrationCode"
                  name="registrationCode"
                  value={form.registrationCode}
                  onChange={(e) =>
                    updateForm({ registrationCode: e.target.value })
                  }
                  required
                  placeholder="######"
                  autoFocus
                />
              </div>
            </div>

            <button
              className="btn btn-primary w-100 py-2"
              disabled={!(form.registrationCode.length > 6)}
              type="submit"
            >
              Submit
            </button>

            <div className="registration-info text-body-secondary">
              <p>
                <strong>
                  * Access to {config.appName} is currently by invitation only.
                </strong>{' '}
                Join our <Link to="/#hero" className="hyperlink-std">waitlist</Link> today, and we’ll notify you as
                soon as access is available. We appreciate your interest and
                look forward to welcoming you onboard soon!
              </p>
            </div>
          </form>
        )}

        {(validRegistrationCode || !config.waitlistMode) && (
          <div>
            <form onSubmit={handleSubmit}>
              <h1 className="form-title">Sign up Form</h1>

              <div className="col-12 mb-2">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={form.email}
                  onChange={(e) =>
                    updateForm({ email: e.target.value, username: e.target.value })
                  }
                />
              </div>

              <div className="col-12 mb-2">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={(e) => updateForm({ password: e.target.value })}
                  required
                />
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={agreeTerms}
                  required
                  onChange={() => setAgreeTerms(!agreeTerms)}
                />
                <label
                  className="form-check-label text-body-secondary policies-agreement-text"
                  htmlFor="flexCheckDefault"
                >
                  <p>
                    By checking this box, you agree to our{' '}
                    <a href="/policy/terms-of-service" className="hyperlink-std">
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href="/policy/privacy-policy" className="hyperlink-std">
                      Privacy Policy
                    </a>
                  </p>
                </label>
              </div>

              <button className="btn btn-primary w-100 py-2 mt-3" type="submit">
                Sign up
              </button>
            </form>

            <div className="google-btn">
              <button
                className="login-with-google-btn mt-3 mb-3"
                onClick={googleAuth}
              >
                Sign up with Google
              </button>
            </div>

            <div className="mx-auto" style={{ width: '240px' }}>
              Already have an account?{' '}
              <Link to="/login" className="hyperlink-std">
                Log In
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupForm;
