import React, { useState } from 'react';
import pako from 'pako';

const originalJson = {
  "_id": {
    "$oid": "65d6c022948668cb2facb802"
  },
  "type": "U2FsdGVkX1/pErBiUdf8h8oCt54kb0o50wvTJZoyUy386kLntHIJs+9rzjsZJzCr7UraJe1LaxzfHKXo36RJZQwQ+2jJ2RS2pd9C1LjcRUw=",
  "title": "U2FsdGVkX1/pzHMgLlKg+0uGowg0019Lz1aqqn3N5hDfk3033JBVzjswPFfYf5/VZQU89m+qfAQwRApLZiPeww==",
  "content": "U2FsdGVkX1+LMENSTa3mFNf8RRvGweE8SNdt/9qlWC9faoZ2VFwdJt44DNY7kHX8i7o+YCGPBIhWMyuLVFskGIh9Dcx3+sbtFRdRopq4ZWkGBF73VR3ivc6XS+30lvgJSCkC4pEwMnitjBSuSGvGngdEWfBGlKcFy3WkRuiqJycHOsg2zfSZGi0kA0LMYGw+7q7IgHzDnb64/sOFG4BJzC+s0r8wJ76mDIQKG/zdF2Kn2BLDQIPpakSOv+7+/sY6Pf2za0JmlFJ3JORYvQdNaP6YfNA/WkIkN1JTZBHaJgQcjEa/FklaRauO/yNXNS26IqLwZZyzxBI9/AWe7seybNtk3RecvLAuXxyqIh6r6FXXj0SSipcKFJ/AtNJH3TUoh7TkMcUKIg318r6+Gbq63kYlo0UcMQsZGAOba36f1OWr6D4J0GJDxdPUf2dOCaQC",
  "lesson": "U2FsdGVkX190TkZ0gqYPY9NumBU+FsavEMBdOdVMo18=",
  "habits": "U2FsdGVkX190tvLpS7QoCIgeU4qjiRbKM5SZXLK1cmTi42zcEbL58p5wvYd7kojPS5/Jg9d2yvrDSF5nMk6MRxf6oCVGZattj3D5uxYDTMZOwNGNT19rKT3hXxRC688waXY0OmZc9HCWlH2hsk0OgjBhMkMkdbE2rpzOf2/RqE5WNocYrbOXXwYQyZZbENpdRVfmwsAf8c1yZABK3vcQu0qNA+ZMfNnN2kNqlHomWsHyhL2cl/Wo0+0en2oOKACECND5KNRgrFjZCN/whnOxzyOwNdNr8Uh0Ror32/sPn9Pz31IeazzAi8iqyZfc/D5pyM8TM4Yd5XV+26CxpYj45BlfOjpaYMJZ9eKiqPsCtDv/dwM0BtfkGUNfImI4Jz4CUaikxs8SqdVcBPmWzcJkgae+JsO+I1x5yf0jVDgzYfrGDeYXy86+zLUPYlqQk3TXeT5Z1bykB/LipKjat2ieSsMFkAtyGfV8r780GP9aRKS+207iaGMhNsA16UQO23+yPj+AfBwiJqwL0TtnW4c1Vpce3IkH46saK6BNlwZDNUdRvjvoVcDsKbnwb8YwvnnFUbMegiUx/X6VROExGMnQf3DmbsdAdgkcgJChTgCTEBpoBSXx3VUg8yzzD7HZ93HUTuos92P88mOM6Ls+KVQnjwkWGRx0k7GEsLPCca5yOWe5P90p7SBnD6w+PVUrax59dMKuJHXMEG1l3tmK8wypIfyshpe8Z+t3S3fQ56iwlp46lUxWA/2yT5zyUmCTr8+WZVm/JPah+yuL0xGEuSXRZFrDFsSYyY59c91OaKffW0kuVlMH0TbEQ0iu++Jdofiegbsig3mYbMb6EPNmHDQJRA7qt4vK6O1fvd/JbjAi9gRg2nVHnLWSOIsS0EfVXw9d4yMZwDQNPBbp44sl5UmCmBTsCEWGdl9QbaoN2jRx35ppE5P55oLL4qh1LPw86pavHdhOnprOJlqESg1ed4unJ5JcMm5G6vYI60MmLdu4Cxxg9Sl7GVjzXT5XZSuovjJHw9qJ8JKAv53HzO0m606LS/RLhDpeuZS+c8FtFxV2oCV6hk1sb2nEKpkCaana7LRgKywPuik5eTHdtgoPgzuRsPsYm7xoItp00YTUcYG88mM87gsNPoTU/y+ggzekeCZPsF70r7AkYUmgYqBamE+/j+ULmmU8hAkl++1Qmt3IQUr9YpzPP/I60pUmR2MycsqKI4FbB8AcP7AOWWDyJitw8CG7CbI4jSl8heisF/AXswlWzBzoR/w+9PcPACmIjK5u0IZU2yVUBtIu7nG00SCS1YX5MlTGWs8khBYP16nqmCmL7aVGWU+CwC5kA765cmTdJIzVTG7g3zFhkvVDJf1xp0h3Q+RMg0AKe7Bb/gj9SUqYteTDn0hckft1AGMDfMEj8eZoAAC4pY0BGtPwa/hfvrahqz6ep+YwcXoL4Ad9rW+TL5r6eufOYddMIb14pr/gGozxDGA1iNElzzWgdrw4bg==",
  "tags": "U2FsdGVkX1/c27K2LBvOogoxeFXm+wOKvSXpWYKsuu4=",
  "link": "U2FsdGVkX19/JjgorVCIV3wrY04+0+UMpp41g7bZ4jY=",
  "isFavorite": "U2FsdGVkX1/c5NEPNKcvVRQfTPRNZMJTg0zSmy817M0=",
  "date": "U2FsdGVkX18KMcHE5Lh2glJf7/FWU3LZAmiq9VmjEO5pXXOAzXkof6qgflDu/rpH",
  "origin": "U2FsdGVkX1+sA1yyCnnuPdb8/KkIRkunWwa4FpP7lro=",
  "template": "U2FsdGVkX1/HPdWV7gPXOQ0OYIZr7eidi5qXAjzAAMQ=",
  "isTemplate": "U2FsdGVkX19cHkDtHUQOuzKe3+A81ZRugwy5B6BgiT8=",
  "creationDate": {
    "$date": {
      "$numberLong": "1708572706651"
    }
  },
  "createdBy": {
    "$oid": "64925594474888c49e850678"
  },
  "lastModificationDate": {
    "$date": {
      "$numberLong": "1708572726721"
    }
  },
  "lastModifiedBy": {
    "$oid": "64925594474888c49e850678"
  },
  "isActive": true,
  "isArchived": false,
  "__v": 0
};

const CompressedTextComponent = () => {
    const [originalText, setOriginalText] = useState(JSON.stringify(originalJson));
    const [compressedData, setCompressedData] = useState('');

    const compressText = () => {
        const compressed = pako.deflate(originalText, { to: 'string' });
        setCompressedData(compressed);
    }

    return (
        <div>
            <button onClick={compressText}>Compress Text</button>
            <div>
                <h2>Original Text:</h2>
                <p>{originalText}</p>
                <p>Size: {originalText.length} bytes</p>
            </div>
            <div>
                <h2>Compressed Data:</h2>
                <p>{compressedData}</p>
                <p>Size: {compressedData.length} bytes</p>
            </div>
        </div>
    );
}

export default CompressedTextComponent;
