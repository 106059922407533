import { useContext } from 'react';
import { UserContext } from '../../contexts/user-context';
import { capitalizeFirstLetter } from '../../utils/dataUtils';
import './sidebar.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const Branding = loadModule('./components/branding/branding.js');
const MainMenu = loadModule('./features/main-menu/components/main-menu.js');
const AppVersionInfo = loadModule('./components/legends/app-version-info.js');

const Sidebar = ({ handleClose }) => { 
  const { theme } = useContext(UserContext);

  return (
    <div className={`sidebar ${theme}`}>
      <div className='sidebar-header'>
        <Branding />
        <div className='sidebar-header-close-icon'>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </div>
      </div>
      <div className='sidebar-content'>
        <div className='sidebar-content-menus'>
          <MainMenu />
        </div>
        <div className='sidebar-content-footer'>
          <AppVersionInfo />
        </div>
      </div>
    </div>
  )
};

export default Sidebar;
