import { useState } from "react";
import { encryptObject } from "../../../../core/utils/data-encryption";
import { createNewAccessRequest, getUserIp, getUserLocation } from "../services/access-request-services";

const useAccessRequest = () => {

  const [ email, setEmail ] = useState("");
  const [ accessRequested, setAccessRequested] = useState(false);

  const getUserInfoAndSendRequest = async () => {
    try {
      const ip = await getUserIp();
      const location = await getUserLocation(ip);
      const city = location.city;
      const country = location.country_name;
      await sendAccessRequest(email, ip, city, country)
    } catch (error) {
      console.error('Error fetching IP and location:', error);
    }
  };

  const sendAccessRequest = async (email, ip, city, country) => {
    try {     
      const userDetails = {
        email: email,
        ip: ip,
        city: city,
        country: country,
        isSystem: true,
      };
      const encryptedUserDetails = encryptObject(userDetails);
      await createNewAccessRequest(encryptedUserDetails);
    } catch (error) {
      console.error('Error sending access request:', error);
    }
  }

  const handleAccessRequest = async (event) => {
    event.preventDefault();
    await getUserInfoAndSendRequest();
    setAccessRequested(true);
  };

  return { setEmail, accessRequested, handleAccessRequest }
};

export default useAccessRequest;