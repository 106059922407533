import axios from 'axios';

export const authenticateUser = async () => {
  try {
    console.log('User not authenticated, trying to authenticate user..');
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/auth/success`, { withCredentials: true });
    return response.data.user;
  } catch (error) {
    console.log('User could not be authenticated');
    return null;
  }
};

export const logoutUser = async (setIsLoading, setUser) => {
  // commented out logic with isLoading as it was causing Suspense in app.js to throw an error
  // setIsLoading(true);
  try {
    setUser(null);
    localStorage.clear();
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/logout`, {}, { withCredentials: true });
    //window.location.href = "/login"; // not necessary anymore

  } catch (err) {
    console.log(err);
  } 
  // finally {
  //   setTimeout(() => { setIsLoading(false) }, 1500); 
  // }
};