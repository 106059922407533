import Main from '../features/user-admin/views/main';

const UserAdmin = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default UserAdmin;