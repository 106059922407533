import useUserStats from "../../../hooks/use-user-stats";

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from "../../../../core/utils/moduleLoader";
const Spinner = loadModule("./components/loading/spinner.js");
const StatsCard = loadModule("./components/stats/stats-card.js");


const UserStats = () => {

  const title = 'Collections';
  const { userStats, isLoading } = useUserStats();

  return (
    <div className="user-main centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
        <StatsCard title={title} stats={userStats}/>
        </div>
      )}
    </div>

  );
};

export default UserStats;
