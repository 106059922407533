import { useContext } from 'react';
import { UserContext } from '../../../contexts/user-context';
import useFileUpload from '../../../hooks/use-file-upload';
import { importUserData, restoreUserData } from '../../../utils/dataImportExport';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const FileUploadActionRow = loadModule('./components/administration/file-upload-action-row.js');

const DataImportSection = () => {

  const { user } = useContext(UserContext);
  const { importedFile, handleFileUpload } = useFileUpload();

  return (
    <div className='data-import'>
      <FileUploadActionRow 
        title={'Import user data'}
        description={'Import user data from a protected backup.'}
        buttonName={'Import'}
        handleAction={() => importUserData(importedFile, user)}
        zipFileUpload={handleFileUpload}
      />
      <FileUploadActionRow 
        title={'Restore backup'}
        description={'Delete all user data and restore data from a protected backup.'}
        buttonName={'Restore'}
        handleAction={() => restoreUserData(importedFile, user)}
        zipFileUpload={handleFileUpload}
        isLast={true}
      />
    </div>
  );
};

export default DataImportSection;