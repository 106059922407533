import { useState, useEffect } from "react";
import loadConfig from "../../../utils/configLoader";

const config = loadConfig('general');

const useCollectionSelected = () => {

  const [ collectionSelected, setCollectionSelected ] = useState("");
  
  const handleCollectionSelected = (event) => {
    setCollectionSelected(event.target.value);
  }

  const [ collectionDropdownValues, setCollectionDropdownValues ] = useState([]);

  // TBD: move to hook useCollectionSelected
  useEffect(() => {
    let values = ["Select a collection.."];
    let collections = [];

    if (config.admin.collections &&  config.admin.collections.length > 0) {
      collections = config.admin.collections.map((collection) => collection.name);
    }

    setCollectionDropdownValues([...values, ...collections]);

  }, [])


  return {
    collectionDropdownValues, 
    setCollectionDropdownValues,
    collectionSelected,
    handleCollectionSelected,
  };
};

export default useCollectionSelected;