const defaultUserParameters = {
  accessibility: {
    highContrastMode: false, // High-contrast color scheme
  },
  appearance: {
    theme: "light", // Options: "light", "dark", "system"
    fontSize: "medium", // Options: "small", "medium", "large"
    accentColor: "blue", // Customizable accent colors
    fontFamily: "default", // Option to choose a custom font
  },
  views: {
    'default': {
      sort: 'desc',
      layout: 'card',
      filters: {},
    },
    // Instructions: addtional views configuration below
    // 'home': {
    //   sort: 'desc',
    //   layout: 'card',
    //   filters: {},
    // },
  },
  search: "",
};

export default defaultUserParameters;