import loadModule from '../../../../core/utils/moduleLoader';

// Loading following components dynamically to allow one-off customization
const ViewContentLayout = loadModule("./layouts/view/view-content-layout.js");
const TypeWriter = loadModule("./features/type-writer/components/type-writer.js");
const ToDo = loadModule("./components/placeholders/todo-card.js");
const Weather = loadModule("./components/placeholders/weather-card.js");
const Achievements = loadModule("./components/placeholders/achievements-card.js");
const Text = loadModule("./components/placeholders/text-card.js");
const Stats = loadModule("./components/placeholders/stats-card.js");


const Panel = () => {
  
  return (
    <ViewContentLayout>
      {/* <Text /> */}
      <TypeWriter />
      {/* <ToDo /> */}
      {/* <Stats /> */}
      {/* <Weather /> */}
      {/* <Achievements /> */}
    </ViewContentLayout>
  );
};

export default Panel;