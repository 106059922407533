import {
  HomeOutlined,
  ViewCompact,
  AccountCircleOutlined,
  ImportExportOutlined,
  SettingsOutlined,
  InfoOutlined,
  NoteOutlined,
} from '@mui/icons-material';

const config = {
  '': [
    {
      label: 'Home',
      icon: HomeOutlined,
      href: '/app/home',
    },
  ],
  'COLLECTIONS': [
    {
      label: 'Notes',
      icon: NoteOutlined,
      href: '/app/notes',
    }, 
  ],
  'ADMINISTRATION': [
    {
      label: 'Data',
      icon: ViewCompact,
      href: '/app/data',
    }, 
    {
      label: 'User',
      icon: AccountCircleOutlined,
      href: '/app/user',
    },
    {
      label: 'Import & Export',
      icon: ImportExportOutlined,
      href: '/app/import-export',
    }, 
    {
      label: 'System',
      icon: SettingsOutlined,
      href: '/app/system',
    },
    {
      label: 'Testing',
      icon: SettingsOutlined,
      href: '/app/test',
    },
  ],
  'RESSOURCES': [
    {
      label: 'Documentation',
      icon: InfoOutlined,
      href: '/app/documentation',
    }, 
  ]
};

export default config;
