// Direct Imports - Protected Modules
import SingleView from '../../../layouts/view/single-view';
import useLoadingIndicator from '../../../hooks/use-loading-indicator';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const Content = loadModule('./features/data-admin/components/content.js');
const Spinner = loadModule('./components/loading/spinner.js');

const DataAdminMain = () => {
  
  const isLoading = useLoadingIndicator();

  return (
    <div className="centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <SingleView
          content={<Content />} 
        />
      )}
    </div>
  );
};

export default DataAdminMain;
