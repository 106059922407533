import { useState, useEffect } from 'react';
import { processHtmlTemplate } from '../utils/htmlTemplateProcessor';
import { appInformation } from '../config';

const useFetchHTML = (htmlFile) => {
  const [htmlString, setHtmlString] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the content of the HTML file
    const fetchHtml = async () => {
      try {
        const response = await fetch(htmlFile);
        if (!response.ok) {
          throw new Error(`Failed to fetch HTML file: ${response.statusText}`);
        }
        const text = await response.text();
        const updatedText = processHtmlTemplate(text, appInformation);
        setHtmlString(updatedText);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching the HTML file:', err);
      }
    };

    fetchHtml();
  }, [htmlFile]);

  return { htmlString, error };
};

export default useFetchHTML;
