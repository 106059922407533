const testEntries = [
  {
    _id: "1",
    entity: "entries",
    isFavorite: false,
    type: {
      name: "Type 1",
      color: "#ff0000"
    },
    title: "Entry 1",
    date: "2024-06-10",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_1", name: "Habit 1" }],
    tags: [{ id: "tag_1", name: "Tag 1" }],
    lesson: "Lesson 1",
    link: "https://google.com",
    isArchived: false
  },
  {
    _id: "2",
    entity: "entries",
    isFavorite: true,
    type: {
      name: "Type 2",
      color: "#00ff00"
    },
    title: "Entry 2",
    date: "2024-06-11",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_2", name: "Habit 2" }],
    tags: [{ id: "tag_2", name: "Tag 2" }],
    lesson: "Lesson 2",
    link: "https://example.com",
    isArchived: true
  },
  {
    _id: "3",
    entity: "entries",
    isFavorite: false,
    type: {
      name: "Type 1",
      color: "#ff0000"
    },
    title: "Entry 3",
    date: "2024-06-12",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    habits: [{ id: "habit_3", name: "Habit 3" }],
    tags: [{ id: "tag_3", name: "Tag 3" }],
    lesson: "Lesson 3",
    link: "https://example.org",
    isArchived: false
  },
  {
    _id: "4",
    entity: "entries",
    isFavorite: true,
    type: {
      name: "Type 2",
      color: "#00ff00"
    },
    title: "Entry 4",
    date: "2024-06-13",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_4", name: "Habit 4" }],
    tags: [{ id: "tag_4", name: "Tag 4" }],
    lesson: "Lesson 4",
    link: "https://example.net",
    isArchived: true
  },
  {
    _id: "5",
    entity: "entries",
    isFavorite: false,
    type: {
      name: "Type 1",
      color: "#ff0000"
    },
    title: "Entry 5",
    date: "2024-06-14",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    habits: [{ id: "habit_5", name: "Habit 5" }],
    tags: [{ id: "tag_5", name: "Tag 5" }],
    lesson: "Lesson 5",
    link: "https://example.edu",
    isArchived: false
  },
  {
    _id: "6",
    entity: "entries",
    isFavorite: true,
    type: {
      name: "Type 2",
      color: "#00ff00"
    },
    title: "Entry 6",
    date: "2024-06-15",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_6", name: "Habit 6" }],
    tags: [{ id: "tag_6", name: "Tag 6" }],
    lesson: "Lesson 6",
    link: "https://example.biz",
    isArchived: true
  },
  {
    _id: "7",
    entity: "entries",
    isFavorite: false,
    type: {
      name: "Type 1",
      color: "#ff0000"
    },
    title: "Entry 7",
    date: "2024-06-16",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_7", name: "Habit 7" }],
    tags: [{ id: "tag_7", name: "Tag 7" }],
    lesson: "Lesson 7",
    link: "https://example.info",
    isArchived: false
  },
  {
    _id: "8",
    entity: "entries",
    isFavorite: true,
    type: {
      name: "Type 2",
      color: "#00ff00"
    },
    title: "Entry 8",
    date: "2024-06-17",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_8", name: "Habit 8" }],
    tags: [{ id: "tag_8", name: "Tag 8" }],
    lesson: "Lesson 8",
    link: "https://example.us",
    isArchived: true
  },
  {
    _id: "9",
    entity: "entries",
    isFavorite: false,
    type: {
      name: "Type 1",
      color: "#ff0000"
    },
    title: "Entry 9",
    date: "2024-06-18",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_9", name: "Habit 9" }],
    tags: [{ id: "tag_9", name: "Tag 9" }],
    lesson: "Lesson 9",
    link: "https://example.co.uk",
    isArchived: false
  },
  {
    _id: "10",
    entity: "entries",
    isFavorite: true,
    type: {
      name: "Type 2",
      color: "#00ff00"
    },
    title: "Entry 10",
    date: "2024-06-19",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_10", name: "Habit 10" }],
    tags: [{ id: "tag_10", name: "Tag 10" }],
    lesson: "Lesson 10",
    link: "https://example.ca",
    isArchived: true
  },
  {
    _id: "11",
    entity: "entries",
    isFavorite: false,
    type: {
      name: "Type 1",
      color: "#ff0000"
    },
    title: "Entry 11",
    date: "2024-06-20",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_11", name: "Habit 11" }],
    tags: [{ id: "tag_11", name: "Tag 11" }],
    lesson: "Lesson 11",
    link: "https://example.au",
    isArchived: false
  },
  {
    _id: "12",
    entity: "entries",
    isFavorite: true,
    type: {
      name: "Type 2",
      color: "#00ff00"
    },
    title: "Entry 12",
    date: "2024-06-21",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    habits: [{ id: "habit_12", name: "Habit 12" }],
    tags: [{ id: "tag_12", name: "Tag 12" }],
    lesson: "Lesson 12",
    link: "https://example.de",
    isArchived: true
  },
]

export default testEntries;
