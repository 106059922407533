import loadModule from '../../core/utils/moduleLoader';
const Main = loadModule('./features/policies/components/privacy-policy.js');

const PrivacyPolicy = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default PrivacyPolicy;