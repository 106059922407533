import { Card } from 'react-bootstrap';

const ComponentCard = ({ title, children }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
          <div style={{ whiteSpace: 'pre-wrap', minHeight: '200px' }}>
            {children}
          </div>
      </Card.Body>
    </Card>
  );
};

export default ComponentCard;