import loadModule from '../utils/moduleLoader';
const Main = loadModule('./features/data-admin/views/main.js');

const DataAdmin = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default DataAdmin;