// keyEncryption.js
import CryptoJS from 'crypto-js';
import axios from 'axios';

// Import custom components
import loadConfig from './configLoader';
import { encryptValue, decryptValue } from './data-encryption';

const config = loadConfig('general');


// Function to derive the encryption key from the user's passphrase using PBKDF2
function deriveEncryptionKey(passphrase, salt) {
  const key = CryptoJS.PBKDF2(passphrase, salt, { keySize: 256/32, iterations: 10000 });
  return key.toString(CryptoJS.enc.Base64);
};

// Potential improvement
// Derive system passphrase using PBKDF2, need to figure out where salt will be stored
export function getSystemEncryptionkey() {
  try {
    return process.env.REACT_APP_SYSTEM_ENCRYPTION_KEY

  } catch (error) {
    console.error('Failed to retrieve system encryption key:', error);
    return undefined;
  }
};

// Function to encrypt the encryption key before storing it in local storage
function encryptEncryptionKey(derivedKey, systemKey) {
  try {
    const iv = CryptoJS.lib.WordArray.random(128/8); // Generate a random IV (Initialization Vector)
    const encryptedKey = CryptoJS.AES.encrypt(derivedKey, systemKey, { iv }).toString();
    return { encryptedKey, iv: iv.toString(CryptoJS.enc.Base64) };
  } catch (error) {
    throw new Error('Error encrypting encryption key: ' + error.message);
  }
}

// Function to decrypt the encryption key stored in local storage
function decryptEncryptionKey(encryptedKeyData, systemKey) {
  try {
    const { encryptedKey, iv } = encryptedKeyData;
    const decrypted = CryptoJS.AES.decrypt(encryptedKey, systemKey, { iv: CryptoJS.enc.Base64.parse(iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw new Error('Error decrypting encryption key: ' + error.message);
  }
}

// Purpose: store user encryption key in local storage to encrypt/decrypt user data
// Approach:
// 1. Derive user passphrase, salt it, and encrypt it with system key
// 2. Store resulting encrypted passphrase with IV used for encryption in localstorage
export function deriveAndStoreUserEncryptionKey(user, userPassphrase) {
  try {
    console.log("Deriving passphrase, encrypting and storing user encryption key..");
    const salt = user.encryptionKeySalt;
    if (!salt || salt === "") {
      // if salt doesn't exist for any reason, we (re)initialize the encryption key with new salt
      return initializeUserEncryptionKey(user, userPassphrase);
    }
    const derivedKey = deriveEncryptionKey(userPassphrase, salt);
    const systemKey = getSystemEncryptionkey();
    const { encryptedKey, iv } = encryptEncryptionKey(derivedKey, systemKey);

    const encryptedKeyData = { encryptedKey, iv };
    localStorage.setItem('user-encryption-key', JSON.stringify(encryptedKeyData));
    setKeyExpiration();
    console.log("User encryption key derived and stored successfully.");

  } catch (error) {
    console.error('Error handling user passphrase and storing encrypted key:', error.message);
    throw error;
  }
};

// Purpose: initialize user encryption key and store salt used for derivation in database
// Approach:
// 1. Derive user passphrase with random generated salt and encrypt it with system key
// 2. Store salt in the database to compute derived key consistently over time
// 3. Store resulting encrypted passphrase with IV used for encryption in localstorage
export async function initializeUserEncryptionKey(user, userPassphrase) {
  try {
    console.log("Initializing user encryption key..");
    const salt = CryptoJS.lib.WordArray.random(128/8).toString();
    const derivedKey = deriveEncryptionKey(userPassphrase, salt); 
    const systemKey = getSystemEncryptionkey();
    const { encryptedKey, iv } = encryptEncryptionKey(derivedKey, systemKey);

    const encryptedKeyData = { encryptedKey, iv };
    localStorage.setItem('user-encryption-key', JSON.stringify(encryptedKeyData));
    setKeyExpiration();

    // storing salt used for encryption for subsequent logins
    // storing cypher text to validate user passphrae in subsequent logins
    const cypherText = encryptValue(config.encryption.knownPlainText, derivedKey);

    const updatedFields =  {
      encryptionKeySalt : salt,
      cypherText: cypherText,    
    };
    await axios.put(`/api/user/${user._id}`, updatedFields);
    
    console.log("User encryption key intialized successfully.");
    return derivedKey;

  } catch (error) {
    console.error('Error initializing user encryption key:', error.message);
    throw error;
  }
};

export async function setUserEncryptionKey(user, userPassPhrase) {
  if (user.isInitialized) {
    await deriveAndStoreUserEncryptionKey(user, userPassPhrase);
  } else {
    await initializeUserEncryptionKey(user, userPassPhrase);
  }
};

export function getUserEncryptionkey() {
  try {
    const encryptedKeyData = JSON.parse(localStorage.getItem('user-encryption-key'));
    const systemKey = getSystemEncryptionkey();
    const userEncryptionKey = decryptEncryptionKey(encryptedKeyData, systemKey)
    return userEncryptionKey; // this is the derived key used for data encryption/decryption

  } catch (error) {
    console.error('Failed to retrieve user encryption key:', error);
    return undefined;
  }
};

export function removeUserEncryptionkey() {
  try {
    localStorage.removeItem('user-encryption-key');
  } catch (error) {
    console.error('Failed to remove user encryption key:', error);
    return undefined;
  }
};

export function setKeyExpiration() {
  console.log("Setting new key expiration time stamp");
  const userEncryptionKeyExpirationTs = new Date().getTime() + config.encryption.userEncryptionKeyTimeout;
  localStorage.setItem('user-encryption-key-exp-ts', userEncryptionKeyExpirationTs);
};


export function checkKeyExpiration() {
  const now = new Date().getTime();
  const userEncryptionKeyExpirationTs = localStorage.getItem('user-encryption-key-exp-ts');

  if((userEncryptionKeyExpirationTs && now > userEncryptionKeyExpirationTs) || !userEncryptionKeyExpirationTs){
    return true
  }
  return false;
};


export function removeUserEncryptionKeyExpirationTs() {
  try {
    localStorage.removeItem('user-encryption-key-exp-ts');
  } catch (error) {
    console.error('Failed to remove user encryption key expiration timestamp:', error);
    return undefined;
  }
};

export function checkEncryptionKeyExists() {
  try {
    return localStorage.getItem('user-encryption-key')!= null ? true : false;

  } catch (error) {
    console.error('Failed to check if user encryption key exists:', error);
    return undefined;
  }
};

export function isPassPhraseValid(user, passphrase) {
  try {
    const derivedPassphrase = deriveEncryptionKey(passphrase, user.encryptionKeySalt);
    const cypherText = user.cypherText;
    const decryptedCypherText = decryptValue(cypherText, derivedPassphrase);
    return decryptedCypherText === config.encryption.knownPlainText
  } catch (error) {
    console.error('Failed to validate user passphrase:', error);
    return undefined;
  }
};