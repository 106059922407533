import { useState } from 'react';
import useModal from '../../../hooks/use-modal';

const useFocusMode = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const [modalContent, setModalContent] = useState('');

  return { isOpen, openModal, closeModal, modalContent, setModalContent };
};

export default useFocusMode;