import { useNavigate } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import './new-entry-menu.css'

const NewEntryMenu = () => {

  const navigate = useNavigate();

  // TBD: move actions to configuration file as this ui component should be config-agnostic!
  const actions = [
    { label: "Note", desc: "Short, informal notes for reminders or quick ideas.", icon: <NoteOutlinedIcon />, onClick: () => navigate('/app/notes/new')},
    { label: "Entry", desc: "Dated log documenting events, thoughts, or reflections.", icon: <DescriptionOutlinedIcon />, onClick: () => navigate('/app/entries/new') },
    { label: "Record", desc: "Structured data for formal documentation or tracking.", icon: <FolderOutlinedIcon />, onClick: () => navigate('/app/records/new') },  
  ];

  return (
    <div className="btn-group">
      <div className="toolbar-button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
        <CreateIcon />
      </div>
      <ul className="dropdown-menu shadow-lg">
        {actions.map((action, index) => (
          <li key={index}>
            <a key={index} className="dropdown-item new-entry-item-row" role="button" onClick={action.onClick}>
              <div className="new-entry-item-content">
                <div className="icon-container">
                  {action.icon}
                </div>
                <div className="text-content">
                  <div className="title">
                    {action.label}
                  </div>
                  <div className="desc">
                    {action.desc}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewEntryMenu;