import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import loadModule from '../../core/utils/moduleLoader';
const WarningFullScreen = loadModule('./components/warning/warning-fullscreen.js');

const Unauthorized = () => {
  return (
    <div className='page'>
        <WarningFullScreen icon={<LockOutlinedIcon style={{ fontSize: '2rem' }} />} title={"Unauthorized"} desc={"Link to homepage"} />
    </div>
  );
};

export default Unauthorized;