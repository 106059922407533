import React from 'react';
import ViewContentLayout from '../../../layouts/view/view-content-layout';
import PlaceholderCard from '../../../components/placeholders/placeholder-card';

const Panel = () => {
  return (
    <ViewContentLayout>
      <PlaceholderCard />
      <PlaceholderCard />
      <PlaceholderCard />
    </ViewContentLayout>
  );
};

export default Panel;
