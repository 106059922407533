import { dumpDatabase, restoreDatabaseDump, resetDatabase, exportSingleCollection, exportAllCollections, importCollections } from "../services/system-services";
import { getCurrentDatabase } from "../../../utils/databases-utils";
import { generateZipFileName } from "../../../utils/file-utils";

// MAIN FUNCTIONS
export const handleDumpDatabase = async (database) => {
  try {
    if (!database) {
      alert('Please select a database to dump.');
      return;
    };
    const blobData = await dumpDatabase(database);
    const fileType = "DB_DUMP";
    const fileName = generateZipFileName(database, fileType);
    downloadBlob(blobData, fileName);
    alert('Database dumped successfully!');
  } catch (error) {
    console.error('Error dumping database: ', error);
    alert('Error dumping database. Please try again.');  }
};

export const handleRestoreDatabase = async (importedFile, displayAlert = true) => {
  if (!importedFile) {
    alert('Please select a file to import.');
    return;
  };
  try {
    await restoreDatabaseDump(importedFile);
    // if (displayAlert) alert('Import successful!'); // TBD: replace by toast
  } catch (error) {
    console.error('Error restoring database: ', error);
    alert('Error restoring database. Please try again.');
  }
};

export const handleResetDatabase = async () => {
  try {
    await resetDatabase();
    alert('Database reset successfully!');
  } catch (error) {
    console.error('Error resetting database: ', error);
    alert('Error resetting database. Please try again.');
  }
};

export const handleExportSingleCollection = async (collection) => {
  try {
    const blobData = await exportSingleCollection(collection);
    const source = getCurrentDatabase();
    const fileType = "DATA_EXPORT";
    const fileName = generateZipFileName(source, fileType);
    downloadBlob(blobData, fileName);
  } catch (error) {
    console.error('Error exporting collection:', error);
    alert('Error exporting collection. Please try again.');
  }
};

export const handleExportAllCollections = async () => {
  try {
    const blobData = await exportAllCollections();
    const source = getCurrentDatabase();
    const fileType = "DATA_EXPORT";
    const fileName = generateZipFileName(source, fileType);
    downloadBlob(blobData, fileName);
  } catch (error) {
    console.error('Error exporting collections:', error);
    alert('Error exporting collections. Please try again.');
  }
};

export const handleImportCollections = async (importedFile, displayAlert = true) => {

  if (!importedFile) {
    alert('Please select a file to import.');
    return;
  }
  try {
    await importCollections(importedFile);
    // if (displayAlert) alert('Import successful!'); // TBD: replace by toast
  } catch (error) {
    console.error('Error importing database: ', error);
    alert('Error importing database. Please try again.');
  }
};

// UTILITY FUNCTIONS

// TBD: should we move this to generic utils folder? or keep it here since only used in this feature for now (but could change)?
const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
