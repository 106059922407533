import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/user-context';
import { isPassPhraseValid, setUserEncryptionKey } from '../../../utils/encryption-key';
import { logoutUser } from '../../../utils/auth';

const useEncryptionKeyModal = (loginMode) => {
  const { setIsUserEncryptionKeyLoaded, setIsLoading, user, setUser, theme } = useContext(UserContext);
  const [showModal, setShowModal] = useState(true);
  const [passphrase, setPassphrase] = useState('');
  const navigate = useNavigate();

  const handleSave = async () => {
    if (!user.isInitialized || isPassPhraseValid(user, passphrase)) {
      await setUserEncryptionKey(user, passphrase); // Generate derived key, encrypt it, and store it locally
      setIsUserEncryptionKeyLoaded(true);
      setShowModal(false);

      if (loginMode) navigate('/app/home');
    } else {
      alert("Invalid passphrase, try again");
      setPassphrase('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    }
  };

  const handleLogout = () => {
    logoutUser(setIsLoading, setUser);
  };

  return {
    showModal,
    passphrase,
    setPassphrase,
    theme,
    handleSave,
    handleKeyPress,
    handleLogout,
  };
};

export default useEncryptionKeyModal;
