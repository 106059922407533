// dataImportExport.js
import axios from "axios"
import loadConfig from "./configLoader";
import JSZip from 'jszip';
import { fetchDocumentsFromDbConcurrent } from "../services/database-services";
import { decryptCol } from "./data-encryption";
import { flattenDbDocuments, sortData, finalizeDataToExport} from './data-processing';
import { cacheUserData } from "./data-initialization";
import { clearUserData } from "./dataCleanup";
import { getCurrentDatabase } from "./databases-utils";
import { generateZipFileName } from "./file-utils";

const config = loadConfig('general');

// MAIN FUNCTIONS
export const exportCollections = async (collections, isExportProtected) => {
  try {
    const dataToExport = [];
    for (const collection of collections) {
      const fetchedData = await fetchDocumentsFromDbConcurrent([collection]);
      
      if (isExportProtected){
        const sortedData = await sortData(fetchedData, 'asc');
        const transformedData = sortedData.map((object) => {
          delete object['entity']; // TBD: remove after release 0.90
          delete object['collection']; // TBD: should we keep this instead of losing the info?
          return object;
        });
        dataToExport.push({collection: collection, data: transformedData });
      }
      else {
        const decryptedData = await decryptCol(fetchedData); 
        const transformedData = await flattenDbDocuments(decryptedData);
        const sortedData = await sortData(transformedData, 'asc');
        const finalizedData = finalizeDataToExport(sortedData);
        dataToExport.push({collection: collection, data: finalizedData });
      }
    }; 

    const source = getCurrentDatabase();
    const fileType = "DATA_EXPORT";
    const fileName = generateZipFileName(source, fileType);
    exportDataToZip(dataToExport, fileName);

  } catch (error) {
    console.error('Error exporting data:', error);
    alert('Error exporting data. Please try again.');
  }
};

export const exportUserData = async (mode, collectionsFilter) => {
  try {
    const collections = collectionsFilter ? collectionsFilter : config.export.collectionsToExport;
    const dataToExport = [];
    for (const collection of collections) {
      const fetchedData = await fetchDocumentsFromDbConcurrent([collection]);
      
      if (mode === 'encrypted'){
        const sortedData = await sortData(fetchedData, 'asc');
        const transformedData = sortedData.map((object) => {
          delete object['entity']; // TBD: remove after release 0.90
          delete object['collection']; // TBD: should we keep this instead of losing the info?
          return object;
        });
        dataToExport.push({collection: collection, data: transformedData });
      }
      else if (mode === 'plaintext') {
        const decryptedData = await decryptCol(fetchedData); 
        const transformedData = await flattenDbDocuments(decryptedData);
        const sortedData = await sortData(transformedData, 'asc');
        const finalizedData = finalizeDataToExport(sortedData);
        dataToExport.push({collection: collection, data: finalizedData });
      }
    }; 

    const source = getCurrentDatabase();
    const fileType = "DATA_EXPORT";
    const fileName = generateZipFileName(source, fileType);
    exportDataToZip(dataToExport, fileName);

  } catch (error) {
    console.error('Error exporting data:', error);
    alert('Error exporting data. Please try again.');
  }
};

export const importUserData = async (importedFile, user, displayAlert = true) => {

  if (!importedFile) {
    alert('Please select a file to import.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('file', importedFile);

    await axios.post('/api/user-admin/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    await cacheUserData(user); // necessary to recompute cache in-session after restore
    // if (displayAlert) alert('Import successful!'); // TBD: replace by toast

  } catch (error) {
    console.error('Error importing user data: ', error);
    alert('Error importing user data. Please try again.');
  }
};

export const restoreUserData = async (importedFile, user) => {
  if (!importedFile) {
    alert('Please select a file to import.');
    return;
  }

  try {
    await clearUserData(false);
    await importUserData(importedFile, user, false);
    alert('Backup restored successfully!');

  } catch (error) {
    console.error('Error restoring user data: ', error);
    alert('Error restoring user data. Please try again.');
  }
};

// UTILITY FUNCTIONS

// TBD: should we move this to generic utils folder? or keep it here since only used in this feature for now (but could change)?
const exportDataToZip = async (data, fileName) => {
  try {

    // EXPORT
    const zip = new JSZip();
      
    // Create a folder in the zip file
    const folder = zip.folder(fileName);

    // Add each object to the folder as a separate file
    data.forEach((collection, index) => {
      folder.file(`${collection.collection}.json`, JSON.stringify(collection.data, null, 2));
    });

    // Generate the zip file asynchronously
    const content = await zip.generateAsync({ type: "blob" });

    // Create a download link for the zip file
    const url = window.URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error exporting collections:', error);
    alert('Error exporting data. Please try again.');
  }
};