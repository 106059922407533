import React, { useState } from "react";
import { Card, ListGroup, Form } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';

const TodoListCard = () => {
  const title = "To-Do List";
  const todos = ["Finish React assignment", "Buy groceries", "Workout for 30 minutes", "Read a book"];

  // State to manage checked status of each item
  const [checkedItems, setCheckedItems] = useState(Array(todos.length).fill(false));

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
  };

  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <ListGroup>
          {todos.map((todo, index) => (
            <ListGroup.Item key={index} style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}>
              <div>
                <Checkbox
                  name={todo}
                  checked={checkedItems[index] || Math.random() < 0.5}
                  onChange={() => handleCheckboxChange(index)}
                  sx={{
                    '&.MuiCheckbox-root': {
                      marginLeft: '-9px',
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 28,
                    },
                    '&.Mui-checked': {
                      color: 'var(--color-text-primary)',
                    },
                  }}
                />
                <label className="form-check-label" htmlFor={todo}>
                  {todo}
                </label>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default TodoListCard;
