import React from 'react';
import DataForm from '../../../components/forms/data-form';
import useUserForm from '../hooks/use-user-form';


const UserSettingsForm = ({ user }) => {

  const { saveForm } = useUserForm(user._id);

  const fields = [
    { label: 'Profile', type: 'text', id: 'profile', disabled: true },
    { label: 'Language Preference', type: 'select', id: 'languagePreference', options: ['English', 'Spanish', 'French', 'German'] },
    { label: 'Default Theme', type: 'select', id: 'defaultTheme', options: ['Light', 'Dark'] },
    { label: 'Default Font Size', type: 'select', id: 'defaultFontSize', options: ['Small', 'Medium', 'Large'] },
    { label: 'Initialized?', type: 'checkbox', id: 'isInitialized', disabled: true },
    { label: 'Migrated?', type: 'checkbox', id: 'isMigrated', disabled: true },
  ].map(field => ({ ...field, value: user[field.id] || null }));

  return <DataForm fields={fields} onSubmit={saveForm} />;
};

export default UserSettingsForm;
