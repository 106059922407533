// TBD: not used for now, need to store those settings in the database, admin-data?
import React from 'react';
import DataForm from '../../../components/forms/data-form';


const SystemSettingsForm = () => {
  const fields = [
    { label: 'Mode', type: 'select', id: 'mode', options: ['Basic', 'Admin', 'Debugging'] },
    { label: 'Language Preference', type: 'select', id: 'languagePreference', options: ['English', 'Spanish', 'French', 'German'] },
    { label: 'Theme (default)', type: 'select', id: 'defaultTheme', options: ['Light', 'Dark']  },
    { label: 'Text Size (default)', type: 'select', id: 'defaultTextSize', options: ['Small', 'Medium', 'Large']  },
    { label: 'Database Backup', type: 'checkbox', id: 'dbBackup' },
    { label: 'End-to-End Encryption', type: 'checkbox', id: 'endToEndEncryption' },
  ];

  const handleSubmit = (formData) => {
    // include logic below to transform and save the data in the DB
    console.log('Settings form submitted:', formData);
  };

  return <DataForm fields={fields} onSubmit={handleSubmit} />;
};

export default SystemSettingsForm;
