// TBD: could use a generic form component for harmonization
import { Link } from 'react-router-dom';
import { useLoginForm } from '../hooks/use-login-form';
import './login.css'

const LoginForm = () => {

  const { form, updateForm, handleSubmit, googleAuth } = useLoginForm();
  
  return (
    <div className='form-container'>
      <div className="form-signin w-100 m-auto">
        {/* <div className='image-logo-container d-flex justify-content-center'>
          <img className="image-logo-large" src={logo} alt="App Logo" />
        </div> */}

        <form onSubmit={handleSubmit} >
          <h1 className='form-title'>Log in Form</h1>

          <div className="col-12 mb-2">
            <label htmlFor="email" className="form-label">Email</label>
            <input 
              type="email" 
              className="form-control" 
              id="email" 
              name="email" 
              value={form.email}
              onChange={(e) => updateForm({ email: e.target.value, username: e.target.value})}
              />
          </div>

          <div className="col-12 mb-2">
            <label htmlFor="password" className="form-label">Password</label>
            <input 
              type="password" 
              className="form-control" 
              id="password" 
              name="password" 
              value={form.password}
              onChange={(e) => updateForm({ password: e.target.value})}
              required />
          </div>

          <button className="btn btn-primary w-100 py-2 mt-3" type="submit">Sign in</button>    
        </form>
        <div className="google-btn">
          {<button className="login-with-google-btn mt-3 mb-3" onClick={googleAuth}>Sign in with Google</button>}
        </div>
        <div className="mx-auto" style={{width: "175px"}}>
          Not a Member? <Link to="/signup" className='hyperlink-std'>Sign Up</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

