// AdminData.js
// Import libraries
import React from 'react';
import { DataTable } from '../../mui-tables/mui-data-table';
import loadConfig from '../../../utils/configLoader';

const config = loadConfig('general');


const PossibleValuesTable = () => {
    const collection = 'possible-values';
    const options = {
      enableGrouping: true,
      groupingColumns: ['fieldCollection'],
      enablePagination: true,
      sorting: [
        {
          id: 'fieldCollection',
          desc: false,
        },
        {
          id: 'fieldName',
          desc: false,
        },
        {
          id: 'fieldValue',
          desc: false,
        },
      ]
    };
    const dataModel = [
      {
        name: "fieldCollection",
        header: "Field Collection",
        type: "list",
        colWidth: 180,
        collection: collection,
        values: config.admin.collections
          .filter((col) => col.name !== collection && col.hasPossibleValues && !col.isRestricted)
          .map((data) => data.name),
        defaultValue: "",
      },
      {
        name: "fieldName",
        header: "Field Name",
        type: "text",
        colWidth: 150,
      },
      {
        name: "fieldValue",
        header: "Field Value",
        type: "text",
        colWidth: 150,
      },
      {
        name: "isDefault",
        header: "Default?",
        type: "boolean",
        defaultValue: false,
        colWidth: 130,
      },
      {
        name: "isActive",
        header: "Active?",
        type: "boolean",
        defaultValue: true,
        colWidth: 130,
      },
    ];

  return (
    <DataTable 
      collection = {collection}
      dynamicQueryKey = {[collection]}
      options = {options}
      dataModel = {dataModel} 
    />
  )
};

export default PossibleValuesTable;