function searchObject(obj, searchText) {
    const results = [];
  
    function searchProperties(object, text) {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = object[key];
          if (typeof value === 'string' && value.toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
            results.push(object);
          } else if (typeof value === 'object') {
            searchProperties(value, text); // Recursively search nested objects
          }
        }
      }
    }
    searchProperties(obj, searchText);
    return results;
}

export function searchObjectsInArray(arrayOfObjects, searchText) {
    const results = [];
  
    arrayOfObjects.forEach((obj, index) => {
      const objResults = searchObject(obj, searchText);
      if (objResults.length > 0) {
        results.push(obj);
      }
    });
    return results;
}

export function searchObjectsWithTag(arrayOfObjects, tagname){
  const results = [];
  const key = "tags";
  tagname = tagname.toLocaleLowerCase(); 

  arrayOfObjects.forEach((object) => {
    if (object.hasOwnProperty(key) && object[key]) {
      const tags = object[key].map(elt => elt.name.toLocaleLowerCase());
      if (tags.includes(tagname)) results.push(object);
    };
  })
  return results;
}