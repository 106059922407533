import { useEffect, createContext, useState } from 'react';
import useLocaleStorage from '../hooks/use-local-storage';
import { authenticateUser } from '../utils/auth';
import { checkIfUserSessionIsInitialized, checkUserSessionExpiration, useInitializeUserSession } from '../utils/data-initialization';
import { checkKeyExpiration, removeUserEncryptionKeyExpirationTs, removeUserEncryptionkey } from '../utils/encryption-key';

import loadConfig from '../utils/configLoader';
const defaultUserParameters = loadConfig('user-parameters');
const UserContext = createContext();

const useUserState = () => {

  const initializeUserSession = useInitializeUserSession();

  // STATES
  //// the goal of each state is to propagate them across all components
  //// states synchronized with local storage - persistent across page reloads
  //// use localstore for non-sentive data OR encrypted data only!
  const [user, setUser] = useLocaleStorage('user', null);
  const [isUserEncryptionKeyLoaded, setIsUserEncryptionKeyLoaded] = useLocaleStorage('is-user-encryption-key-loaded', false);
  const [isUserSessionInitialized, setIsUserSessionInitialized] = useLocaleStorage('is-user-session-initialized', false);
  const [theme, setTheme] = useLocaleStorage('theme', 'light');
  const [isSafeguardOn, setIsSafeguardOn] = useLocaleStorage('is-safeguard-on', true);
  const [dailyDigest, setDailyDigest] = useLocaleStorage('daily-digest', null);
  const [ userParameters, setUserParameters ] = useLocaleStorage('user-parameters', null);


  //// regular states - not persistent across page reloads
  //// use regular state for sensitive data OR plain data!
  const [isLoading, setIsLoading] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [splashScreenMessage, setSplashScreenMessage] = useState("");
  const [splashScreenPc, setSplashScreenPc] = useState(0);

  // This trigger OOB Bootstrap themes across all elements
  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme);

  // TBD: fix this by overriding bootstrap variables globally using scss file
  if (theme === 'dark') {
    document.documentElement.style.setProperty('--color-modal-bg', '#272727'); // Dark mode color
  } else {
    document.documentElement.style.setProperty('--color-modal-bg', 'white'); // Light mode color
  }

  }, [theme]);
  
  // USER SESSION MANAGEMENT
  //// authenticate user and store it in state & local storage
  useEffect(() => {
    const checkUserAuthentication = async () => {
      if (!user || !user._id) {
        const authenticatedUser = await authenticateUser();
        setUser(authenticatedUser);
      }
    };
    checkUserAuthentication();
  }, []);

  //// initialize user session
  useEffect(() => {
    if (user && isUserEncryptionKeyLoaded && !isUserSessionInitialized) {
      setShowSplashScreen(true);
      initializeUserSession(
        user, 
        setSplashScreenMessage, 
        setSplashScreenPc,
        setIsUserSessionInitialized,
      );
    }
  }, [isUserEncryptionKeyLoaded]); // tiggered whenever encryption key is changed

  //// splash screen
  useEffect(() => {
    if (user && isUserEncryptionKeyLoaded && isUserSessionInitialized) {
      // Introduce a delay before hiding splash screen
      setTimeout(() => {
        setShowSplashScreen(false);
        setSplashScreenMessage('');
        setSplashScreenPc(0);
      }, 2000);
    }
  }, [isUserSessionInitialized]); // tiggered whenever user session has finished loaded

  // Loading default user parameters
  // IMPROVEMENT: save user parameters in persistent table to allow persistency across sessions
  useEffect(() => {
    const initializeUserParameters = () => {
      setUserParameters(defaultUserParameters);
    };
    if (!userParameters) {
      initializeUserParameters();
    }
  }, []); // triggered at every rendering but only initialized once

  useEffect(() => {
    const getFontSizeValue = (size) => {
      switch (size) {
        case 'small':
          return '14px';
        case 'medium':
          return '16px';
        case 'large':
          return '18px';
        default:
          return '16px'; // Default value if none specified
      }
    };
  
    // Apply the font size value from user parameters
    if (userParameters?.appearance?.fontSize) {
      const fontSizeValue = getFontSizeValue(userParameters?.appearance?.fontSize);
      document.documentElement.style.setProperty('--font-size', fontSizeValue);
    }
  }, [userParameters?.appearance?.fontSize]);
  

  //// check status of encryption key at regular intervals and reset when expired
  useEffect(() => {
    const intervalId = setInterval(() => {
      const isSafeguardOn = JSON.parse(localStorage.getItem("is-safeguard-on"));
      if (!isSafeguardOn) return; // Skip checking key expiration if safeguard is off
      const isUserEncryptionKeyExpired = checkKeyExpiration();
      if (isUserEncryptionKeyExpired) {
        removeUserEncryptionkey();
        removeUserEncryptionKeyExpirationTs();
        setIsUserEncryptionKeyLoaded(false); //update state only TBD
      }
    }, 15 * 1000); // Execute every 15 seconds

    return () => clearInterval(intervalId);
  }, []);

  // check status of user session at regular time intervals
  useEffect(() => {
    const intervalId = setInterval(() => {
      const isUserSessionExpired = checkUserSessionExpiration();
      if (isUserSessionExpired) {
        clearInterval(intervalId);
        localStorage.clear();
        setUser(null);
      }
    }, 15 * 1000); // Execute every 60 seconds

    return () => clearInterval(intervalId);
  }, []);


  return {
    isLoading, setIsLoading, 
    showSplashScreen, splashScreenMessage, setSplashScreenMessage, splashScreenPc,
    user, setUser, 
    theme, setTheme, 
    isUserEncryptionKeyLoaded, setIsUserEncryptionKeyLoaded,
    isSafeguardOn, setIsSafeguardOn,
    dailyDigest, setDailyDigest,
    userParameters, setUserParameters,
  };
};


const UserProvider = ({ children }) => {
  const userState = useUserState();

  return (
    <UserContext.Provider value={userState}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };