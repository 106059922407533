import React from 'react';
import useFetchHTML from '../hooks/useFetchHTML';
import '../styles/static-html.css'; // Import the styles here

const StaticHTML = ({ htmlFile }) => {
  const { htmlString, error } = useFetchHTML(htmlFile);

  if (error) {
    return <div>Error loading the HTML file. Please try again later.</div>;
  }

  return (
    <div className='policies-container' dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

export default StaticHTML;
