import './action-row.css';

const FileUploadActionRow = ({icon, title, titleColor, description, zipFileUpload, buttonName, buttonClass, handleAction, isLast}) => {
  return (
    <div>
      <div className="action-row">
        {icon && 
          <div className="action-col icon">
            {icon}
          </div>    
        }
        <div className="action-col text">
          <h4 className="action-title" style={{color: titleColor}}>{title}</h4>
          <p className="action-desc">{description}</p>

          <div className="col-md-6">
            {/* handle file upload */}
            {zipFileUpload && <input type="file" className="action-field" accept=".zip" onChange={zipFileUpload} />}
          </div>
          
        </div>
        <div className="action-col button">
          <button type="button" className= {buttonClass ? buttonClass : "btn btn-primary"} onClick={handleAction}>{buttonName}</button>
        </div>
      </div>
      {isLast ? null : <hr className="hr" />}
    </div>
  )
};


export default FileUploadActionRow;