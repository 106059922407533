// TBD: move to flex-form feature as it is only used there.
// TBD: replace this static file by dynamic feature maintainable in the front end

// internal attributes, set in the backed when entries are created/updated
// those attributes are NOT encrypted
export const internal = [
  { label: 'Object ID', type: 'string', name: '_id', disabled: true },
  { label: 'Creation Date', type: 'date', name: 'creationDate', disabled: true },
  // { label: 'Created By', type: 'string', name: 'createdBy', disabled: true },
  { label: 'Last Modification Date', type: 'date', name: 'lastModificationDate', disabled: true },
  // { label: 'Last Modified By', type: 'string', name: 'lastModifiedBy', disabled: true },
  // { label: 'Is System', type: 'boolean', name: 'isSystem', disabled: true },
  // { label: '__v', type: 'number', name: '__v', disabled: true },
];

// core attributes are used for all entries regarding their type
// core attributes are added to the end and ARE encrypted
export const core = [
  { label: 'Type', type: 'custom-select', name: 'type' },
  { label: 'Template', type: 'custom-select', name: 'template' },
  { label: 'Date', type: 'date', name: 'date' },
  { label: 'Tags', type: 'custom-multiselect', name: 'tags' },
  { label: 'Is Template', type: 'boolean', name: 'isTemplate' },
  { label: 'Source', type: 'custom-select', name: 'source' },
  { label: 'URL', type: 'url', name: 'url' },
];

// specific attributes are driven by the entry type
// specific attributes ARE encrypted
export const specific = {
  // NOTES
  "Fleeting Note": [
  ],
  "Permanent Note": [
  ],
  "Index Note": [
  ],
  "Literature Note": [
    { label: 'Book', type: 'string', name: 'book' },
  ],

  // JOURNAL ENTRIES
  "Personal Journal": [
    // { label: 'Mood', type: 'select', name: 'mood', options: ['Happy', 'Sad', 'Excited', 'Relaxed', 'Anxious']},
  ],
  "Travel Journal": [
    { label: 'Location', type: 'string', name: 'destination' },
    { label: 'Weather', type: 'string', name: 'destination' },
  ],
  "Work Journal": [
  ],

  // RECORDS
  "Running": [
    { label: 'Time', type: 'time', name: 'time' },
    { label: 'Distance', type: 'string', name: 'distance' },
    { label: 'Duration', type: 'string', name: 'duration' },
    { label: 'Pace', type: 'string', name: 'pace' },
    { label: 'Average HR', type: 'number', name: 'averageHr' },
    { label: 'Location', type: 'string', name: 'location' },
  ],
  "Workout": [
    { label: 'Time', type: 'time', name: 'time' },
    { label: 'Session', type: 'custom-select', name: 'session', options: [{ value: 'Legs', label: 'Legs' }] },
    { label: 'Intensity', type: 'string', name: 'intensity' },
    { label: 'Energy', type: 'string', name: 'energy' },
  ],
};
