import { data_migration_client_side_release090 } from '../../../utils/data-migration';

export const runClientMigration = async () => {
  try {
    // Note: client side migration triggered when user logs in now, now manually from system page
    await data_migration_client_side_release090();
  } catch (error) {
    console.error(error);
    alert("An error occurred during server-side migration.");
  }
};