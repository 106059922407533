import React, { useContext } from 'react';
import { UserContext } from '../../contexts/user-context';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

const ThemeToggle = () => {
  
  const { theme, setTheme } = useContext(UserContext);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme==='dark' ? 'light' : 'dark');
  };
  
  return (
    <div className='theme-toggle'>
      <button
        onClick={toggleTheme}
        className="btn d-flex align-items-center justify-content-center"
      >
        {theme==='dark' ? <LightModeOutlinedIcon className='navbar-option-bar-icon'/> : <DarkModeOutlinedIcon className='navbar-option-bar-icon'/>}
      </button>
    </div>
  )
};

export default ThemeToggle;