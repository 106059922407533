import { useState } from "react";
import { getCurrentDatabase } from "../../../utils/databases-utils";

const useSourceDbSelected = () => {

  const [ sourceDbSelected, setSourceDbSelected ] = useState(getCurrentDatabase());
  
  const handleSourceDbSelected = (event) => {
    setSourceDbSelected(event.target.value);
  }

  return {
    sourceDbSelected,
    handleSourceDbSelected,
  };
};

export default useSourceDbSelected;