import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import { sortData } from "../../../utils/data-processing";
import { getUserParametersForView } from "../../../utils/caching";

const useSorting = (viewId, data) => {

  const [isLoading, setIsLoading] = useState(true);
  const [ sortedData, setSortedData ] = useState([]);
  const { userParameters } = useContext(UserContext);

  useEffect(() => {
    const sortCurrentData = async () => {
      try {       
        const sortDirection = getUserParametersForView(userParameters, viewId, 'sort');
        const sortedData = await sortData(data, sortDirection);
        setSortedData(sortedData);
      } catch (error) {
        console.error('Failed to sort data: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    sortCurrentData();
  }, [viewId, data, userParameters?.[viewId]?.['sort']]); 

  return { sortedData, isLoading };

};

export default useSorting;