// Direct Imports - Protected Modules
import useAccessRequest from '../hooks/use-access-request';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { LongText, LargeImage, ImageGrid, CardsGrid } from '../../../testing/components/test-components';

// Dynamic Imports - Core Components That Can Be Customized
import loadConfig from '../../../utils/configLoader';
import loadModule from '../../../utils/moduleLoader';
const config = loadConfig('general');
loadModule('./features/visitor/components/content.css');

const Hero = () => {

  const { setEmail, accessRequested, handleAccessRequest } = useAccessRequest();

  return (
    <section className='section-container' id='hero'>
      <div className="section-content hero">
        {/* pulling a big image here to test css styles resize as expected */}
        <img src="https://via.placeholder.com/2000" alt="App Screenshot" />
        <div className='app-info'>
          <h3>Welcome to <strong>{config.brandName}</strong> </h3>
          <p><strong>{config.brandName}</strong> is a cutting-edge SaaS product designed to revolutionize the way you manage your business. With our intuitive platform, you can streamline workflows, automate repetitive tasks, and enhance collaboration across teams. <strong>{config.brandName}</strong> provides real-time insights and analytics to help you make data-driven decisions effortlessly. Experience unparalleled efficiency and productivity with <strong>{config.brandName}</strong>, the ultimate solution for your business needs.</p>
        </div>
        <div className='call-for-action'>
          <form onSubmit={handleAccessRequest} className='access-request'>
            <p><strong>Access to {config.brandName} is currently by invitation only.</strong> Join our waitlist today, and we’ll notify you as soon as access is available. We appreciate your interest and look forward to welcoming you onboard soon!</p>
            {/* <label htmlFor="email" className="form-label">Email address</label> */}
            <div className='access-request-email'>
              <input 
                type="email" 
                className="form-control" 
                id="email" 
                placeholder="name@example.com"
                aria-describedby="emailHelp"
                required
                // autoComplete='off'
                onChange={(e) => setEmail( e.target.value)}
              ></input>
              { accessRequested ? 
                <button type="button" className="btn btn-success btn-md">
                  Access Requested
                </button>
                : 
                <button type="submit" className="btn btn-warning btn-md">
                  <FlashOnIcon />
                  Join Waitlist
                </button>
              }
            </div>
            {/* <div id="emailInfo" className="form-text">We'll never share your email with anyone else.</div> */}
          </form>
        </div>
      </div>
    </section>
  );
};

const Features = () => {
  return (
    <section className='section-container' id='features'>
      <div className="section-content features">
          <h2>Features</h2>
          <ul>
              <li>Feature 1: Amazing functionality</li>
              <li>Feature 2: User-friendly interface</li>
              <li>Feature 3: Secure and reliable</li>
              <li>Feature 3: Secure and reliable</li>
              <li>Feature 3: Secure and reliable</li>
              <li>Feature 3: Secure and reliable</li>
              <li>Feature 3: Secure and reliable</li>
              <li>test length: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
          </ul>
      </div>
    </section>

  );
};

const OurValues = () => {
  return (
    <section className='section-container' id='values'>
      <div className="section-content our-values">
          <h2>Our Values</h2>
          <ul>
              <li>Transparency: We operate with honesty and integrity in everything we do.</li>
              <li>Innovation: We continuously strive to improve and innovate our products.</li>
              <li>Customer Satisfaction: Our customers are at the heart of our business.</li>
              <li>Quality: We ensure the highest standards of quality in our products and services.</li>
              <li>Teamwork: We believe in the power of collaboration and teamwork.</li>
              <li>Respect: We treat everyone with respect and dignity.</li>
              <li>Sustainability: We are committed to sustainable business practices.</li>
              <li>test length: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
          </ul>
      </div>
    </section>

  );
};

const Testimonials = () => {
  return (
    <section className='section-container' id='testimonials'>
      <div className="section-content testimonials">
          <h2>Testimonials</h2>
          <blockquote>
              "This app has changed my life for the better!" - Happy Customer
          </blockquote>
          <blockquote>
              "Incredible features and easy to use." - Satisfied User
          </blockquote>
          <blockquote>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." - Satisfied User
          </blockquote>
      </div>
    </section>

  );
};

const Projects = () => {
  return (
    <section className='section-container' id='projects'>
      <div className="section-content projects">
          <h2>Projects</h2>
          <CardsGrid />
      </div>
    </section>

  );
};

const VisitorContent = () => {
  return (
    <div className='visitor-content'>
      <Hero />
      <Features />
      <OurValues />
      <Testimonials />
      <Projects />
    </div>
  )
};

export default VisitorContent;