import React from 'react';
import StaticHTML from './static-html';
import cookiePolicyHtml from '../html-files/cookie_policy_template.txt';

const CookiePolicy = () => {
  return (
    <div>
      <StaticHTML htmlFile={cookiePolicyHtml} />
    </div>
  );
};

export default CookiePolicy;
