import React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import "./tools.css"

export const AdvancedSort = ({ sortDesc, setSortDesc }) => {

  const handleClick = (event) => {
    setSortDesc((prev) => !prev);
  };

  return (
    <div className="clickable-tool-small" onClick={(event) => handleClick(event)}>
      {sortDesc && <ArrowUpwardIcon />}
      {!sortDesc && <ArrowDownwardIcon />}
    </div>
  )
};
