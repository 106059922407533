// UserRecordsTable.js
// Import libraries
import React from 'react';

// Custom Components
import { DataTable } from '../../mui-tables/mui-data-table';
import { getCollectionCategories } from "../../../utils/caching";


const table = () => {
    const collection = 'user-records';
    const options = {
      enableCreation: false,
      enableGrouping: false,
      groupingColumns: null,
      enablePagination: true,
      sorting: [
        {
          id: 'date',
          desc: true,
        },
      ],
      columnVisibility: {
      }
    };
    const dataModel = [
      // {
      //   name: 'type',
      //   type: 'object',
      //   colWidth: 180,
      //   entity: 'admin-data',
      //   values: [null, ...getCollectionCategories('user-entry-type')],
      //   defaultValue: '',
      // },
      {
        name: 'title',
        type: 'text',
        colWidth: 210,
      },
      {
        name: 'content',
        type: 'text', //used to be 'textarea'
        colWidth: 600,
      },
      {
        name: 'link',
        type: 'text',
        colWidth: 180,
      },
      {
        name: 'date',
        type: 'date',
        colWidth: 80,
      },
      {
        name: 'isFavorite',
        header: 'Favorite?',
        type: 'boolean',
        defaultValue: false,
        colWidth: 120,
      },
      {
        name: 'isActive',
        header: 'Active?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 110,
      },
    ];

  return (
    <DataTable 
      collection = {collection}
      dynamicQueryKey = {[collection]}
      options = {options}
      dataModel = {dataModel} 
    />
  )
};

export default table;