// instructions: add old migration functions below for tracking purposes
import axios from 'axios';
import { decryptCol, encryptCol, encryptObject } from './data-encryption';

export async function data_migration_client_side_release080(){
  try{
    const releaseName = "Release 0.80";
    const migrationDescription = "Goal of the migration here: update relations on all collections";
    console.log(`${releaseName} - ${migrationDescription}`);
    console.log("Starting data migration");

    const transformEntries = async () => {
      try {
        const transformCollection = async (collection, fields) => {
          const response = await axios.get(`/api/data-migration/${collection}/`);
          const documents = response.data.documents;
          const rawData = decryptCol(documents);

          let adminData ;

          if (fields.includes('category')){
            // cache admin data for relations
            const collection='admin-data'
            const response = await axios.get(`/api/data-migration/${collection}/`);
            const documents = response.data.documents;
            adminData = decryptCol(documents);
          }
  
          const updatedEntries = {};
  
          for (const entry of rawData) {
            for (const fieldName of fields) {
              if (entry[fieldName]){
                const prevValue = entry[fieldName];
                let newValue; // this is the converted value with the new format
                
                if (fieldName === 'category') {
                  const originalObject = Object.values(adminData).find(data => data._id === prevValue)
                  if (originalObject) {
                    newValue = {
                      _id: originalObject._id,
                      name: originalObject.name,
                    };
                  } else {
                    console.log(`Could not find original object for entry ${entry.name}, fieldName: ${fieldName}, value: ${prevValue} on collection: ${collection}`);
                  }

                } 
                else if (fieldName === 'habits') {
                  newValue = prevValue.map((habit) => {
                    return [
                      {
                        _id: habit._id, 
                        name: habit.name,
                      }, {
                        value: habit.value
                      }
                    ]
                  });
                  
                } else if (fieldName === 'origin' || fieldName === 'template') {
                  newValue = {
                    _id: prevValue._id,
                    name: prevValue.title,
                  };
                }

                updatedEntries[entry._id] = {
                  ...updatedEntries[entry._id],
                  _id: entry._id, 
                  [fieldName]: newValue,
                  system: entry.system, // passing this to ensure it's encrypted properly later
                };

              } else {
                console.log(`${fieldName} does not exist on collection: ${collection}`);
              }
            }
          };
  
          let count = 0;
          for (const updatedEntry of Object.values(updatedEntries)) {
            try {
              // encrypt obj with user key
              const encryptedNewObject = encryptObject(updatedEntry);
  
              // post new object
              await axios.put(`/api/data-migration/${collection}/${encryptedNewObject._id}`, encryptedNewObject);

              count++
  
            } catch (error) {
              console.error(`Error modifying entry: ${updatedEntry} : ${error.message}`);
            }
          };
          console.log(`Successfully migrated ${count} entries on collection ${collection}`);
        }
        // list out collections that should be updated
        // await transformCollection('admin-data', ['type']); // not needed for now since there is no object for the 4 groupings - should we include them as well?
        await transformCollection('tags', ['category']);
        await transformCollection('habits', ['category']);
        await transformCollection('user-entries', ['habits', 'origin', 'template']);
        await transformCollection('user-records', ['origin', 'template']);

      } catch(error) {
        console.error('Failed to transform entries: ', error);
      }
    }
    await transformEntries();

    console.log("Data migration completed successfully: data_migration_client_side_release80");

  } catch (error){
    console.error('Data Migration failed', error);
    throw error; 
  }
}

// Release 0.77: simplify how habits are stored on entries to reduce disk usage
export async function data_migration_client_side_release077(user, key){
  try{
    console.log("Starting data migration: data_migration_client_side_release077");

    const transformEntries = async (key) => {
      try {
        console.log("Starting transforming entries - reformating habits")

        const transformTypeOnCollection = async (collection) => {
          const response = await axios.get(`/api/data-migration/${collection}/`);
          const documents = response.data.documents;
          const rawData = decryptCol(documents, key);
  
          const updatedEntries = [];
  
          for (const entry of rawData) {
            if(entry.habits && entry.habits.length > 0){
              const transformedHabits = entry.habits.map((habit) => {
                return {
                  _id: habit._id,
                  name: habit.name,
                  value: habit.result === "Positive" ? true : habit.result === "Negative" ? false : null
                }
              });
  
              updatedEntries.push({
                _id: entry._id,
                habits: transformedHabits,
              });
            }
          };
  
          for (const updatedEntry of updatedEntries) {
            try {
              // encrypt obj with user key
              const encryptedNewObject = encryptObject(updatedEntry, key);
  
              // post new object
              const response = await axios.put(`/api/data-migration/${collection}/${encryptedNewObject._id}`, encryptedNewObject);
              const entry = response.data.document;
  
            } catch (error) {
              console.error(`Error modifying entry: ${updatedEntry} : ${error.message}`);
            }
          };
        }
        await transformTypeOnCollection('user-entries');

      } catch(error) {
        console.error('Failed to transform habits on entries', error);
      }
    }

    // Execute migration 
    await transformEntries(key);

    console.log("Data migration completed successfully: data_migration_client_side_release077");

  } catch (error){
    console.error('Data Migration failed', error);
    throw error; 
  }
}

// FEB 19 2024
// Release 0.76 and 0,76b: fix favorite flags on admin tables
export async function data_migration_client_side_release076(user, key){
  try{
    console.log("Starting data migration: data_migration_client_side_release076");

    const fixFavoriteFlag = async (userEncryptionKey) => {
      try {
        console.log("Fixing format of Favorite flag on collections")
    
        const fixFavoriteFlagOnCollection = async (collection) => {
          console.log(`Fixing collection: ${collection}`);
          let response;
          if (user.isAdministrator) {
            response = await axios.get(`/api/data-migration/encryption/system-data/${collection}`);
          } else {
            response = await axios.get(`/api/data-migration/encryption/user-data/${collection}`);
          }
          const documents = response.data.documents;
          const rawData = decryptCol(documents, userEncryptionKey);
    
          const updatedEntries = [];
    
          for (const entry of rawData) {
            if (entry.favorite !== true) {
              //console.log(`Handling non-true value: ${entry.favorite} of type ${typeof entry.favorite} for entry: ${entry._id}`);
              if (entry.favorite === "true") {
                updatedEntries.push({
                  ...entry,
                  favorite: true,
                });
              } else {
                updatedEntries.push({
                  ...entry,
                  favorite: false,
                });
              }
            }
          }
    
          for (const updatedEntry of updatedEntries) {
            try {
              // encrypt obj with user key
              const encryptedNewObject = encryptObject(updatedEntry, userEncryptionKey);
    
              // post new object
              let response;
              if (user.isAdministrator) {
                response = await axios.put(`/api/data-migration/encryption/system-data/${collection}/${encryptedNewObject._id}`, encryptedNewObject);;
              } else {
                response = await axios.put(`/api/data-migration/encryption/user-data/${collection}/${encryptedNewObject._id}`, encryptedNewObject);
              }

              const entry = response.data.document;
              // console.log(`Entry modified successfully for entry: ${entry}`);
    
            } catch (error) {
              console.error(`Error modifying entry: ${updatedEntry} : ${error.message}`);
            }
          }
        }
        await fixFavoriteFlagOnCollection('admin-data');
        await fixFavoriteFlagOnCollection('habits');
        await fixFavoriteFlagOnCollection('tags');
    
      } catch(error) {
        console.error('Failed to fix favorite flag on collections.', error);
      }
    }

    // Execute migration 
    await fixFavoriteFlag(key);

    console.log("Data migration completed successfully: data_migration_client_side_release076");

  } catch (error){
    console.error('Data Migration failed', error);
    throw error; 
  }
}


// JAN 25 2024 - RELEASE 0.75
// This migration is design to rename the field "deleted" "archived" in all collections
export async function data_migration_client_side_release075(user, key){
  try{
    console.log("Starting data migration: data_migration_client_side_release075");

    // Rename deleted field -> archived
    const renamedDeletedField = async () => {
      try {
        console.log("Starting renaming of deleted field")

        const collections = ["user-entries", "user-records", "admin-data", "tags", "habits"];
        for (const collection of collections){
          try {
            await axios.post(`/api/data-migration/update-collection/${collection}/`);
            console.log(`Data migrated successfully for collection: ${collection}`);
          } catch (error) {
            console.error(`Error migrating data for collection: ${collection} : ${error.message}`);
          }
        }

      } catch(error) {
        console.error('Failed to renamed deleted field', error);
      }
    }

    // Transforming entries - reformating type field and removing prefix from title
    const transformEntries = async () => {
      try {
        console.log("Starting transforming entries - reformating type field and removing prefix from title")

        const response = await axios.get(`/api/data-migration/admin-data`);
        const documents = response.data.documents;
        const rawAdminData = decryptCol(documents, key);

        const getTypeName = (typeId) => {
          const type = rawAdminData.find((data) => data._id === typeId);
          const typeName = type ? type.name : undefined;
          return typeName;
        }


        const transformTypeOnCollection = async (collection) => {
          const response = await axios.get(`/api/data-migration/${collection}/`);
          const documents = response.data.documents;
          const rawData = decryptCol(documents, key);
  
          const updatedEntries = [];
  
          for (const entry of rawData) {
            if (true) {
              const typeId = entry.type;
              const typeName = getTypeName(typeId);
              const type = {
                _id: typeId,
                name: typeName,
              }
              const newTitle = entry.title.substring(9);

              updatedEntries.push({
                _id: entry._id,
                title: newTitle,
                type: type,
              });
            }
          };
  
          for (const updatedEntry of updatedEntries) {
            try {
              // encrypt obj with user key
              const encryptedNewObject = encryptObject(updatedEntry, key);
  
              // post new object
              const response = await axios.put(`/api/data-migration/${collection}/${encryptedNewObject._id}`, encryptedNewObject);
              const entry = response.data.document;
  
            } catch (error) {
              console.error(`Error modifying entry: ${updatedEntry} : ${error.message}`);
            }
          };
        }
        await transformTypeOnCollection('user-entries');
        await transformTypeOnCollection('user-records');

      } catch(error) {
        console.error('Failed to transform type field', error);
      }
    }


    // Execute migration 
    await renamedDeletedField();
    await transformEntries();

    console.log("Data migration completed successfully: data_migration_client_side_release075");

    // Rename collection admin-data-system to admin-data - NOT WORKING because of admin rights
    // const collectionOld = 'admin-data-system';
    // const collectionNew = 'admin-data';
    // try {
    //   await axios.post(`/api/data-migration/rename-collection/${collectionOld}/${collectionNew}/`);
    //   console.error(`Collection renamed successfully: ${collectionNew}`);
    // } catch (error) {
    //   console.error(`Error renaming collection: ${collectionOld} : ${error.message}`);
    // }

  } catch (error){
    console.error('Data Migration failed', error);
    throw error; 
  }
}


// JAN 17 2024 - RELEASE 0.74
// This migration script is designed to duplicate admin data for user JM so data can be properly pushed to the new model 

// SEQUENCE
// 1. Load plain-text admin data
// 2. Delete old admin data to prevent duplicate IDs
// 3. Transform objects: remove fields, update system, updated last modif, createdby
// 3b. Transform entry types and record types
// 4. Encrypt new object with user key
// 5. Create new objects to appropriate collection
// 6. Duplicate new records - remove user and mark as system = true

export async function data_migration_client_side_release074(user, key){
  try{
    console.log("Starting data migration");

    const migrateMyAdminData = async () => {

      console.log("Starting to migrate current user's admin data: lyft and shift method.");
      // 1. fetch data from database
      const response = await axios.get(`/api/data-migration/admin-data`);
      const input = response.data.documents;

      if (input.length > 0) {
        // 2. process data    
        // decrypt payload
        const decryptedCollection = decryptCol(input, key);

        // 2. Delete old admin data
        async function deleteOldData(collection, decryptedCollection) {
          for (const obj of decryptedCollection) {
            try {
              await axios.delete(`/api/data-migration/${collection}/${obj._id}`);
              // console.log(`Data deleted successfully for collection: ${collection} and ID: ${obj._id}`);
            } catch (error) {
              console.error(`Error creating data for collection: ${collection} : ${error.message}`);
            }
          }
        }
        await deleteOldData('admin-data', decryptedCollection);
        
        // transform payload, 3 use cases habit, tags, and everthing else = adminData
        const adminData = decryptedCollection
          .filter((obj) => (obj.type !== 'habit' && obj.type !== 'tag'))
          .map((obj) => {
            const { beneficial, detrimental, weight, priority, frequency, _id,  system, ...rest } = obj; // we want to remove fields:  beneficial, detrimental, weight, priority, frequency  
            return { 
              _id,
              system: false,
              createdBy: user._id,
              lastModifiedBy: user._id,
              ...rest,
            }
          });

        const habits = decryptedCollection
          .filter((obj) => obj.type === 'habit')
          .map((obj) => {
            const { _id, system, ...rest } = obj;
            return { 
              _id,
              system: false,
              createdBy: user._id,
              lastModifiedBy: user._id,
              ...rest,
            }
          });

        const tags = decryptedCollection
          .filter((obj) => obj.type === 'tag')
          .map((obj) => {
            const { beneficial, detrimental, weight, priority, frequency, _id,  system, ...rest } = obj; // we want to remove fields:  beneficial, detrimental, weight, priority, frequency  
            return { 
              _id,
              system: false,
              createdBy: user._id,
              lastModifiedBy: user._id,
              ...rest,
            }
          });

        // Update type value for user-entry and user-record
        adminData.forEach((data, index) => {
          if (data.type === 'user-entry') adminData[index].type = 'user-entry-type';
          else if (data.type === 'user-record') adminData[index].type = 'user-record-type';
        });


        // 3. encrypt payloads
        const habitsEncrypted = encryptCol(habits, key);
        const tagsEncrypted = encryptCol(tags, key);
        const adminDataEncrypted = encryptCol(adminData, key);
        

        // 4. push new object to database
        async function createData(collection, encryptedCollection) {
          for (const obj of encryptedCollection) {
            try {
              await axios.post(`/api/data-migration/${collection}/`, obj);
              // console.log(`Data created successfully for collection: ${collection} and ID: ${obj._id}`);
            } catch (error) {
              console.error(`Error creating data for collection: ${collection} : ${error.message}`);
            }
          }
        }
        await createData('habits', habitsEncrypted);
        await createData('tags', tagsEncrypted);
        await createData('admin-data', adminDataEncrypted);

        console.log("Migration of current user's admin data completed successfully");

      }
    };

    const createNewSystemData = async () => {
      try {
        console.log("Starting to migrate system's data: lyft, transform and shift method.");

        const collections = ['admin-data', 'tags', 'habits'];
        const mappingOldNew = {}; // {oldId: newId};

        for (const collection of collections) {
          const response = await axios.get(`/api/data-migration/${collection}`);
          const input = response.data.documents;
          const decryptedCollection = decryptCol(input, key);
          
          for (const obj of decryptedCollection) {
            try {
              // transform data
              const { _id, system, category, ...rest } = obj; 
              const oldId = _id;
              const newObj = {
                ...rest,
                system: true,
                category: mappingOldNew[category], // swap oldId with new id just created previously
                createdBy: null, // deliberate absence of a value for system data
                lastModifiedBy: null, // deliberate absence of a value for system data
              }

              // encrypt obj with user key
              const encryptedNewObject = encryptObject(newObj, key);

              // post new object
              const response = await axios.post(`/api/data-migration/${collection}/`, encryptedNewObject);
              
              // update mapping to keep track of old vs new ids
              const newId = response.data.document._id;
              mappingOldNew[oldId] = newId;

              // console.log(`Data created successfully for collection: ${collection}`);
            } catch (error) {
              console.error(`Error creating data for collection: ${collection} : ${error.message}`);
            }
          }
        }
        console.log("Migration of system's data completed successfully");

      } catch (error) {
        console.error("Failed to migrate system's data", error);
      }
    };

    const setInitialize = async () => {
      try {
        await axios.put(`/api/user/${user._id}`, {initialized: true});
        console.log("Set initialized flag on user.")

      } catch(error) {
        console.error('Failed to set initialized flag on user.', error);
      }
    }

    const fixFavoriteFlag = async () => {
      try {
        console.log("Starting entry conversion to fix favorite flag")

        const fixFavoriteFlagOnCollection = async (collection) => {
          const response = await axios.get(`/api/data-migration/${collection}/`);
          const documents = response.data.documents;
          const rawData = decryptCol(documents, key);
  
          const updatedEntries = [];
  
          for (const entry of rawData) {
            if (entry.favorite !== true) {
              // console.log(`Handling non-true value: ${entry.favorite} of type ${typeof entry.favorite} for entry: ${entry._id}`);
              if (entry.favorite === "true") {
                updatedEntries.push({
                  ...entry,
                  favorite: true,
                });
              } else {
                updatedEntries.push({
                  ...entry,
                  favorite: false,
                });
              }
            }
          }
  
          for (const updatedEntry of updatedEntries) {
            try {
              // encrypt obj with user key
              const encryptedNewObject = encryptObject(updatedEntry, key);
  
              // post new object
              const response = await axios.put(`/api/data-migration/${collection}/${encryptedNewObject._id}`, encryptedNewObject);
              const entry = response.data.document;
              // console.log(`Entry modified successfully for entry: ${entry}`);
  
            } catch (error) {
              console.error(`Error modifying entry: ${updatedEntry} : ${error.message}`);
            }
          }
        }
        await fixFavoriteFlagOnCollection('user-entries');
        await fixFavoriteFlagOnCollection('user-records');

      } catch(error) {
        console.error('Failed to set initialized flag on user.', error);
      }
    }

    // Execute migration 
    await migrateMyAdminData();
    await createNewSystemData();
    await setInitialize();
    await fixFavoriteFlag();

    console.log("End of data migration");

  } catch (error){
    console.error('Data Migration failed', error);
    throw error; 
  }
}