import { fetchDocumentFromDatabase, createDocumentInDatabase, deleteDocumentFromDatabase } from '../services/database-services';

export const deleteEntry = async (collection, id) => {
  await deleteDocumentFromDatabase(collection, id); 
  // navigate(`/app/home`); //not working, not refreshing the page
  window.location.href = `/app/home`; 
};

export const duplicateEntry = async (collection, id, navigate)  => {
  const originDocument = await fetchDocumentFromDatabase(collection, id);
  const { _id, ...newEntry } = originDocument;
  // TBD: set originDocument.date to be the date of the day
  const newDocument = await createDocumentInDatabase(collection, newEntry);
  navigate(`/app/${collection}/edit/${newDocument._id}`);
};