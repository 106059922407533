import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import EntryDropdown from './entry-dropdown-menu';
import { getEntryTitle } from '../utils/get-entry-title';
import "./data-entry-block.css";

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const OpenPageInNewTabTool = loadModule("./components/tools/open-page-in-new-tab.js");
const OpenLinkInNewTabTool = loadModule("./components/tools/open-link-in-new-tab.js");


const DataEntryBlock= (props) => {

  const url = `/app/${props.object.collection}/edit/${props.object._id}`;
  const navigate = useNavigate();

  // const activeTags = props.object.tags?.filter((tag) => getDataFromCache('tags', tag)?.isActive);
  const activeTags = props.object.tags;

  const handleCardClick = () => {
    navigate(url);
  };

  const handleTagClick = (event, tagName) => {
    event.stopPropagation();
    navigate(`/app/search?tag=${tagName}`);
  }

  const mode = "block"; // TBD: testing different mode, need to sync CSS if needed

  return (
    <div className={`data-entry-block ${mode}`} onClick={handleCardClick}>
        <div className='data-entry-block-header'>
          <div className='data-entry-block-top-row'>
            <div className='data-entry-block-date'>
              {moment(props.object.date).format('dddd, MMMM D, YYYY')}
            </div>
            <div className="data-entry-block-toolbar">
              <OpenPageInNewTabTool url={url} />
              <EntryDropdown collection={props.object.collection} id={props.object._id} />
            </div>
          </div> 
          <div className='data-entry-block-title'>{getEntryTitle(props.object)}</div>
          {props.type ?
            <div className='data-entry-block-type'>
            {/* <div className='data-entry-block-type' style={{backgroundColor: props.type.color ? props.type.color : '#e6e6e6'}}> */}
              <p>{props.type.value}</p>
            </div>
            : null
          }
        </div>

        <div className='data-entry-block-content'>
          {props.object.content}
        </div>

        <div className='data-entry-block-footer'>
          <div className='tags-and-url'>
            {(activeTags) && (
              <div className="row user-entry-card-tags">
                <p className="text-muted">
                  {activeTags.map((tag, index) => ( 
                    <span
                      key={index}
                      onClick={(event) => handleTagClick(event, tag.value)}
                      className="tag-link user-entry-card-footer-text"
                    >
                      #{tag.value}{' '}
                    </span>
                    ))}
                </p>
              </div>
            )} 
            {props.object.url && <OpenLinkInNewTabTool url={props.object.url} displayUrl={true}/> } 
          </div>
        </div>
        <div className='data-entry-block-separator'></div>
    </div>
  )
};

export default DataEntryBlock;