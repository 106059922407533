const config = {
  baseDelay: 50,
  delayVariance: 30,
  minChunkSize: 3,
  maxChunkSize: 12,
  pauseProbability: 0.15,
  pauseDuration: 500,
  pauseCharacters: ['.', '?', '!', ',', ';', ':'],
  punctuationPauses: {
    '.': 400,
    '?': 400,
    '!': 400,
    ',': 200,
    ';': 300,
    ':': 300
  }
};

export default config;