import React from 'react';
import Select from 'react-select';
import useIsMobile from '../../hooks/use-is-mobile';

const MultiSelect = ( {fieldName, options, value, onChange, isClearable, isDisabled} ) => {

  const isMobile = useIsMobile();
  const selectStyles = {
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: 'var(--color-text-placeholder)',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isDisabled ? 'var(--color-disabled-bg)' : 'var(--color-surface)', 
      color: 'var(--bs-body-color)',
      borderColor: 'var(--bs-border-color)',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'var(--color-bg-secondary)', 
      color: 'var(--color-text-secondary)',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isSelected ? 'var(--color-text-primary)' : baseStyles.color,
      backgroundColor: state.isSelected ? 'var(--color-active-bg)' : state.isFocused ? 'var(--color-hover-bg)' : baseStyles.backgroundColor,
      ':hover': {
        backgroundColor: 'var(--color-hover-bg)',
        color: 'var(--color-hover-text)',
      },
    }),
    multiValue: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'var(--color-hover-bg)',
    }),
    multiValueLabel: (baseStyles, state) => ({
      ...baseStyles,
      color: 'var(--bs-body-color)',
    }),
  };
    
  return (
    <Select
      isMulti      
      className="basic-multi-select"
      classNamePrefix="select"
      isDisabled={isDisabled}
      isLoading={false}
      isClearable={isClearable}
      isSearchable={!isMobile}
      name={fieldName}
      value={value}
      onChange={onChange}
      options={options}
      styles={selectStyles}
    />
  );
};

export default MultiSelect;
