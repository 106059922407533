import useFileUpload from '../../../hooks/use-file-upload';
import useCollectionSelected from '../hooks/use-collection-selected';
import { handleExportSingleCollection, handleExportAllCollections, handleImportCollections } from '../utils/sytem-utils';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');
const DropdownActionRow = loadModule('./components/administration/dropdown-action-row.js');
const FileUploadActionRow = loadModule('./components/administration/file-upload-action-row.js');

const SystemDataImportExport = () => {

  const { importedFile, handleFileUpload } = useFileUpload();
  const { collectionDropdownValues, collectionSelected, handleCollectionSelected } = useCollectionSelected();

  return (
    <div>
      <DropdownActionRow 
        title={'Export a single collection'}
        description={'Export selected collection and generate ZIP file. Useful to move one collection across environments.'}
        dropdownValues={collectionDropdownValues}
        handleDropdownChange={handleCollectionSelected}
        selectedValue={collectionSelected}
        buttonName={'Export'}
        handleAction={() => collectionSelected !== "Select a collection.." && collectionSelected !== "" ? handleExportSingleCollection(collectionSelected) : alert("Select a collection to export") }
      />
      <ButtonActionRow 
        title={'Export collections'}
        description={'Export collections and generate ZIP file.'}
        buttonName={'Export'}
        handleAction={() => handleExportAllCollections()}
      />
      <FileUploadActionRow 
        title={'Import collections'}
        description={'Import ZIP file and create/update data in collections.'}
        buttonName={'Import'}
        handleAction={() => handleImportCollections(importedFile)}
        zipFileUpload={handleFileUpload}
        isLast={true}
      />
    </div>
  );
};

export default SystemDataImportExport;