// caching.js
import axios from 'axios';
import loadConfig from './configLoader';
import { processDbDocuments } from './data-processing';
import { applyFiltersToObject } from './dataUtils';
import { encryptObject, decryptObject, decryptCol } from './data-encryption';

const config = loadConfig('general');
const defaultUserParameters = loadConfig('user-parameters');

// CACHING - CORE MECHANISM
export const storeUserDataCache = (cache) => {
  return localStorage.setItem('user-cache', JSON.stringify(cache));
};

export const getUserDataCache = () => {
  return JSON.parse(localStorage.getItem('user-cache'))
};

export const initializeUserDataCache = async () => {
  try {
    const cache = {};
    const collections = config.caching.collections;

    for (const collection of collections) {
      try {
        const response = await axios.get(
          `/api/generic-data/${collection.name}`
        );

        const dbDocuments = response.data.documents;
        const data = processDbDocuments(dbDocuments);

        const cachedCollection = data
          .filter((data) => applyFiltersToObject(data, collection.filters) && !data.isArchived)
          .reduce((accumulator, data) => {
            if (config.caching.isEncrypted) {
              accumulator[data._id] = encryptObject(data);
            } else {
              accumulator[data._id] = data;
            }
            return accumulator;
          }, {});

        cache[collection.name] = cachedCollection;

      } catch (error) {
        console.error(`Error retrieving data for collection: ${collection.name} : ${error.message}`);
      }
    }

    storeUserDataCache(cache);
    console.log(cache);
    
  } catch (error) {
    console.error(`Error retrieving data : ${error.message}`);
  }
};

// CACHING - UTILITY FUNCTIONS TO CREATE, READ, UPDATE, DELETE cache
export const getObjectFromCache = (collection, id) => {
  try {
    const cache = getUserDataCache();

    if (cache && cache[collection]) {
      const objects = cache[collection];

      if (objects[id]) {
        const cachedObject = objects[id];

        if (config.caching.isEncrypted) {
          return decryptObject(cachedObject);
        }
        return { ...cachedObject, collection: collection };
      }
    }
    return undefined;

  } catch (error) {
    console.error(`Error retrieving cache data for collection: ${collection} and id: ${id} : ${error.message}`);
    return undefined;
  }
};

export const searchObjectInCache = async (collection, filters) => {
  try {
    if (!collection) return {};

    const cache = getUserDataCache();

    if (cache && cache[collection]) {
      const data = Object.values(cache[collection]);
      const decryptedData = config.caching.isEncrypted ? await decryptCol(data) : data;
      for (const object of decryptedData) {
        if (applyFiltersToObject(object, filters)) {
          return { ...object, collection: collection };
        }
      }
    }
    return undefined;

  } catch (error) {
    console.error(`Error searching cache data for collection: ${collection} and filters: ${JSON.stringify(filters)} : ${error.message}`);
    return [];
  }
};

export const searchObjectsInCache = async (collection, filters) => {
  try {
    if (!collection) return [];

    const cache = getUserDataCache();
    const results = [];

    if (cache && cache[collection]) {
      const data = Object.values(cache[collection]);
      const decryptedData = config.caching.isEncrypted ? await decryptCol(data) : data; 
      for (const object of decryptedData) {
        if (applyFiltersToObject(object, filters)) {
          results.push({ ...object, collection: collection });
        }
      }
    }

    return results;

  } catch (error) {
    console.error(`Error searching cache data for collection: ${collection} and filters: ${JSON.stringify(filters)} : ${error.message}`);
    return [];
  }
};

export const printCache = async () => {
  try {
    const cache = getUserDataCache(); // Assume this gets the cached data
    const plainCache = {};

    // Iterate over each key (collection) and its associated value (data)
    for (const [collection, data] of Object.entries(cache)) {
      // Check if cache exists for the collection
      if (data) {
        // Decrypt if encryption is enabled, otherwise use the data as is
        const decryptedData = config.caching.isEncrypted ? await decryptCol(Object.values(data)) : Object.values(data);
        
        // Filter the data based on provided filters (if any)
        plainCache[collection] = decryptedData;
      }
    }

    // Return the newly constructed plainCache object
    return plainCache;

  } catch (error) {
    console.error(`Error printing cache data: ${error.message}`);
    return {};
  }
};


// OLD VERSION - should not be used anymore
export const getDataFromCache = (collection, filters) => {
  try {
    if (!collection || !filters) return undefined;
    
    const userDataCache = getUserDataCache();

    if (userDataCache && userDataCache.hasOwnProperty(collection)) {
      const data = userDataCache[collection];

      if (filters.hasOwnProperty('_id')) {
        const matchingRecord = Object.values(data).find(data => data._id === filters._id);
        if (matchingRecord) {
          return matchingRecord
        }
      } 
      
      if (filters.hasOwnProperty('value')) {
        const matchingRecord = Object.values(data).find(data => data.name === filters.value);
        if (matchingRecord) {
          return matchingRecord
        }
      }

      if (filters.hasOwnProperty('name')) {
        const matchingRecord = Object.values(data).find(data => data.name === filters.name);
        if (matchingRecord) {
          return matchingRecord
        }
      }
    }
    return undefined;
  } catch (error) {
    console.error(`Error searching cache data: ${error.message}`);
    return undefined;
  }
};


// TBD: evaluate the need to keep functions below this line
export const getTypesFromCache = (collection) => {
  try {
    let type;
    if (collection === 'entries' || collection === 'user-entries') {
      type = 'user-entry-type';
    } else if (collection === 'records' || collection === 'user-records') {
      type = 'user-record-type';
    } else if (collection === 'notes') {
      type = 'user-note-type';
    };
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache['admin-data']){
      return Object.values(userDataCache['admin-data'])
      .filter(data => data.type === type && data.isActive)
      .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  } catch (error)
  {
    console.error(`Error retriving entry types : ${error.message}`);
  }
};

export const getDefaultTypeFromCache = (collection) => {
  try {
    let type;
    if (collection === 'user-entries') {
      type = 'user-entry-type';
    } else if (collection === 'user-records') {
      type = 'user-record-type';
    };
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('admin-data')){
      return Object.values(userDataCache['admin-data'])
      .find(data => data.type === type && data.isActive && data.isFavorite);
    }
    return null;
  } catch (error)
  {
    console.error(`Error retriving entry types : ${error.message}`);
  }
};


export const getSubtypesFromCache = (collection, parentType) => {
  try {
    let type;
    if (collection === 'user-entries') {
      type = 'user-entry-subtype';
    } else if (collection === 'user-records') {
      type = 'user-record-subtype';
    };
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('admin-data')){
      return Object.values(userDataCache['admin-data'])
      .filter(data => data.type === type && data.parent === parentType && data.isActive)
      .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  } catch (error)
  {
    console.error(`Error retriving entry types : ${error.message}`);
  }
};

// remove userDataCache from argument, set const userDataCache = getUserDataCache(); 
export const getCollectionCategories = (type) => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('admin-data')){
      return Object.values(userDataCache['admin-data'])
      .filter(data => data.type === type)
      .map(data => ({_id: data._id, value: data.name}))
    } else {
      return [];
    }
  } catch (error)
  {
    console.error(`Error retriving entry types : ${error.message}`);
  }
};

export const getAdminDataCache = () => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('admin-data')){
      //return Object.values(userDataCache['admin-data']); // return an array of objects, not indexed
      return userDataCache['admin-data'];
    } else {
      return {};
    }
  } catch (error)
  {
    console.error(`Error retriving admin-data from cache : ${error.message}`);
  }
}; 

export const getHabitsDataCache = () => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('habits')){
      //return Object.values(userDataCache['habits']); // return an array of objects, not indexed
      return userDataCache['habits'];
    } else {
      return {};
    }
  } catch (error)
  {
    console.error(`Error retriving habits from cache : ${error.message}`);
  }
}; 

export const getTagsDataCache = () => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('tags')){
      //return Object.values(userDataCache['tags']); // return an array of objects, not indexed
      return userDataCache['tags'];
    } else {
      return {};
    }
  } catch (error)
  {
    console.error(`Error retriving tags from cache : ${error.message}`);
  }
}; 

export const getDefaultEntryType = (type) => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('admin-data')){
      return Object.values(userDataCache['admin-data'])
      .find(data => data.type === type && data.isFavorite)
    } else {
      return undefined;
    }
  } catch (error)
  {
    console.error(`Error retriving entry types : : ${error.message}`);
  }
};

export const getDataCacheFromId = (entity, id) => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty(entity)){
      return Object.values(userDataCache[entity])
      .find(data => data._id === id)
    } else {
      return undefined;
    }
  } catch (error)
  {
    console.error(`Error retriving cache from id : ${error.message}`);
  }
};

export const getDataCacheFromName = (entity, name) => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty(entity)){
      return Object.values(userDataCache[entity])
      .find(data => data.name === name)
    } else {
      return undefined;
    }
  } catch (error)
  {
    console.error(`Error retriving cache from name : ${error.message}`);
  }
};

export const getTemplates = (cacheType, entryType) => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty(cacheType)){
      return Object.values(userDataCache[cacheType]).filter((template) => template.type._id === entryType);
    } else {
      return [];
    }
  } catch (error)
  {
    console.error(`Error retriving templates : ${error.message}`);
  }
};

export const getTemplatesFromCache = (collection) => {
  try {
    let cacheType = `${collection}-templates`;
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty(cacheType)){
      return Object.values(userDataCache[cacheType])
        .filter(template => template.isActive )
        .sort((a, b) => a.title.localeCompare(b.title));
    } else {
      return [];
    }
  } catch (error)
  {
    console.error(`Error retriving templates : ${error.message}`);
  }
};

// export const getTemplatesFromCache = (collection, type, subtype) => {
//   try {
//     let cacheType = `${collection}-templates`;
//     const userDataCache = getUserDataCache();
//     if (userDataCache && userDataCache.hasOwnProperty(cacheType)){
//       return Object.values(userDataCache[cacheType])
//         .filter((template) => {
//           if (!template.isActive) return false
//           if (type && template.type._id !== type._id) return false
//           if (subtype && template.subtype._id !== subtype._id) return false
//           return true
//         })
//         .sort((a, b) => a.name.localeCompare(b.name));
//     } else {
//       return [];
//     }
//   } catch (error)
//   {
//     console.error(`Error retriving templates : ${error.message}`);
//   }
// };

export const getTagsFromCache = () => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('tags')){
      return Object.values(userDataCache['tags'])
        .filter((data) => data.isActive)
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  } catch (error)
  {
    console.error(`Error retriving templates : ${error.message}`);
  }
};

export const addNewDataToCache = (collection, data) => {
  try {
    const userDataCache = getUserDataCache();
    if(userDataCache && userDataCache.hasOwnProperty(collection)){
      const newCache = {...userDataCache};
      newCache[collection] = {
        ...newCache[collection],
        [data._id]: data,
      }
      storeUserDataCache(newCache);
      return newCache;
    }
    console.log('Could not find collection in data cache, returning original cache');
    return userDataCache;
  } catch (error)
  {
    console.error(`Error adding data to cache : ${error.message}`);
  }
}

export const updateDataInCache = (collection, data) => {
  try {
    // reusing addNewDataToCache() as it adding and updating are the same operations on an object
    return addNewDataToCache(collection, data)
  } catch (error)
  {
    console.error(`Error updating data to cache : ${error.message}`);
  }
}

export const deleteDataFromCache = (collection, dataId) => {
  try {
    const userDataCache = getUserDataCache();
    if(userDataCache && userDataCache.hasOwnProperty(collection)){
      const newCache = {...userDataCache};
      if (dataId in newCache[collection]) delete newCache[collection][dataId];
      storeUserDataCache(newCache);
      return newCache;
    }
    console.log('Could not find collection in data cache, returning original cache');
    return userDataCache;
  } catch (error)
  {
    console.error(`Error deleting data to cache : ${error.message}`);
  }
};

// create a mapping between user template admin data and current user admin data to fix relations
export const mapAdminData = (collection, dataId) => {
  try {
    const mapping = {}; // {templateID, userId}
    return mapping;
  } catch (error)
  {
    console.error(`Error mapping admin data : ${error.message}`);
  }
};

export const getQuickNotesType = () => {
  try {
    const userDataCache = getUserDataCache();
    if (userDataCache && userDataCache.hasOwnProperty('admin-data')){
      return Object.values(userDataCache['admin-data'])
      .find(data => data.type === "user-entry-type" && data.name === "Notes")
    } else {
      return undefined;
    }
  } catch (error)
  {
    console.error(`Error retriving entry type: ${error.message}`);
  }
};


// General getter for non-view categories
export const getUserParameters = (userParameters, category, parameter) => {
  try {
    if (userParameters && userParameters[category] && userParameters[category][parameter]) {
      return userParameters[category][parameter];
    }

    // Fallback to defaultUserParameters if userParameters are not present
    return defaultUserParameters[category] ? defaultUserParameters[category][parameter] : undefined;
  } catch (error) {
    console.error(`Error getting user parameter for category ${category} parameter=${parameter}:`, error);
    return undefined;
  }
};

// General setter for non-view categories
export const updateUserParameters = (setUserParameters, category, parameter, value) => {
  try {
    setUserParameters((prevParams) => ({
      ...prevParams,
      [category]: {
        ...prevParams[category],
        [parameter]: value,
      },
    }));
    return true;
  } catch (error) {
    console.error(`Error updating user parameter for category: ${category} parameter: ${parameter}:`, error);
    return false;
  }
};

// Getter for view parameters
export const getUserParametersForView = (userParameters, viewId, parameter) => {
  try {
    const views = userParameters?.views || {};
    const specificViewParams = views[viewId] || {};
    const defaultViewParams = views['default'] || defaultUserParameters.views['default'];

    // Return specific view parameter, fallback to default view if not present
    return specificViewParams[parameter] || defaultViewParams[parameter];
  } catch (error) {
    console.error(`Error getting user parameter for viewId: ${viewId} parameter: ${parameter}:`, error);
    return undefined;
  }
};

// Setter for view parameters
export const updateUserParametersForView = (setUserParameters, viewId, parameter, value) => {
  try {
    setUserParameters((prevParams) => ({
      ...prevParams,
      views: {
        ...prevParams.views,
        [viewId]: {
          // Spread existing view parameters if they exist, otherwise use default or empty object
          ...(prevParams.views[viewId] || {}),
          [parameter]: value,
        },
      },
    }));
    return true;
  } catch (error) {
    console.error(`Error updating user parameter for viewId: ${viewId} parameter: ${parameter}:`, error);
    return false;
  }
};

export const updateUserParametersForSearch = (setUserParameters, value) => {
  try {
    setUserParameters((prevParams) => ({
      ...prevParams,
      search: value,
    }));
    return true;
  } catch (error) {
    console.error(`Error updating search filter in user parameters:`, error);
    return false;
  }
};