import loadModule from '../../core/utils/moduleLoader';
const Main = loadModule('./features/authentication/views/signup.js');

const Signup = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default Signup;