import React from 'react';
import StaticHTML from './static-html';
import termsOfServiceHtml from '../html-files/terms_of_service_template.txt';

const TermsOfService = () => {
  return (
    <div>
      <StaticHTML htmlFile={termsOfServiceHtml} />
    </div>
  );
};

export default TermsOfService;
