import { useNavigate } from 'react-router-dom';
import { duplicateEntry } from "../../../utils/entry-actions";
import { deleteEntry } from "../../../utils/entry-actions";
import { Delete, FileCopy } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const DropdownMenu = loadModule("./components/dropdowns/dropdown-menu.js");


export const EntryDropdownMenu = ({ collection, id, onDeletion }) => {
  const navigate = useNavigate();

  // const handleDelete = async () => {
  //   try {
  //     await deleteEntry(collection, id);
  //     onDeletion(id);
  //   } catch (error) {
  //     console.error("Failed to delete entry:", error);
  //   }
  // };

  const actions = [
    { label: "Duplicate", icon: <FileCopy />, onClick: () => duplicateEntry(collection, id, navigate) },
    { label: "Delete", icon: <Delete />, onClick: () => deleteEntry(collection, id, navigate) },
    // { label: "Archive", icon: <ArchiveIcon />, onClick: () => console.log("Archive action triggered") },
    // { label: "Restore", icon: <UnarchiveIcon />, onClick: () => console.log("Restore action triggered") },
  ];

  return (
    <DropdownMenu
      actions={actions}
      icon={<MoreHorizIcon />}
      iconOnly={true}
    />
  );
};

export default EntryDropdownMenu;