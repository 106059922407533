// releaseManagement.js

  // Note: update migration script below as needed - decomission after use
  export const runClientMigration = async () => {
    //false ? await data_migration_server_side_release077().catch(console.error) : alert("No script attached yet.")
  } 
  export const runServerMigration = async () => {
    //false ? await axios.post(`/api/admin/data/migrate`).catch(console.error) : alert("No script attached yet.");
  } 

