// dataUtils.js
const moment = require('moment');

export function printObjects(objects) {
  objects.forEach((obj, index) => {
    console.log(`Object ${index + 1}:`, obj);
  });
}

export function printObjectProperties(obj) {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      console.log(`${key}:`, obj[key]);
    }
  }
}

export function printDataProperties(data) {
  if (typeof data !== 'object' || data instanceof Array) {
    console.log(data);
  } else {
    const obj = data;
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        console.log(`    ${key}:`, obj[key]);
      }
    }
  }
};

export function printDebugInfo(data) {
  console.log("Printing relevant data for debugging purpose: ");
  printDataProperties(data);
}

export function printObjectNicely(obj) {
  // Check if the argument is an object
  if (typeof obj !== 'object' || obj === null) {
    console.log('Provided argument is not an object');
    return;
  }

  // Function to handle nested objects
  function printNestedObject(o, indentLevel) {
    for (const key in o) {
      if (o.hasOwnProperty(key)) {
        const value = o[key];
        const indent = '  '.repeat(indentLevel);
        
        if (typeof value === 'object' && value !== null) {
          console.log(`${indent}${key}:`);
          printNestedObject(value, indentLevel + 1);
        } else {
          console.log(`${indent}${key}: ${value}`);
        }
      }
    }
  }

  // Start printing with an initial indent level of 0
  printNestedObject(obj, 0);
};

export function convertDbTimestamp(timeStamp){
  if(timeStamp){
    const date = new Date(timeStamp);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");
    return formattedDate;
  } else {
    return null;
  }
}

export function connectionsPerWeek(creationDate, totalConnections){
  if(creationDate && totalConnections > 0){
    const oneWeek = 24 * 60 * 60 * 1000 * 7; 
    const today = new Date();
    const targetDate = new Date(creationDate);
    const durationInMilliseconds = Math.abs(today.getTime() - targetDate.getTime());
    const durationInWeeks = Math.round(durationInMilliseconds / oneWeek);
    const connectionsPerWeek = durationInWeeks > 1 ? (totalConnections / durationInWeeks) : totalConnections;
    return connectionsPerWeek.toFixed(0);
  } else {
    return null;
  }
}

function getNumberOfWeeksElapsed(date) {
  let numberOfWeeks = null;
  if (date) {
    const oneWeek = 24 * 60 * 60 * 1000 * 7; 
    const pastDate = new Date(date);
    const today = new Date();
    const durationInMilliseconds = Math.abs(today.getTime() - pastDate.getTime());
    numberOfWeeks = Math.round(durationInMilliseconds / oneWeek);
  }
  return numberOfWeeks;
}

export function entriesPerWeek(totalEntries, creationDate) {
  if (!creationDate || !totalEntries) {
    return 0; // Handle cases where creationDate or totalEntries is falsy
  }

  const numberOfWeeksElapsed = getNumberOfWeeksElapsed(creationDate);

  if (numberOfWeeksElapsed === null || numberOfWeeksElapsed === 0) {
    return totalEntries; // Avoid division by zero or null
  }

  const entriesPerWeek = totalEntries / numberOfWeeksElapsed;
  return Math.round(entriesPerWeek); // Round the result if necessary
}


export function titlePrefix(){
  const currentDate = new Date();
  const options = { day: '2-digit', month: 'short'};
  const formatedDate = currentDate.toLocaleDateString('en-US', options).toUpperCase();
  return formatedDate;
}

export function capitalizeText(text) {
  const smallWords = ['and', 'for', 'but', 'yet', 'so', 'in', 'of', 'the', 'a', 'an', 'at', 'by', 'from'];
  
  const words = text.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
      if (i === 0 || i === words.length - 1 || !smallWords.includes(words[i])) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
  }

  return words.join(' ');
}

export function capitalizeFirstLetter(word) {
  if (!word) return ''
  
  return word.charAt(0).toUpperCase() + word.slice(1);
}


export function isValidDate(dateString) {
  const regEx = /^\d{1,2}\/\d{1,2}\/\d{4}$/; // MM/DD/YYYY format
  return regEx.test(dateString);
}

// used in forms to get the latest second of the date selected and convert it to UTC date
export function getEndOfDayUTC(dateSelected) {
  // console.log("Date before conversion (utc) " + dateSelected);
  const inputDate = moment(dateSelected); // convert string date to a moment / date object
  const adjustedDate = inputDate.endOf('day').utc(); // set time component to end of day and convers to UTC
  const utcDate = adjustedDate.toISOString();  // string representation of the date and time in the ISO 8601 format.
  // console.log("Date after conversion (utc) " + utcDate); 
  return utcDate;
}

export function convertUtcDateToLocalAndFormatShort(utcDate) {
  // console.log("Date before conversion (utc) " + utcDate);
  //const localDate = new Date(utcDate).toLocaleDateString();
  const localDate = moment(utcDate).local().format('YYYY-MM-DD');
  // console.log("Date after conversion (local GMT) " + localDate);
  return localDate;
}

export function convertUtcDateToLocalAndFormatLong(utcDate) {
  // console.log("Date before conversion (utc) " + utcDate);
  //const localDate = new Date(utcDate).toLocaleDateString();
  const localDate = moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss');
  // console.log("Date after conversion (local GMT) " + localDate);
  return localDate;
}


export function removePrefix(str) {
  const prefixPattern = /^[^-]*-\s/; // Matches patterns like "prefix - "
  if (str) return str.replace(prefixPattern, "");
}

export function stringifyObject(obj) {
  try{
    const stringifiedObject = {...obj};
    for (let key in stringifiedObject){
      const value = stringifiedObject[key];
      if (value instanceof Array){
        stringifiedObject[key] = JSON.stringify(value);
      }
    }
    return stringifiedObject;
  } catch (err){
    console.log("Unable to stringify object: " + obj , err);
  }
}

export function getColor(collection, id) {
  try{
    const color = collection.find(obj => obj._id === id).color;
    return color;
  } catch (err){
    console.log("Unable to identify color in collection: " + collection , err);
  }
}

export function getThemeColor(themes, value) {
  try{
    const color = themes.find(theme => theme.value === value).color;
    return color;
  } catch (err){
    console.log("Unable to identify color in collection: " + themes , err);
  }
}

export function getFieldValue(collection, field, id) {
  try{
    const object = collection.find(obj => obj._id === id);
    const value = object[field];
    return value;
  } catch (err){
    console.log("Unable to return field value in collection: " + collection , err);
  }
};

export const applyFiltersToObject = (object, filters) => {
  if (!filters) return true;
  
  for (const [key, value] of Object.entries(filters)) {
    if (!object.hasOwnProperty(key) || object[key] !== value) {
      return false;
    }
  }
  return true;
};