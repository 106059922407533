import axios from "axios";

export const createNewAccessRequest = async (data) => {
  await axios.post('/api/access-request/', data);
};

export const getUserIp = async () => {
  const ipResponse = await axios.get('https://api.ipify.org?format=json');
  const ipData = ipResponse.data;
  const ip = ipData.ip;
  return ip;
};

export const getUserLocation = async (ip) => {
  const locationResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
  const locationData = locationResponse.data;
  return locationData;
};