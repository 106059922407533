import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from "../../../contexts/user-context";
import { getUserParametersForView, updateUserParametersForView } from '../../../utils/caching';
import ViewFilterForm from './view-filters-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import ViewHeadlineOutlinedIcon from '@mui/icons-material/ViewHeadlineOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../../styles/toolbars.css';

const Toolbar = ({ viewId, viewFilters }) => {

  const { userParameters, setUserParameters } = useContext(UserContext);
  const [ showFiltersForm, setShowFiltersForm ] = useState(false);

  const hideFiltersForm = () => { setShowFiltersForm(false) };

  const updateSortParameter = () => {
    const prevValue = getUserParametersForView(userParameters, viewId, 'sort');
    const newValue = prevValue !== 'asc' ? 'asc' : 'desc';
    updateUserParametersForView(setUserParameters, viewId, 'sort', newValue);
  };

  const updateLayoutParameter = () => {
    const prevValue = getUserParametersForView(userParameters, viewId, 'layout');
    const newValue = prevValue !== 'card' ? 'card' : 'list';
    updateUserParametersForView(setUserParameters, viewId, 'layout', newValue);
  };


  const resetFilters = () => {
    updateUserParametersForView(setUserParameters, viewId, 'filters', {});
  }

  return (
    <div className="view-toolbar">
      <div className="view-toolbar-icon custom-shadow" onClick={() => resetFilters()}>
        <RefreshIcon />
      </div>
      <div className="view-toolbar-icon custom-shadow" onClick={() => updateSortParameter()}>
        <SwapVertOutlinedIcon />
      </div>
      <div className="view-toolbar-icon custom-shadow" onClick={() => updateLayoutParameter()}>
        { getUserParametersForView(userParameters, viewId, 'layout') === "card" ? <ViewHeadlineOutlinedIcon /> : <ViewDayOutlinedIcon />}
      </div>
      <div className="view-toolbar-icon custom-shadow" onClick={() => setShowFiltersForm(true)}>
        <TuneOutlinedIcon />
      </div>

      {/* Section necessary to show FilterFrom via modal */}
      {showFiltersForm && 
        <ViewFilterForm 
          viewId={viewId}
          viewFilters={viewFilters}
          hideFiltersForm={hideFiltersForm}
        />
      }

    </div>
  );
  
};

export default Toolbar;