import React from 'react';
import './tab.css';

const Tab = ({ tabName, isSelected, onChange }) => (
  <li className="tab-item">
    <a
      href="#"
      className={`tab-link ${isSelected ? 'tab-link-active' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        onChange(tabName);
      }}
    >
      {tabName}
    </a>
  </li>
);

export default Tab;