import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import loadModule from '../../core/utils/moduleLoader';
const WarningFullScreen = loadModule('./components/warning/warning-fullscreen.js');

const NotFound = () => {
  return (
    <div className='page'>
      <WarningFullScreen icon={<ReportProblemOutlinedIcon style={{ fontSize: '2rem' }} />} title={"Page Not Found"} desc={"Link to homepage"} 
      />
    </div>
  );
};

export default NotFound;