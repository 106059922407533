import { useState } from 'react';
import { signup, verifyRegistrationCode, googleAuth } from '../services/auth-service';
import loadConfig from '../../../utils/configLoader';
const config = loadConfig('general');

export const useSignupForm = () => {
  const [form, setForm] = useState({
    email: "",
    username: "",
    password: "",
    registrationCode: "",
  });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [validRegistrationCode, setValidRegistrationCode] = useState(false);

  const updateForm = (value) => {
    setForm((prev) => ({ ...prev, ...value }));
  };

  const handleSubmitRegistrationCode = async (e) => {
    e.preventDefault();
    try {
      const isCodeCorrect = await verifyRegistrationCode(form.registrationCode);
      if (isCodeCorrect) {
        setValidRegistrationCode(true);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isCodeCorrect = await verifyRegistrationCode(form.registrationCode);
      if (!isCodeCorrect) return;
      await signup(form);
      window.location.href = "/app/home";
    } catch (error) {
      alert(error.message);
    }
  };

  return {
    form,
    updateForm,
    handleSubmit,
    handleSubmitRegistrationCode,
    googleAuth,
    agreeTerms,
    setAgreeTerms,
    validRegistrationCode,
    config,
  };
};
