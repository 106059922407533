import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import { filterData } from "../../../utils/data-processing";
import { getUserParametersForView } from '../../../utils/caching';
import { printDebugInfo } from "../../../utils/dataUtils";

import loadConfig from "../../../utils/configLoader";
const config = loadConfig('general');


const useFiltering = (viewId, data, search, tagname, query) => {

  const [ filteredData, setFilteredData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const { userParameters } = useContext(UserContext);
  const filters = getUserParametersForView(userParameters, viewId, 'filters');

  useEffect(() => {
    const filterSortData = async () => {
      try {
        const consolidatedFilters = {
          ...filters,
          tag: tagname,
          query: query,
          // favorite: showFavorite,
        };
        const filteredData = filterData(data, consolidatedFilters);
        setFilteredData(filteredData);
        if(config.admin.debugMode) printDebugInfo(filteredData);
      } catch (error) {
        console.error('Failed to filter data: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    filterSortData();
  }, [viewId, data, userParameters, search, tagname, query]); 

  return { filters, filteredData, isLoading };

};

export default useFiltering;