// dataFilters.js

import moment from 'moment';

export function applyDateRangeFilter(date, startDate, endDate) {
  if (!date) return false;
  if (!startDate && !endDate) return true;
  const currentDate = moment(date);
  if (startDate && endDate) {
    return currentDate.isSameOrBefore(moment(endDate).utc()) && currentDate.isSameOrAfter(moment(startDate).utc());
  }
  if (!startDate && endDate) {
    return currentDate.isSameOrBefore(moment(endDate).utc());
  }
  if (startDate && !endDate) {
    return currentDate.isSameOrAfter(moment(startDate).utc());
  }
  return true;
};

// TBD: decommissioned after release 0.90
export function applyEntityFilter(entry, filters) {
  if (filters && filters.entity && filters.entity !== 'Any' && entry.entity !== filters.entity) {
    return false;
  }
  return true;
};

export function applyCollectionFilter(entry, filters) {
  if (filters && filters.collections && !filters.collections.includes("Any") && !filters.collections.includes(entry.collection)) {
    return false;
  }
  return true;
};

export function applyTypeFilter(entry, filters) {
  if (filters && filters.types && !filters.types.includes("Any") && !filters.types.includes(entry.type?.value)) {
    return false;
  }
  return true;
};

// TBD: decommissioned after release 0.90
export function applyEntryTypeFilter(entry, filters) {
  if (filters) {
    if (!entry.type) return false;
    
    if (
      (filters.entity === 'user-entries' || filters.entity === 'Any') &&
      filters.entryType !== 'Any' &&
      entry.entity === 'user-entries' &&
      entry.type.value !== filters.entryType
    ) {
      return false;
    }

    if (
      (filters.entity === 'user-records' || filters.entity === 'Any') &&
      filters.recordType !== 'Any' &&
      entry.entity === 'user-records' &&
      entry.type.value !== filters.recordType
    ) {
      return false;
    }
  }
  return true;
};

export function applyShorcutsFilter(entry, filters) {
  if (filters) {
    if (filters.isFavorite === true) {
      return entry.isFavorite;
    }
    // Add any other shortcuts filters here
  }
  return true;
}

export function objectHasTag(object, tagname){
  const key = "tags";
  tagname = tagname.toLocaleLowerCase(); 
  if (object.hasOwnProperty(key) && object[key]) {
    const tags = object[key].map(elt => elt.value?.toLocaleLowerCase());
    if (tags.includes(tagname)) return true;
  };
  return false;
}

export function objectHasText(object, text) {
  function searchProperties(object, text) {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        if (typeof value === 'string' && value.toLowerCase().includes(text.toLowerCase())) {
          return true; // Found a match, return true
        } else if (typeof value === 'object') {
          if (searchProperties(value, text)) {
            return true; // Match found in nested object, return true
          }
        }
      }
    }
    return false; // No match found in this object
  }

  return searchProperties(object, text);
}

export function applyGolbalSearchFilter(entry, filters) {
  if (filters) {
    if (filters.tag) {
      return objectHasTag(entry, filters.tag);
    }
    if(filters.query) {
      return objectHasText(entry, filters.query);
    }
  }
  return true;
};

export function applyFocusFilters(entry, filters) {
  // identify scenario where we should filter out the entry, and return false
  // return true at the end if no reason to filter out / block the entry
  if (filters && filters.type) {
    if (filters.favoritesOnly && !entry.isFavorite) {
      return false;
    }
    else if (filters.templatesOnly && !entry.isTemplate) {
      return false;
    }
    else if (filters.linksOnly && (!entry.link || entry.link.length === 0)) {
      return false;
    }
    else if (filters.tagsOnly && (!entry.tags || entry.tags.length === 0)) {
      return false;
    }
  }
  return true;
};

export function applyOtherFilters(entry, filters) {
  // identify scenario where we should filter out the entry, and return false
  // return true at the end if no reason to filter out / block the entry
  if (filters && filters.type) {
    if ((!filters.archivedOnly && entry.isArchived) || (filters.archivedOnly && !entry.isArchived)) {
      return false;
    }
  }
  return true;
};

export function applyTextFilter(entry, filters) {
  if (filters && filters.text) {
    return objectHasText(entry, filters.text);
  }
  return true;
};

export function applyKeywordsFilter(entry, filters) {
  if (filters && filters.keywords) {
    const keywordsArray = filters.keywords.split(',').map(word => word.trim());
    for (let word of keywordsArray) {
      if (objectHasText(entry, word)) {
        return true;
      }
    }
    return false;
  }
  return true;
};