import loadModule from '../../core/utils/moduleLoader';
const Main = loadModule('./features/visitor/views/main.js');

const Visitor = () => {

  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default Visitor;