import React from "react";
import { Card, ProgressBar } from "react-bootstrap";

const AchievementCard = () => {
  const title = "Achievement Tracker";
  const achievements = [
    { label: 'Project A Completed', value: 100 },
    { label: 'Learning React', value: 70 },
    { label: 'Reading Goal', value: 50 },
    { label: 'Fitness Progress', value: 80 },
  ];

  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        {achievements.map((achievement, index) => (
          <div key={index} className="mb-3">
            <h6>{achievement.label}</h6>
            <ProgressBar now={achievement.value} label={`${achievement.value}%`} />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default AchievementCard;
