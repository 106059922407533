// Direct Imports - Protected Modules
import TabViewLayout from '../../../layouts/view/tab-view';
import ViewContentLayout from '../../../layouts/view/view-content-layout';
import useIsSystemAdmin from '../../../hooks/use-is-system-admin';
import PossibleValues from './possible-values-table';
import Tags from './tags-table';
import Habits from './habits-table';
import Users from './users-table';
import AccessRequests from './access-requests-table';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';

const Content = () => {

  const isAdministrator = useIsSystemAdmin();

  // TBD: create a generic component that loads the table using collection, options and data model as input
  // then replace tabMap here?

  // generic tabs
  const tabMap = {
    "Possible Values": <PossibleValues />,
    "Tags": <Tags />,
    "Habits": <Habits />,
  };

  // restricted tabs
  if (isAdministrator) {
    tabMap["Users"] = <Users />;
    tabMap["Access Requests"] = <AccessRequests />;
  }

  return (
    <ViewContentLayout 
      title={"Data Administration"}
      subtitle={"Manage all your data in table format"}
    >
      <TabViewLayout tabMap={tabMap} />
    </ViewContentLayout>
  );
};

export default Content;
