import { Card } from 'react-bootstrap';

const TextCard = ({ title, text }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
          <div style={{ whiteSpace: 'pre-wrap', minHeight: '200px' }}>
            {text}
          </div>
      </Card.Body>
    </Card>
  );
};

export default TextCard;