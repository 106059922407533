// TestComponents.js
import React, {useState, useEffect} from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import FoldableCard from '../../components/cards/foldable-card';
import './test-components.css'

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');


export const PlaceholderCard = () => {
  return (
    <div className="card">
      <svg className="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#20c997"></rect></svg>

      <div className="card-body">
        <h5 className="card-title">Card title</h5>
        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        <a href="#" className="btn btn-primary">Go somewhere</a>
      </div>
    </div>
  )
}

export const CardsGrid = () => {
  return (
    <div className="container">
      <div className="row">
        {Array.from({ length: 9 }).map((_, index) => (
          <div key={index} className="col-12 col-md-6 col-lg-4 mb-3">
            <PlaceholderCard />
          </div>
        ))}
      </div>
    </div>
  );
};

export const LongText = () => {
  return (
    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec interdum euismod lorem, nec blandit leo hendrerit sed. Proin vulputate posuere dolor, in fringilla mi sollicitudin non. Aenean eu ex tortor. Integer scelerisque mi tellus, in elementum dui ultrices sit amet. Pellentesque ut neque sit amet felis aliquam dictum pharetra eget purus. Cras eu ante sit amet lectus pharetra efficitur a at sem. Sed malesuada magna quis justo dapibus fringilla. Suspendisse nec eleifend libero. Pellentesque cursus scelerisque massa, in vehicula metus imperdiet sit amet.

        Morbi suscipit pulvinar purus at vehicula. Integer gravida felis eu facilisis luctus. Donec eget imperdiet nulla, at sollicitudin quam. Nam pretium dolor eu pretium tincidunt. Integer eget nulla nec lorem ullamcorper blandit. Nulla commodo eros vel augue commodo volutpat. In in consectetur tortor, et lacinia turpis. Nullam venenatis scelerisque dignissim. Proin laoreet posuere urna nec condimentum. Vivamus et enim a quam lobortis feugiat. Vestibulum ipsum nunc, dignissim sed purus et, faucibus venenatis nunc. Duis sit amet tellus quis nulla tincidunt commodo vitae at nisl. Fusce consequat tincidunt nibh, eget mattis dui convallis eget. Maecenas luctus tincidunt scelerisque. Mauris non ipsum ornare, faucibus neque nec, porttitor dolor.

        Curabitur ut accumsan ipsum. Morbi sit amet nulla eu risus interdum aliquet nec sit amet libero. Donec id odio dictum mi volutpat interdum. Nunc tincidunt ipsum metus, id imperdiet risus faucibus a. Phasellus vitae egestas ligula, non molestie nunc. Duis semper, turpis non egestas pulvinar, justo erat cursus augue, eget posuere ipsum velit eu turpis. Praesent eget elit euismod, eleifend magna accumsan, porta justo. Donec facilisis luctus dui, sed semper nulla imperdiet vel. Morbi tristique tellus sed augue rhoncus consequat ac et erat. Donec tristique sagittis mauris vel maximus. Maecenas purus magna, congue at lacinia id, efficitur in risus. Integer dignissim congue lorem vitae eleifend. Phasellus ultricies sem placerat tortor suscipit, vehicula vehicula augue malesuada. Suspendisse potenti. Donec accumsan augue id bibendum iaculis.

        Donec a dolor suscipit elit lobortis faucibus quis ut urna. Mauris pharetra vitae elit eget auctor. Sed vitae quam et erat ultricies efficitur ac consectetur arcu. Duis vel felis vitae lorem posuere pharetra. Ut interdum suscipit mauris vel semper. Vivamus at bibendum elit. Nunc ligula erat, mollis quis metus et, sodales pretium felis. Aliquam porta felis ultricies lectus fermentum iaculis. Nulla tempor sapien sed enim consectetur pellentesque. Quisque sodales ultrices enim ornare condimentum. Curabitur varius varius tellus eget tincidunt. Praesent pretium metus nisi, ac pharetra nunc semper at.

        Nulla facilisi. In lobortis eget nisl eget imperdiet. Vivamus sit amet neque elementum, lacinia arcu ut, dapibus dolor. Sed laoreet erat a faucibus fringilla. In sollicitudin, orci vitae porttitor porttitor, quam sapien dapibus ligula, eu ultrices erat dolor non nunc. Duis pretium quam ut molestie tempor. Ut auctor ipsum quis urna eleifend commodo.

        Sed risus risus, maximus ac ipsum ac, interdum euismod mauris. Praesent sed orci ligula. Maecenas condimentum blandit libero semper ultrices. Aenean id mattis nisi. Praesent nec sollicitudin enim. Mauris id posuere ex, vel egestas nisl. Sed condimentum nisi vel mauris interdum, ac tincidunt mi mollis. Interdum et malesuada fames ac ante ipsum primis in faucibus. In eu arcu nibh. Integer sapien tortor, aliquam at sapien vitae, facilisis pretium sem. Pellentesque vel elit non metus malesuada eleifend.

        Phasellus blandit iaculis urna, in semper diam maximus a. Pellentesque faucibus lacinia nulla nec imperdiet. Donec in volutpat erat. Nullam ornare ac nisi et mattis. Integer volutpat magna risus, ac rutrum mauris pretium eget. Cras efficitur ipsum ut leo ornare, eu ullamcorper felis egestas. Duis aliquet mi quis euismod eleifend. Curabitur volutpat, arcu varius feugiat consequat, quam ex malesuada dolor, eget varius turpis sem et urna. Fusce et convallis lacus, tincidunt aliquam urna. Sed in rutrum massa. Donec mattis ligula vel mi auctor, vel gravida arcu facilisis. Morbi eleifend, odio scelerisque dignissim tempor, leo metus lobortis eros, in finibus eros justo eget sapien. Morbi elementum, nibh ac tincidunt ultricies, augue metus blandit diam, in rhoncus tellus sapien nec est. Donec venenatis, augue vitae fermentum varius, arcu nunc dignissim tortor, ac congue libero lacus a sem. Proin commodo eget turpis ut aliquet.

        Duis eu pellentesque nulla, eget convallis eros. Mauris urna est, ullamcorper in libero sed, bibendum gravida velit. Praesent mattis ligula dignissim enim ornare vestibulum. Ut eget turpis in est vehicula pharetra. Vivamus non tempor velit, ac eleifend massa. Donec lorem ligula, dignissim eu dolor sed, tempus sollicitudin libero. Suspendisse id velit eu velit auctor sagittis a sed lectus. Duis non odio vitae mauris dictum euismod. Phasellus pretium posuere eros. Duis ultricies nibh turpis. Nullam facilisis, ante vel sodales convallis, orci ipsum faucibus enim, ut interdum felis dolor eu turpis.

        Aenean vehicula commodo maximus. Sed sagittis nisl ut libero malesuada, placerat aliquet quam elementum. Aenean iaculis enim sit amet pharetra tempor. Integer nec odio vel neque porttitor congue. Donec ut malesuada augue, vel egestas ante. Vestibulum pretium lacinia ornare. In erat enim, blandit eget vestibulum eu, molestie in mi. Nulla erat nisl, commodo id libero ut, tempor ultrices arcu. Etiam a elit sit amet enim accumsan tempor id vitae erat. Vestibulum urna neque, placerat vitae justo id, rutrum venenatis dui. Proin dapibus venenatis tempus. Suspendisse posuere magna ac libero elementum bibendum.

        Cras mauris metus, posuere aliquet ligula ut, porta rhoncus libero. Nunc non tempor nibh, ac accumsan dui. Duis tristique felis sit amet erat aliquet commodo. Mauris vitae ipsum id nibh interdum fringilla. Curabitur ac tristique ante. Integer feugiat viverra arcu, sed posuere justo vulputate id. In blandit vel elit id scelerisque. Praesent nec ex mi. Mauris posuere pretium ligula, sit amet placerat ante accumsan nec. Nullam dignissim ex arcu, vel molestie orci gravida vel. In maximus viverra dui at lacinia. Sed quis augue rutrum, eleifend urna aliquet, fermentum orci. Ut sit amet libero laoreet, volutpat tortor in, rhoncus nunc. Etiam magna mi, fringilla at lorem vel, fermentum tristique tellus.

        Donec eget egestas eros. Duis euismod elit mi, sit amet efficitur lorem feugiat eget. Fusce quis tincidunt quam. Fusce vel efficitur dolor. Quisque varius euismod eleifend. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Ut ullamcorper leo.
      </p>
    </div>
  )
}

export const LongParagraph = () => {
  return (
    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec interdum euismod lorem, nec blandit leo hendrerit sed. Proin vulputate posuere dolor, in fringilla mi sollicitudin non. Aenean eu ex tortor. Integer scelerisque mi tellus, in elementum dui ultrices sit amet. Pellentesque ut neque sit amet felis aliquam dictum pharetra eget purus. Cras eu ante sit amet lectus pharetra efficitur a at sem. Sed malesuada magna quis justo dapibus fringilla. Suspendisse nec eleifend libero. Pellentesque cursus scelerisque massa, in vehicula metus imperdiet sit amet.
      </p>
    </div>
  )
}

export const LargeImage = () => {
  return (
    <div>
      <img src="https://via.placeholder.com/3000" alt="App Screenshot" />
    </div>
  )
}

export const SmallImage = () => {
  return (
    <div>
      <img src="https://via.placeholder.com/300" alt="App Screenshot" />
    </div>
  )
}

export const EntryCardListTest = () => {

  const largeCardData = Array.from({ length: 10 }, (_, i) => ({
    title: `Main Card ${i + 1}`,
    text: `Today was a day of quiet reflection and small accomplishments. I started my morning with a peaceful walk in the park, the air fresh with the scent of blooming flowers. The sunlight filtered through the trees, casting a golden glow on everything it touched. After breakfast, I spent some time organizing my workspace, decluttering both physically and mentally. It felt good to have a clean and orderly environment to work in. I made significant progress on my current project, finding solutions to some problems that had been troubling me. In the afternoon, I took a break to read a few chapters of a novel I've been enjoying. The story is captivating, and it's a wonderful escape from reality. As the evening approached, I cooked a simple but delicious dinner, savoring each bite. Now, as I wind down for the night, I feel a sense of calm and satisfaction, ready to embrace whatever tomorrow brings. This is the text for main card ${i + 1}.`
  }));

  const LargeCard = ({ title, text }) => (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );

  return (
    <div>
      {largeCardData.map((card, index) => (
        <LargeCard key={index} title={card.title} text={card.text} />
      ))}
    </div>
  )
};

export const StatsCard = ({ title, stats }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <div className="stats">
          {stats.map((stat, index) => (
            <div key={index} className="stat-item">
              <h5>{stat.value}</h5>
              <p>{stat.label}</p>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export const IndicatorCard = ({ title, indicators }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <div className="indicators">
          {indicators.map((indicator, index) => (
            <div key={index} className="indicator-item">
              <div
                className="indicator-color"
                style={{ backgroundColor: indicator.color }}
              ></div>
              <div className="indicator-text">{indicator.label}</div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export const ProgressCard = ({ title, progressData }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        {progressData.map((data, index) => (
          <div key={index} className="mb-3">
            <h6>{data.label}</h6>
            <ProgressBar now={data.value} label={`${data.value}%`} />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export const TextCard = ({ title, text }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
          <div style={{ whiteSpace: 'pre-wrap', minHeight: '200px' }}>
            {text}
          </div>
      </Card.Body>
    </Card>
  );
};

export const UserStats = () => {
  const appStats = [
    { value: '1,234', label: 'Users' },
    { value: '567', label: 'Orders' },
    { value: '89%', label: 'Satisfaction' },
    { value: '12', label: 'New Signups' }
  ];

  const indicators = [
    { label: 'Online', color: 'green' },
    { label: 'Offline', color: 'red' },
    { label: 'Idle', color: 'yellow' },
    { label: 'Busy', color: 'orange' }
  ];

  const progressData = [
    { label: 'Health', value: 75 },
    { label: 'Relationships', value: 60 },
    { label: 'Finance', value: 50 },
    { label: 'Career', value: 80 },
    { label: 'Passions', value: 90 }
  ];

  return (
    <div className="home-right-panel">
      {/* <TypingAgent dailyDigest={dailyDigest} setDailyDigest={setDailyDigest} /> */}
      <StatsCard title="User Stats" stats={appStats} />
      <IndicatorCard title="Status Indicators" indicators={indicators} />
      <ProgressCard title="Life Progress" progressData={progressData} />
    </div>
  );
};

export const SystemStats = () => {
  const appStats = [
    { value: '1,234', label: 'Users' },
    { value: '567', label: 'Orders' },
    { value: '89%', label: 'Satisfaction' },
    { value: '12', label: 'New Signups' }
  ];

  const indicators = [
    { label: 'Online', color: 'green' },
    { label: 'Offline', color: 'red' },
    { label: 'Idle', color: 'yellow' },
    { label: 'Busy', color: 'orange' }
  ];

  return (
    <div className="home-right-panel">
      <StatsCard title="User Stats" stats={appStats} />
      <IndicatorCard title="Status Indicators" indicators={indicators} />
    </div>
  );
};

export const JournalEntryHtml = () => {
  return (
    <div>
      <h2>May 24, 2024</h2>
      <p>Today was a productive and enjoyable day. Here's a quick recap:</p>
      <ul>
        <li><strong>Morning:</strong>
          <ul>
            <li>Took a peaceful walk in the park.</li>
            <li>Enjoyed the scent of blooming flowers and the golden sunlight filtering through the trees.</li>
          </ul>
        </li>
        <li><strong>After Breakfast:</strong>
          <ul>
            <li>Organized my workspace.</li>
            <li>Decluttered both physically and mentally.</li>
          </ul>
        </li>
        <li><strong>Work Progress:</strong>
          <ul>
            <li>Made significant progress on my current project.</li>
            <li>Found solutions to some troubling problems.</li>
          </ul>
        </li>
        <li><strong>Afternoon:</strong>
          <ul>
            <li>Took a break to read a few chapters of a captivating novel.</li>
          </ul>
        </li>
        <li><strong>Evening:</strong>
          <ul>
            <li>Cooked a simple but delicious dinner.</li>
            <li>Savored each bite and enjoyed the meal.</li>
          </ul>
        </li>
      </ul>
      <p>Overall, I felt a sense of calm and satisfaction by the end of the day. Here's what I accomplished:</p>
      <ol>
        <li>Peaceful walk in the park.</li>
        <li>Organized my workspace.</li>
        <li>Made progress on my project.</li>
        <li>Read a few chapters of a novel.</li>
        <li>Cooked and enjoyed dinner.</li>
      </ol>
      <p>Tasks for tomorrow:</p>
      <ul>
        <li><input type="checkbox" /> Morning jog</li>
        <li><input type="checkbox" /> Continue work on project</li>
        <li><input type="checkbox" /> Finish reading the novel</li>
        <li><input type="checkbox" /> Try a new recipe for dinner</li>
      </ul>
    </div>
  );
};

export const AdminCardTest = () => {
  return (
    <div>
      <FoldableCard title={'Group of actions'} expandable={true} >
        <ButtonActionRow 
          title={'Action name'}
          description={'Action description'}
          buttonName={'Action'}
          handleAction={() => alert(1)}
        />
        <ButtonActionRow 
          title={'Action name'}
          description={'Action description'}
          buttonName={'Action'}
          handleAction={() => alert(1)}
        />
        <ButtonActionRow 
          title={'Action name'}
          description={'Action description'}
          buttonName={'Action'}
          handleAction={() => alert(1)}
          isLast={true}
        />
      </FoldableCard>
    </div>
  );
};

export const AdminCardListTest = () => {
  const cards = Array.from({ length: 4 }, (_, i) => ({
    id: i + 1,
    title: `Card ${i % 3 + 1}`,
    description: `Description ${i % 3 + 1}`,
    onButtonClick: (id) => alert(`Button clicked for card with id: ${id}`)
  }));

  return (
    <div>
      {cards.map(card => (
        <AdminCardTest />
      ))}
    </div>
  )
};

export const NewPost = () => {
  return (
      <div className="container">
          <div className="form-group mb-3">
              <label htmlFor="newPost" className="h4">New Post</label>
              <textarea 
                  className="form-control" 
                  id="newPost" 
                  placeholder="Write your post here..."
                  style={{height: '300px'}}
              />
          </div>
          <button type="button" className="btn btn-primary mb-3">Submit</button>
      </div>
  );
};