import React from "react";
import { Card, Placeholder } from "react-bootstrap";

const PlaceholderCard = ({ contentCount = 1 }) => {
  return (
    <Card className="mb-4 placeholder-card">
      <Card.Body>
        <Card.Title className="card-title">
          <Placeholder as="h5" animation="wave">
            <Placeholder xs={6} />
          </Placeholder>
        </Card.Title>
        <Card.Text>
          {[...Array(contentCount)].map((_, index) => (
            <Placeholder as="p" animation="wave" key={index}>
              <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
              <Placeholder xs={8} />
            </Placeholder>
          ))}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PlaceholderCard;
