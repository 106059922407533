import { useState } from 'react';
import './action-row.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const ConfirmationModal = loadModule('./components/modals/confirmation-modal.js');

const ButtonActionRow = ({icon, title, titleColor, description, buttonName, buttonClass, handleAction, requestConfirmation, confirmationMessage, isLast}) => {

  const [ showModal, setShowModal ] = useState(false);

  const handleConfirmation = (confirmed) => {
    setShowModal(false);
    if (confirmed) {
      // Use a callback function to ensure handleAction is called after setShowModal has updated the state
      setTimeout(() => { handleAction() }, 100);
    }
  };

  const handleClick = () => {
    if (requestConfirmation) setShowModal(true);
    else handleAction();
  };

  return (
    <div>
      <div className="action-row">
        {icon && 
          <div className="action-col icon">
            {icon}
          </div>    
        }
        <div className="action-col text">
          <h4 className="action-title" style={{color: titleColor}}>{title}</h4>
          <p className="action-desc">{description}</p>
        </div>
        <div className="action-col button">
          <button type="button" className= {buttonClass ? buttonClass : "btn btn-primary"} onClick={handleClick}>{buttonName}</button>
        </div>
      </div>
      {isLast ? null : <hr className="hr" />}
      {showModal && (
        <ConfirmationModal message={confirmationMessage} handleConfirmation={handleConfirmation} />
      )}
    </div>
  )
};

export default ButtonActionRow;