// TBD: replace this static file by dynamic feature maintainable in the front end

// internal attributes, set in the backed when entries are created/updated
// those attributes are NOT encrypted
export const internal = [
  { label: 'Object ID', type: 'string', name: '_id', disabled: true },
  { label: 'Creation Date', type: 'date', name: 'creationDate', disabled: true },
  // { label: 'Created By', type: 'string', name: 'createdBy', disabled: true },
  { label: 'Last Modification Date', type: 'date', name: 'lastModificationDate', disabled: true },
  // { label: 'Last Modified By', type: 'string', name: 'lastModifiedBy', disabled: true },
  // { label: 'Is System', type: 'boolean', name: 'isSystem', disabled: true },
  // { label: '__v', type: 'number', name: '__v', disabled: true },
];

// core attributes are used for all entries regarding their type
// core attributes are added to the end and ARE encrypted
export const core = [
  { label: 'Type', type: 'custom-select', name: 'type' },
  { label: 'Template', type: 'custom-select', name: 'template' },
  { label: 'Date', type: 'date', name: 'date' },
  { label: 'Tags', type: 'custom-multiselect', name: 'tags' },
  { label: 'Is Template', type: 'boolean', name: 'isTemplate' },
];

// specific attributes are driven by the entry type
// specific attributes ARE encrypted
export const specific = {
  "Fleeting Note": [
    { label: 'Context', type: 'textarea', name: 'context' },
    { label: 'Ideas', type: 'textarea', name: 'ideas' },
    { label: 'Source', type: 'string', name: 'source' },
    { label: 'Reflection', type: 'textarea', name: 'reflection' },
  ],
  "Permanent Note": [
    { label: 'Summary', type: 'textarea', name: 'summary' },
    { label: 'Source', type: 'string', name: 'source' },
    { label: 'References', type: 'textarea', name: 'references' },
    { label: 'Connections', type: 'textarea', name: 'connections' },
  ],
  "Index Note": [
    { label: 'Topic', type: 'string', name: 'topic' },
    { label: 'Keywords', type: 'custom-multiselect', name: 'keywords' },
    { label: 'Associated Notes', type: 'textarea', name: 'associatedNotes' },
  ],
  "Literature Note": [
    { label: 'Title', type: 'string', name: 'title' },
    { label: 'Author', type: 'string', name: 'author' },
    { label: 'Summary', type: 'textarea', name: 'summary' },
    { label: 'Key Ideas', type: 'textarea', name: 'keyIdeas' },
    { label: 'Quotes', type: 'textarea', name: 'quotes' },
  ],
  "Personal Journal": [
    { label: 'Mood', type: 'string', name: 'mood' },
    { label: 'Reflection', type: 'textarea', name: 'reflection' },
    { label: 'Highlights', type: 'textarea', name: 'highlights' },
  ],
  "Travel Journal": [
    { label: 'Destination', type: 'string', name: 'destination' },
    { label: 'Itinerary', type: 'textarea', name: 'itinerary' },
    { label: 'Highlights', type: 'textarea', name: 'highlights' },
    { label: 'Challenges', type: 'textarea', name: 'challenges' },
  ],
  "Work Journal": [
    { label: 'Project Name', type: 'string', name: 'projectName' },
    { label: 'Key Learnings', type: 'textarea', name: 'keyLearnings' },
    { label: 'Challenges', type: 'textarea', name: 'challenges' },
  ],
  "Running": [
    { label: 'Duration', type: 'string', name: 'duration' },
    { label: 'Distance', type: 'string', name: 'distance' },
    { label: 'Pace', type: 'string', name: 'pace' },
    { label: 'Location', type: 'string', name: 'location' },
    { label: 'Average HR', type: 'number', name: 'averageHr' },
    { label: 'URL', type: 'url', name: 'url' },
  ],
  "Workout": [
    { label: 'Exercise Type', type: 'string', name: 'exerciseType' },
    { label: 'Reps', type: 'number', name: 'reps' },
    { label: 'Sets', type: 'number', name: 'sets' },
    { label: 'Weight', type: 'number', name: 'weight' },
    { label: 'Notes', type: 'textarea', name: 'notes' },
  ],
  "Checklist": [
    { label: 'Task Name', type: 'string', name: 'taskName' },
    { label: 'Due Date', type: 'date', name: 'dueDate' },
    { label: 'Priority', type: 'custom-select', name: 'priority' },
  ],
  "Nutrition": [
    { label: 'Meal', type: 'string', name: 'meal' },
    { label: 'Calories', type: 'number', name: 'calories' },
    { label: 'Macros', type: 'textarea', name: 'macros' },
    { label: 'Meal Time', type: 'time', name: 'mealTime' },
  ],
  "Life Hack": [
    { label: 'Description', type: 'textarea', name: 'description' },
    { label: 'Implementation Steps', type: 'textarea', name: 'implementationSteps' },
    { label: 'Expected Results', type: 'textarea', name: 'expectedResults' },
  ],
  "Productivity": [
    { label: 'Goal', type: 'string', name: 'goal' },
    { label: 'Task Breakdown', type: 'textarea', name: 'taskBreakdown' },
    { label: 'Time Allotment', type: 'time', name: 'timeAllotment' },
  ],
  "Recipe": [
    { label: 'Ingredients', type: 'textarea', name: 'ingredients' },
    { label: 'Cooking Instructions', type: 'textarea', name: 'instructions' },
    { label: 'Preparation Time', type: 'time', name: 'prepTime' },
  ],
  "Software Idea": [
    { label: 'Concept', type: 'string', name: 'concept' },
    { label: 'Features', type: 'textarea', name: 'features' },
    { label: 'Technical Requirements', type: 'textarea', name: 'techRequirements' },
  ],
  "Idea": [
    { label: 'Concept', type: 'textarea', name: 'concept' },
    { label: 'Potential Impact', type: 'textarea', name: 'impact' },
  ],
  "Medical": [
    { label: 'Condition', type: 'string', name: 'condition' },
    { label: 'Symptoms', type: 'textarea', name: 'symptoms' },
    { label: 'Treatment Plan', type: 'textarea', name: 'treatmentPlan' },
  ],
  "Fitness Level": [
    { label: 'Current Level', type: 'number', name: 'currentLevel' },
    { label: 'Goal Level', type: 'number', name: 'goalLevel' },
    { label: 'Assessment', type: 'textarea', name: 'assessment' },
  ],
  "Finance": [
    { label: 'Account', type: 'string', name: 'account' },
    { label: 'Balance', type: 'number', name: 'balance' },
    { label: 'Transactions', type: 'textarea', name: 'transactions' },
  ],
  "Quotes": [
    { label: 'Quote', type: 'textarea', name: 'quote' },
    { label: 'Source', type: 'string', name: 'source' },
    { label: 'Personal Interpretation', type: 'textarea', name: 'interpretation' },
  ],
  "Business Idea": [
    { label: 'Concept', type: 'string', name: 'concept' },
    { label: 'Market Potential', type: 'textarea', name: 'marketPotential' },
    { label: 'Revenue Streams', type: 'textarea', name: 'revenueStreams' },
  ],
  "Things To Do": [
    { label: 'Task', type: 'string', name: 'task' },
    { label: 'Due Date', type: 'date', name: 'dueDate' },
    { label: 'Priority', type: 'custom-select', name: 'priority' },
  ],
  "Review": [
    { label: 'Reviewed Item', type: 'string', name: 'reviewedItem' },
    { label: 'Rating', type: 'number', name: 'rating' },
    { label: 'Feedback', type: 'textarea', name: 'feedback' },
  ],
  "Memo": [
    { label: 'Topic', type: 'string', name: 'topic' },
    { label: 'Summary', type: 'textarea', name: 'summary' },
  ],
  "Places to See": [
    { label: 'Place Name', type: 'string', name: 'placeName' },
    { label: 'Location', type: 'string', name: 'location' },
    { label: 'Description', type: 'textarea', name: 'description' },
  ]
};
