import loadConfig from "../../../utils/configLoader";
const config = loadConfig('general');

export const getEntryTitle = (entry) => {
  let title;

  // First, grab data from title or content
  if (entry.title) {
    title = entry.title;
  } else if (entry.content) {
    // Check if content contains a newline character
    const firstLineBreakIndex = entry.content.indexOf('\n');
    if (firstLineBreakIndex !== -1) {
      // Set title to the content up to the first line break
      title = entry.content.substring(0, firstLineBreakIndex);
    } else {
      title = entry.content;
    }
  }

  // Then we check the length and reduce it if needed
  if (title && title.length > config.ui.titleCharLimitCards) {
    return title.substring(0, config.ui.titleCharLimitCards - 4) + '[..]';
  }
  
  return title;
};
