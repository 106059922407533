import { useContext } from "react";
import { UserContext } from "../contexts/user-context";

const useTheme = () => {
  const { theme, setTheme } = useContext(UserContext);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme==='dark' ? 'light' : 'dark');
  };

  return { theme, toggleTheme };
};

export default useTheme;