import './MessageCard.css';

const MessageCard = ({ message }) => {
  return (
    <div className=''>
      {message.length === 0 ?
        <div class="alert alert-warning" role="alert">
          {message}
        </div>
      : <div class="alert alert-success" role="alert">
          {message}
        </div>
      }
    </div>
  )
}

export default MessageCard;