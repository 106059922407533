import { useState } from 'react';
import { PanelLeftClose, PanelRightClose } from 'lucide-react';
import './split-view-foldable.css';

const SplitViewFoldableLayout = ({ content, panel, isPanelContrasted = false, isPanelFoldable = true }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  return (
    <div className="split-view-foldable">

      <div className="main-content">
        {content}
        {isPanelFoldable && (
          <div className="separator">
            <button
              onClick={() => setIsPanelOpen(!isPanelOpen)}
              className={`toggle-button`}
              aria-label={isPanelOpen ? 'Close panel' : 'Open panel'}
            >
              {isPanelOpen ? (
                <PanelRightClose className="toggle-button-icon" />
              ) : (
                <PanelLeftClose className="toggle-button-icon" />
              )}
            </button>
          </div>
        )}
      </div>

      <div className={`panel ${isPanelOpen ? 'panel-visible' : 'panel-hidden'} ${isPanelContrasted ? 'panel-contrasted' : ''}`}>
        {panel}
      </div>
    </div>
  );
};

export default SplitViewFoldableLayout;
