import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import "./tools.css"

// TBD: adapt code top open URL in new window
const OpenPageInNewWindowTool = ({ url }) => {

  const handleOpenInNewWindow = async (event) => {
    event.stopPropagation();
    try {
      const openPopup = (url) => {
        window.open(url, '_blank'); // You can customize width and height
      };
      openPopup(url)
    } catch (error) {
      console.error('Failed to open entry in new window:', error);
    }
  };

  return (
    <div className="clickable-tool-small" onClick={(event) => handleOpenInNewWindow(event, url)}>
    <OpenInNewIcon />
  </div>
  )
};

export default OpenPageInNewWindowTool;