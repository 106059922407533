import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import loadConfig from "../../../utils/configLoader";
import { printDebugInfo } from "../../../utils/dataUtils";
import { fetchUserFromDatabase } from "../services/user-services";
import { decryptObject } from "../../../utils/data-encryption";

const config = loadConfig('general');

const useUserData = () => {

  const { user } = useContext(UserContext);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const document = await fetchUserFromDatabase(user._id);
        const decryptedUser = decryptObject(document);      
        setData(decryptedUser);
        if(config.admin.debugMode) printDebugInfo(decryptedUser);
      } catch (error) {
        console.error('Failed to fetch user : ', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchUserData();
  }, []);

  return { data, isLoading };

};

export default useUserData;