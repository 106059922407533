// Refactor with https://chatgpt.com/c/672d522e-9b3c-800d-a901-8dae058468c7
// TBD: move API calls to /services
// TBD: move this file to /components/tables
import React, { useMemo, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../contexts/user-context';
import { decryptCol, decryptObject, encryptObject } from '../../utils/data-encryption';
import { addNewDataToCache, updateDataInCache, deleteDataFromCache } from '../../utils/caching';
import { printDebugInfo, capitalizeText } from '../../utils/dataUtils';
import { EditBooleanColumn, ReadBooleanColumn, ReadObjectColumn, EditObjectColumn, ReadListColumn, EditListColumn, ReadDateColumn, EditDateColumn } from '../../components/tables/custom-fields';
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MaterialReactTable, MRT_EditActionButtons, useMaterialReactTable } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
import loadConfig from '../../utils/configLoader';
const config = loadConfig('general');

const TableDefinition = ({ collection, dynamicQueryKey, options, dataModel }) => {

  const { user } = useContext(UserContext);

  function useCreateRecord() {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async recordDetails => {
        if (user.isAdministrator) recordDetails.isSystem = true;
  
        const encryptedRecord = encryptObject(recordDetails);
        const response = await axios.post(`/api/generic-data/${collection}/`, encryptedRecord);
        const document = response.data.document;
  
        let rawData = decryptObject(document);
        addNewDataToCache(collection, rawData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(dynamicQueryKey);
      },
    });
  };
  
  function useGetRecords() {
    return useQuery({
      queryKey: dynamicQueryKey,
      queryFn: async () => {
        try {
          const response = await axios.get(`/api/generic-data/${collection}/`);
          const documents = response.data.documents;
          const rawData = await decryptCol(documents);
          if (config.admin.debugMode) printDebugInfo(rawData);
          return rawData;
        } catch (error) {
          throw new Error(`Error fetching data: ${error.message}`);
        }
      },
      refetchOnWindowFocus: false,
    });
  };
  
  function useUpdateRecords() {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async recordDetails => {
        if (user.isAdministrator) recordDetails.isSystem = true;
  
        const encryptedRecord = encryptObject(recordDetails);
        const response = await axios.put(`/api/generic-data/${collection}/${encryptedRecord._id}`, encryptedRecord);
        const document = response.data.document;
  
        const rawData = decryptObject(document);
        updateDataInCache(collection, rawData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(dynamicQueryKey);
      },
    });
  };

  function useDeleteRecord() {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async recordId => {
        const response = await axios.delete(`/api/generic-data/${collection}/${recordId}`);
        const message = response.data.message;
        const deletedDocument = response.data.document;
  
        deleteDataFromCache(collection, recordId);
      },
      onMutate: recordId => {
        queryClient.setQueryData(dynamicQueryKey, prevRecords => prevRecords?.filter(record => record.id !== recordId));
      },
      onSettled: () => {
        queryClient.invalidateQueries(dynamicQueryKey);
      },
    });
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateRequired = (value) => value != null && value.length > 0;

  function validateRecord(record) {
    return {
      // name: !validateRequired(record.name) ? 'Name is Required' : '',
      // category: !validateRequired(record.category) ? 'Category is Required' : '',
    };
  };

  const { mutateAsync: createRecord, isPending: isCreatingRecord } = useCreateRecord();
  const { data: fetchedRecords = [], isError: isLoadingRecordsError, isFetching: isFetchingRecords, isLoading: isLoadingRecords } = useGetRecords();
  const { mutateAsync: updateRecord, isPending: isUpdatingRecord } = useUpdateRecords();
  const { mutateAsync: deleteRecord, isPending: isDeletingRecord } = useDeleteRecord();

  const columns = useMemo(
    () =>
      dataModel.map(field => {
        const column = {
          accessorKey: field.name,
          header: field.header || capitalizeText(field.name),
          size: field.colWidth ?? null,
          enableEditing: field.enableEditing !== undefined ? field.enableEditing : true,
          muiEditTextFieldProps: {
            type: field.type,
          },
        };

        if (field.type === 'boolean') {
          column.Cell = ({ row }) => <ReadBooleanColumn row={row} columnName={field.name} />;
          column.Edit = ({ row }) => <EditBooleanColumn row={row} defaultValue={field.defaultValue} columnName={field.name} header={field.header}/>;
        } 
        else if (field.type === 'date') {
          column.Cell = ({ row }) => <ReadDateColumn row={row} columnName={field.name} />;
          column.Edit = ({ row }) => <EditDateColumn row={row} defaultValue={field.defaultValue} columnName={field.name} header={field.header}/>;
        } 
        else if (field.type === 'list') {
          column.Cell = ({ row }) => <ReadListColumn row={row} columnName={field.name} />;
          column.Edit = ({ row }) => <EditListColumn row={row} defaultValue={field.defaultValue} columnName={field.name} header={field.header} values={field.values} />;
        } 
        else if (field.type === 'object') {
          column.Cell = ({ row }) => <ReadObjectColumn row={row} columnName={field.name} collection={field.collection} />;
          column.Edit = ({ row }) => <EditObjectColumn row={row} defaultValue={field.defaultValue} columnName={field.name} values={field.values} />;
        } 
        else if (field.type === 'textarea') {
          column.Cell = ({ row }) => (
            <div style={{ textAlign: 'left' }}>
              <textarea
                style={{
                  width: field.colWidth * 0.95,
                  height: Math.ceil(row._valuesCache[field.name].length / (field.colWidth / 10)) * 21,
                  resize: 'vertical', // Allow vertical resizing
                  border: '0px solid #ccc',
                  backgroundColor: 'inherit'
                }}
                value={row._valuesCache[field.name]}
                readOnly
              />
            </div>
          );
        }
        return column;
      }),
    [fetchedRecords, validationErrors]
  );

  const handleCreateRecord = async ({ values, row, table }) => {
    const newValidationErrors = validateRecord(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});

    await createRecord(values);
    table.setCreatingRow(null);
  };

  const handleSaveRecord = async ({ row, values, table }) => {
    const newValidationErrors = validateRecord(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});

    const updatedData = { _id: row.id, ...values };

    await updateRecord(updatedData);
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = row => {
    if (window.confirm('Are you sure you want to delete this data?')) {
      deleteRecord(row.id);
    }
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const table = useMaterialReactTable({
    columns,
    data: fetchedRecords,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: isDesktop && options.enablePagination,
    enableEditing: true,
    enableGrouping: options.enableGrouping,
    grouping: options.groupingColumns,
    enableColumnFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    positionActionsColumn: 'first',
    groupedColumnMode: 'false',
    rowNumberDisplayMode: 'static',
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowActions: true,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      sorting: options.sorting ?? null,
      grouping: options.groupingColumns,
      pagination: { pageSize: 25, pageIndex: 0 },
      columnVisibility: options.columnVisibility,
    },
    defaultColumn: {
      maxSize: 900,
      minSize: 20,
      size: 160,
      enableColumnActions: true,
      enableColumnDragging: false,
      enableSorting: true,
    },

    muiPaginationProps: {
      rowsPerPageOptions: [15, 25, 50, 100],
      showFirstButton: true,
      showLastButton: true,
    },
    getRowId: row => row._id,
    muiTableContainerProps: {
      sx: {
        maxHeight: isDesktop ? '60vh' : '70vh',
      },
    },
    muiToolbarAlertBannerProps: isLoadingRecordsError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateRecord,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveRecord,

    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions',
        size: isDesktop ? 120 : 80,
      },
    },

    muiTableBodyProps: {
      sx: {
        alignItems: 'start',
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(odd) > td': {
          // backgroundColor: '#f5f5f5',
        },
      },
    },

    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        whiteSpace: 'normal',
        alignItems: 'start',
      },
    }),

    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">New</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>{internalEditComponents}</DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Edit</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>{internalEditComponents}</DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {isDesktop && (
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),

    renderTopToolbarCustomActions: ({ table }) => {    
      if (options.enableCreation !== undefined ? options.enableCreation : true) {
        return (      
          <Button variant="contained" onClick={() => table.setCreatingRow(true)}>
            New
          </Button>
        );
      }
    
      return null; // Return null when the condition is not met
    },

    state: {
      isLoading: isLoadingRecords,
      isSaving: isCreatingRecord || isUpdatingRecord || isDeletingRecord,
      showAlertBanner: isLoadingRecordsError,
      showProgressBars: isFetchingRecords,
    },
  });

  return <MaterialReactTable table={table} />;
};

export const DataTable = ({ collection, dynamicQueryKey, options, dataModel }) => (
  <div className="page-table">
    <div className="table-container">
      <QueryClientProvider client={new QueryClient()}>
        <TableDefinition
          collection={collection}
          dynamicQueryKey={dynamicQueryKey}
          options={options}
          dataModel={dataModel}
        />
      </QueryClientProvider>
    </div>
  </div>
);