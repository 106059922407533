import useTheme from '../../../hooks/use-theme';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ToolbarButton = loadModule('./components/toolbars/toolbar-button.js');

const ThemeBtn = () => {

  const { theme, toggleTheme } = useTheme(); 

  return (
      <ToolbarButton label={"Dark/Light Mode"} icon={theme==='dark' ? <LightModeIcon /> : <DarkModeIcon/>} onClick={toggleTheme}/>
  );
};

export default ThemeBtn;