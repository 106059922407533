import React from 'react';
import loadConfig from './configLoader';
import WarningFullScreen from '../components/warning/warning-fullscreen';
import WarningSmall from '../components/warning/warning-small';

const config = loadConfig('general');

// Static Webpack contexts for core and custom directories
let coreContext;
try {
  coreContext = require.context(
    '../../../../client/src/core/',   // Path to core directory
    true,                             // Include subdirectories
    /\.[^/]+$/                        // Match any file with an extension (exclude folders)
  );
} catch (error) {
  console.warn("Core directory not found, unable to load core components.");
  coreContext = null;
}

let customContext;
try {
  customContext = require.context(
    '../../../../client/src/custom/', // Path to custom directory
    true,                             // Include subdirectories
    /\.[^/]+$/                        // Match any file with an extension (exclude folders)
  );
} catch (error) {
  console.warn("Custom directory not found, unable to load custom components.");
  customContext = null;
}

// Debug logging if enabled
if (false && config.admin.debugMode) {
  if (coreContext) {
    console.log("Core components:", coreContext.keys());
  }
  if (customContext) {
    console.log("Custom components:", customContext.keys());
  }
}

const loadModule = (path) => {
  try {
    let module;
    const formattedPath = `${path}`;

    if (config.admin.allowCustomization && customContext && customContext.keys().includes(formattedPath)) {
      module = customContext(formattedPath);
      if (config.admin.debugMode) console.info(`Custom component found at "${path}", replacing core component.`);
    } else if (coreContext && coreContext.keys().includes(formattedPath)) {
      module = coreContext(formattedPath);
    } else {
      console.warn(`Component "${path}" not found in either core nor custom contexts.`);
      // Return a fallback placeholder component to avoid rendering errors
      return () => <WarningSmall title={`🚧 Component not found: ${path}`} desc={"Link to homepage"} />
    }
    
    // handling non-components such as hooks, utility functions, config files, images etc.
    if (path.includes('/hooks') || path.endsWith('config.js') || path.endsWith('png') ) {
      return module.default || module;
    } 
    // handling components that must be monted using React.lazy for code-splitting
    else {
      return React.lazy(() => {
        return Promise.resolve({ default: module.default || module });
      });
    }
  } catch (error) {
    console.error(`Unable to load component dynamically from path: "${path}".`, error);
    // Return a fallback component in case of error
    return () => <WarningFullScreen title={`🚧 Error loading component: ${path}`} desc={"Link to homepage"} />
  }
};

export default loadModule;
