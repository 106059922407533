import React from 'react';
import ViewContentLayout from '../../../layouts/view/view-content-layout';
import PlaceholderCard from '../../../components/placeholders/placeholder-card';

const Content = ({ 
  toolbar 
}) => {
  return (
    <ViewContentLayout title={"Content"} toolbar={toolbar}>
      <PlaceholderCard contentCount={2}/>
      <PlaceholderCard contentCount={3}/>
      <PlaceholderCard contentCount={1}/>
    </ViewContentLayout>
  );
};

export default Content;
