import ViewContentLayout from '../../../layouts/view/view-content-layout';
import { convertUtcDateToLocalAndFormatShort } from '../../../utils/dataUtils';
import { transformObjForReactSelect } from '../../../utils/data-processing';
import { isPropertySpecific } from '../utils/flex-form-utils';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import SourceInputField from '../../links/components/source-input-field';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const SingleSelect = loadModule("./components/selects/single-select.js");
const MultiSelect = loadModule("./components/selects/multi-select.js");

const Properties = ({
  collection,
  formProperties,
  formData,
  types,
  subtypes,
  templates,
  tags,
  handleTypeChange,
  handleGenericChange,
  handleSingleSelectChange,
  handleMultiSelectChange,
  handleBooleanChange,
  handleTemplateChange,
  toolbar,
}) => {

  return (
    <ViewContentLayout title={"Properties"} toolbar={toolbar} toolbarOnMobileOnly={true}>
      <form className="properties-form">

      {/* 1. Displaying CORE fields seperately due to exceptional behaviors */}
      {true && 
        <div key={'type'} className="mb-3">
          <label htmlFor={'type'} className="form-label">
            {'Type'}
          </label>
          <div className="select-tags flex-grow-1">
            <SingleSelect
              fieldName={'type'}
              options={types}
              value={formData.type ? transformObjForReactSelect(formData.type) : null}
              onChange={(selected) => handleTypeChange(selected)}
              isClearable={false}
            />
          </div>
        </div>
      }

      {true && formProperties && formProperties.filter((property) => property.name === 'template') && 
        <div key={'template'} className="mb-3">
          <label htmlFor={'template'} className="form-label">
            {'Template'}
          </label>
          <div className="select-tags flex-grow-1">
            <SingleSelect
              fieldName={'template'}
              options={templates ? templates.filter(template => template.type?._id === formData.type?._id) : []}
              value={formData.template ? transformObjForReactSelect(formData.template) : null}
              onChange={(selected) => handleTemplateChange(selected)}
              isClearable={true}
            />
          </div>
        </div>
      }

      {/* Links */}
      { true && formProperties && formProperties.filter((property) => property.name === 'source') && 
        <SourceInputField collection={collection} value={formData['source'] } handleSingleSelectChange={handleSingleSelectChange}/>
      }

      {true && formProperties && formProperties.filter((property) => property.name === 'date') && 
        <div key={'date'} className="mb-3">
          <label htmlFor={'date'} className="form-label">
            {'Date'}
          </label>
          <input
            type={'date'}
            className="form-control custom-input-field myspecial"
            name={'date'}
            value={formData['date'] ? convertUtcDateToLocalAndFormatShort(formData['date']) : ""}
            onChange={handleGenericChange}
          />
        </div>
      }

      {/* 2. Display type-SPECIFIC properties with generic behavior */}
      {formData.type && formProperties && formProperties
        .filter(property => isPropertySpecific(formData.type, property)) 
        .map((property) => (
        <div key={property.name} className="mb-3">
          {property.type === 'boolean' ? (
            <div>
              <Checkbox
                name={property.name}
                checked={formData[property.name]}
                onChange={handleBooleanChange}
                disabled={property.disabled}
                sx={{
                  '&.MuiCheckbox-root': {
                    marginLeft: '-9px',
                    color: 'var(--color-text-primary)',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                  '&.Mui-checked': {
                    color: 'var(--color-text-primary)',
                  },
                  '&.Mui-disabled': {
                    color: 'var(--color-disabled-text)',
                    cursor: 'not-allowed',
                  }
                }}
              />
              <label className="form-check-label" htmlFor={property.name}>
                {property.label}
              </label>
            </div>
          ) : property.type === 'select' ? (
              <div>
                <label htmlFor={property.name} className="form-label">
                  {property.label}
                </label>
                <select
                  name={property.name}
                  className="form-select custom-input-field"
                  value={formData[property.name]}
                  onChange={handleGenericChange}
                  disabled={property.disabled}
                >
                  {property.options && property.options.map((option) => (
                    <option key={option._id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
          ) : property.type === 'custom-select' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <div className="select-tags flex-grow-1">
                <SingleSelect
                  fieldName={property.label}
                  options={property.options}
                  value={formData[property.name] ? { _id: formData[property.name]._id , value: formData[property.name].value, label: formData[property.name].value } : null} // Replace with utility function
                  onChange={(selected) => handleSingleSelectChange(selected, property.name)}
                  isClearable={property.isClearable}
                  isDisabled={property.disabled}
                />
              </div>
            </div>
          ) : property.type === 'custom-multiselect' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <div className="select-tags flex-grow-1">
                <MultiSelect
                  fieldName={property.label}
                  options={property.options}
                  value={formData[property.name] ? formData[property.name].map(obj => ({ _id: obj._id, value: obj.value, label: obj.value })) : null} // Replace with utility function
                  onChange={(selected) => handleMultiSelectChange(selected, property.name)}
                  isClearable={true}
                  isDisabled={property.disabled}
                />
              </div>
            </div>
          ) : property.type === 'textarea' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <textarea 
                className="form-control custom-input-field" 
                rows="3"
                name={property.name}
                value={formData[property.name]}
                onChange={handleGenericChange}
                placeholder={property.label}
                disabled={property.disabled}
              >
              </textarea>
            </div>
          ) : property.type === 'calendar' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar 
                  sx={{
                    '& .MuiDateCalendar-root': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiPickersDay-root': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiIconButton-root': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiDayCalendar-weekDayLabel': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      border: 'black'
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          ) : property.type === 'date' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <input
                type={property.type}
                className="form-control custom-input-field myspecial"
                name={property.name}
                value={formData[property.name] ? convertUtcDateToLocalAndFormatShort(formData[property.name]) : ""}
                onChange={handleGenericChange}
                disabled={property.disabled}
              />
            </div>
          ) : (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <input
                type={property.type}
                className="form-control custom-input-field"
                name={property.name}
                value={formData[property.name] || ""}
                onChange={handleGenericChange}
                disabled={property.disabled}
                placeholder={property.label}
              />
            </div>
          )}
        </div>
      ))}

      {true && formProperties && formProperties.filter((property) => property.name === 'url') && 
        <div key={'url'} className="mb-3">
          <label htmlFor={"url"} className="form-label">
            {"URL"}
          </label>
          <input
            type={"url"}
            className="form-control custom-input-field"
            name={"url"}
            value={formData["url"] || ""}
            onChange={handleGenericChange}
            placeholder={"URL"}
          />
        </div>
      }

      {true && formProperties && formProperties.filter((property) => property.name === 'tags') && 
        <div key={'tags'} className="mb-3">
          <label htmlFor={'tags'} className="form-label">
            {'Tags'}
          </label>
          <div className="select-tags flex-grow-1">
            <MultiSelect
              fieldName={'Tags'}
              options={tags}
              value={formData['tags'] ? formData['tags'].map(obj => (transformObjForReactSelect(obj))) : null}
              onChange={(selected) => handleMultiSelectChange(selected, 'tags')}
              isClearable={true}
            />
          </div>
        </div>
      }

      {true && formProperties && formProperties.filter((property) => property.name === 'isTemplate') && 
        <div key={'isTemplate'} className="mb-3">
          <div>
            <Checkbox
              name={"isTemplate"}
              checked={formData["isTemplate"]}
              onChange={handleBooleanChange}
              disabled={false}
              sx={{
                '&.MuiCheckbox-root': {
                  marginLeft: '-9px',
                  color: 'var(--color-text-primary)',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 28,
                },
                '&.Mui-checked': {
                  color: 'var(--color-text-primary)',
                },
                '&.Mui-disabled': {
                  color: 'var(--color-disabled-text)',
                  cursor: 'not-allowed',
                }
              }}
            />
            <label className="form-check-label" htmlFor={'isTemplate'}>
              {"Is Template?"}
            </label>
          </div>
        </div>
      }

      </form>
    </ViewContentLayout>
  );
};

export default Properties;
