import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  Logout as LogoutIcon,
  GppGood as GppGoodIcon,
  GppBad as GppBadIcon,
  FormatSize as FormatSizeIcon,
} from '@mui/icons-material';

import ButtonGroupSelector from "./button-group-selector";
import SafeguardBtn from './safeguard-btn';
import ThemeBtn from './theme-btn';
import NewNote from './new-note';
import NewEntryMenu from './new-entry-menu';
import MoreOptionsMenu from './more-options-menu';
import useMoreOptionsMenu from "../hooks/user-more-options-menu";

const AppToolbar = () => {

  const {
    theme,
    toggleTheme,
    isSafeguardOn,
    toggleSafeguard,
    handleUserLogout,
  } = useMoreOptionsMenu();

  // Instructions: add/remove options for both desktop and mobile
  // Format expected: label, icon, onClick OR custom component
  const moreOptionsDesktop = [
    { label: "Font Size", icon: <FormatSizeIcon />, component: <ButtonGroupSelector /> },
    { label: "Sign Out", icon: <LogoutIcon />, onClick: () => handleUserLogout() },
  ];

  const moreOptionsMobile = [
    { label: "Safeguard", icon: !isSafeguardOn ? <GppBadIcon className='red-icon' /> : <GppGoodIcon />, onClick: () => toggleSafeguard() },
    { label: "Theme", icon: theme==='dark' ? <LightModeIcon /> : <DarkModeIcon/>, onClick: () => toggleTheme() },
    { label: "Font Size", icon: <FormatSizeIcon />, component: <ButtonGroupSelector />},
    { label: "Sign Out", icon: <LogoutIcon />, onClick: () => handleUserLogout() },
  ];

  return (
    <div className='app-toolbar'>

      <div className='app-toolbar-desktop'>
        {/* 1. Direct shorcuts user can trigger in one click */}
        <NewNote />
        <NewEntryMenu />
        <SafeguardBtn />
        <ThemeBtn />

        {/* 2. Additional options via dropdown menu */}
        <MoreOptionsMenu options={moreOptionsDesktop} />
      </div>

      <div className='app-toolbar-mobile'>
        {/* 1. Direct shorcuts user can trigger in one click */}
        <NewNote />
        <NewEntryMenu />
        {/* 2. Additional options via dropdown menu */}
        <MoreOptionsMenu options={moreOptionsMobile}/>
      </div>
    </div>

  )
};

export default AppToolbar;