import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { convertUtcDateToLocalAndFormatShort } from '../../utils/dataUtils';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const SingleSelect = loadModule("./components/selects/single-select.js");
const MultiSelect = loadModule("./components/selects/multi-select.js");

const PropertiesForm = ({ formData, formProperties }) => {
  
  return (
    <form className="properties-form">

      {formProperties && formProperties.map((property) => (
        <div key={property.name} className="mb-3">
          {property.type === 'boolean' ? (
            <div>
              <Checkbox
                name={property.name}
                checked={formData[property.name]}
                onChange={property.onChange || (() => {})} // fallback no-op function
                disabled={property.disabled}
                sx={{
                  '&.MuiCheckbox-root': {
                    marginLeft: '-9px',
                    color: 'var(--color-text-primary)',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                  '&.Mui-checked': {
                    color: 'var(--color-text-primary)',
                  },
                  '&.Mui-disabled': {
                    color: 'var(--color-disabled-text)',
                    cursor: 'not-allowed',
                  }
                }}
              />
              <label className="form-check-label" htmlFor={property.name}>
                {property.label}
              </label>
            </div>
          ) : property.type === 'select' ? (
              <div>
                <label htmlFor={property.name} className="form-label">
                  {property.label}
                </label>
                <select
                  name={property.name}
                  className="form-select custom-input-field"
                  value={formData[property.name]}
                  onChange={property.onChange || (() => {})} // fallback no-op function
                  disabled={property.disabled}
                >
                  {property.options.map((option) => (
                    <option key={option._id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
          ) : property.type === 'custom-select' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <div className="select-tags flex-grow-1">
                <SingleSelect
                  fieldName={property.label}
                  options={property.options}
                  value={formData[property.name] ? { _id: formData[property.name]._id , value: formData[property.name].value, label: formData[property.name].value } : null} // Replace with utility function
                  onChange={property.onChange || (() => {})} // fallback no-op function
                  isClearable={property.isClearable}
                  isDisabled={property.disabled}
                />
              </div>
            </div>
          ) : property.type === 'custom-multiselect' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <div className="select-tags flex-grow-1">
                <MultiSelect
                  fieldName={property.label}
                  options={property.options}
                  value={formData[property.name] ? formData[property.name].map(obj => ({ _id: obj._id, value: obj.value, label: obj.value })) : null} // Replace with utility function
                  onChange={property.onChange || (() => {})} // fallback no-op function
                  isClearable={true}
                  isDisabled={property.disabled}
                />
              </div>
            </div>
          ) : property.type === 'textarea' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <textarea 
                className="form-control custom-input-field" 
                rows="3"
                name={property.name}
                value={formData[property.name]}
                onChange={property.onChange || (() => {})} // fallback no-op function
                placeholder={property.label}
                disabled={property.disabled}
              >
              </textarea>
            </div>
          ) : property.type === 'calendar' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar 
                  sx={{
                    '& .MuiDateCalendar-root': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiPickersDay-root': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiIconButton-root': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .MuiDayCalendar-weekDayLabel': {
                      color: 'var(--color-text-primary)',
                    },
                    '& .Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      border: 'black'
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          ) : property.type === 'date' ? (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <input
                type={property.type}
                className="form-control custom-input-field myspecial"
                name={property.name}
                value={formData[property.name] ? convertUtcDateToLocalAndFormatShort(formData[property.name]) : ""}
                onChange={property.onChange || (() => {})} // fallback no-op function
                disabled={property.disabled}
              />
            </div>
          ) : (
            <div>
              <label htmlFor={property.name} className="form-label">
                {property.label}
              </label>
              <input
                type={property.type}
                className="form-control custom-input-field"
                name={property.name}
                value={formData[property.name] || ""}
                onChange={property.onChange || (() => {})} // fallback no-op function
                disabled={property.disabled}
                placeholder={property.label}
              />
            </div>
          )}
        </div>
      ))}
    </form>
  );

};

export default PropertiesForm;
