import loadConfig from "./configLoader";

const { featuresAccess } = loadConfig('profiles');

/**
 * Checks whether a user has access to a specific feature based on their profile.
 * @param {string} feature - The feature to check access for.
 * @param {object} user - The user object containing profile information.
 * @returns {boolean} - True if the user has access, false otherwise.
 */
export function hasAccessToFeature(feature, user) {
  try {
    if (featuresAccess.hasOwnProperty(feature)) {
      return featuresAccess[feature].includes(user.profile);
    } else {
      console.log(`Feature '${feature}' does not have permissions set up.`);
      return false;
    }
  } catch (error) {
    console.error('Error checking access to feature: ', error.message);
    return false;
  }
}