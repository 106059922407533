// dataEncryption.js
import { AES, enc } from 'crypto-js';
import axios from 'axios';
import loadConfig from './configLoader';
import { getUserEncryptionkey, getSystemEncryptionkey } from './encryption-key';
import { printDebugInfo } from './dataUtils';

const config = loadConfig('general');

// JAN 01 - New encryption wrappers that include serialization + encryption
export function encryptValue(value, customEncryptionKey) {
  try {
    const encryptionKey = customEncryptionKey ? customEncryptionKey : getUserEncryptionkey();
    if (!encryptionKey || encryptionKey.length < 1) return null;
    const stringifiedValue = JSON.stringify(value);
    const encryptedValue = AES.encrypt(stringifiedValue, encryptionKey).toString();
    return encryptedValue; 
  } catch (error) {
    console.error('Error encrypting string:', error.message);
  }
}

export function decryptValue(value, customEncryptionKey) {
  try {
    if (!value) return;
    const encryptionKey = customEncryptionKey ? customEncryptionKey : getUserEncryptionkey();
    if (!encryptionKey || encryptionKey.length < 1) return null;
    const decryptedValue = AES.decrypt(value, encryptionKey).toString(enc.Utf8);
    const decryptedAndParsedValue = JSON.parse(decryptedValue);
    return decryptedAndParsedValue; 
  } catch (error) {
    console.error('Error decrypting string:', error.message);
  }
}

export function encryptObject(obj, customEncryptionKey) {
  try {
    const encryptedObject = {};

    let encryptionKey = "";
    if (customEncryptionKey){
      encryptionKey = customEncryptionKey;
    } else {
      if (obj.system === "true" || obj.system === true || obj.isSystem === "true" || obj.isSystem === true ) {
        encryptionKey = getSystemEncryptionkey();
      } else {
        encryptionKey = getUserEncryptionkey()
      }
    }

    if (!encryptionKey || encryptionKey.length < 1) return null;
    
    for (const [property, value] of Object.entries(obj)) {
      if(config.encryption.plainTextFields.includes(property)){
        encryptedObject[property] = value;
      } else {
        const stringifiedValue = JSON.stringify(value);
        const encryptedValue = AES.encrypt(stringifiedValue, encryptionKey).toString();
        encryptedObject[property] = encryptedValue;
      }
    }
    // encryptedObject.is_encrypted = true; // TBD uncomment when release 0.90 is done
    return encryptedObject;
  } catch (error) {
    console.error('Error encrypting object:', error.message);
  }
};

export function decryptObject(obj, customEncryptionKey) {
  try {
    // if (!obj.is_encrypted) return obj; // TBD uncomment when release 0.90 is done
    const decryptedObject = {};

    let encryptionKey = "";
    if (customEncryptionKey){
      encryptionKey = customEncryptionKey;
    } else {
      // TBD: remove obj.system after reslease 0.90 is done
      if (obj.system === "true" || obj.system === true || obj.isSystem === "true" || obj.isSystem === true ) {
        encryptionKey = getSystemEncryptionkey();
      } else {
        encryptionKey = getUserEncryptionkey();
      }
    };

    if (!encryptionKey || encryptionKey.length < 1) return null;

    for (const [property, encryptedValue] of Object.entries(obj)) {
      if(config.encryption.plainTextFields.includes(property)){
        decryptedObject[property] = encryptedValue;
      } else {
        try {
          const decryptedValue = AES.decrypt(encryptedValue, encryptionKey).toString(enc.Utf8);
          decryptedObject[property] = decryptedValue ? JSON.parse(decryptedValue) : null;
        } catch (decryptionError) {
          console.error(`Error decrypting property ${property} of objectID ${obj._id}: ${decryptionError.message}`);
          decryptedObject[property] = encryptedValue;
        }
      }
    }
    return decryptedObject;
  } catch (error) {
    console.error('Error decrypting object:', error.message);
  }
};

export async function encryptCol(collection, customEncryptionKey) {
  try {
    const encryptedCol = [];
    const encryptionKey = customEncryptionKey ? customEncryptionKey : null;
    collection.forEach((obj) => encryptedCol.push(encryptObject(obj, encryptionKey)));
    return encryptedCol;
  } catch (error) {
    console.error('Error encrypting collection:', error.message);
  }
};

export async function decryptCol(collection, customEncryptionKey) {
  try {
    const decryptedCol = [];
    const encryptionKey = customEncryptionKey ? customEncryptionKey : null;
    collection.forEach((obj) => decryptedCol.push(decryptObject(obj, encryptionKey)));
    return decryptedCol;
  } catch (error) {
    console.error('Error decrypting collection:', error.message);
  }
};


export async function reencryptUserData(oldKey, newKey) {
  try {

    const collections = ["admin-data", "tags", "habits", "user-entries", "user-records", "users"];
    
    for (const collection of collections) {
      const response = await axios.get(`/api/data-migration/encryption/user-data/${collection}`);
      const documents = response.data.documents;
      const decryptedCollection = decryptCol(documents, oldKey);
      
      for (const obj of decryptedCollection) {
        try {
          const encryptedNewObject = encryptObject(obj, newKey);
          await axios.put(`/api/data-migration/encryption/user-data/${collection}/${encryptedNewObject._id}`, encryptedNewObject);
          // console.log(`Data reencrypted successfully for collection: ${collection} and ID: ${encryptedNewObject._id}`);

        } catch (error) {
          console.error(`Error reencrypting data for collection: ${collection} : ${error.message}`);
        }
      }
    }
    console.log("Admin data updated successfully");

  } catch(error) {
    console.error('Failed to update admin data.', error);
  }
};


// Not needed for now, as we decided to keep data encrypted as-is with simple system key, not derived and not salted
export async function reencryptSystemData(oldKey, newKey) {
  try {

    const collections = ["admin-data", "tags", "habits"];

    for (const collection of collections) {
      const response = await axios.get(`/api/data-migration/encryption/system-data/${collection}`);
      const documents = response.data.documents;
      const decryptedCollection = decryptCol(documents, oldKey);
      
      for (const obj of decryptedCollection) {
        try {
          const encryptedNewObject = encryptObject(obj, newKey);
          await axios.put(`/api/data-migration/encryption/system-data/${collection}/${encryptedNewObject._id}`, encryptedNewObject);
          // console.log(`Data reencrypted successfully for collection: ${collection} and ID: ${encryptedNewObject._id}`);

        } catch (error) {
          console.error(`Error reencrypting data for collection: ${collection} : ${error.message}`);
        }
      }
    }
    console.log("Admin data updated successfully");

  } catch(error) {
    console.error('Failed to update admin data.', error);
  }
};