import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import loadConfig from '../../../utils/configLoader';

import { searchObjectInCache, searchObjectsInCache, getTemplatesFromCache, getTagsFromCache } from '../../../utils/caching';
import { transformObjForReactSelect, transformSelectedObjBeforeSaving } from '../../../utils/data-processing';

import { fetchDocumentFromDatabase, createDocumentInDatabase, updateDocumentInDatabase}  from '../../../services/database-services';
import { processFormData, getFormProperties } from '../utils/flex-form-utils';
import { processDbDocument } from '../../../utils/data-processing';
import { printDebugInfo } from '../../../utils/dataUtils';

const config = loadConfig('general');

const useFlexForm = (collection) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [formProperties, setFormProperties] = useState([]);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSaving, setIsSaving] = useState(false);
  const [manualChange, setManualChange] = useState(false);
  const updateMode = id ? true : false;

  const [types, setTypes] = useState([]);
  const [subtypes, setsubtypes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [tags, setTags] = useState([]);

  const navigate = useNavigate();
  const initialLoadComplete = useRef(false);
  const autoSaveRef = useRef();

  useEffect(() => {
    const initializeForm = async () => {
      if (!id) {
        console.log("Entering form in Creation mode");

        // Instructions: add form data fields that needs to be set to a default value below
        const defaultType = await searchObjectInCache("possible-values", {fieldCollection: collection, fieldName: "type", isDefault: true});
        setFormData({ 
          type: defaultType ? transformObjForReactSelect(defaultType) : null,
          date: new Date(), 
          // field_name: default_value,
        });
        setFormProperties(getFormProperties());
      } else {
        console.log("Entering form in Update mode");
        const dbDocument = await fetchDocumentFromDatabase(collection, id);
        const data = processDbDocument(dbDocument);
        setFormData(data);
        setFormProperties(getFormProperties(data.type));
        setLoading(false);
        if(config.admin.debugMode) printDebugInfo(data);
      }
      initialLoadComplete.current = true;

      // Fetch data necessary to populate form
      const typesFromCache = await searchObjectsInCache("possible-values", {fieldCollection: collection, fieldName: "type"});
      setTypes(typesFromCache.map(type => transformObjForReactSelect(type)).sort((a, b) => a.label.localeCompare(b.label)));

      const templatesFromCache = await searchObjectsInCache(collection, {isTemplate: true});
      setTemplates(templatesFromCache.map(template => transformObjForReactSelect(template)).sort((a, b) => a.label.localeCompare(b.label)));

      const tagsFromCache = await searchObjectsInCache("tags");
      setTags(tagsFromCache.map(tag => transformObjForReactSelect(tag)).sort((a, b) => a.label.localeCompare(b.label)));

    };

    initializeForm();
  }, [id, collection]);

  // Rebuild form when type is updated
  // Note: useEffect needed in order to re-render the form with the new field!
  useEffect(() => {
    const selectedType = formData.type;
    const rebuildFlexForm = (type) => {
      const properties = getFormProperties(formData.type);
      setFormProperties(properties);
    
    }
    if (selectedType) {
      rebuildFlexForm(selectedType);
    };
  }, [formData.type]);

  const handleTypeChange = (selected) => {
    // commented out to not trigger save when type is changed
    // if (!manualChange) {
    //   setManualChange(true);
    // }
    const selectedRelation = transformSelectedObjBeforeSaving(selected);
    setFormData({ 
      ...formData, 
      type: selectedRelation,
      template: null,
      origin: null,
    });
  };

  const handleGenericChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (!manualChange) setManualChange(true);
  };

  const handleBooleanChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    if (!manualChange) setManualChange(true);
  };

  const handleSingleSelectChange = (selected, fieldName) => {
    const selectedRelation = transformSelectedObjBeforeSaving(selected);
    setFormData({ ...formData, [fieldName]: selectedRelation });
    if (!manualChange) setManualChange(true);
  };

  const handleMultiSelectChange = (selected, fieldName) => {
    const selectedRelations = selected.map(obj => transformSelectedObjBeforeSaving(obj));
    setFormData({ ...formData, [fieldName]: selectedRelations });
    if (!manualChange) setManualChange(true);
  };

  const handleTemplateChange = (selected) => {
    if (!manualChange) setManualChange(true);
    if (selected) {
      const template = transformSelectedObjBeforeSaving(selected);
      const templateObj = templates.find(obj => obj._id === template._id);

      const propsToPropagateFromTemplate = ['title', 'content', 'tags']; // TBD: move to config
      const propagatedProps = {};
      for (const prop of propsToPropagateFromTemplate) {
        propagatedProps[prop] = templateObj[prop];
      };

      setFormData({ 
        ...formData,
        ...propagatedProps,
        template: template,
        origin: template,
      });

    } else {
      setFormData({ 
        ...formData,
        template: null,
        origin: null,
      });
    }
  };

  const saveForm = async (formData) => {
    const convertedData = processFormData(formData, formProperties);
    console.log(`Saving form in ${updateMode ? "Update" : "Creation"} mode`);
    if (autoSaveRef.current) {
      autoSaveRef.current.cancel();  // Cancel the debounced auto-save
    }
    if (updateMode && id) {
      await updateDocumentInDatabase(collection, id, convertedData);
    } else {
      const newObj = await createDocumentInDatabase(collection, convertedData);
      navigate(`/app/${collection}/edit/${newObj._id}`);
    };
    setManualChange(false);
    // if(config.admin.debugMode) printDebugInfo(formData);
  };

  useEffect(() => {
    autoSaveRef.current = debounce(async (formData) => {
      setIsSaving(true);
      await saveForm(formData);
      setTimeout(() => setIsSaving(false), config.autoSaving.indicatorDelay);
    }, config.autoSaving.interval);

    // Cleanup function to cancel debounced autoSave
    return () => {
      if (autoSaveRef.current) {
        autoSaveRef.current.cancel(); // Cancel any pending auto-save
      }
    };
  }, [id, formProperties, collection]);  // Dependencies on form properties and ID

  // Handle form updates and trigger auto-save
  useEffect(() => {
    if (initialLoadComplete.current && manualChange && Object.keys(formData).length !== 0) {
      autoSaveRef.current(formData);  // Use the debounced function from useRef
    }
  }, [formData, manualChange]);
  

  return {
    id,
    formData,
    formProperties,
    loading,
    isSaving,
    types,
    subtypes,
    templates,
    tags,
    handleTypeChange,
    handleGenericChange,
    handleBooleanChange,
    handleSingleSelectChange,
    handleMultiSelectChange,
    handleTemplateChange,
    saveForm,
  };
};

export default useFlexForm;