// habits.js
import moment from 'moment';

import { getDataFromCache, getHabitsDataCache } from './caching';


export function getHabitScore(habit, value){

  if (habit) {
    const habitDetails = getDataFromCache('habits', habit);
    const weight = habitDetails.weight;
    const factor = 
      value === true ? 1 : 
      value === false ? -1 : 
      0; 
    const score = weight * factor;
    return score;
  }
  return 0;
}

export function getHabitCount(habit, value){

  if (getHabitScore(habit, value) > 0) return 1;
  if (getHabitScore(habit, value) < 0) return 0; // could be -1 to account for detrimental behavior
  return 0;

}

export function getDailyScore(entry){

  try {
    if (entry.habits && entry.habits.length > 0) {
      const applicableHabits = entry.habits.filter((entryHabit) => {
        const habit = entryHabit[0];
        const habitDetails = getDataFromCache('habits', habit);
        return isHabitApplicable(habitDetails, entry.date)
      });

      const totalWeight = applicableHabits.reduce((accumulator, entryHabit) => {
        const habit = entryHabit[0];
        const habitDetails = getDataFromCache('habits', habit);
        const weight = habitDetails.weight;
        return accumulator + weight;
      }, 0);

      const totalScore = entry.habits.reduce((accumulator, selectedEntryHabit) => {
        const selectedHabit = selectedEntryHabit[0];
        const value = selectedEntryHabit[1].value;
        const weight = getDataFromCache('habits', selectedHabit).weight;
        const factor = 
          value === true ? 1 : 
          value === false ? -1 : 
          0; 
        const subScore = weight * factor;
        return accumulator +  subScore;
      }, 0);

      const dailyScore = (totalWeight !== 0) ? (100 * totalScore / totalWeight) : 0;
      return Math.round(dailyScore);

    }
    return null

  } catch (error) {
    console.log("Error in getDailyScore: " + error);
    return 0;
  }
};

// not sure still in use
export function getDailyScoreByHabit(entryHabit, habitsData) {

  const habit = entryHabit[0];
  const value = entryHabit[1].value;

  if (habit) {
    const weight = habit.weight ? habit.weight : habitsData[habit._id]?.weight;   // we check if weight was stored when habit got selected, otherwise we get current value from cache
    const factor = 
      value === true ? 1 : 
      value === false ? -1 : 
      0; 
    const score = weight * factor;
    return score;
  }
  return 0;
};

export function getScorePerEntry(entries) {

  try {
    const scorePerEntry = entries
    .map(entry => {
      return {
        x: moment(entry.date).startOf('day').format('YYYY-MM-DD'),
        y: entry.type !== 'dummy' ? getDailyScore(entry) : null
      }
    }); 

    return scorePerEntry;  

  } catch (error) {
    console.error('Error transforming data:', error);
    throw error;  }
};

export function getRandomHabit() {

  const habits = Object.values(getHabitsDataCache()); 
  if (habits.length > 0){
    const randomElement = habits[0];
    return randomElement;
  } else {
    console.log(`No matching elements found in habits : ${habits}`);
    return null;
  }
};

export function isLastDayOfWeek(date) {

  if (date == null || date === undefined || !(date instanceof Date)) return null;

  const momentDate = moment(date);
  const getDay = momentDate.isoWeekday();
  
  return getDay === 7;

};

export function isLastDayOfMonth(date) {

  if (date == null || date === undefined || !(date instanceof Date)) return null;

  const momentDate1 = moment(date);
  const momentDate2 = momentDate1.clone();

  return momentDate1.isSame(momentDate2.endOf('month'), 'day');

};

export function isLastDayOfQuarter(date) {

  if (date == null || date === undefined || !(date instanceof Date)) return null;

  const momentDate1 = moment(date);
  const momentDate2 = momentDate1.clone();

  return momentDate1.isSame(momentDate2.endOf('quarter'), 'day');

};

export function isLastDayOfYear(date) {

  if (date == null || date === undefined || !(date instanceof Date)) return null;

  const momentDate1 = moment(date);
  const momentDate2 = momentDate1.clone();

  return momentDate1.isSame(momentDate2.endOf('year'), 'day');

};

export function isHabitApplicable(habit, entryDate) {
  
  if (!habit.isActive) return false;
  
  let result = false;
  const frequency = habit.frequency;
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const date = entryDate !== undefined ? new Date(entryDate) : new Date();

  if (frequency === 'Daily') {
    result = true;
  } else if (frequency === 'Weekly') {
    result = isLastDayOfWeek(date);
  } else if (frequency === 'Monthly') {
    result = isLastDayOfMonth(date);
  } else if (frequency === 'Quarterly') {
    result = isLastDayOfQuarter(date);
  } else if (frequency === 'Yearly') {
    result = isLastDayOfYear(date);
  }
  return result;

};

export function isHabitSelected(habit, entry) {
  if (entry.habits && entry.habits.length > 0) {
    if (entry.habits.some(entryHabit => {
      const habitObj = entryHabit[0];
      const value = entryHabit[1].value;
      return habitObj.name === habit.name && value !== null
    })) {
      return true;
    }
  }
  return false;
}


export function getDurationInDays(timeUnit) {
  const currentDate = moment();
  let endDate;

  switch (timeUnit.toLowerCase()) {
    case 'week':
      endDate = currentDate.clone().add(1, 'week');
      break;
    case 'month':
      endDate = currentDate.clone().add(1, 'month');
      break;
    case 'quarter':
      endDate = currentDate.clone().add(3, 'months');
      break;
    case 'year':
      endDate = currentDate.clone().add(1, 'year');
      break;
    default:
      throw new Error('Invalid time unit');
  }

  const durationInDays = endDate.diff(currentDate, 'days');
  return Math.round(durationInDays);
};

export function isHabitActive(habit) {
  return getDataFromCache('habits', habit)?.isActive;
};

export function isHabitInCategory(habit, categoryId) {
  const habitCategoryId = getDataFromCache('habits', habit)?.category;
  return habitCategoryId === categoryId
};

export function isHabitInCategories(habit, categories) {
  const habitCategoryName = getDataFromCache('habits', habit)?.category?.name;
  if (categories.map((category) => category.name).includes(habitCategoryName)) return true
  return false;
};

export function entryHasCategories(entry, categories) {
  if (!entry.habits || entry.habits.length === 0) return false;

  for (const entryHabit of entry.habits) {
    const habit = entryHabit[0];
    const habitCategoryName = getDataFromCache('habits', habit)?.category?.name;
    if (categories.map((category) => category.name).includes(habitCategoryName)) {
      return true;
    }
  }
  return false;
}
