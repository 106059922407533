import ViewContentLayout from '../../../layouts/view/view-content-layout';
import { TextAreaWithFocus } from "../../focus-mode/components/text-area-with-focus";

const Content = ({
  formData,
  handleGenericChange,
  isSaving,
  toolbar,
}) => {

  return (
    <ViewContentLayout title={"Content"} toolbar={toolbar}>
      <form className="content-form">
        <div className="content-form-title mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control custom-input-field"
            name="title"
            placeholder="Title.."
            onChange={handleGenericChange}
            value={formData.title || ""}
            style={{ fontWeight: "bold" }}
          />
        </div>

        <div className="content-form-content">
          <TextAreaWithFocus
            fieldName={"content"}
            fieldValue={formData["content"] || ""}
            onChange={handleGenericChange}
            handleGenericChange={handleGenericChange}
          />
        </div>

        <div className="mb-3">
          {isSaving && (
            <p className="form-saving-indicator">Saving form.. </p>
          )}
        </div>
      </form>
    </ViewContentLayout>
  )
};

export default Content;
