import loadModule from '../../../utils/moduleLoader';
const LoginForm = loadModule('./features/authentication/components/login-form.js');

const LoginView = () => {
  return (
    <div className="view">
      <LoginForm />
    </div>
  );
};

export default LoginView;
