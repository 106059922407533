import axios from 'axios';

export const fetchDocumentFromDatabase = async (collection, id) => {
  const response = await axios.get(`/api/generic-data/${collection}/${id}`);
  const dbDocument = response.data.document;
  return dbDocument;
};

// API calls are sequentially - slower but more predictable and resource-efficient
// Deprecetad until concurrent approach is no longer needed / possible
export const fetchDocumentsFromDbSequential = async (collections) => {
  try {

    const flattenedData = [];

    for (const collection of collections) {
      try {
        const response = await axios.get(`/api/generic-data/${collection}`);
        const documents = response.data.documents;
        documents.forEach((document) => { document.collection = collection });
        flattenedData.push(...documents);
      } catch (error) {
        console.error(`Failed to fetch data for ${collection}:`, error);
      }
    }

    return flattenedData;

  } catch (error) {
    console.error('Failed to fetch data:', error);
    return [];
  } 
};

// API calls are concurrently - faster but resource-intense
export const fetchDocumentsFromDbConcurrent = async (collections) => {
  try {

    const dataPromises = collections.map(async (collection) => {
      const response = await axios.get(`/api/generic-data/${collection}`);
      const documents = response.data.documents;
      documents.forEach((document) => { document.collection = collection });
      return documents;
    }); 

    const tempData = await Promise.all(dataPromises);
    const flattenedData = tempData.flat();
    
    return flattenedData;

  } catch (error) {
    console.error('Failed to fetch data:', error);
    return [];
  } 
};

export const createDocumentInDatabase = async (collection, data) => {
  const response = await axios.post(`/api/generic-data/${collection}`, data);
  const dbDocument = response.data.document;
  return dbDocument;
};

export const updateDocumentInDatabase = async (collection, id, data) => {
  const response = await axios.put(`/api/generic-data/${collection}/${id}`, data);
  const dbDocument = response.data.document;
  return dbDocument;
};

export const deleteDocumentFromDatabase = async (collection, id) => {
  await axios.delete(`/api/generic-data/${collection}/${id}`);
};

export const getAllDocumentsCount = async (collection) => {
  const response = await axios.get(`/api/system-admin/db/count/${collection}`);
  return response.data.count;
};

export const getUserDocumentsCount = async (collection) => {
  const response = await axios.get(`/api/generic-data/${collection}`);
  return response.data.documents.length;
};