import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ViewContentLayout from '../../../layouts/view/view-content-layout';
import ViewToolbar from './toolbar';
import { getUserParametersForView } from '../../../utils/caching';
import { UserContext } from '../../../contexts/user-context';
import useData from '../../../hooks/use-data';
import useFiltering from '../hooks/use-filtering';
import useSorting from '../hooks/use-sorting';
import usePagination from '../hooks/use-pagination';
import DataEntryCard from './data-entry-card';
import DataEntryBlock from './data-entry-block';
import FilterResultAlert from './filter-results-alert';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const Spinner = loadModule("./components/loading/spinner.js");
const SpinnerMore = loadModule("./components/loading/SpinnerMore.js");

const DataRendering = ({viewId, viewName, viewDesc, viewFilters}) => {

  const { userParameters } = useContext(UserContext);
  const [ isLoading, setIsLoading ] = useState(true);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tagname = queryParams.get('tag');
  const query = queryParams.get('query');
  const { collections } = viewFilters;
  
  // TBD: evaluate all the below then Finally set isLoading to false?
  const { data, isLoading: isLoadingHomeData } = useData(collections);
  const { filters, filteredData, isLoading: isLoadingFilteredData } = useFiltering(viewId, data, search, tagname, query);
  const { sortedData, isLoading: isLoadingSortedData } = useSorting(viewId, filteredData);
  const { currentPageItems, setCurrentPageItems, lastItemRef, loadingMore, isLoading: isLoadingPaginatedData } = usePagination(sortedData);
  const layout =  getUserParametersForView(userParameters, viewId, 'layout');

  // Effect to manage overall loading state
  useEffect(() => {
    if (!isLoadingHomeData && !isLoadingFilteredData && !isLoadingSortedData && !isLoadingPaginatedData) {
      setIsLoading(false);
    }
  }, [isLoadingHomeData, isLoadingFilteredData, isLoadingSortedData, isLoadingPaginatedData]);

  const onDeletion = (id) => {
    setCurrentPageItems((prev) => prev.filter((entry) => entry._id !== id));
  }

  return (
    <ViewContentLayout 
      title={viewName}
      subtitle={viewDesc}
      toolbar={<ViewToolbar viewId={viewId} viewFilters={viewFilters} />}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='view-content'>
          {((filters && Object.keys(filters).length !== 0) || tagname || query) && 
            <FilterResultAlert viewId={viewId} numberOfEntries={filteredData?.length}/>
          }
          {currentPageItems.map((item, index) => (
            layout === "list" ? (
              <DataEntryBlock 
                key={index}
                object={item.flexDataContainer || item}
                type={item.type}
                onDeletion={onDeletion}
              />
            ) : (
              <DataEntryCard 
                key={index}
                object={item.flexDataContainer || item}
                type={item.type}
                onDeletion={onDeletion}
              />
            )
          ))}
          {/* Ref for the last item to trigger fetching more data */}
          <div ref={lastItemRef} style={{ height: '1px', overflow: 'hidden' }}></div>
          {loadingMore && (
              <SpinnerMore />
          )}
        </div>
      )}
    </ViewContentLayout>
  );
};

export default DataRendering;
