import axios from 'axios';
import { getUserEncryptionkey, getSystemEncryptionkey } from './encryption-key';
import { decryptCol, encryptObject } from './data-encryption'; 
import { initializeUserDataCache }  from './caching';
import loadConfig from './configLoader';
import { printDebugInfo } from './dataUtils';
import { data_migration_client_side_release090 } from './data-migration';

const config = loadConfig('general');

export function useInitializeUserSession() {

  return async function initializeUserSession(
    user, 
    setSplashScreenMessage, 
    setSplashScreenPc,
    setIsUserSessionInitialized,
  ) {
    try {
      setSplashScreenPc(10);
      setSplashScreenMessage('Initializing user session..');
      console.log('Initializing user session..');

      // challenge the use of the hook as condition, not sure if needed
      if (!checkIfUserIsInitialized(user)) {
        setSplashScreenMessage('Setting up new user..');
        await handleUsersFirstLogin(user);
        await markUserAsInitialized(user);
        setIsUserInitialized(true);
        setSplashScreenPc(15);
      }

      // TBD - challenge the use of the hook as condition, not sure if needed
      if (!checkIfUserIsMigrated(user) && isDataMigrationApplicable(user)) {
        setSplashScreenMessage('Migrating user data..');
        await migrateData(user);
        await markUserAsMigrated(user);
        setIsUserMigrated(true);
        setSplashScreenPc(20);
      }

      if (!checkIfUserCacheIsLoaded()) {
        setSplashScreenMessage('Caching user data..');
        await cacheUserData();
        setIsUserCacheLoaded(true);
        setSplashScreenPc(90);
      }

      // run this at the end to mark user as initialized if first log in
      // if (!user.isInitialized  && !isUserInitialized) {
      //   setSplashScreenMessage('Marking user as initialized');
      //   setSplashScreenPc(90);
      //   await markUserAsInitialized(user)
      // }
      setSplashScreenPc(100);     
      setIsUserSessionInitialized(true);
      setUserSessionExpirationTs();
      console.log("User session initialized successfully, redirecting user to homepage.");
      
    } catch (error) {
      console.error(error);
    }
  };
};

export async function handleUsersFirstLogin(user) {
  
  if (!user.isAdministrator && user._id !== '64925594474888c49e850678') {
    console.log("Setting up new user..")
    const userEncryptionKey = getUserEncryptionkey();
    if (config.dataInitialization.isActive) await initializeAdminData(user, userEncryptionKey);
    await encryptUserInfo(user, userEncryptionKey); // encrypt user data inherited from sign up?
    // ..
    console.log("New user setup successfully.");
  }
};

export async function initializeAdminData(user, userEncryptionKey) {
  try {
    console.log("Initializing admin data");

    const collections = config.dataInitialization.collections;
    const mappingOldNew = {}; // {oldId: newId};

    for (const collection of collections) {
      await initializeCollection(collection, user, userEncryptionKey, mappingOldNew);
      console.log(`Data initialized successfully for collection: ${collection}`);
    }

    console.log(`Data initialization completed successfully for all collections: ${collections}`);
  } catch (error) {
    console.error('Admin data initialization failed.', error);
    throw error; // Re-throwing the error to propagate it to the outer catch block
  }
};

export async function initializeCollection(collection, user, userEncryptionKey, mappingOldNew) {
  try {
    const response = await axios.get(`/api/user-admin/initialize/${collection}`);
    const decryptedCollection = await decryptCol(response.data.documents);

    for (const obj of decryptedCollection) {
      try {
        const {
          // removing core fields as they can't be passed in body to create new object
          _id,
          __v,
          creationDate,
          createdBy,
          lastModificationDate,
          lastModifiedBy,
          isSystem,
          category,
          // isolating specific fields via ...rest
          ...rest
        } = obj;
        const oldId = _id;
        const newObj = {
          ...rest,
        };
        if (category) { // add category conditionally since not present on all collections
          const oldCategoryId = category._id;
          newObj.category = { _id: mappingOldNew[oldCategoryId], value: category.value};
        }
        
        
        const encryptedNewObject = encryptObject(newObj, userEncryptionKey);
        const createdObject = await axios.post(`/api/user-admin/initialize/${collection}/`, encryptedNewObject);
        const newId = createdObject.data.document._id;
        mappingOldNew[oldId] = newId;

        // Uncomment the line below if you want to log successful initialization for each object
        // console.log(`Data initialized successfully for collection: ${collection} and ID: ${newId}`);
      } catch (error) {
        console.error(`Error creating data for collection: ${collection} : ${error.message}`);
      }
    }
  } catch (error) {
    console.error(`Failed to fetch data for collection: ${collection}`, error);
    throw error; // Re-throwing the error to propagate it to the outer catch block
  }
};

export async function migrateTestUser(userEncryptionKey) {
  try {
    console.log("Remapping relations after generating test data");

    const responseAdminData = await axios.get(`/api/generic-data/admin-data`); 
    const adminData = await decryptCol(responseAdminData.data.documents);
    const collections = [['tags','tag-category'], ['habits', 'habit-category']];

    for (const collection of collections) {
      const response = await axios.get(`/api/generic-data/${collection[0]}`);
      const decryptedCollection = await decryptCol(response.data.documents);

      for (const obj of decryptedCollection) {
        const { _id, category, ...rest } = obj;

        if (category) {
          const matchingCategory = adminData.find(data => data.type === collection[1] && data.value === category.value);
          const newCategoryId = matchingCategory ? matchingCategory._id : null; // or any default value you prefer
          const updatedObj = {
            ...rest,
            category: { _id: newCategoryId, value: category.value},
          };

          const encryptedObject = encryptObject(updatedObj, userEncryptionKey);
          await axios.put(`/api/generic-data/${collection[0]}/${_id}`, encryptedObject);
        }
      }
      console.log(`Collection ${collection[0]} migrated successfully`);
    }

    // specific to user entries
    const responseHabitData = await axios.get(`/api/generic-data/habits`); 
    const habitsData = await decryptCol(responseHabitData.data.documents);
    const response = await axios.get(`/api/generic-data/user-entries`);
    const decryptedCollection = await decryptCol(response.data.documents);

    for (const obj of decryptedCollection) {
      const { _id, entryHabits, ...rest } = obj;

      if (entryHabits?.length > 0) {
        const newEntryHabits = [];
        for (const entryHabit of entryHabits) {
          const habit = entryHabit.value;
          const value = entryHabit.result;

          const matchingHabit = habitsData.find(data => data.value === habit.value);
          const newHabitId = matchingHabit ? matchingHabit._id : null; // or any default value you prefer
          newEntryHabits.push({_id: newHabitId, value: value})
        }
        const updatedObj = {
          ...rest,
          habits: newEntryHabits,
        };

        const encryptedObject = encryptObject(updatedObj, userEncryptionKey);
        await axios.put(`/api/generic-data/user-entries/${_id}`, encryptedObject);
      }
    }
    console.log(`Collection user-entries migrated successfully`);

    console.log(`Test user initialized successfully`);

  } catch (error) {
    console.error(`Failed to initialize test user`, error);
    throw error; // Re-throwing the error to propagate it to the outer catch block
  }
};

export function isDataMigrationApplicable(user){
  // Update as needed
  return true
  // return (user._id === '64925594474888c49e850678')
  // return false
};

export async function migrateData(user) {
  if (user.isTest) {
    const userEncryptionKey = getUserEncryptionkey(); 
    // await migrateTestUser(userEncryptionKey);
    // console.log("User migrated successfully.");
  }
  else {
    // Instructions: replace migration function when migration is needed, comment out after
    await data_migration_client_side_release090(user)
    console.log("User migrated successfully.");
  }

};

export async function cacheUserData() {
  try {
    console.log("Caching user data..");
    await initializeUserDataCache();
    // setIsCacheInitialized(true); //no longer needed?, context should handle it when state is stored and pulled from localstorage
    console.log("User data cached successfully.");      
  } catch (error) {
    console.error(error);
  }
};

export async function markUserAsInitialized(user) {
  try {
    const updatedFields = { 
      isInitialized: true,
    }
    await axios.put(`/api/user/${user._id}`, updatedFields);
    console.log("User marked as initialized.")

  } catch(error) {
    console.error('Could not mark user as initialized.', error);
  }
};

export async function markUserAsMigrated(user) {
  try {
    const updatedFields = { 
      isMigrated: true,
    }
    await axios.put(`/api/user/${user._id}`, updatedFields);
    console.log("User marked as migrated.")

  } catch(error) {
    console.error('Could not mark user as migrated.', error);
  }
};

export const encryptUserInfo = async (user, userEncryptionKey) => {
  try {
    console.log("Encrypting user information..");
    const encryptedUser = { 
      ...user, 
      ...encryptObject(user, userEncryptionKey)};

    await axios.put(`/api/user/${user._id}`, encryptedUser);
    
    console.log("User information encrypted successfully.")

  } catch(error) {
    console.error('Could not encrypt user information.', error);
  }
};

// export const checkIfUserSessionIsInitialized = () => {
//   try {
//     return (localStorage.getItem('is-user-session-initialized'))

//   } catch (error) {
//     console.error(`Error checking is-user-session-initialized in local storage: ${error.message}`);
//   }
// };

// export function setIsUserSessionInitialized() {
//   try {
//     localStorage.setItem('is-user-session-initialized', true);
//   } catch (error) {
//     console.error(`Error setting is-user-session-initialized in local storage: ${error.message}`);
//   }
// };

export function setUserSessionExpirationTs() {
  try {
    const userSessionExpirationTs = new Date().getTime() + config.userSession.timeout;
    localStorage.setItem('user-session-exp-ts', userSessionExpirationTs);
  } catch (error) {
    console.error(`Error setting user-session-exp-ts in local storage: ${error.message}`);
  }
};

export const checkUserSessionExpiration = () => {
  try {
    const now = new Date().getTime();
    const userSessionExpirationTs = localStorage.getItem('user-session-exp-ts');
    // condition that determines if a user sessin is valid, could be improved with cache, token, etc.
    const isUserSessionExpired = userSessionExpirationTs ? now > userSessionExpirationTs : undefined;
    return isUserSessionExpired;
  } catch (error) {
    console.error(`Error checking user-session-exp-ts in local storage: ${error.message}`);
  }
};

export const checkIfUserIsInitialized = (user) => {
  try {
    // check both user value and local storage to know if the user was ever initialized
    return (user.isInitialized || localStorage.getItem('is-user-initialized'))

  } catch (error) {
    console.error(`Error checking is-user-initialized in local storage: ${error.message}`);
  }
}

export function setIsUserInitialized() {
  try {
    localStorage.setItem('is-user-initialized', true);
  } catch (error) {
    console.error(`Error setting is-user-initialized in local storage: ${error.message}`);
  }
};

export const checkIfUserIsMigrated = (user) => {
  try {
    // check both user value and local storage to know if the user was ever migrated
    return (user.isMigrated || localStorage.getItem('is-user-migrated'))

  } catch (error) {
    console.error(`Error checking is-user-migrated in local storage: ${error.message}`);
  }
}

export function setIsUserMigrated() {
  try {
    localStorage.setItem('is-user-migrated', true);
  } catch (error) {
    console.error(`Error setting is-user-migrated in local storage: ${error.message}`);
  }
};

export const checkIfUserCacheIsLoaded = () => {
  try {
    return (localStorage.getItem('is-user-cache-loaded'))

  } catch (error) {
    console.error(`Error checking is-user-cache-loaded in local storage: ${error.message}`);
  }
}

export function setIsUserCacheLoaded() {
  try {
    localStorage.setItem('is-user-cache-loaded', true);
  } catch (error) {
    console.error(`Error setting is-user-cache-loaded in local storage: ${error.message}`);
  }
};


