import './action-row.css';

const DropdownActionRow = ({icon, title, titleColor, description, dropdownValues, handleDropdownChange,selectedValue, buttonName, buttonClass, handleAction, isLast}) => {
  return (
    <div>
      <div className="action-row">
        {icon && 
          <div className="action-col icon">
            {icon}
          </div>    
        }
        <div className="action-col text">
          <h4 className="action-title" style={{color: titleColor}}>{title}</h4>
          <p className="action-desc">{description}</p>

          <div className="col-md-6">
            {/* handle dropdown */}
            {dropdownValues && 
              <select 
                className="form-select action-dropdown custom-input-field" 
                aria-label="Default select example"
                onChange={handleDropdownChange}
                value={selectedValue}
              >
                {dropdownValues.map((value) => {
                  return <option value={value} key={value}>{value}</option>
                })}            
              </select>
            }
          </div>
        </div>
        <div className="action-col button">
          <button type="button" className= {buttonClass ? buttonClass : "btn btn-primary"} onClick={handleAction}>{buttonName}</button>
        </div>
      </div>
      {isLast ? null : <hr className="hr" />}
    </div>
  )
};

export default DropdownActionRow;