// Direct Imports - Protected Modules
import { useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Router must be imported for the app to work correctly!
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserContext } from '../core/contexts/user-context';
import StandardLayout from '../core/layouts/app/standard-layout';
import SidebarLayout from '../core/layouts/app/sidebar-layout';
import Header from '../core/layouts/app/header';
import HeaderVisitor from '../core/layouts/app/header-visitor';
import Sidebar from '../core/layouts/app/sidebar';
import Footer from '../core/layouts/app/footer';
import Visitor from '../core/pages/visitor';
import TermsOfService from '../core/pages/terms-of-service';
import PrivacyPolicy from '../core/pages/privacy-policy';
import CookiePolicy from '../core/pages/cookie-policy';
import Login from '../core/pages/login';
import Signup from '../core/pages/signup';
import Home from '../core/pages/home';
import System from '../core/pages/system-admin';
import ImportExport from '../core/pages/import-export';
import User from '../core/pages/user-admin';
import Unauthorized from '../core/pages/unauthorized';
import NotFound from '../core/pages/not-found';
import Data from '../core/pages/data-admin';
import FlexForm from '../core/pages/flex-form';
import Notes from '../core/pages/notes';
import Test from '../core/pages/test';
import PrivateRoute from '../core/features/authentication/components/private-route';
import EncryptionKeyModal from '../core/features/encryption/components/encryption-key-modal';
import Entries from './pages/entries';
import Records from './pages/records';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../core/utils/moduleLoader';
const SpinnerFullScreen = loadModule('./components/loading/spinner-fs.js');
const Spinner = loadModule('./components/loading/spinner.js');
const SplashScreen = loadModule('./components/loading/SplashScreen.js');
loadModule('./styles/app.css');
loadModule('./styles/modals.css');
loadModule('./styles/bootstrap-overrides.css');
loadModule('./styles/mui-overrides.css');
loadModule('./styles/toolbars.css');

function App() {

  const { user, isUserEncryptionKeyLoaded, isLoading, showSplashScreen, splashScreenMessage, splashScreenPc, theme } = useContext(UserContext);

  // APP OVERLAYS
  // desc: components that must be displayed instead of anything else (spinners, modals etc.)

  if (showSplashScreen) {
    return <SplashScreen message={splashScreenMessage} percentage={splashScreenPc} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (user && !isUserEncryptionKeyLoaded) {
    return <EncryptionKeyModal loginMode={true} />;
  }

  const muiDarkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const muiLightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  // END OF APP OVERLAYS, RENDERING APP..
  return (
    <ThemeProvider theme={theme === "dark" ? muiDarkTheme : muiLightTheme}> 
      <div className={`app ${theme}`}>
        <Suspense fallback={<SpinnerFullScreen />}>
          <Routes>
            {/* Layout for Unprotected Routes */}
            <Route element={<StandardLayout header={<HeaderVisitor />} footer={<Footer />} />}>
              <Route path="/" element={<Visitor />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/policy/terms-of-service" element={<TermsOfService />} />
              <Route path="/policy/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/policy/cookie-policy" element={<CookiePolicy />} />
            </Route>

            {/* Layout for Protected Routes */}
            <Route element={<PrivateRoute />}>
              <Route element={<SidebarLayout sidebar={<Sidebar />} header={<Header />} footer={<Footer />} />}>
                <Route path="/app" element={<Home />} />
                <Route path="/app/home" element={<Home />} />
                <Route path="/app/search" element={<Home />} />
                <Route path="/app/data" element={<Data />} />
                <Route path="/app/notes" element={<Notes />} />
                <Route path="/app/notes/new" element={<FlexForm collection="notes" />} />
                <Route path="/app/notes/edit/:id" element={<FlexForm collection="notes" />} />
                <Route path="/app/entries" element={<Entries />} />
                <Route path="/app/entries/new" element={<FlexForm collection="entries" />} />
                <Route path="/app/entries/edit/:id" element={<FlexForm collection="entries" />} />
                <Route path="/app/records" element={<Records />} />
                <Route path="/app/records/new" element={<FlexForm collection="records" />} />
                <Route path="/app/records/edit/:id" element={<FlexForm collection="records" />} />
                <Route path="/app/system" element={<System />} />
                <Route path="/app/user" element={<User />} />
                <Route path="/app/test" element={<Test />} />
                <Route path="/app/import-export" element={<ImportExport />} />
              </Route>
            </Route>

            <Route path="/app/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NotFound />}/>
            
          </Routes>
        </Suspense>

        {/* In-app overlays */}
        {(user && !isUserEncryptionKeyLoaded) && <EncryptionKeyModal loginMode={false} />}
      </div>
    </ThemeProvider>
  );
}

export default App;