import loadConfig from "../../../utils/configLoader";
const { routesAccess } = loadConfig('profiles');

/**
 * Checks whether a user has access to a specific route based on their profile.
 * @param {string} route - The route to check access for.
 * @param {object} user - The user object containing profile information.
 * @returns {boolean} - True if the user has access, false otherwise.
 */
export function hasAccessToRoute(route, user) {
  try {
    // Check if the exact route is defined in routesAccess
    if (routesAccess.hasOwnProperty(route)) {
      return routesAccess[route].includes(user.profile);
    }

    // If not, check if any parent route grants access
    for (const definedRoute of Object.keys(routesAccess)) {
      if (route.startsWith(definedRoute)) {
        return routesAccess[definedRoute].includes(user.profile);
      }
    }

    // If no matching parent route is found, log an error
    console.log(`Route '${route}' does not exist or does not have permissions set up.`);
    return undefined;
  } catch (error) {
    console.error('Error checking access to route: ', error.message);
    return undefined;
  }
}
