import { Button, Modal } from 'react-bootstrap';
import useEncryptionKeyModal from '../hooks/use-encryption-modal';
import './encryption-key-modal.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadConfig from '../../../../core/utils/configLoader';
const config = loadConfig('general');

const EncryptionKeyModal = ({ loginMode }) => {
  const {
    showModal,
    passphrase,
    setPassphrase,
    theme,
    handleSave,
    handleKeyPress,
    handleLogout,
  } = useEncryptionKeyModal(loginMode);

  return (
    <div>
      <Modal show={showModal} centered={false} className={theme}>
        <Modal.Header>
          <Modal.Title>User Passphrase Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-warning-message'>
            {config.encryptionKeyWarning}
          </div>
          <input 
            type="password" 
            className="form-control" 
            name="passPhrase" 
            onChange={(e) => setPassphrase(e.target.value)}
            onKeyPress={handleKeyPress}
            required 
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleLogout}
          >
            Log Out
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (passphrase) {
                handleSave();
              }
            }}
            disabled={!passphrase} // Disable the button if encryptionKey is empty
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EncryptionKeyModal;
