import axios from "axios";

export const clearTempDirectory = async () => {
  try {
    await axios.post('/api/system-admin/clear-temp-dir');
    alert('Temp files deleted successfully!');
  } catch (error) {
    console.error('Error deleting temp files:', error);
    alert('Error deleting temp files. Please try again.');
  }
};

export const runServerPreMigration = async () => {
  try {
    await axios.post(`/api/data-migration/run-server-side-pre-migration`);
  } catch (error) {
    console.error(error);
    alert("An error occurred during server-side pre-migration.");
  }
};

export const runServerPostMigration = async () => {
  try {
    await axios.post(`/api/data-migration/run-server-side-post-migration`);
  } catch (error) {
    console.error(error);
    alert("An error occurred during server-side post-migration.");
  }
};

export const resetIsMigratedFlagOnUsers = async () => {
  try {
    await axios.post(`/api/data-migration/users/reset-is-migrated`);
  } catch (error) {
    console.error(error);
    alert("An error occurred whe resetting isMigrated flag on users.");
  }
};

export const resetIsInitializedFlagOnUsers = async () => {
  try {
    await axios.post(`/api/data-migration/users/reset-is-initialized`);
  } catch (error) {
    console.error(error);
    alert("An error occurred whe resetting isInitialized flag on users.");
  }
};

export const dumpDatabase = async (database) => {
  try {
    const response = await axios.get(`/api/system-admin/db/dump/${database}`, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error(`Error dumping database : ${database}`, error);
    throw new Error('Error dumping database. Please try again.');
  }
};

export const restoreDatabaseDump = async (importedFile) => {
  try {
    const formData = new FormData();
    formData.append('file', importedFile);

    await axios.post('/api/system-admin/db/restore', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error restoring database: ', error);
    throw new Error('Error restoring database. Please try again.');
  }
};

export const resetDatabase = async () => {
  try {
    await axios.post(`/api/system-admin/db/reset`);
  } catch (error) {
    console.error('Error resetting database:', error);
    throw new Error('Error resetting database. Please try again.');
  }
};

export const exportSingleCollection = async (collection) => {
  try {
    const response = await axios.get(`/api/system-admin/db/export-collection/${collection}`, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error('Error exporting collection:', error);
    throw new Error('Error exporting data. Please try again.');
  }
};

export const exportAllCollections = async () => {
  try {
    const response = await axios.get('/api/system-admin/db/export-collections', { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error('Error exporting collections:', error);
    throw new Error('Error exporting data. Please try again.');
  }
};

export const importCollections = async (importedFile) => {
  try {
    const formData = new FormData();
    formData.append('file', importedFile);

    await axios.post('/api/system-admin/db/import-collections', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error importing database: ', error);
    throw new Error('Error importing database. Please try again.');
  }
};
