// replaces admin-data table for now
export const adminPossibleValues = [
  { _id: 1, collection: 'notes', fieldName: 'type', value: 'Fleeting Note', default: true },
  { _id: 1, collection: 'notes', fieldName: 'type', value: 'Literature Note' },
  { _id: 1, collection: 'notes', fieldName: 'type', value: 'Permanent Note' },
  { _id: 1, collection: 'notes', fieldName: 'type', value: 'Index Note' },
  { _id: 1, collection: 'journal-entries', fieldName: 'type', value: 'Personal Journal', default: true },
  { _id: 1, collection: 'journal-entries', fieldName: 'type', value: 'Work Journal' },
  { _id: 1, collection: 'journal-entries', fieldName: 'type', value: 'Job Search' },
  { _id: 1, collection: 'records', fieldName: 'type', value: 'Running', default: true },
  { _id: 1, collection: 'records', fieldName: 'type', value: 'Workout' },
  { _id: 1, collection: 'records', fieldName: 'type', value: 'Checklist' },
];

export const templatesTest = [
  { _id: 1, value: 'journal', label: 'Journal' },
  { _id: 2, value: 'essay', label: 'Essay' },
  { _id: 3, value: 'thoughts', label: 'Thoughts' },
];

export const tagsTest = [
  { _id: 1, value: 'technology', label: 'Technology' },
  { _id: 2, value: 'health', label: 'Health' },
  { _id: 3, value: 'science', label: 'Science' },
  { _id: 4, value: 'education', label: 'Education' },
  { _id: 5, value: 'environment', label: 'Environment' },
  { _id: 6, value: 'business', label: 'Business' },
  { _id: 7, value: 'sports', label: 'Sports' },
  { _id: 8, value: 'entertainment', label: 'Entertainment' },
  { _id: 9, value: 'politics', label: 'Politics' },
  { _id: 10, value: 'travel', label: 'Travel' },
  { _id: 11, value: 'food', label: 'Food' },
  { _id: 12, value: 'fashion', label: 'Fashion' },
  { _id: 13, value: 'finance', label: 'Finance' },
  { _id: 14, value: 'culture', label: 'Culture' },
  { _id: 15, value: 'lifestyle', label: 'Lifestyle' },
  { _id: 16, value: 'wellness', label: 'Wellness' },
  { _id: 17, value: 'history', label: 'History' },
  { _id: 18, value: 'literature', label: 'Literature' },
  { _id: 19, value: 'music', label: 'Music' },
  { _id: 20, value: 'art', label: 'Art' }
];

export const entryTypes = [
  { _id: "6541cf31fda1f4124ceadb97", value: 'journal', label: 'Journal' },
  { _id: 2, value: 'notes', label: 'Notes' },
  { _id: 3, value: 'thoughts', label: 'Thoughts' },
];

export const recordTypes = [
  { _id: 1, value: 'assets', label: 'Assets' },
  { _id: 2, value: 'job-applications', label: 'Job Applications' },
  { _id: 3, value: 'nutrition', label: 'Nutrition' },
  { _id: 4, value: 'checklist', label: 'Checklist' },
  { _id: 5, value: 'household', label: 'Household' },
  { _id: 6, value: 'jobs', label: 'Jobs' },
  { _id: 7, value: 'journaling', label: 'Journaling' },
  { _id: 8, value: 'life-hack', label: 'Life Hack' },
  { _id: 9, value: 'my-journal', label: 'My Journal' },
  { _id: 10, value: 'productivity', label: 'Productivity' },
  { _id: 11, value: 'recipe', label: 'Recipe' },
  { _id: 12, value: 'software-idea', label: 'Software Idea' },
  { _id: 13, value: 'software-inspiration', label: 'Software Inspiration' },
  { _id: 14, value: 'things-to-do', label: 'Things To Do' },
  { _id: 15, value: 'video-idea', label: 'Video Idea' },
  { _id: 16, value: 'idea', label: 'Idea' },
  { _id: 17, value: 'running', label: 'Running' },
  { _id: 18, value: 'medical', label: 'Medical' },
  { _id: 19, value: 'fitness', label: 'Fitness' },
  { _id: 20, value: 'travel', label: 'Travel' },
  { _id: 21, value: 'fitness-level', label: 'Fitness Level' },
  { _id: 22, value: 'workout', label: 'Workout' },
  { _id: 23, value: 'dream', label: 'Dream' },
  { _id: 24, value: 'finance', label: 'Finance' },
  { _id: 25, value: 'budgeting', label: 'Budgeting' },
  { _id: 26, value: 'business-idea', label: 'Business Idea' },
  { _id: 27, value: 'memo', label: 'Memo' },
  { _id: 28, value: 'review', label: 'Review' },
  { _id: 29, value: 'bilan', label: 'Bilan' },
  { _id: 30, value: 'release-notes', label: 'Release Notes' },
  { _id: 31, value: 'retrospective', label: 'Retrospective' },
  { _id: 32, value: 'quotes', label: 'Quotes' },
  { _id: 33, value: 'cycling', label: 'Cycling' },
  { _id: 34, value: 'places-to-see', label: 'Places to See' }
];

export const recordSubtypes = {
  running : [
    { _id: 1, value: 'trail-running', label: 'Trail Running' },
    { _id: 2, value: 'road-running', label: 'Road Running' },
    { _id: 3, value: 'treadmill-running', label: 'Treadmill Running' },
    { _id: 4, value: 'interval-training', label: 'Interval Training' },
    { _id: 5, value: 'long-distance', label: 'Long Distance' },
    { _id: 6, value: 'hill-repeats', label: 'Hill Repeats' },
    { _id: 7, value: 'tempo-run', label: 'Tempo Run' },
    { _id: 8, value: 'recovery-run', label: 'Recovery Run' },
    { _id: 9, value: 'fartlek', label: 'Fartlek' },
    { _id: 10, value: 'sprint', label: 'Sprint' }
  ],
  
}

export const recordTemplates = {
  running : [
    { _id: 6, value: 'long-run', label: 'Long' },
    { _id: 7, value: 'tempo-run', label: 'Tempo Run' },
    { _id: 8, value: 'recovery-run', label: 'Recovery Run' },
  ],
  
}