import React, { useState } from 'react';

const BootstrapContent = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="container">

      {/* Badges */}
      <div className="mb-3">
        <h2>Demo Test Page <span className="badge bg-secondary">New</span></h2>
      </div>

      {/* Accordion */}
      <div className="accordion mb-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Accordion Item #1
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Accordion Item #2
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Accordion Item #3
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
            </div>
          </div>
        </div>
      </div>
      
      {/* Alerts */}
      <div className="mb-3">
        <div className="alert alert-primary" role="alert">
          A simple primary alert—check it out!
        </div>
        <div className="alert alert-secondary" role="alert">
          A simple secondary alert—check it out!
        </div>
      </div>

      {/* Card */}
      <div className="mb-3">
        <div className="card" style={{ width: '18rem' }}>
          <img src="https://via.placeholder.com/150" className="card-img-top" alt="Example" />
          <div className="card-body">
            <h5 className="card-title">Card title</h5>
            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>

      {/* Progress */}
      <div className="mb-3">
        <label htmlFor="exampleProgress" className="form-label">Example Progress</label>
        <div className="progress" id="exampleProgress">
          <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
        </div>
      </div>

      {/* Modal */}
      <div className="mb-3">
        <button type="button" className="btn btn-primary" onClick={toggleModal}>
          Launch demo modal
        </button>

        {showModal && (
          <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Modal title</h5>
                  <button type="button" className="btn-close" onClick={toggleModal}></button>
                </div>
                <div className="modal-body">
                  <p>Modal body text goes here.</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                  <button type="button" className="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Textarea */}
      <div className="mb-3">
        <label htmlFor="exampleTextarea" className="form-label">Example Textarea</label>
        <textarea className="form-control" id="exampleTextarea" rows="3"></textarea>
      </div>

      {/* Input */}
      <div className="mb-3">
        <label htmlFor="exampleInput" className="form-label">Example Input</label>
        <input type="text" className="form-control" id="exampleInput" placeholder="Enter text here" />
      </div>

      {/* Button */}
      <div className="mb-3">
        <button type="button" className="btn btn-primary">Primary Button</button>
      </div>

      {/* Dropdown */}
      <div className="mb-3">
        <label htmlFor="exampleDropdown" className="form-label">Example Dropdown</label>
        <select className="form-select" id="exampleDropdown">
          <option defaultValue>Select an option</option>
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
          <option value="3">Option 3</option>
        </select>
      </div>

      {/* Table */}
      <div className="mb-3">
        <h3 className="mb-3">Example Table</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>John</td>
              <td>Doe</td>
              <td>@johndoe</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jane</td>
              <td>Smith</td>
              <td>@janesmith</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Bob</td>
              <td>Johnson</td>
              <td>@bobjohnson</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BootstrapContent;
