import LockIcon from '@mui/icons-material/Lock';
import './SplashScreen.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadConfig from '../../utils/configLoader';
import loadModule from '../../utils/moduleLoader';
const config = loadConfig('general');
const AppLogo = loadModule("./components/branding/app-logo.js"); // using loadModule here because this component CAN be customized directly

const SplashScreen = ({ message, percentage }) => {
  return (
    <div className="splash-screen">
      <div className="splash-screen-content">

        <div className="splash-screen-brand">
          <AppLogo size={"medium"} />
          <div className="splash-screen-brand-name">{config.brandName}</div>
        </div>

        <div className="splash-screen-message">
          <div>{message}</div>
        </div>

        <div className="progress" role="progressbar" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${percentage}%` }}></div>
        </div>

        <div className="splash-screen-footer">
          <LockIcon style={{ fontSize: '1rem' }}/>
          <div>End-to-End Encrypted</div>
        </div>

      </div>
    </div>
  );
};

export default SplashScreen;
