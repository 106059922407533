import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/user-context';
import { hasAccessToRoute } from '../utils/user-access-control';

const PrivateRoute = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const currentPath = location.pathname;

  if (!user) {
    return <Navigate to="/login" />;
  }

  return hasAccessToRoute(currentPath, user) ? <Outlet /> : <Navigate to="/app/unauthorized" />;
};

export default PrivateRoute;