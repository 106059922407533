import loadModule from '../../core/utils/moduleLoader';
const Main = loadModule('./features/home/views/main.js');

const Home = () => {

  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default Home;