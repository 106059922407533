import loadModule from '../../../utils/moduleLoader';
const SignupForm = loadModule('./features/authentication/components/signup-form.js');

const SignupView = () => {
  return (
    <div className="view">
      <SignupForm />
    </div>
  );
};

export default SignupView;
