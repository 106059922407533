import React, { useState } from 'react';
import useModal from './use-modal';

const useFocusMode = (collection, id, fieldName) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [modalContent, setModalContent] = useState('');

  const handleCancel = () => {
    closeModal();
    setModalContent('');
  };

  const handleClose = () => {
    closeModal();
    setModalContent('');
  };

  const handleSave = async () => {
    try {
      // await updateEntry({ fieldName: modalContent });
      closeModal();
      setModalContent('');
    } catch (error) {
      console.error('Error saving new entry:', error);
    }
  };

  // const updateEntry = async () => {
  //   const defaultType = await searchObjectInCache("possible-values", {fieldCollection: collection, fieldName: "type", isDefault: true});
  //   let formData = {
  //     type: defaultType ? transformObjForReactSelect(defaultType) : null,
  //     content: modalContent,
  //   };
  //   const formProperties = getFormProperties();
  //   const newEntry = processFormData(formData, formProperties);
  //   await createDocumentInDatabase(collection, newEntry);
  // };

  return {
    isOpen,
    openModal,
    handleClose,
    modalContent,
    setModalContent,
    handleSave,
    handleCancel,
  };
};

export default useFocusMode;
