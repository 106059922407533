import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const StatsCard = () => {
  const title = "Performance Stats";
  const stats = [
    { label: 'Page Views', value: 12000, max: 20000, unit: "" },
    { label: 'User Sign-ups', value: 800, max: 1000, unit: "" },
    { label: 'Revenue', value: 15000, max: 25000, unit: "$" },
    { label: 'Customer Satisfaction', value: 85, max: 100, unit: "%" }
  ];

  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <Row>
          {stats.map((stat, index) => (
            <Col key={index} xs={12} className="mb-3">
              <div className="d-flex justify-content-between">
                <div>
                  <h6>{stat.label}</h6>
                  <small>Max: {stat.max}{stat.unit}</small>
                </div>
                <div>
                  <h4>
                    {stat.unit}{stat.value}
                  </h4>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default StatsCard;
