import { useEffect, useState } from "react";
import { fetchDocumentsFromDbConcurrent } from "../services/database-services";
import { decryptCol } from "../utils/data-encryption";
import { transformData } from "../utils/data-processing";

const useData = (collections) => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDecryptTransformData = async () => {
      try {
        const documents = await fetchDocumentsFromDbConcurrent(collections);
        const decryptedData = await decryptCol(documents);      
        const transformedData = await transformData(decryptedData);
        setData(transformedData);
      } catch (error) {
        console.error('Failed to fetch, decrypt or transform data: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchDecryptTransformData();
  }, [collections]); 

  return { data, isLoading };

};

export default useData;