import { Button, Modal } from 'react-bootstrap';
import AddBoxIcon from '@mui/icons-material/AddBox';
import useNewNote from '../hooks/use-new-note';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../../core/utils/moduleLoader';
const ToolbarButton = loadModule('./components/toolbars/toolbar-button.js');

const NewNote = ({ collection = "notes" }) => {
  const {
    isOpen,
    openModal,
    handleClose,
    modalContent,
    setModalContent,
    handleSave,
    handleCancel,
  } = useNewNote(collection);

  return (
    <div>
      <ToolbarButton label={"New Note"} icon={<AddBoxIcon />} onClick={openModal} />

      <Modal
        dialogClassName="modal-fullscreen-lg-down modal-lg modal-new-note"
        show={isOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex flex-column h-100">
          <textarea
            placeholder="Content.."
            className="form-control flex-grow-1 modal-custom-textarea"
            id="content"
            value={modalContent}
            onChange={(e) => setModalContent(e.target.value)}
          ></textarea>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewNote;
