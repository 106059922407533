import { encryptObject } from "../../../../core/utils/data-encryption";
import { getEndOfDayUTC } from "../../../../core/utils/dataUtils";
import { internal, core, specific }  from '../config/properties';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
import loadConfig from "../../../../core/utils/configLoader";
const config = loadConfig('general');

export const processFormData = (formData, properties) => {

  // rebuild form data so it can be sent to dabase with expected format
  let result = {
    flexDataContainer : {},
  };

  // update date format
  const date = formData.date ? formData.date : new Date();
  const utcDate = getEndOfDayUTC(date);
  formData.date = utcDate;
  // formData.type = transformSelectedObjBeforeSaving(formData.type); // already transformed within the form before getting here

  // iterate over formdata, look for specific properties (non-core) and add corresponding key-value pairs to new object
  Object.entries(formData).forEach(([key, value]) => {
    // we only add non-internal properties to result because internal props are handled in back-end
    if(!internal.map(property => property.name).includes(key) && formData[key] != null) {
      // check if property/key in formData is still relevant (may have changed since last update if Type was changed)
      if ((['title', 'content', 'type'].includes(key)) || (properties && properties.map(property => property.name).includes(key))){
        // add specific key-value pair to flexDataContainer
        result.flexDataContainer[key] = value;
      }
    };
  });

  // encrypt document (when applicable)
  if(config.encryption.isActive) {
    result = encryptObject(result);
  };

  return result;
};

export const resetFormData = (formData) => {

  const newFormData = { ...formData };
  // iterate over formdata, look for specific properties (non-core) and delete corresponding key-value pairs
  Object.keys(newFormData).forEach((key) => {
    // check if property is core or specific
    if(!core.map(property => property.name).includes(key) && newFormData[key] != null) {
      // delete specific key-value pair to flexDataContainer
      delete newFormData[key];
    };
  });

  return newFormData;
};

// export const transformDbRelations = (dbDocument) => {
//   let newObj = { ...dbDocument };
//   Object.entries(newObj).forEach(([key, value]) => {
//     if
//   })
//   for (let key in newObj) {
//     if (newObj.hasOwnProperty(key)) {
//       newObj[key] = newObj.flexDataContainer[key];
//     }
//   }
//   return newObj;
// }

export const getFormProperties = (type) => {
  try {
    return [
      ...core,      
      ...(specific[type?.value] || []),
      ...(config.forms.showInternalFields ? internal : []),
    ];
  } catch (error) {
    console.error(`Error getting form properties for type: ${type} : ${error.message}`);
    return [];
  }
};

export const isPropertySpecific = (type, property) => {
  try {
    if (!type?.value || !property?.name) {
      console.error(`Invalid type or property: type=${type}, property=${property}`);
      return false;
    }

    return specific[type.value]?.some((prop) => prop.name === property.name) || false;
  } catch (error) {
    console.error(`Error getting property's type for property "${property?.name}": ${error.message}`);
    return false;
  }
};

export const updateFormProperties = (properties, name, key, value) => {
  let index = properties.findIndex(prop => prop.name === name);
  if (index !== -1) {
    properties[index][key] = value;
  }
}