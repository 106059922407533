import React from "react";
import LinkIcon from '@mui/icons-material/Link';
import "./tools.css"

const OpenLinkInNewTabTool = ({ url, displayUrl }) => {

  const handleClick = async (event) => {
    event.stopPropagation(); // stop event from propagating and triggering onClick on parent's component
    try {
      const openTab = (url) => {
        window.open(url, '_blank'); // You can customize width and height
      };
      openTab(url)
    } catch (error) {
      console.error('Failed to open entry in new window:', error);
    }
  };

  return (
    <div className="clickable-url" onClick={(event) => handleClick(event, url)}> 

      {/* <div className="hyperlink-std me-2"><LinkIcon /></div> */}
      
      {displayUrl && <p className="clickable-url-full hyperlink-std"><LinkIcon />[{url}]</p>}
      
    </div> 
  )
};

export default OpenLinkInNewTabTool;