import loadModule from '../../../../core/utils/moduleLoader';
import config from '../config';

// Loading following components dynamically to allow one-off customization
const DataRendering = loadModule("./features/data-rendering/components/data-rendering.js");

const Content = () => {
  return (
    <DataRendering
      viewId={config.viewId}
      viewName={config.viewName}
      viewDesc={config.viewDesc}
      viewFilters={config.viewFilters}
    />
  );
};

export default Content;