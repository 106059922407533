import { FilterAlt, Edit, MoreHorizOutlined, SaveOutlined } from '@mui/icons-material';
import '../../../styles/toolbars.css';

const HomeToolbar = () => {

  return (
    <div className="view-toolbar">
      <div className="view-toolbar-icon custom-shadow" onClick={() => alert("TBD") }>
        <Edit />
      </div>
      <div className="view-toolbar-icon custom-shadow" onClick={() => alert("TBD") }>
        <SaveOutlined />
      </div>
      <div className="view-toolbar-icon custom-shadow" onClick={() => alert("TBD") }>
        <FilterAlt />
      </div>
      <div className="view-toolbar-icon custom-shadow" onClick={() => alert("TBD") }>
        <MoreHorizOutlined />
      </div>

    </div>
  );
  
};

export default HomeToolbar;