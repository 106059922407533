import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { capitalizeFirstLetter } from '../../../utils/dataUtils';

import './focus-modal.css';

const FocusModal = ({ isOpen, closeModal, modalContent, setModalContent, fieldName, handleGenericChange }) => {

  const handleClose = () => {
    closeModal();
    setModalContent('');
  };

  const handleChange = (e) => {
    setModalContent(e.target.value);
    handleGenericChange(e);
  }

  return (
    <Modal dialogClassName='modal-fullscreen-xl-down modal-xl focus-modal' show={isOpen} onHide={handleClose}>
      
      <Modal.Header closeButton>
        <Modal.Title>{capitalizeFirstLetter(fieldName)}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column h-100">
        <textarea
          placeholder="Content.."
          className="form-control flex-grow-1 modal-custom-textarea"
          name={fieldName}
          value={modalContent}
          onChange={handleChange}
        ></textarea>
      </Modal.Body>

      <Modal.Footer>
        {/* Note: no longer need Save or Cancel because updates are automatically synched to form and save every X seconds, thanks to auto-save */}
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>

    </Modal>
    
  );
}

export default FocusModal;
