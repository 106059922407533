import loadModule from '../../core/utils/moduleLoader';
const Main = loadModule('./features/policies/components/cookie-policy.js');

const CookiePolicy = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default CookiePolicy;