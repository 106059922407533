import loadConfig from "../../utils/configLoader";

const config = loadConfig('general');

const domain = '.io';
const appUrl = 'https://www.' + config.appName.toLowerCase() + domain;
const appProviderUrl = 'www.' + config.appName.toLowerCase() + domain;
const appContact = 'contact@' + config.appName.toLowerCase() + domain;
const appTosUrl = appUrl + '/policy/terms-of-service';
const appPrivacyPolicyUrl = appUrl + '/policy/privacy-policy';
const appCookiePolicysUrl = appUrl + '/policy/cookie-policy';

export const appInformation = {
  VAR_APP_NAME: config.appName,
  VAR_APP_URL: appUrl,
  VAR_APP_PROVIDER_URL: appProviderUrl,
  VAR_APP_CONTACT: appContact,
  VAR_APP_TOS_URL: appTosUrl,
  VAR_APP_PRIVACY_POLICY_URL: appPrivacyPolicyUrl,
  VAR_APP_COOKIE_POLICY_URL: appCookiePolicysUrl,
  '"<strong>Company</strong>," ': '', // trick to remove "Company" from policies
};