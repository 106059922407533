import loadModule from '../utils/moduleLoader';
const Main = loadModule('./features/flex-form/views/main.js');

const FlexForm = ({ collection }) => {
  return (
    <div className='page'>
      <Main collection={collection}/>
    </div>
  );
};

export default FlexForm;