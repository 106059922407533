import { useState } from 'react';
import useModal from '../../../hooks/use-modal';
import { createDocumentInDatabase } from '../../../services/database-services';
import { processFormData } from '../../flex-form/utils/flex-form-utils';
import { getFormProperties } from '../../flex-form/utils/flex-form-utils';
import { searchObjectInCache } from '../../../utils/caching';
import { transformObjForReactSelect } from '../../../utils/data-processing';

const useNewNote = (collection) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [modalContent, setModalContent] = useState('');

  const handleCancel = () => {
    closeModal();
    setModalContent('');
  };

  const handleClose = () => {
    closeModal();
    setModalContent('');
  };

  const handleSave = async () => {
    try {
      await saveNewEntry({ content: modalContent });
      closeModal();
      setModalContent('');
    } catch (error) {
      console.error('Error saving new entry:', error);
    }
  };

  const saveNewEntry = async () => {
    const defaultType = await searchObjectInCache("possible-values", {fieldCollection: collection, fieldName: "type", isDefault: true});
    let formData = {
      type: defaultType ? transformObjForReactSelect(defaultType) : null,
      content: modalContent,
    };
    const formProperties = getFormProperties();
    const newEntry = processFormData(formData, formProperties);
    await createDocumentInDatabase(collection, newEntry);
  };

  return {
    isOpen,
    openModal,
    handleClose,
    modalContent,
    setModalContent,
    handleSave,
    handleCancel,
  };
};

export default useNewNote;
