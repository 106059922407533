import MoreVertIcon from "@mui/icons-material/MoreVert";

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../../core/utils/moduleLoader';
const ToolbarMenu = loadModule('./components/toolbars/toolbar-menu.js');

const MoreOptionsMenu = ({ options }) => {

  return (
    <div>
      <ToolbarMenu menuIcon={<MoreVertIcon />} options={options} />
    </div>
  )
}

export default MoreOptionsMenu;