import axios from 'axios';

export const fetchUserFromDatabase = async (id) => {
  const response = await axios.get(`/api/user/${id}`);
  const dbDocument = response.data.document;
  return dbDocument;
};

export const updateUserInDatabase = async (id, data) => {
  const response = await axios.put(`/api/user/${id}`, data);
  const dbDocument = response.data.document;
  return dbDocument;
};

export const deleteUserFromDatabase = async (id) => {
  try {
    await axios.delete(`/api/user/${id}`).catch(console.error);
  } catch (error) {
    console.error('Error deleting user account: ', error);
    alert('Error deleting user account. Please try again.');
  }
};