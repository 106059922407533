import ViewContentLayout from '../../../layouts/view/view-content-layout';
import UserInfoForm from './user-info-form';
import UserSettingsForm from './user-settings-form';
import UserAdminForm from './user-admin-form';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const FoldableCard = loadModule('./components/cards/foldable-card.js');

const Content = ({data}) => {

  return (
    <ViewContentLayout 
      title={"User Profile"}
      subtitle={"All your user settings"}
    >
      {/* <FoldableCard title={'User Information'} expandable={true}>
        <UserInfoForm user={data} />
      </FoldableCard> */}

      <FoldableCard title={'User Settings'} expandable={true}>
        <UserSettingsForm user={data} />
      </FoldableCard>

      <FoldableCard title={'User Administration'} expandable={true}>
        <UserAdminForm user={data} />
      </FoldableCard>
    </ViewContentLayout>
  );
};

export default Content;