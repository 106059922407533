import React, { useMemo, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import './sidebar-layout.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
import loadConfig from '../../utils/configLoader';
const config = loadConfig('general');
const SpinnerFullScreen = loadModule('./components/loading/spinner-fs.js');

const SidebarLayout = React.memo(({ sidebar, header, footer, transparentNavbar }) => {

  const location = useLocation();

  const memoizedSidebar = useMemo(() => sidebar, [sidebar]);
  const memoizedHeader = useMemo(() => header, [header]);
  const memoizedFooter = useMemo(() => footer, [footer]);

  const showFooter = () => {
    try {
    // Return false if any of the specified paths are included in the current pathname
    const shouldHideFooter = config.ui.hideFooterOnRoutes.some(route => location.pathname.includes(route));
    return !shouldHideFooter; // Return false if a route is matched, otherwise true
    } catch(error) {
      console.warn("Error in function showFooter, returning true by default");
      return true
    }
  };

  return (
    <div className="sidebar-layout">
      {sidebar && <div className="sidebar-layout-sidebar">{memoizedSidebar}</div>}

      <div className="sidebar-layout-right-container">
        {header && <div className={`sidebar-layout-header fixed-top ${transparentNavbar ? 'transparent' : ''}`}>{memoizedHeader}</div>}

        <div className="sidebar-layout-page">
          <div className="sidebar-layout-main">
            <Suspense fallback={<SpinnerFullScreen />}>
              <Outlet />
            </Suspense>
          </div>

          {footer && showFooter() && <div className="sidebar-layout-footer">{memoizedFooter}</div>}
        </div>
      </div>
    </div>
  );
});

export default SidebarLayout;
