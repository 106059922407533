import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import loadConfig from "../../../utils/configLoader";

// Loading config dynamically to allow one-off customization
const config = loadConfig('general');

const useTypingAgent = ({ fullText }) => {
  const {dailyDigest, setDailyDigest} = useContext(UserContext)
  const [generatedText, setGeneratedText] = useState('');

  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    if (isTyping && fullText !== dailyDigest) {
      const typingSpeed = config.ui.typingSpeed; // Adjust typing speed here - TBD add config parameter
      const pauseAfterDot = typingSpeed * 2; // Adjust this multiplier for the pause duration after a dot


      if (config.ui.typingAgentMode === 'char') {
        // mode 1: char by char
        let index = 0;
        const typingInterval = setInterval(() => {
          if (index < fullText.length){
            let current = fullText[index];
            setGeneratedText(prevValue => prevValue + current);
            index += 1;

            // If the current character is a dot, increase the interval
            // if (current === '.') {
            //   clearInterval(typingInterval);
            //   setTimeout(() => {
            //     setIsTyping(true);
            //   }, pauseAfterDot);
            //   return;
            // }

          } else {
            console.log("Done iterating, setting context var..")
            setIsTyping(false);
            setDailyDigest(fullText);
          }
        }, typingSpeed); // Adjust typing speed here - TBD add config parameter

        return () => clearInterval(typingInterval);
      } else if (config.ui.typingAgentMode === 'word') {
        // mode 2: word by word
        let index = 0;
        const words = fullText.split(/\s+/); // Split the fullText by spaces
      
        const typingInterval = setInterval(() => {
          if (index < words.length){
            let current = words[index];
            setGeneratedText(prevValue => prevValue + (prevValue ? " " : "") + current);
            index += 1;

            // Add pause if the current word ends with a dot
            // if (current.endsWith('.')) {
            //   clearInterval(typingInterval);
            //   setTimeout(() => {
            //     setIsTyping(true);
            //   }, pauseAfterDot);
            //   return;
            // }

          } else {
            setIsTyping(false);
            setDailyDigest(fullText);
          }
        }, typingSpeed); // Adjust typing speed here - TBD add config parameter

        return () => clearInterval(typingInterval);
      }
    }
  }, [isTyping, fullText]);

  return {
    generatedText
  };
};

export default useTypingAgent;