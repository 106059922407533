import useTheme from '../../../hooks/use-theme';
import useSafeguard from '../../../hooks/use-safeguard';
import useLogout from '../../../hooks/use-logout';

const useMoreOptionsMenu = () => {
  const { theme, toggleTheme } = useTheme(); 
  const { isSafeguardOn, toggleSafeguard } = useSafeguard(); 
  const { handleUserLogout } = useLogout();

  return {
    theme,
    toggleTheme,
    isSafeguardOn,
    toggleSafeguard,
    handleUserLogout,
  };
};

export default useMoreOptionsMenu;
