// TBD: pretty complex component, keeping as-is for now since used temporarily fo testing
import axios from 'axios';
import moment from 'moment';
import loadConfig from '../../../utils/configLoader';

import { decryptCol, encryptObject } from '../../../utils/data-encryption';
import { initializeUserEncryptionKey } from '../../../utils/encryption-key';
import { markUserAsInitialized } from '../../../utils/data-initialization';
import { searchObjectInCache } from '../../../utils/caching';

const config = loadConfig('general');

const userEntriesSample = require('../../../testing/data/user-entries-sample.json');
const userRecordsSample = require('../../../testing/data/user-records-sample.json');


export async function createTemplateUser(
  startDate,
  endDate,
  numberOfEntriesPerDay,
  createEntryHabits,
  createEntryTags,
  numberOfRecordsPerDay,
  createRecordTags
  ) {
  
  try {
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    console.log("numberOfEntriesPerDay:", numberOfEntriesPerDay);
    console.log("createEntryHabits:", createEntryHabits);
    console.log("createEntryTags:", createEntryTags);
    console.log("numberOfRecordsPerDay:", numberOfRecordsPerDay);
    console.log("createRecordTags:", createRecordTags);

    const testUserPassword = "123456"; //same for all test users
    const userPassphrase = "123456"; //same for all test users

    const numberOfHabitsMin = 7;
    const numberOfHabitsMax = 17;
    const tagsMin = 1;
    const tagsMax = 4;
    
    const users = ['template'];


    const generateUserData = async (username) => {

      let userId = undefined; // will be set when user is created
      let userEncryptionKey = undefined; // will be initialized after user is created

      const deletePreviousTestData = async () => {
        try {
          const response = await axios.get(`/api/user/username/${username}`);
          const user = response.data.user;
          if(user){      
            await axios.delete(`/api/user/${user._id}`); // hanldes deletion of user and all its data
            console.log(`User ${username} delete successfully`);
          }
        } catch (error) {
          console.log(`Error deleting user ${username}`);
        }
      } 

      const createUser = async () => {
        try {
          const user = {
            email: username,
            username: username,
            password: testUserPassword,
          };

          const response = await axios.post('/api/user/', user);
          const newUser = response.data.user;

          if(newUser) {
            userId = newUser._id;
            console.log(`New user created successfully: ${username}`);
          }

        } catch (error) {
          console.log(`Error creating user: ${username}`);
        }
      };

      // tweaked original variation in dataInitialization.js to bypass createdBy: req.user._id in data-initilization route
      const initializeAdminData = async(user, userEncryptionKey) => {
        try {
          console.log("Initializing admin data");
      
          const collections = config.dataInitialization.collections; // ['possible-values', 'tags', 'habits']
          const mappingOldNew = {}; 
      
          for (const collection of collections) {
            await initializeCollection(collection, user, userEncryptionKey, mappingOldNew);
            console.log(`Data initialized successfully for collection: ${collection}`);
          }
      
          console.log(`Data initialization completed successfully for all collections: ${collections}`);
        } catch (error) {
          console.error('Admin data initialization failed.', error);
          throw error; // Re-throwing the error to propagate it to the outer catch block
        }
      };

       // tweaked original variation in dataInitialization.js to bypass createdBy: req.user._id in data-initilization route
      const initializeCollection = async(collection, user, userEncryptionKey, mappingOldNew) => {
        try {
          const response = await axios.get(`/api/data-loading/${collection}`, {
            params: {
              isSystem: true, // we want to retrive system data to generate template data
            }
          });
          const decryptedCollection = await decryptCol(response.data.documents);
      
          for (const obj of decryptedCollection) {
            try {
              const { _id, isSystem, category, ...rest } = obj;
              const oldId = _id;
              const newObj = {
                ...rest,
                createdBy: user._id, // Important: unlike other creation route we drive the owner
                // isSystem: false,
              };
              if (category) { // add category conditionally since not present on all collections
                const oldCategoryId = category._id;
                newObj.category = { _id: mappingOldNew[oldCategoryId], value: category.name}; // TBD: add collection to meet new relation standards
              }
              
              const encryptedNewObject = encryptObject(newObj, userEncryptionKey);
              const createdObject = await axios.post(`/api/data-loading/${collection}/`, encryptedNewObject);
              const newId = createdObject.data.document._id;
              mappingOldNew[oldId] = newId;
      
              // Uncomment the line below if you want to log successful initialization for each object
              // console.log(`Data initialized successfully for collection: ${collection} and ID: ${newId}`);
            } catch (error) {
              console.error(`Error creating data for collection: ${collection} : ${error.message}`);
            }
          }
        } catch (error) {
          console.error(`Failed to fetch data for collection: ${collection}`, error);
          throw error; // Re-throwing the error to propagate it to the outer catch block
        }
      };

      const initializeUser = async () => {
        try {

          // retrieve user from db
          const response = await axios.get(`/api/user/username/${username}`);
          const user = response.data.user;

          // initialize user encryption key
          userEncryptionKey = await initializeUserEncryptionKey(user, userPassphrase);
          
          // encrypt user info
          const response2 = await axios.get(`/api/user/username/${username}`);
          const updatedUser = response2.data.user;
          const encryptedUser = encryptObject(updatedUser, userEncryptionKey)
          await axios.put(`/api/user/${user._id}`, encryptedUser);

          // initialize user data
          await initializeAdminData(updatedUser, userEncryptionKey);
          await markUserAsInitialized(user)

        } catch (error) {
          console.log(`Error initializing user: ${username}`);
        }
      };

      const createEntries = async () => {
        try {
          
          let response = await axios.get('/api/data-loading/possible-values', {
            params: {
              createdBy: userId,
            }
          });

          const adminPossibleValues = await decryptCol(response.data.documents, userEncryptionKey);

          response = await axios.get('/api/data-loading/habits', {
            params: {
              createdBy: userId,
            }
          });
          const habits = await decryptCol(response.data.documents, userEncryptionKey);
          const transformedhabits = habits.map((habit) => ({
            _id: habit._id,
            value: habit.name,
            collection: 'habits',
          }));

          const pickRandomHabits = async () => {    
            // Function to generate a random value
            const getRandomValue = () => {
              const randomValue = Math.random();
              if (randomValue < 0.3) {
                return true;
              } else if (randomValue < 0.6) {
                return false;
              } else {
                return null;
              }
            };
      
            // Function to create a new object with the "value" property
            // Update MAY 1 2024 - compliant with new format for relations
            const createNewObject = (originalObject) => ([ { ...originalObject }, { value: getRandomValue() } ]);
      
            const shuffledArray = transformedhabits.sort(() => Math.random() - 0.5);
      
            // Select a random number of elements between MIN and MAX from the shuffled array
            const numberOfElements = Math.floor(Math.random() * (numberOfHabitsMax - numberOfHabitsMin + 1)) + numberOfHabitsMin;
            const newArray = shuffledArray.slice(0, numberOfElements).map(createNewObject);
      
            return newArray;
          };

          response = await axios.get('/api/data-loading/tags', {
            params: {
              createdBy: userId,
            }
          });
          const tags = await decryptCol(response.data.documents, userEncryptionKey);
          const transformedTags = tags.map((tag) => ({
              _id: tag._id,
              value: tag.name,
              collection: 'tags',
            }));

          const pickRandomTags = async () => {    

            const shuffledArray = transformedTags.sort(() => Math.random() - 0.5);
      
            // Select a random number of elements between MIN and MAX from the shuffled array
            const numberOfElements = Math.floor(Math.random() * (tagsMax - tagsMin + 1)) + tagsMin;
            const newArray = shuffledArray.slice(0, numberOfElements);
      
            return newArray;
          };

          const createUserEntries = async () => {
            const entryDate = moment(startDate);
      
            while (entryDate <= moment(endDate)){     
              for (let i = 0; i < numberOfEntriesPerDay; i++){
                try {
                  const entryTypes = adminPossibleValues.filter(data => data.fieldCollection === "entries" && data.fieldName === "type");
                  const randmonType = entryTypes[Math.floor(Math.random() * entryTypes.length)]; 
                  const type = randmonType ? {_id: randmonType._id, value: randmonType.fieldValue, collection: 'possible-values' } : null;
                  const randomIndex =Math.floor(Math.random() * userEntriesSample.length);
                  const entryDetails = userEntriesSample[randomIndex];
                  const habits = createEntryHabits ? await pickRandomHabits() : [];
                  const tags = createEntryTags ? await pickRandomTags() : [];

                  let entry = { 
                    createdBy: userId,
                    flexDataContainer : {
                      type: type,
                      title: entryDetails.title,
                      content: entryDetails.content,
                      lesson: entryDetails.lesson,
                      habits: habits,
                      tags: tags,
                      link: "https://chat.openai.com/",
                      date: entryDate,
                    }
                  };


                  const encryptedEntry = encryptObject(entry, userEncryptionKey)
                  const newEntry = await axios.post('/api/data-loading/entries/', encryptedEntry);

                  if(newEntry) {
                    console.log(`New entry created successfully: ${entry.flexDataContainer.title} date: ${entry.flexDataContainer.date}`);
                  }    
                } catch (error) {
                  console.error('Error creating new entry: ', error);
                }
              }
              entryDate.add(1, 'day');
            }
          };
          if (numberOfEntriesPerDay > 0) await createUserEntries();

          const createUserRecords = async () => {
            const entryDate = moment(startDate);
      
            while (entryDate <= moment(endDate)){     
              for (let i = 0; i < numberOfRecordsPerDay; i++){
                try {
                  const recordTypes = adminPossibleValues.filter(data => data.fieldCollection === "records" && data.fieldName === "type");
                  const randmonType = recordTypes[Math.floor(Math.random() * recordTypes.length)]; 
                  const type = randmonType ? {_id: randmonType._id, value: randmonType.fieldValue, collection: 'possible-values' } : null;
                  const randomIndex = Math.floor(Math.random() * userRecordsSample.length);
                  const entryDetails = userRecordsSample[randomIndex];
                  const tags = createRecordTags ? await pickRandomTags() : [];

                  let entry = { 
                    createdBy: userId,
                    flexDataContainer : {
                      type: type,
                      title: entryDetails.title,
                      content: entryDetails.content,
                      tags: tags,
                      link: "https://chat.openai.com/",
                      date: entryDate,
                      isFavorite: false,
                    }
                  };

                  const encryptedEntry = encryptObject(entry, userEncryptionKey)
                  const newEntry = await axios.post('/api/data-loading/records/', encryptedEntry);

                  if(newEntry) {
                    console.log(`New record created successfully: ${entry.flexDataContainer.title} date: ${entry.flexDataContainer.date}`);
                  }    
                } catch (error) {
                  console.error('Error creating new record: ', error);
                }
              }
              entryDate.add(1, 'day');
            }
          };
          if (numberOfRecordsPerDay > 0) await createUserRecords();

          const createUserNotes = async () => {
            const entryDate = moment(startDate);
      
            while (entryDate <= moment(endDate)){     
              for (let i = 0; i < numberOfRecordsPerDay; i++){
                try {
                  const noteTypes = adminPossibleValues.filter(data => data.fieldCollection === "notes" && data.fieldName === "type");
                  const randmonType = noteTypes[Math.floor(Math.random() * noteTypes.length)]; 
                  const type = randmonType ? {_id: randmonType._id, value: randmonType.fieldValue, collection: 'possible-values' } : null;
                  const randomIndex = Math.floor(Math.random() * userRecordsSample.length);
                  const entryDetails = userRecordsSample[randomIndex];
                  const tags = createRecordTags ? await pickRandomTags() : [];

                  let entry = { 
                    createdBy: userId,
                    flexDataContainer : {
                      type: type,
                      title: entryDetails.title,
                      content: entryDetails.content,
                      tags: tags,
                      link: "https://chat.openai.com/",
                      date: entryDate,
                      isFavorite: false,
                    }
                  };

                  const encryptedEntry = encryptObject(entry, userEncryptionKey)
                  const newEntry = await axios.post('/api/data-loading/notes/', encryptedEntry);

                  if(newEntry) {
                    console.log(`New note created successfully: ${entry.flexDataContainer.title} date: ${entry.flexDataContainer.date}`);
                  }    
                } catch (error) {
                  console.error('Error creating new note: ', error);
                }
              }
              entryDate.add(1, 'day');
            }
          };
          if (numberOfRecordsPerDay > 0) await createUserNotes();

        } catch (error) {
          console.log(`Error creating entries/records/notes for user: ${username}`, error);
        }
      } 
      
      await deletePreviousTestData();
      await createUser();
      await initializeUser();
      if(userId) await createEntries();
    }

    // Use Promise.allSettled to handle errors without stopping execution
    const results = await Promise.allSettled(users.map((username) => generateUserData(username)));

    results.forEach((result, index) => {
      if (result.status === 'rejected') {
        console.error(`Error for user ${users[index]}: ${result.reason.message}`);
      }
    });

    console.log("End of data loading");

  } catch (error) {
    console.error(`Error while generating test data: ${error.message}`);
  }
};

// TBD: function to create data from json
// export async function loadDataFromFile(jsonData, userId, userEncryptionKey, collection) {
//   // iterate on the json passed as argument
//   // for each key-value pair, check if value is hardcoded (simple) or relation (complex)
//   // make a new object and add internal fields
//   // encrypt object
//   // post new object
//   // TBD: unable file upload from the UI to create data in bulk mode
//   jsonData.forEach(async (obj) => {
//     let newObject = {
//       ...obj,
//       createdBy: userId,
//     }
//     Object.entries(newObject).forEach(([key, value]) => {
//       if (typeof value === "object"){
//         const fieldName = key;
//         const possibleValue = value.value;
//         const sourceObj = searchObjectInPossibleValues(collection, fieldName, possibleValue);
//         newObject.key = {
//           id: sourceObj._id,
//           value: value.value,
//           collection: value.collection,
//         }
//       }
//     });
//     const encryptedNewObject = encryptObject(newObject, userEncryptionKey);
//     const createdObject = await axios.post(`/api/data-loading/${collection}/`, encryptedNewObject);
//   })
// }

export async function loadTestData(numberOfUsers, numberOfEntries){
  try {
    await axios.post('/api/data-loading/drop-test-data');
    await axios.post(`/api/data-loading/duplicate-test-data/`, {numberOfUsers, numberOfEntries});
    console.log("End of data loading");

  } catch (error) {
    console.error(`Error while generating test data: ${error.message}`);
  }
};