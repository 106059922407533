import React, { useState, useEffect } from 'react';
import { DataTable } from '../../mui-tables/mui-data-table';
import { searchObjectsInCache } from "../../../utils/caching";
import { transformObjForReactSelect } from '../../../utils/data-processing';

const Table = () => {
    const collection = 'tags';
    const [categories, setCategories] = useState([null]);
    const loadCategories = async () => {
      const categories = await searchObjectsInCache("possible-values", { fieldCollection: collection, fieldName: "category" });
      const categoriesSanitized = categories ? categories.map((obj) => transformObjForReactSelect(obj)) : [];    
      setCategories(categoriesSanitized);
    };

    useEffect(() => {
      loadCategories();
    }, []);
    
    const options = {
      enableGrouping: true,
      groupingColumns: null,
      enablePagination: true,
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
      columnVisibility: {
        icon: false,
        color: false
      }
    };
    const dataModel = [
      {
        name: 'name',
        type: 'text',
        colWidth: 180,
      },
      {
        name: 'category',
        type: 'object',
        colWidth: 180,
        entity: 'admin-data',
        values: [null, ...categories],
        defaultValue: {},
      },
      {
        name: 'order',
        type: 'text',
        colWidth: 110,
      },
      {
        name: 'color',
        type: 'color',
        colWidth: 110,
      },
      {
        name: 'icon',
        type: 'text',
        colWidth: 80,
      },
      {
        name: 'isActive',
        header: 'Active?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 110,
      },
    ];

  return (
    <DataTable 
      collection = {collection}
      dynamicQueryKey = {[collection]}
      options = {options}
      dataModel = {dataModel} 
    />
  )
};

export default Table;