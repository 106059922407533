// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const logo = loadModule('./assets/images/app-logo.png');

const AppLogo = ({ size }) => {

  const sizeMap = {
    small: "40px",
    medium: "120px",
    large: "200px",
    default: "40px"
  };
  
  const pixels = sizeMap[size] || sizeMap.default;

  return (
    <img 
      className="app-logo" 
      style={{ width: pixels, height: pixels }} 
      src={logo} 
      alt="App Logo" 
    />
  );
};

export default AppLogo;