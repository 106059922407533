import { useState } from 'react';
import './tab-view.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const WarningSmall = loadModule('./components/warning/warning-small.js');
const TabBar = loadModule('./components/tabbar/tabbar.js');

const TabViewLayout = ({ title, subtitle, tabMap, defaultTab, isFullWidth = false }) => {
  // Set the default tab as the first tab if `defaultTab` is not provided
  const initialTab = defaultTab || (tabMap && Object.keys(tabMap)[0]);
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
  };

  const tabNames = Object.keys(tabMap);

  return (
    <div>
      <TabBar tabNames={tabNames} selectedTab={selectedTab} onChange={handleTabChange} />
      {/* Render selected tab's content or show a warning if the tab is not found */}
      {tabMap[selectedTab] || <WarningSmall title={`🚧 Tab not found: ${selectedTab}`} />}
    </div>
  );
};

export default TabViewLayout;