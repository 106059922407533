import { useState, useEffect } from "react";
import loadConfig from "../utils/configLoader";

const config = loadConfig('general');

const useLoadingIndicator = () => {
  const [isLoading, setIsLoading] = useState(true); // Fixed typo

  useEffect(() => {
    const timer = setTimeout(() => { setIsLoading(false) }, config.loading.minDelay);
    return () => clearTimeout(timer);
  }, []);

  return isLoading;
};

export default useLoadingIndicator;