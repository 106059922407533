const WarningSmall = ({ icon, title, desc }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ padding: '0px' }}>
      <div className="text-center">
        <div className='d-flex align-items-center mb-3' style={{ gap: '8px' }}>
          {icon}
          <h3 style={{ margin: 0 }}>{title}</h3>
        </div>
        
        <a href="/app/home" className='hyperlink-std'>{desc}</a>
      </div>
    </div>
  )
};

export default WarningSmall;