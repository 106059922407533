import "./view-header.css";

const ViewHeader = ({ title, subtitle, toolbar, toolbarOnMobileOnly }) => {
  if (!(title || toolbar)) return null;

  return (
    <div className="view-header">   
      <div className='view-header-top'>
        <div className='view-header-title'>
          {title}
        </div>
        {toolbar && 
          <div className={`view-header-toolbar ${toolbarOnMobileOnly ?  'mobile-only' : ''}`}>
            {toolbar}
          </div> 
        }
      </div>
      <div className='view-header-subtitle'>
        {subtitle}
      </div>
    </div>
  )
};

export default ViewHeader;