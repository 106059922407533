import React from 'react';
import loadModule from '../../core/utils/moduleLoader';

// Loading following components dynamically to allow one-off customization
const Main = loadModule('./features/entries/views/main.js');

const Entries = () => {

  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default Entries;