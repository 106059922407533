import { useContext } from "react";
import { UserContext } from "../contexts/user-context";
import { setKeyExpiration } from "../utils/encryption-key";

const useSafeguard = () => {
  const { isSafeguardOn, setIsSafeguardOn } = useContext(UserContext);

  const toggleSafeguard = () => {
    if (!isSafeguardOn) {
      setKeyExpiration();
    }
    setIsSafeguardOn(prevValue => !prevValue);
  };

  return { isSafeguardOn, toggleSafeguard };
};

export default useSafeguard;