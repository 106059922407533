import { DataTable } from '../../mui-tables/mui-data-table';

const table = () => {
    const collection = 'access-requests';
    const options = {
      enableCreation: false,
      enableGrouping: true,
      groupingColumns: null,
      enablePagination: true,
      sorting: [
        {
          id: 'creationDate',
          desc: false,
        },
      ],
      columnVisibility: {
      }
    };
    const dataModel = [
      {
        name: 'email',
        type: 'text',
        colWidth: 200,
        enableEditing: false,
      },
      {
        name: 'ip',
        header: 'IP',
        type: 'text',
        enableEditing: false,
      },
      {
        name: 'country',
        type: 'text',
        enableEditing: false,
      },
      {
        name: 'city',
        type: 'text',
        enableEditing: false,
      },
      {
        name: 'creationDate',
        header: 'Creation Date',
        type: 'text',
        colWidth: 180,
        enableEditing: false,
      },
      {
        name: 'isApproved',
        type: 'boolean',
        defaultValue: true,
        colWidth: 150,
      },
    ];

  return (
    <DataTable 
      collection={collection}
      options = {options}
      dataModel = {dataModel} 
    />
  )
};

export default table;