import ViewContentLayout from '../../../layouts/view/view-content-layout';
import SystemSettingsForm from '../components/system-settings-form';
import SystemDbManagement from '../components/system-db-management';
import SystemDevTools from '../components/system-dev-tools';
import SystemDataImportExport from '../components/system-data-import-export';
import SystemDataLoading from '../components/system-data-loading';
import SystemReleaseManagement from '../components/system-release-management';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const FoldableCard = loadModule('./components/cards/foldable-card.js');

const Content = () => {

  return (
    <ViewContentLayout 
        title={"System Administration"}
        subtitle={"All system settings"}>
        <div className="view-content">
          {/* <FoldableCard title={'System Settings'} expandable={true} >
            <SystemSettingsForm />
          </FoldableCard> */}

          <FoldableCard title={'Database Management'} expandable={true} >
            <SystemDbManagement />
          </FoldableCard>

          <FoldableCard title={'Data Import/Export'} expandable={true} >
            <SystemDataImportExport />
          </FoldableCard>

          <FoldableCard title={'Release Management'} expandable={true}>
            <SystemReleaseManagement />
          </FoldableCard>

          <FoldableCard title={'Dev Tools'} expandable={true}>
            <SystemDevTools />
          </FoldableCard>

          <FoldableCard title={'Data Loading'} expandable={true}>
            <SystemDataLoading />
          </FoldableCard>

        </div>
    </ViewContentLayout>
  );
};

export default Content;