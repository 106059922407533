import { capitalizeFirstLetter } from '../../utils/dataUtils';
import './app-version-info.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadConfig from '../../utils/configLoader';
const config = loadConfig('general');

const AppVersionInfo = () => {
  return (
    <div className='app-version-info'>
      { `${config.appVersion} - ${capitalizeFirstLetter(config.env)}` }
    </div>
  );
};

export default AppVersionInfo;