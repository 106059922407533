const testDbCollection = [
  {
    "_id": "60e9af7f1804f63e9e3a9918",
    "createdBy": "60e9aec21804f63e9e3a9914",
    "creationDate": "2023-07-04T09:00:00Z",
    "last_modification_date": "2023-07-04T17:00:00Z",
    "isActive": true,
    "is_encrypted": true,
    "is_system": false,
    "flexDataContainer": {
      "type": "user_record",
      "sub_type": "work_log",
      "date": "a5s6d4f8s6df4s6d4f5s6d4f8s6df4s6d4f8s6df4s65d4f",
      "project": "8s6df4s6d4f5s6d4f8s6df4s6d4f8s6df4s65d4fa5s6d4f8s6d",
      "task_description": "4s6d4f8s6df4s6d4f5s6d4f8s6df4s65d4fa5s6d4f8s6df",
      "hours_worked": "f4s6d4f8s6df4s65d4f8s6df4s6d4f5s6d4fa5s6d4f8s6df"
    }
  },
  {
    "_id": "60e9af7f1804f63e9e3a9918",
    "createdBy": "60e9aec21804f63e9e3a9914",
    "creationDate": "2023-07-04T09:00:00Z",
    "last_modification_date": "2023-07-04T17:00:00Z",
    "isActive": true,
    "is_encrypted": false,
    "is_system": false,
    "flexDataContainer": {
      "type": "user_record",
      "sub_type": "work_log",
      "date": "2023-07-04T09:00:00Z",
      "project": "React Boilerplate Development",
      "task_description": "Implemented dynamic forms for user entries.",
      "hours_worked": 8
    }
  },
  {
    "_id": "60e9af7f1804f63e9e3a9919",
    "createdBy": "60e9aec21804f63e9e3a9915",
    "creationDate": "2023-06-01T09:00:00Z",
    "last_modification_date": "2023-06-01T17:00:00Z",
    "isActive": true,
    "is_encrypted": false,
    "is_system": false,
    "flexDataContainer": {
      "type": { _id: "2", name: "Journal" },
      "sub_type": "journal",
      "template": { _id: 2, name: 'Essay' },
      "date": "2023-07-04T09:00:00Z",
      "title": "Daily Reflections",
      "content": "Today I worked on the React boilerplate project.",
      "tags": [{ _id: "1", name: "Technology" }, { _id: "2", name: "Health" }],
    }
  },
  {
    "_id": "60e9af7f1804f63e9e3a9920",
    "createdBy": "60e9aec21804f63e9e3a9916",
    "creationDate": "2023-07-04T09:00:00Z",
    "last_modification_date": "2023-07-04T17:00:00Z",
    "isActive": true,
    "is_encrypted": false,
    "is_system": false,
    "flexDataContainer": {
      "type": "user_record",
      "sub_type": "physical_activity",
      "date": "2023-07-04T09:00:00Z",
      "activity_type": "Running",
      "duration_minutes": 30,
      "calories_burned": 300,
      "comments": "Morning run in the park."
    }
  }
];

export default testDbCollection;
