import SplitViewResponsiveLayout from '../../../layouts/view/split-view-responsive';
import Content from '../components/content';
import Panel from '../components/panel';

const SystemMain = () => {
  
  return (
    <div className="centered-content">
      <SplitViewResponsiveLayout 
        content={<Content />}
        panel={<Panel/>}
        isPanelContrasted={false}
      />
    </div>
  );
};

export default SystemMain;