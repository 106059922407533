import { SampleText } from "../sample-text"; //TBD: for testing only, replace hard coded text by anything you want
import './type-writer.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from "../../../utils/moduleLoader";
const TextCard = loadModule("./components/cards/text-card.js");
const ComponentCard = loadModule("./components/cards/component-card.js");
const useTypeWriter = loadModule("./features/type-writer/hooks/use-type-writer.js");

const TypeWriter = () => {

  const fullText = SampleText;
  const { generatedText, isTyping } = useTypeWriter({fullText});

  return (
    <ComponentCard title="Daily Digest">
      <div>
        {generatedText}
        {/* <span className={`cursor ${!isTyping ? "blink" : ""}`}></span> */}
      </div>
    </ComponentCard>
  );
};

export default TypeWriter;