const config = {
  viewId: "records",
  viewName: "🗂️ Records",
  viewDesc: "All your records",
  viewFilters: {
    collections: ["records"],
    // TBD: in the future we could pass more data to tailor the content of the view, but this must be handled in the filter forms as we can't have dimennsions here AND in the filter form
    // types: ["Travel", "Medical"],
    // startDate: new Date(),
    // endDate: null,
  },
};

export default config;