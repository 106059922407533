import React from "react";
import "./dropdown-menu.css";

const DropdownMenu = ({ actions, icon, buttonLabel, buttonClass, iconOnly }) => {

  // function hideDropdown() {
  //   const dropdownElement = document.getElementById("dropdown-menu");
  //   if (dropdownElement) {
  //     dropdownElement.style.display = "none";
  //   }
  // }

  return (
    <div className={`btn-group ${iconOnly ? 'dropstart' : ''}`} >
      {iconOnly ? (
        <div
          className="clickable-tool-small"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          onClick={(event) => event.stopPropagation()} // Prevent card click
        >
          {icon}
        </div>
      ) : (
        <button
          type="button"
          className={`btn ${buttonClass} dropdown-toggle`}
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          onClick={(event) => event.stopPropagation()} // Prevent card click
        >
          {buttonLabel}
        </button>
      )}
      <ul className="dropdown-menu shadow-lg">
        {actions.map((action, index) => (
          <li key={index}>
            <a
              href="#"
              className="dropdown-item action-item-row"
              role="button"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                // hideDropdown();
                action.onClick();
              }}
            >
              {action.icon}
              {action.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenu;