import loadModule from '../../core/utils/moduleLoader';
const Main = loadModule('./features/policies/components/terms-of-service.js');

const TermsOfService = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default TermsOfService;