import React, { useState, useEffect } from 'react';

const SpinnerWithTimer = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 3000);
        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            {loading ? (
                <div className="spinner-border" role="status"></div>
            ) : (
                <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>Placeholder Text</p>
            )}
        </div>
    );
};

export default SpinnerWithTimer;
