import { Card } from 'react-bootstrap';
import { camelCaseToTitle } from '../../utils/string';
import './field-value-card.css';

// Utility function to format dates
const formatDate = (value) => {
  const date = new Date(value);
  // Check if the value is a valid date
  if (!isNaN(date.getTime())) {
    return date.toLocaleDateString('en-GB'); // e.g., "DD/MM/YYYY"
  }
  return value; // Return the original value if not a valid date
};

const FieldValueCard = ({ title, data }) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div>
          {Object.entries(data).map(([key, value], index) => (
            <div key={index} className="field-item">
              <div className="field-label-container">
                <span className="field-label">{camelCaseToTitle(key)}</span>
              </div>
              <div>
                <span className="field-value">
                  {typeof value === 'number'
                    ? value.toLocaleString()
                    : value instanceof Date || !isNaN(Date.parse(value))
                    ? formatDate(value)
                    : value || 'N/A'}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default FieldValueCard;
