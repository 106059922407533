import React from "react";
import { Card } from "react-bootstrap";

const TextCard = () => {
  const title = "Inspirational Quote";
  const text = "The only way to do great work is to love what you do. – Steve Jobs";

  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TextCard;
