import { useEffect, useState } from "react";
import loadConfig from "../utils/configLoader";
import { getUserDocumentsCount } from "../services/database-services";

const config = loadConfig('general');

const useUserStats = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [userStats, setUserStats] = useState([]);

  useEffect(() => {
    const getUserStats = async () => {
      try {
        const collections = config.admin.collections.filter(collection => !collection.isRestricted).map(collection => collection.name);
        const dataToExport = await Promise.all(collections.map(async (collection) => {
          try {
            const count = await getUserDocumentsCount(collection);
            return { label: collection, number: count };
          } catch (error) {
            console.error(`Failed to process collection ${collection}: `, error);
            return { label: collection, number: 0 };
          }
        }));
        setUserStats(dataToExport);
      } catch (error) {
        console.error('Failed to fetch model: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    getUserStats();
  }, []);

  return { userStats, isLoading };

};

export default useUserStats;
