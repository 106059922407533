import React from 'react';

const Spinner = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
            <div className="spinner-border" role="status"></div>
        </div>
    );
};

export default Spinner;