import loadModule from '../utils/moduleLoader';
const Main = loadModule('./features/system-admin/views/main.js');

const SystemAdmin = () => {
  return (
    <div className='page'>
      <Main />
    </div>
  );
};

export default SystemAdmin;