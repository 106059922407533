import axios from 'axios';
import loadConfig from './configLoader';

import { decryptCol, decryptObject, encryptCol, encryptObject } from './data-encryption';
import { applyDateRangeFilter, applyCollectionFilter, applyTypeFilter, applyEntityFilter, applyEntryTypeFilter, applyShorcutsFilter, applyGolbalSearchFilter, applyFocusFilters, applyOtherFilters, applyTextFilter, applyKeywordsFilter } from './dataFilters';
import { convertUtcDateToLocalAndFormatShort } from './dataUtils';

const config = loadConfig('general');


export const pauseExecution = (duration) => {
  return new Promise(resolve => setTimeout(resolve, duration));
}

// MOVED TO database-services.js
// In this version, we make API calls sequentially - slower but more predictable and resource-efficient
// export const fetchDataSequential = async (types, user) => {
//   try {

//     const flattenedData = [];

//     for (const type of types) {
//       try {
//         const response = await axios.get(`/api/generic-data/${type}`);
//         const documents = response.data.documents;
//         documents.forEach((obj) => { obj.entity = type });
//         flattenedData.push(...documents);
//       } catch (error) {
//         console.error(`Failed to fetch data for ${type}:`, error);
//       }
//     }

//     return flattenedData;

//   } catch (error) {
//     console.error('Failed to fetch data:', error);
//     return [];
//   } 
// };

// In this version, we make API calls concurrently - faster but resource-intense
// export const fetchDataConcurrent = async (types) => {
//   try {

//     const dataPromises = types.map(async (type) => {
//       const response = await axios.get(`/api/generic-data/${type}`);
//       const documents = response.data.documents;
//       documents.forEach((document) => { document.entity = type }); // adding entity to the object for distinction
//       return documents;
//     }); 

//     const tempData = await Promise.all(dataPromises);
//     const flattenedData = tempData.flat(); // Flatten the array of arrays
    
//     return flattenedData;

//   } catch (error) {
//     console.error('Failed to fetch data:', error);
//     return [];
//   } 
// };

// Use decryptCol in dataEncryption instead 
// export const decryptData = async (documents) => {
//   try {
//     return decryptCol(documents);
//   } catch (error) {
//     console.error('Failed to decrypt data:', error);
//   }
// };

export const transformData = async (documents) => {
  try {
    const result = [];

    for (const document of documents) {
      const newDocument = flattenDbDocument(document);
      // Instructions: add other transformation below, calling utility function to process data
      // ..
      result.push(newDocument);
    }
    return result;
    
  } catch (error) {
    console.error('Failed to transformed data:', error);
  }
};

// documents.forEach((document) => { document.collection = collection });

export const filterData = (data, filters) => {
  try {

    // TBD: improve filtering by trying to harmonize the functions below (like one function per type of validation: range, single value, boolean etc.)
    let filteredData = [...data]
    .filter((obj) => applyDateRangeFilter(obj.date, filters.startDate, filters.endDate))
    .filter((obj) => applyCollectionFilter(obj, filters))
    .filter((obj) => applyTypeFilter(obj, filters))
    // .filter((obj) => applyEntityFilter(obj, filters)) // TBD: decomission after release 0.90
    // .filter((obj) => applyEntryTypeFilter(obj, filters)) // TBD: decomission after release 0.90
    .filter((obj) => applyShorcutsFilter(obj, filters))
    .filter((obj) => applyGolbalSearchFilter(obj, filters))
    .filter((obj) => applyFocusFilters(obj, filters))
    .filter((obj) => applyOtherFilters(obj, filters))
    .filter((obj) => applyTextFilter(obj, filters))
    .filter((obj) => applyKeywordsFilter(obj, filters))

    return filteredData;

  } catch (error) {
    console.error('Failed to fitler data:', error);
  } 
};

export const sortData = async (data, sort) => {
  try {
    const sortedData = [...data]
      .filter(data => data.date || data.creationDate) // using && may prevent sorting issues if one of the dates is missing
      .sort((a, b) => {
      if (sort === 'desc') {
        if (a.date === b.date) return new Date(b.creationDate) - new Date(a.creationDate);
        return new Date(b.date) - new Date(a.date);
      } else if (sort === 'asc') {
        if (a.date === b.date) return new Date(a.creationDate) - new Date(b.creationDate);
        return new Date(a.date) - new Date(b.date);
      }
      return false;
    });

    return sortedData;
  } catch (error) {
    console.error('Failed to sort data:', error);
    throw error; // Propagate the error
  }
};

export const paginateData = (data, currentPage, itemsPerPage, upToCurrentPage) => {
  const startIndex = upToCurrentPage ? 0 : (currentPage - 1) * itemsPerPage;
  const endIndex = upToCurrentPage ? currentPage * itemsPerPage : startIndex + itemsPerPage;

  return data.slice(startIndex, endIndex);
};

export const finalizeDataToExport = (data) => {
  try {
    const finalizedData = [...data]
      .map((object) => {
        for (const field of config.export.fieldsToIgnore) {
          if (object.hasOwnProperty(field)) {
            delete object[field];
          }      
        }

        return object;
      });

    return finalizedData;

  } catch (error) {
    console.error('Failed to finalize data to export:', error);
  }
};

export const transformObjForReactSelect = (obj) => {
  try {
    if (!obj) {
      throw new Error("Invalid input: object is null or undefined");
    }
    
    // we do this because the original object may not have a label nor a value
    // since we need both value and label in dropdown, we set them here
    const textDesc = obj.name || obj.value || obj.fieldValue || obj.title || "Unnamed"; // Add default fallback text if needed
    
    // decision to pass the entire object to select, instead of just necessary field to not lose info
    // then, before saving, the data selected is trimmed by transformSelectedObjBeforeSaving
    const result = {
      ...obj,
      // _id: obj._id,
      value: textDesc, // value needed in react-select
      label: textDesc, // label needed in react-select
    };

    return result;
  } catch (error) {
    console.error(`Error transforming object for select input: ${JSON.stringify(obj)} : ${error.message}`);
    return null;
  }
};

// setRelation(obj)
export const transformSelectedObjBeforeSaving = (obj) => {
  // Format needed: {_id, value, collection?}
  try {
    if (!obj) {
      throw new Error("Invalid input: object is null or undefined");
    }

    const result = {
      _id: obj._id,
      value: obj.value,
      collection: obj.collection,
    };

    return result;
  } catch (error) {
    console.error(`Error transforming selected object before saving: ${JSON.stringify(obj)} : ${error.message}`);
    return null;
  }
};

export const flattenDbDocuments = async (documents) => {
  try {
    const result = [];

    for (const document of documents) {
      const newDocument = flattenDbDocument(document);
      result.push(newDocument);
    }
    return result;
    
  } catch (error) {
    console.error('Failed to transformed data:', error);
  }
};

export const flattenDbDocument = (dbDocument) => {
  let newObj = { ...dbDocument };
  if (newObj.flexDataContainer) {
    for (let key in newObj.flexDataContainer) {
      if (newObj.flexDataContainer.hasOwnProperty(key)) {
        newObj[key] = newObj.flexDataContainer[key];
      }
    }
    delete newObj.flexDataContainer;
  }
  return newObj;
};

export const transformDbDocument = (dbDocument) => {
  let newDocument = { ...dbDocument };

  if(newDocument.type && typeof newDocument.type === "object"){
    const prevType = newDocument.type;
    const newType = { 
      _id: prevType._id,
      value: prevType.value,
      collection: prevType.collection,
    }
    newDocument.type = newType;
  }

  if(newDocument.date){
    const utcDate = newDocument.date;
    newDocument.date = convertUtcDateToLocalAndFormatShort(utcDate);
  }

  return newDocument;
};

export const processDbDocument = (dbDocument) => {
  let result = {};
  if(config.encryption.isActive) {
    // 1 decrypt document so it can be transformed
    result = decryptObject(dbDocument);
  }
  // 2 transform document
  result = flattenDbDocument(result);
  result = transformDbDocument(result);
  return result;
};


export const processDbDocuments = (dbDocuments) => {
  let result = [];
  for (const dbDocument of dbDocuments) {
    result.push(processDbDocument(dbDocument));
  }
  return result;
};