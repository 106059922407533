import { Link } from 'react-router-dom';
import './branding.css'

// Dynamic Imports - Core Components That Can Be Customized
import loadConfig from '../../utils/configLoader';
import loadModule from '../../utils/moduleLoader';
const config = loadConfig('general');
const AppLogo = loadModule("./components/branding/app-logo.js");

const Branding = () => {

  // TBD: check if user is authenticated and direct to / or /app/home
  const hyperlink = '/app/home';
  
  return (
    <Link to={hyperlink} className="branding-link">
      <div className="branding">
        <div className='branding-logo'>
          <AppLogo />
        </div>
        <div className='branding-brandname'>
          {config.brandName}
        </div>
      </div>
    </Link>
  )
};

export default Branding;