import React from 'react';
import "./toolbar.css";

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const AppVersionInfo = loadModule('./components/legends/app-version-info.js');

const ToolbarMenu = ({ menuIcon, options }) => {
  return (
    <div className="btn-group">
      <div className="toolbar-button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
        {menuIcon}
      </div>
      <ul className="dropdown-menu shadow-lg">
        {options.map((option, index) => (
          <li key={index}>
            <a
              href="#"
              className="dropdown-item dropdown-item-row"
              onClick={(e) => {
                e.preventDefault();
                if (option.onClick) {
                  const handler = option.onClick;
                  handler();
                } else {
                  return
                }
              }}
              style={{ pointerEvents: option.onClick ? 'auto' : 'none' }}
            >
              <div className="dropdown-item-icon">{option.icon}</div>
              <div className="dropdown-item-label">{option.label}</div>
              <div className='dropdown-item-component' style={{ pointerEvents: 'auto' }}>{option.component}</div>
            </a>
          </li>
        ))}
        <div className='app-version-info-container'>
          <AppVersionInfo />
        </div>
      </ul>
    </div>
  );
};

export default ToolbarMenu;
