import { useCallback } from "react";
import loadConfig from "../../../utils/configLoader";
import { printDebugInfo } from "../../../utils/dataUtils";
import { updateUserInDatabase } from "../services/user-services";
import { encryptObject } from "../../../utils/data-encryption";

const config = loadConfig('general');

const useUserForm = (userId) => {

  const saveForm = useCallback(async (formData) => {
    try {
      const encryptedUserData = encryptObject(formData);
      await updateUserInDatabase(userId, encryptedUserData);
      if(config.admin.debugMode) printDebugInfo(encryptedUserData);
    } catch (error) {
      console.error('Failed to update user : ', error);
    }
  }, [userId]);

  return { saveForm };

};

export default useUserForm;
