import { Row, Col } from 'react-bootstrap';
import './views.css'

const QuadView = ({TopLeft, TopRight, BottomLeft, BottomRight}) => {

  return (
    <div className='quad-view-container'>
      <Row>
        <Col md={6} className='quad-view-quadrant'>
          {TopLeft}
        </Col>
        <Col md={6} className='quad-view-quadrant'>
          {TopRight}
        </Col>
      </Row>
      <Row>
        <Col md={6} className='quad-view-quadrant'>
          {BottomLeft}
        </Col>
        <Col md={6} className='quad-view-quadrant'>
          {BottomRight}
        </Col>
      </Row>
    </div>
  );
};

export default QuadView;