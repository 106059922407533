import axios from 'axios';
import loadConfig from '../../../../core/utils/configLoader';
const config = loadConfig('general');

export const login = async (form) => {
  const response = await axios.post('/api/auth/login', form);
  return response.data;
};

export const signup = async (form) => {
  const response = await axios.post('/api/auth/register', form);
  return response.data;
};

export const verifyRegistrationCode = async (code) => {
  if (config.registrationCodes.includes(code)) {
    return true;
  } else {
    throw new Error('Registration code incorrect, please try again or request a new access code.');
  }
};

export const googleAuth = () => {
  window.open(`${process.env.REACT_APP_SERVER_URL}/api/auth/google/callback`, "_self");
};
