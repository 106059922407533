import React from "react";
import { Card, ProgressBar } from 'react-bootstrap';

const IndicatorCard = () => {

  const title = 'Indicators';

  const progressData = [
    { label: 'Health', value: 75 },
    { label: 'Relationships', value: 60 },
    { label: 'Finance', value: 50 },
    { label: 'Career', value: 80 },
    { label: 'Passions', value: 90 }
  ];

  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        {progressData.map((data, index) => (
          <div key={index} className="mb-3">
            <h6>{data.label}</h6>
            <ProgressBar now={data.value} label={`${data.value}%`} />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default IndicatorCard;