import React, { useRef, useState } from 'react';
import './text-editor.css';

const TextEditor = () => {
  const editorRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState('');

  const formatText = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const handleSave = () => {
    if (editorRef.current) {
      setHtmlContent(editorRef.current.innerHTML);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would send `htmlContent` to your backend server to save it
    console.log('Saved content:', htmlContent);
  };

  return (
    <div className="text-editor">
      <div className="toolbar">
        <button onClick={() => formatText('bold')}>Bold</button>
        <button onClick={() => formatText('italic')}>Italic</button>
        <button onClick={() => formatText('insertUnorderedList')}>Bullet Points</button>
        <button onClick={() => formatText('insertOrderedList')}>Numbered Points</button>
        <button onClick={() => formatText('hiliteColor', 'yellow')}>Highlight</button>
      </div>
      <div
        className="editor"
        contentEditable={true}
        ref={editorRef}
      ></div>
      <button onClick={handleSave}>Save</button>
      <form onSubmit={handleSubmit}>
        <textarea
          name="content"
          value={htmlContent}
          onChange={(e) => setHtmlContent(e.target.value)}
          style={{ display: 'none' }}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default TextEditor;
