import loadConfig from "./configLoader";

const config = loadConfig('general');

export const getCurrentDatabase = () => {
  try {
    if (config && config.databases && config.databases[config.env]) {
      return config.databases[config.env];
    }

    console.warn('Could not retrieve current database name. Verify your configuration and try again.');
    return null;

  } catch (error) {
    console.error('Error getting current database: ', error.message || error);
    alert('Error getting current database. Verify your configuration and try again.');
    return null;
  }
};