import React from 'react';
import StaticHTML from './static-html';
import privacyPolicyHtml from '../html-files/privacy_policy_template.txt';

const PrivacyPolicy = () => {
  return (
    <div>
      <StaticHTML htmlFile={privacyPolicyHtml} />
    </div>
  );
};

export default PrivacyPolicy;
