import React from "react";
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import "./tools.css"

export const OpenPageInNewTabTool = ({ url }) => {

  const handleClick = async (event) => {
    event.stopPropagation(); // stop event from propagating and triggering onClick on parent's component
    try {
      const openTab = (url) => {
        window.open(url, '_blank'); // You can customize width and height
      };
      openTab(url)
    } catch (error) {
      console.error('Failed to open entry in new window:', error);
    }
  };

  return (
    <div className="clickable-tool-small" onClick={(event) => handleClick(event, url)}>
      <OpenInNewIcon />
    </div>
  )
};

// TBD: adapt code top open URL in new window
// export const OpenInNewWindowTool = ({ url }) => {

//   const handleOpenInNewWindow = async (event) => {
//     event.stopPropagation();
//     try {
//       const openPopup = (url) => {
//         window.open(url, '_blank'); // You can customize width and height
//       };
//       openPopup(url)
//     } catch (error) {
//       console.error('Failed to open entry in new window:', error);
//     }
//   };

//   return (
//     <div className="clickable-tool-small" onClick={(event) => handleOpenInNewWindow(event, url)}>
//     <OpenInNewIcon />
//   </div>
//   )
// };

export const OpenLinkInNewTabTool = ({ url, displayUrl }) => {

  const handleClick = async (event) => {
    event.stopPropagation(); // stop event from propagating and triggering onClick on parent's component
    try {
      const openTab = (url) => {
        window.open(url, '_blank'); // You can customize width and height
      };
      openTab(url)
    } catch (error) {
      console.error('Failed to open entry in new window:', error);
    }
  };

  return (
    <div className="clickable-url" onClick={(event) => handleClick(event, url)}> 

      {/* <div className="hyperlink-std me-2"><LinkIcon /></div> */}
      
      {displayUrl && <p className="clickable-url-full hyperlink-std"><LinkIcon />[{url}]</p>}
      
    </div> 
  )
};