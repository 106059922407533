import dayjs from "dayjs";

// Core
const config = {
  appName: 'jotvault',
  brandName: 'JotVault',
  appVersion: 'Version 0.90 - Nov 2024',
  version: 0.90,
  env: process.env.REACT_APP_ENV  || 'local',
  footerMessage: 'Designed, Built and Deployed Out of Necessity',
  contactEmail: 'contact@brand.com',
  registrationCodes: ['2024BETA100'],
  waitlistMode: true,
  isAuthenticationEnabled: true,
  testDataMode: true,
  encryptionKeyWarning: "This passphrase is used to protect your personal data.\nDon't lose it. Never share it with anyone.",
};


// Admin options
config.admin = {
  allowCustomization: true,
  debugMode: process.env.REACT_APP_ENV !== "production",
  collections: [
    // { name: "admin-data", label: "Admin Data", hasAdminTable: true },
    // { name: "admin-types", label: "Admin Types", hasAdminTable: true },
    { name: "possible-values", label: "Possible Values", hasAdminTable: true },
    { name: "access-requests", label: "Access Requests", hasAdminTable: true, isRestricted: true },
    { name: "users", label: "Users", hasAdminTable: true, isRestricted: true },
    { name: "tags", label: "Tags", hasAdminTable: true, hasPossibleValues: true},
    { name: "habits", label: "Habits", hasAdminTable: true, hasPossibleValues: true},
    { name: "notes", label: "Notes", hasPossibleValues: true},
    { name: "entries", label: "Entries", hasPossibleValues: true},
    { name: "records", flabel:  "Records", hasPossibleValues: true},
  ],
};

// Data model
config.datModel = {
  collections: [], // TBD: insert all collection as {} with all their properties
  coreFields: [
    "creationDate",
    "createdBy",
    "lastModificationDate",
    "lastModifiedBy",
    "isSystem",
  ],
  
}

// Encryption
config.encryption = {
  isActive: true,
  knownPlainText: "this is some plain text 123456",
  userEncryptionKeyTimeout: process.env.REACT_APP_ENV === "production"
  ? 1 * 15 * 60 * 1000 // 15 minutes in milliseconds
  : 1 * 60 * 60 * 1000 // 60 minutes in milliseconds
  ,
  plainTextFields: [
    // core fields - never encrypted because not sensitive AND needed by system for loading etc.
    "_id", 
    "__v",
    "creationDate",
    "createdBy",
    "lastModificationDate",
    "lastModifiedBy",
    "isSystem",
  
    // misc fields, each field should be justified via comment
    "collection", // only used by client to tag entries fetched
    "archived", // TBD
    "migrated",
    "system",
    "approved",
    "isTest",
    
  
    // user specific fields - not encrypted because needed by system
    "salt",
    "hash",
    "encryptionKeySalt",
    "googleId",
    "username",
    "email",
    "password",
    "profile",
    "totalConnections",
    "isInitialized",
    "isMigrated",
    "isAdministrator",
    "cypherText",
    "lastConnection",
  
    "encryptionEnabled", // TBD: delete?
  ],
}

// User session
// must match cookie session timeout in server.js "maxAge"
config.userSession = {
  timeout: process.env.REACT_APP_ENV === "production"
  ? 1 * 60 * 60 * 1000 // 1 hour in milliseconds
  : 1 * 60 * 60 * 1000 // 1 hour in milliseconds
  ,
};

// Caching
config.caching = {
  isActive: true,
  isEncrypted: true,
  collections: [
    // Instructions: collections to cache must be added manually with their filters to prevent data vulnerabilities
    // { name: "admin-data", filters: null },
    { name: "possible-values", filters: null },
    { name: "tags", filters: null },
    { name: "habits", filters: null },
    { name: "notes", filters: { isTemplate: true } },
    { name: "entries", filters: { isTemplate: true } },
    { name: "records", filters: { isTemplate: true } },
  ],
};

// Mapping environment - database
config.databases = {
  'local': `${config.appName}-local`,
  'development': `${config.appName}-dev`,
  'stage': `${config.appName}-stage`, 
  'production': `${config.appName}-prod`,
  'temp': `journal-app-prod`,   
};

// UI
config.ui = {
  mobileBreakpoint: 768, 
  titleCharLimitCards: 50,
  typingAgentMode: 'char',
  typingSpeed: 25, //20-40 closer to natural typing
  hideFooterOnRoutes: ['/notes', '/entries', '/records'], //['/app', '/home', '/notes', '/entries', '/records', '/test']
}

config.agent = {
  speed : 3,
}

config.copyrightMessage = `© ${dayjs().year()} ${config.brandName} - All Rights Reserved`;

// User Data
config.data = {
  entriesPerPage: 14,
  upToCurrentPage: true,
  collections: [
    { label: 'Notes', value: 'notes' },
    { label: 'Entries', value: 'entries' },
    { label: 'Records', value: 'records' },
    { label: 'user-entries', value: 'user-entries' }, // TBD: deleted after 0.90
    { label: 'user-records', value: 'user-records' }, // TBD: deleted after 0.90
  ],  
};

// Loading
config.loading = {
  minDelay: 1000,
  minTimeBtwSteps: 5000,
};

// Auto saving
config.autoSaving = {
  active: true,
  interval: 5000,
  indicatorDelay: 3000,
}

// Data Initialization
config.dataInitialization = {
  isActive: true,
  collections: ['possible-values', 'tags', 'habits'],
}

// Data export
config.export = {
  collectionsToExport: ['possible-values', 'tags', 'habits', 'notes', 'entries', 'records'],
  // TBD: update fields with new convention release 0.90 is..
  fieldsToIgnore: [
    "__v",
    "encryptionEnabled",
    "createdBy",
    "lastModifiedBy",
    "entity",
    "isActive",
    "isArchived",
    "isSystem",
    "isTemplate",
    "isDefault",
  ],
};

config.forms = {
  showInternalFields: true,
  fieldsToPropagateFromTemplate: ['title', 'content', 'tags'],
};

config.habits = {
  frequencies: ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"],
}

export default config;