import React from 'react';
import DataForm from '../../../components/forms/data-form';
import useUserForm from '../hooks/use-user-form';


const UserInfoForm = ({ user }) => {
 
  const { saveForm } = useUserForm(user._id); 

  const fields = [
    { label: 'Username', type: 'text', id: 'username', disabled: true },
    { label: 'User ID', type: 'text', id: '_id', disabled: true },
    { label: 'Creation Date', type: 'text', id: 'creationDate', disabled: true },
    { label: 'Number of Connections', type: 'number', id: 'totalConnections', disabled: true },
    // { label: 'First Name', type: 'text', id: 'firstName' },
    // { label: 'Last Name', type: 'text', id: 'lastName' },
    // { label: 'Email', type: 'email', id: 'email' },
    // { label: 'Country', type: 'select', id: 'country', options: ['USA', 'UK', 'FR', 'LATAM'] },
    // { label: 'City', type: 'text', id: 'city' },
    // { label: 'State', type: 'text', id: 'state' },
    // { label: 'Zip Code', type: 'text', id: 'zip' },
    // { label: 'Verified?', type: 'checkbox', id: 'verified' },
    // { label: 'Administrator?', type: 'checkbox', id: 'isAdministrator', disabled: true },
  ].map(field => ({ ...field, value: user[field.id] || null }));

  return <DataForm fields={fields} onSubmit={saveForm} disableSave={true} />;
};

export default UserInfoForm;
