import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import "./tools.css"

const OpenPageInNewTabTool = ({ url }) => {

  const handleClick = async (event) => {
    event.stopPropagation(); // stop event from propagating and triggering onClick on parent's component
    try {
      const openTab = (url) => {
        window.open(url, '_blank'); // You can customize width and height
      };
      openTab(url)
    } catch (error) {
      console.error('Failed to open entry in new window:', error);
    }
  };

  return (
    <div className="clickable-tool-small" onClick={(event) => handleClick(event, url)}>
      <OpenInNewIcon />
    </div>
  )
};

export default OpenPageInNewTabTool;