import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/user-context';
import { updateUserParametersForView, updateUserParametersForSearch } from '../../../utils/caching';

const FilterResultAlert = ({ viewId, numberOfEntries }) => {

  const { setUserParameters } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const resetFilters = () => {
    updateUserParametersForView(setUserParameters, viewId, 'filters', {});
    updateUserParametersForSearch(setUserParameters, "");
    if(location.pathname.startsWith("/app/search")) {
      navigate('/app/home/');
    }
  }

  return (
    <div>
      {numberOfEntries === 0 ?
        <div className="alert alert-warning d-flex" role="alert">
          <div className='me-2'>
            {'No entry found with your search criteria. Update or clear filters to display data'}
            <button 
            type="button" 
            className="btn btn-secondary btn-sm custom-button-alert" 
            onClick={() => resetFilters()}
            style={{
              '--bs-btn-font-size': '.75rem',
              borderRadius: '15px',
              marginLeft: '5px',
            }}
          >Clear Filters</button>
          </div>
        </div>
      : <div className="alert alert-success d-flex" role="alert">
          <div className='me-2'>
            <strong>{numberOfEntries} entries </strong> found with your <strong>search</strong> criteria
            <button 
              type="button" 
              className="btn btn-secondary btn-sm custom-button-alert" 
              onClick={() => resetFilters()}
              style={{
                '--bs-btn-font-size': '.75rem',
                borderRadius: '15px',
                marginLeft: '5px',
              }}
            >Clear Filters</button>
          </div>
        </div>
      }
    </div>
  )
}

export default FilterResultAlert;