const loadConfig = (configFile) => {
  let coreConfig = {};
  let customConfig = {};

  // Helper function to handle default and named exports
  const mergeExports = (module) => {
    return module.default ? { ...module.default, ...module } : { ...module };
  };

  // Try loading core config dynamically
  try {
    const coreModule = require(`../../core/config/${configFile}`);
    coreConfig = mergeExports(coreModule);
    // console.log(`Loaded core config for: ${configFile}`);
  } catch (e) {
    console.error(`Core config not found for: ${configFile}`, e);
  }

  // Try loading custom config dynamically
  // TBD could add a check if custmization/configuration is allowed
  try {
    const customModule = require(`../../custom/config/${configFile}`);
    customConfig = mergeExports(customModule);
    // console.log(`Loaded custom config for: ${configFile}`);
  } catch (e) {
    console.log(`No custom config found for: ${configFile}, using core config only.`);
  }

  // Merge custom config over core config
  const finalConfig = { ...coreConfig, ...customConfig };
  // console.log('Final merged config:', finalConfig);

  return finalConfig;
};

export default loadConfig;