import { useNavigate } from 'react-router-dom';
import { duplicateEntry } from "../../../utils/entry-actions";
import { deleteEntry } from "../../../utils/entry-actions";
import { Delete, FileCopy } from '@mui/icons-material';
import '../../../styles/toolbars.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const DropdownMenu = loadModule("./components/dropdowns/dropdown-menu.js");

const EntryToolbar = ({
  formData,
  saveForm,
  collection,
}) => {

  const navigate = useNavigate();

  // TBD: move to config file?
  const actions = [
    { label: "Duplicate", icon: <FileCopy />, onClick: () => duplicateEntry(collection, formData._id, navigate) },
    { label: "Delete", icon: <Delete />, onClick: () => deleteEntry(collection, formData._id, navigate) },
  ];

  return (
    <div className="view-toolbar">
      <button
        type="submit"
        className="btn btn-primary"
        onClick={() => saveForm(formData)}
      >
        Save
      </button>
      {formData._id && (
        <DropdownMenu
          actions={actions}
          iconOnly={false}
          buttonLabel={"More"}
          buttonClass={"btn-secondary"}
        />
      )}
    </div>
  );
  
};

export default EntryToolbar;