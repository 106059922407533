import { useEffect, useState, useMemo } from "react";
import useData from "../../../hooks/use-data";
import { transformObjForReactSelect } from '../../../utils/data-processing';
import { sortData } from "../../../utils/data-processing";
import { capitalizeFirstLetter } from "../../../utils/dataUtils";

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const SingleSelect = loadModule("./components/selects/single-select.js");

const SourceInputField = ({collection, value, handleSingleSelectChange}) => {

  const collections = useMemo(() => [collection], [collection]);
  const { data } = useData(collections);
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const sortEntries = async () => {
      if (data) {
        const sorted = await sortData(data, 'desc');
        const filtered = sorted.filter(x => x.title && x.title !== "" && x.title !== 'Unnamed' && !x.isTemplate)
        setEntries(filtered);
      }
    };

    sortEntries();
  }, [data]);
 
  return (
    <div key={'source'} className="mb-3">
      <label htmlFor={'source'} className="form-label">
        {`Source ${capitalizeFirstLetter(collection)}`}
      </label>
      <div className="select-source flex-grow-1">
        <SingleSelect
          fieldName={'source'}
          options={entries ? entries.map(obj => (transformObjForReactSelect(obj))) : null}
          value={value ? transformObjForReactSelect(value) : null}
          onChange={(selected) => handleSingleSelectChange(selected, 'source')}
          isClearable={true}
        />
      </div>
    </div>
  )
}

export default SourceInputField;