import React from 'react';

const ViewWithSections = () => {
  return (
    <div>
      <section id="section1">
        <h2>Section 1 Title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempor dolor id libero hendrerit, sed ultricies est sollicitudin. Phasellus vitae eros ac quam consequat bibendum. Proin eu arcu malesuada, pharetra ipsum at, tincidunt lorem. Nullam sit amet arcu urna. Praesent auctor vel lacus at malesuada. Vivamus volutpat, nisl in tincidunt elementum, felis augue interdum libero, at bibendum libero erat et sapien. Cras nec turpis et leo auctor suscipit. Maecenas venenatis, velit ut suscipit vulputate, risus ipsum iaculis risus, at iaculis nisi sem a mauris. Ut non erat et nunc pellentesque feugiat. Nam blandit orci at libero consequat fermentum. Sed a nisl ac eros commodo blandit non et libero. Aliquam erat volutpat.
        </p>
        <p>
          Morbi eu turpis auctor, venenatis lectus ac, pretium orci. Fusce varius, ligula vel tempor tempor, lacus leo sagittis orci, non consequat nisi dui at ipsum. Curabitur ut consectetur urna. Pellentesque scelerisque mi quis consectetur blandit. Aenean cursus, eros eget posuere scelerisque, lacus enim vehicula nunc, in gravida lectus erat id risus. Sed sit amet eros sem. Proin rutrum ligula eget suscipit auctor. Nam auctor eros sit amet interdum facilisis. Nunc non urna vel arcu posuere auctor. Fusce feugiat arcu eu lacus ultrices, id scelerisque purus sollicitudin. Nam id ligula in lorem aliquam faucibus. Quisque ac leo id sapien consequat viverra.
        </p>
      </section>

      <section id="section2">
        <h2>Section 2 Title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur consectetur, lorem a tincidunt pharetra, mauris eros hendrerit odio, eget vehicula libero libero ac erat. Vestibulum et aliquet nulla. Nullam vel purus et mi sagittis volutpat. Sed non orci a orci varius tincidunt. Aliquam vestibulum nunc non sapien mollis, nec fringilla elit pulvinar. Mauris facilisis, sapien a ullamcorper faucibus, lorem ex pharetra orci, et tempor sapien urna ut quam. Nulla facilisi. Cras facilisis semper est, a feugiat est dapibus a. Nulla facilisi. Duis auctor elit in ligula ultricies, et vestibulum lectus convallis. Donec fringilla posuere enim, vel vestibulum felis venenatis et.
        </p>
        <p>
          Ut convallis, leo at vestibulum convallis, eros felis eleifend lacus, eget hendrerit odio erat a est. Sed dignissim, turpis at pharetra lacinia, lorem sapien laoreet ex, a luctus erat felis nec turpis. Etiam malesuada felis at libero malesuada, et dignissim purus scelerisque. Morbi tristique, dui eget lacinia sodales, lacus odio tempus urna, non pretium ligula ligula ut nunc. Nam sit amet sodales lectus, vel congue odio. Mauris sed nisi suscipit, malesuada orci sed, venenatis libero. Donec feugiat ultricies metus, vitae congue lacus luctus vel. Nullam eu ipsum at enim tempor blandit. Aliquam id mi a ligula facilisis tincidunt. Proin hendrerit magna velit, sit amet vehicula arcu dignissim ac. Sed eu nulla turpis.
        </p>
      </section>

      <section id="section3">
        <h2>Section 3 Title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam ultricies magna, vitae ultricies nulla auctor a. Fusce pharetra cursus augue, a scelerisque lacus cursus sit amet. Ut blandit augue non ante interdum, vel tincidunt sapien convallis. Suspendisse potenti. Vestibulum fringilla, lectus nec dictum dignissim, ex ligula dictum dui, a cursus elit tortor sit amet orci. Proin ac orci vitae metus aliquet aliquam. Sed aliquet quam id bibendum interdum. Proin et laoreet magna. Mauris vel mi sed risus malesuada auctor. In non purus ipsum. Sed vel odio in lorem tincidunt convallis.
        </p>
        <p>
          Quisque scelerisque feugiat augue a efficitur. Ut at est quam. Nulla sit amet scelerisque justo. Aliquam erat volutpat. Ut tincidunt turpis vel ante efficitur, ac varius enim scelerisque. Cras fringilla nunc non magna fermentum, eget luctus justo malesuada. Donec convallis sit amet lorem eget condimentum. Nam auctor efficitur eros, eget scelerisque nisi ultrices sit amet. Integer sodales justo et nisi egestas, id facilisis enim consequat. Donec sodales magna ac tincidunt auctor. Fusce ac odio id odio tincidunt tristique. Sed vulputate eros nec arcu sagittis, a rhoncus felis fringilla. Duis vel nisi purus. Vivamus pharetra nulla sed pharetra dignissim. Suspendisse potenti. Ut sit amet malesuada odio.
        </p>
      </section>

      <section id="section4">
        <h2>Section 4 Title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum diam ac tincidunt iaculis. Morbi sed urna vel nunc aliquam cursus. Phasellus ac convallis nisl, non fringilla lacus. Cras ac posuere augue. Integer sagittis malesuada turpis id tincidunt. Sed et dolor dolor. Sed id augue sed urna tempus tincidunt. Integer a bibendum risus. Duis consequat lacus id velit cursus, vel volutpat nunc scelerisque. In hac habitasse platea dictumst. Nullam feugiat est ac dui fermentum bibendum. Aenean lacinia velit eget velit ullamcorper venenatis. Nulla facilisi. Mauris ac orci dolor. Suspendisse ullamcorper augue vitae eros ullamcorper facilisis.
        </p>
        <p>
          Curabitur aliquet, eros id fringilla laoreet, risus turpis consequat felis, sit amet molestie magna nunc id metus. Ut pretium nulla sed velit venenatis, a dapibus libero scelerisque. Integer feugiat convallis ipsum, at consectetur sem ultrices quis. Etiam sit amet ligula leo. Pellentesque ac diam a felis dignissim vestibulum. Aliquam dictum orci ac velit blandit tincidunt. Sed ultricies sapien libero, ut elementum odio pellentesque in. Nunc sit amet ex id nisi vehicula efficitur. Nam venenatis justo velit, nec vehicula orci tempus a. Fusce malesuada dolor eu mi posuere, a posuere nulla tristique. Sed interdum, erat et cursus dapibus, sapien ligula sollicitudin odio, sed ultrices risus felis in magna. Suspendisse potenti. Phasellus sed consequat justo. Proin ut viverra est.
        </p>
      </section>

      <section id="section5">
        <h2>Section 5 Title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel nisl ut ex vulputate gravida. Integer interdum magna nisi, eget posuere sapien tincidunt non. Curabitur sit amet ligula quis velit pulvinar dictum. Suspendisse potenti. Donec auctor turpis nec leo ultricies, nec efficitur nulla vestibulum. Integer at suscipit urna. Donec sit amet eros ut dolor varius congue id sed eros. Quisque finibus risus vel facilisis tincidunt. Aliquam erat volutpat. Sed sit amet dapibus nisi. Proin vitae scelerisque purus. Nullam nec fermentum mauris. In hac habitasse platea dictumst. Nulla interdum luctus felis, ac fermentum arcu condimentum non.
        </p>
        <p>
          Aliquam erat volutpat. Duis et libero tincidunt, bibendum lacus quis, cursus dolor. Integer non odio vel urna facilisis volutpat. Nulla facilisi. Sed et tortor ac felis eleifend finibus sit amet id mauris. Ut eget arcu id augue luctus pretium. Donec laoreet scelerisque purus, a egestas odio tincidunt sit amet. Aliquam quis odio eu nisl tincidunt facilisis. Proin egestas vel lectus eget eleifend. Nam dapibus, urna at hendrerit hendrerit, libero enim condimentum orci, eget consequat erat lacus sit amet augue. Suspendisse potenti. Etiam luctus urna in elit ullamcorper lacinia. Vestibulum vitae suscipit nisl, id sagittis enim. Fusce mollis aliquam suscipit. Sed nec posuere lectus.
        </p>
      </section>
    </div>
  );
}

export default ViewWithSections;
