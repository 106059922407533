import React from 'react';
import { exportUserData } from '../../../utils/dataImportExport';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');

const DataExportSection = () => {

  return (
    <div className='data-import'>
      {/* TBD make this section dynamic, iterate on all collections to allow export OR make one block where user can select the collection to export */}
      <ButtonActionRow 
        title={'Notes'}
        description={'Export all your notes in plain text format for external use.'}
        buttonName={'Export'}
        handleAction={() => exportUserData('plaintext', ['notes'])}
      />
      <ButtonActionRow 
        title={'Journal entries'}
        description={'Export all your journal entries in plain text format for external use.'}
        buttonName={'Export'}
        handleAction={() => exportUserData('plaintext', ['entries'])}
      />
      <ButtonActionRow 
        title={'User records'}
        description={'Export all your personal records in plain text format for external use.'}
        buttonName={'Export'}
        handleAction={() => exportUserData('plaintext', ['records'])}
      />
      <ButtonActionRow 
        title={'All user data'}
        description={'Export all your data including journal entries, records and admin data in plain text format for external use.'}
        buttonName={'Export'}
        handleAction={() => exportUserData('plaintext')}
      />
      <ButtonActionRow 
        title={'Protected backup'}
        description={'Export an archive of all your data that can be reimported at a later point in time.'}
        buttonName={'Export'}
        handleAction={() => exportUserData('encrypted')}
        isLast={true}
      />
    </div>
  );
};

export default DataExportSection;