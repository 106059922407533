import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = ({ message, handleConfirmation }) => {
  const [showModal, setShowModal] = useState(true);
  const handleCancel = () => {
    setShowModal(false);
    handleConfirmation(false);
  }
  const handleSave = () => {
    setShowModal(false);
    handleConfirmation(true);
  }

  return (
    <div>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message || "Please confirm Action to Proceed"}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmationModal;