import { useEffect, useState } from "react";
import { getAllDocumentsCount } from "../services/database-services";
import loadConfig from "../utils/configLoader";

const config = loadConfig('general');

const useSystemStats = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [systemStats, setSystemStats] = useState([]);

  useEffect(() => {
    const getUserStats = async () => {
      try {
        const collections = config.admin.collections.map(collection => collection.name);
        const dataToExport = await Promise.all(collections.map(async (collection) => {
          try {
            const count = await getAllDocumentsCount(collection);
            return { label: collection, number: count };
          } catch (error) {
            console.error(`Failed to process collection ${collection}: `, error);
            return { label: collection, number: 0 };
          }
        }));
        setSystemStats(dataToExport);
      } catch (error) {
        console.error('Failed to fetch model: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    getUserStats();
  }, []);

  return { systemStats, isLoading };

};

export default useSystemStats;
