import SplitViewResponsiveLayout from '../../../layouts/view/split-view-responsive';
import Content from '../components/content';
import Panel from '../components/panel';
import useUserData from '../hooks/use-user-data';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const Spinner = loadModule('./components/loading/spinner.js');

const UserMain = () => {
  
  // loading data, states and handlers only once to benefit all sub components
  const { data, isLoading } = useUserData();

  return (
    <div className="centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <SplitViewResponsiveLayout 
          content={<Content data={data} />}
          panel={<Panel data={data} />}
          isPanelContrastEnabled={false}
          // isPanelFoldable={true}
        />
      )}
    </div>
  );
};

export default UserMain;