import ViewContentLayout from '../../../layouts/view/view-content-layout';
import UserStats from '../../user-stats/components/user-stats';

const Panel = ({ data }) => {

  return (
    <ViewContentLayout 
      // title={"⚙️ Utility Panel"}
      // subtitle={"All your user settings"}
    >
      <UserStats />
    </ViewContentLayout>
  );
};

export default Panel;