import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../../core/contexts/user-context';
import { hasAccessToFeature } from '../../../../core/utils/user-access-control';
import { WarningAmberOutlined } from '@mui/icons-material';
import './main-menu.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const config = loadModule("./features/main-menu/config.js");

const MainMenu = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const isMenuItemActive = (item) => {
    if (location.pathname === item.href) return true;
    if (
      location.pathname.startsWith(item.href) &&
      (location.pathname[item.href.length] === '/' || location.pathname[item.href.length] === undefined)
    )
      return true;
    return false;
  };

  return (
    <div className="main-menu">
      {Object.entries(config).map(([category, items]) => {
        const accessibleItems = items.filter((item) => hasAccessToFeature(item.label, user));

        if (accessibleItems.length > 0) {
          return (
            <div className="menu-grouping" key={category}>
              {category && <div className="menu-category">{category}</div>}
              {accessibleItems.map((item) => {
                const MuiIconComponent = item.icon || WarningAmberOutlined;  // Assume icon is a valid component

                return (
                  <a
                    key={item.href}
                    href={item.href}
                    onClick={item.onClick}
                    className={`menu-item-row ${isMenuItemActive(item) ? 'active' : ''}`}
                    aria-label={item.label}
                    title={item.label}
                  >
                    <MuiIconComponent className="menu-item-icon" />
                    <span className="menu-item-label">{item.label}</span>
                  </a>
                );
              })}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default MainMenu;