import { useEffect, useState, useRef } from "react";
import { paginateData } from "../../../utils/data-processing";

import loadConfig from "../../../utils/configLoader";
const config = loadConfig('general');

const usePagination = (data) => {

  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0); 
  
  const [currentPage, setCurrentPage] = useState(1); 
  const [currentPageItems, setCurrentPageItems] = useState([]);
 
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef(null);
  const lastItemRef = useRef(null);


   // IMPORTANT: for some reason, this has to stay outside of the useEffect, 
 // otherwise observer not working well when no filter applied. TBD.
 const fetchMoreData = async () => {
  if (currentPage * config.data.entriesPerPage < totalItems) {
    setLoadingMore(true);
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
      setLoadingMore(false);      
    }, config.loading.minDelay);
  }
};

// Effect to set up IntersectionObserver and observe the last item
useEffect(() => {
  if (lastItemRef.current) {
    observer.current = new IntersectionObserver(
      (sortedData) => {
        if (sortedData[0].isIntersecting) {
          fetchMoreData();
        }
      },
      { threshold: 1, rootMargin: '50px' } // Added rootMargin to ensure it works on Mobile when scrolling down
      );

    observer.current.observe(lastItemRef.current);
  }

  // Cleanup the observer on component unmount
  return () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };
}, [lastItemRef, fetchMoreData]);



useEffect(() => {

  const paginateEntries = () => {
    try {
      const renderedData = [...data]; // Create a copy of the array
      setTotalItems(renderedData.length); // readjust total items based on user selection
  
      const paginatedData = paginateData(renderedData, currentPage, config.data.entriesPerPage, config.data.upToCurrentPage);
  
      setCurrentPageItems(paginatedData);
  
    } catch (error) {
      console.error('Failed to paginate data:', error);
    }
  }; 

  if (data) { 
    paginateEntries(); // we only paginate when data is ready
    setIsLoading(false);
  };
  
}, [currentPage, config.data.itemsPerPage, data]);

  return { currentPageItems, setCurrentPageItems, lastItemRef, loadingMore, isLoading };

};

export default usePagination;
