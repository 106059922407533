import loadConfig from '../../utils/configLoader';
import './footer.css'

const config = loadConfig('general');

const Footer = () => {
  return (
    <div className='footer py-4'>
      <div className="container">
        <div className="justify-content-center links">
            <ul className='list-unstyled d-flex justify-content-center mb-3'>
              <li className="mx-2"><a href="/policy/terms-of-service">Terms of Service</a></li>
              <li className="mx-2"><a href="/policy/privacy-policy">Privacy Policy</a></li>
              <li className="mx-2"><a href="/policy/cookie-policy">Cookie Policy</a></li>
              <li className="mx-2"><a href={`mailto:${config.contactEmail}`}>Contact</a></li>
            </ul>
        </div>
        <div className="justify-content-center footer-message">
          <div className="text-center text-body-secondary">
            <p className="mb-0">{config.footerMessage}</p>
            <p className="mb-0">{config.copyrightMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;