import React from 'react';

const Metadata = () => {
  return (
    <div className="container">
      <h2>Metadata Form</h2>
      <form>
        {/* Text Input */}
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input type="text" className="form-control" id="title" />
        </div>
        
        {/* Dropdown */}
        <div className="mb-3">
          <label htmlFor="category" className="form-label">Category</label>
          <select className="form-select" id="category">
            <option value="">Select Category</option>
            <option value="category1">Category 1</option>
            <option value="category2">Category 2</option>
            <option value="category3">Category 3</option>
          </select>
        </div>
        
        {/* Text Input */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea className="form-control" id="description" rows="3"></textarea>
        </div>
        
        {/* Checkbox */}
        <div className="mb-3">
          <label className="form-label">Options</label>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="option1" />
            <label className="form-check-label" htmlFor="option1">Option 1</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="option2" />
            <label className="form-check-label" htmlFor="option2">Option 2</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="option3" />
            <label className="form-check-label" htmlFor="option3">Option 3</label>
          </div>
        </div>
        
        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default Metadata;
