import "./Split3Col.css";

const Split3Col = ({ left, center, right }) => {
  return (
    <div className="three-sections-toolbar">
      {/* Left */}
      <div className="three-sections-toolbar-left">
        {left}
      </div>

      {/* Center */}
      <div className="three-sections-toolbar-center">
        {center}
      </div>

      {/* Right */}
      <div className="three-sections-toolbar-right">
        {right}
      </div>
    </div>
  )
};

export default Split3Col;
