import useFileUpload from '../../../hooks/use-file-upload';
import useSourceDbSelected from '../hooks/use-source-db-selected';
import { handleResetDatabase, handleRestoreDatabase, handleDumpDatabase } from '../utils/sytem-utils';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
import loadConfig from '../../../utils/configLoader';
const config = loadConfig('general');
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');
const DropdownActionRow = loadModule('./components/administration/dropdown-action-row.js');
const FileUploadActionRow = loadModule('./components/administration/file-upload-action-row.js');

const DatabaseManagementSection = () => {

  const { importedFile, handleFileUpload } = useFileUpload();
  const { sourceDbSelected, handleSourceDbSelected } = useSourceDbSelected();

  return (
    <div>
      <DropdownActionRow 
        title={'Dump database'}
        description={'Take a full backup of selected database via mongodump utility. Must be supported by app server.'}
        dropdownValues={config.databases ? Object.values(config.databases) : ["No database found"]}
        handleDropdownChange={handleSourceDbSelected}
        selectedValue={sourceDbSelected}
        buttonName={'Dump'}
        handleAction={() => handleDumpDatabase(sourceDbSelected)}
      />
      <FileUploadActionRow 
        title={'Restore database'}
        description={'Restore a full database backup via mongorestore utility. Must be supported by app server (local only).'}
        buttonName={'Restore'}
        handleAction={() => handleRestoreDatabase(importedFile)}
        zipFileUpload={handleFileUpload}
      />
      <ButtonActionRow 
        title={'Reset database'}
        description={'Delete all data in database.'}
        buttonName={'Reset'}
        handleAction={() => handleResetDatabase()}
        isLast={true}
      />
    </div>
  );
};

export default DatabaseManagementSection;