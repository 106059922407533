import loadConfig from "../../core/utils/configLoader";
const config = loadConfig('general');

export const profiles = ["Basic", "Advanced", "Pro", "Administrator"];
export const defaultUserProfile = "Basic";

export const routesAccess = {
  "/app/home":                     ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/search":                   ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/notes":                    ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/journals":                 ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/entries":                  ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/records":                  ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/user":                     ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/user/charts":              ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/data":                     ["Basic", "Advanced", "Pro", "Administrator"],
  "/app/system":                   ["Administrator"],
  "/app/import-export":            ["Basic", "Advanced", "Pro", "Administrator"],
};

export const featuresAccess = {
  "Home":                     ["Basic", "Advanced", "Pro", "Administrator"],
  "User":                     ["Basic", "Advanced", "Pro", "Administrator"],
  "Data":                     ["Basic", "Advanced", "Pro", "Administrator"],
  "System":                   ["Administrator"],
  "Documentation":            ["Basic", "Advanced", "Pro", "Administrator"],
  "Notes":                    ["Basic", "Advanced", "Pro"],
  "Journals":                 ["Basic", "Advanced", "Pro"],
  "Entries":                  ["Basic", "Advanced", "Pro"],
  "Records":                  ["Basic", "Advanced", "Pro"],
  "Lessons":                  ["Basic", "Advanced", "Pro"],
  "Manage Tags":              ["Basic", "Advanced", "Pro", "Administrator"],
  "Manage Habits":            ["Basic", "Advanced", "Pro", "Administrator"],
  "Data Manager":             ["Basic", "Advanced", "Pro", "Administrator"],
  "Import & Export":          ["Basic", "Advanced", "Pro", "Administrator"],
  "Reset User Data":          ["Administrator"],
  "Dump Database":            ["Administrator"],
  "Restore Database":         ["Administrator"],
  "Reset Database":           ["Administrator"],
  "Load Test Data":           ["Administrator"]
};

// Development overrides
if (config.env === 'dev' || config.env === 'local') {
  routesAccess["/app/test"] = ["Basic", "Advanced", "Pro", "Administrator"];
  featuresAccess["Testing"] = ["Basic", "Advanced", "Pro", "Administrator"];
};
