// TBD: pretty complex component, keeping as-is for now since used temporarily fo testing
import React, { useState} from 'react';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { createTemplateUser, loadTestData } from "../utils/data-loading";
import "./system-data-loading.css"

const SystemDataLoading = () => {

  const today = new Date();
  // const startOfYear = moment(today).subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
  // const endOfYear = moment(today).endOf('year').format('YYYY-MM-DD');
  const ed = moment(today).format('YYYY-MM-DD');
  const sd = moment(today).subtract(1, 'year').format('YYYY-MM-DD');
  const [ startDate, setStartDate ] = useState(sd);
  const [ endDate, setEndDate ] = useState(ed);

  const [ numberOfEntriesPerDay, setNumberOfEntriesPerDay ] = useState(1);
  const [ createEntryHabits, setCreateEntryHabits ] = useState(false);
  const [ createEntryTags, setCreateEntryTags ] = useState(true);

  const [ numberOfRecordsPerDay, setNumberOfRecordsPerDay ] = useState(2);
  const [ createRecordTags, setCreateRecordTags ] = useState(false);
  
  const [ numberOfUsers, setNumberOfUsers ] = useState(2);
  const [ numberOfEntries, setNumberOfEntries ] = useState(0);

  const callCreateTemplateUser = async () => await createTemplateUser(
    startDate,
    endDate,
    numberOfEntriesPerDay,
    createEntryHabits,
    createEntryTags,
    numberOfRecordsPerDay,
    createRecordTags
  );
  // const callCreateTemplateUser = () => {console.log("TBD")}

  const callLoadTestData = async () => {
    await loadTestData(
      numberOfUsers, 
      numberOfEntries,
      );
  };
  // const callLoadTestData = () => {console.log("TBD")}


  return (
    <div>

      {/* CREATE TEMPLATE USER */}
      <div className="admin-action-row" style={{marginBottom: 0}}>
        <div className="admin-action-col text">
          <h4 className="admin-action-title">Setup Template User</h4>
          <p className="admin-action-desc">Create a template user with test data.</p>

        </div>
        <div className="admin-action-col button">
          <button type="button" className="btn btn-primary" style={{alignSelf: 'flex-end'}} onClick={callCreateTemplateUser}>Create User</button>
        </div>
        
      </div>
      <div className="admin-action-parameters">
        <p>Parameters</p>
        <div className='row-of-paramaters'>
          <div className="admin-action-parameter">
            <label htmlFor='numberOfUsers' className="form-label">Start Date</label>
            <input 
              type='date'
              className="form-control" 
              id='startDate'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></input>
          </div>
          <div className="admin-action-parameter">
            <label htmlFor='endDate' className="form-label">End Date</label>
            <input 
              type='date'
              className="form-control" 
              id='endDate'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            ></input>
          </div>
        </div>
        <hr className="hr" />

        <div className="admin-action-parameter">
          <label htmlFor='endDate' className="form-label"># Entries per Day</label>
          <input 
            type='number'
            className="form-control" 
            id='endDate'
            value={numberOfEntriesPerDay}
            onChange={(e) => setNumberOfEntriesPerDay(e.target.value)}
          ></input>
        </div>

        <div className='row-of-paramaters'>
          <div className="admin-action-parameter">
            <FormControlLabel 
              label="Habits?"
              control={
                <Checkbox
                  className='filter-checkbox'
                  checked={createEntryHabits}
                  onChange={() => setCreateEntryHabits(!createEntryHabits)}
                />
              }
            />
          </div>
          <div className="admin-action-parameter">
            <FormControlLabel 
              label="Tags?"
              control={
                <Checkbox
                  className='filter-checkbox'
                  checked={createEntryTags}
                  onChange={() => setCreateEntryTags(!createEntryTags)}
                />
              }
            />
          </div>
        </div>
        <hr className="hr" />

        <div className="admin-action-parameter">
          <label htmlFor='numberOfUsers' className="form-label"># Records per Day</label>
          <input 
            type='number'
            className="form-control" 
            id='startDate'
            value={numberOfRecordsPerDay}
            onChange={(e) => setNumberOfRecordsPerDay(e.target.value)}
          ></input>
        </div>

        <div className='row-of-paramaters'>
          <div className="admin-action-parameter">
            <FormControlLabel 
              label="Tags?"
              control={
                <Checkbox
                  className='filter-checkbox'
                  checked={createRecordTags}
                  onChange={() => setCreateRecordTags(!createRecordTags)}
                />
              }
            />
          </div>
        </div>

      </div>
      <hr className="hr" />

      {/*LOAD DATA*/}
      <div className="admin-action-row" style={{marginBottom: 0}}>
        <div className="admin-action-col text">
          <h4 className="admin-action-title">Load Test Data</h4>
          <p className="admin-action-desc">Create test users and load test data for a large number of users to test system performances.</p>
        </div>
        <div className="admin-action-col button">
          <button type="button" className="btn btn-primary" onClick={callLoadTestData}>Load</button>
        </div>
      </div>
      <div className="admin-action-parameters">
        <p>Parameters</p>
        <div className="admin-action-parameter">
          <label htmlFor='numberOfUsers' className="form-label">Number of Users</label>
          <input 
            type='number'
            className="form-control" 
            id='numberOfUsers'
            value={numberOfUsers}
            onChange={(e) => setNumberOfUsers(e.target.value)}
          ></input>
        </div>
      </div>

    </div>
  );
};

export default SystemDataLoading;