import { useState, useEffect, useRef } from 'react';
import './card-content.css';

const CardContentWithBlur = ({ content, folded }) => {
  // State to track if the height exceeds 200px
  const [height, setHeight] = useState(false);

  // Ref to access the div
  const divRef = useRef(null);

  useEffect(() => {
    // Function to check height
    const checkHeight = () => {
      if (divRef.current) {
        // Get the height of the div
        const contentHeight = divRef.current.scrollHeight;
        // Update the state based on the height
        setHeight(contentHeight);
      }
    };

    // Initial check
    checkHeight();

    // Set up an observer to check for changes in height
    const resizeObserver = new ResizeObserver(checkHeight);
    resizeObserver.observe(divRef.current);

    // Clean up observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div 
      ref={divRef}
      className={`card-content ${!folded ? "card-content-expanded" : ""}`}>
      {/* <div className='card-content-height'>
        {height}
      </div> */}
      <div className='card-content-text'>
        {content}
      </div>
      {(folded && height > 300) && <div className="card-content-blur-overlay"></div>}
    </div>
    
  );
};

export default CardContentWithBlur;
