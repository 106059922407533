import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import './standard-layout.css';

const StandardLayout = ({ header, footer, transparentHeader, alternateSecWidth, alternateSecColors, showSecFullHeight }) => {

  // Memoize header, content, and footer to avoid unnecessary re-renders
  const memoizedHeader= useMemo(() => header, [header]);
  const memoizedFooter = useMemo(() => footer, [footer]);

  return (
    <div className='standard-layout'>
      <div className={`standard-layout-navbar fixed-top ${transparentHeader? 'transparent' : ''}`}>
        {memoizedHeader}
      </div>
      <div className={`standard-layout-main ${alternateSecWidth ? 'alternate-section-width' : ''} ${alternateSecColors ? 'alternate-section-colors' : ''} ${showSecFullHeight ? 'full-height' : ''}  `}>
        <Outlet />
      </div>
      <div className='standard-layout-footer'>
        {memoizedFooter}
      </div>
    </div>
  );
};

export default StandardLayout;