// Users.js
// Import libraries
import React from 'react';

// Custom Components
import { DataTable } from '../../mui-tables/mui-data-table';

const table = () => {
    const collection = 'users';
    const options = {
      enableGrouping: true,
      enableCreation: false,
      groupingColumns: null,
      enablePagination: true,
      sorting: [
        {
          id: 'creationDate',
          desc: false,
        },
      ],
      columnVisibility: {
      }
    };
    const dataModel = [
      {
        name: 'username',
        type: 'text',
        colWidth: 200,
      },
      {
        name: 'totalConnections',
        header: 'Total Connection',
        type: 'number',
        colWidth: 180,
        enableEditing: false,
      },
      {
        name: 'profile',
        type: 'text',
        colWidth: 130,
      },
      {
        name: 'creationDate',
        header: 'Creation Date',
        type: 'text',
        colWidth: 180,
        enableEditing: false,
      },
      {
        name: 'lastConnection',
        header: 'Last Connection',
        type: 'text',
        colWidth: 180,
        enableEditing: false,
      },
      {
        name: 'isAdministrator',
        header: 'Administrator?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 150,
      },
      {
        name: 'isTest',
        header: 'Test?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 150,
      },
      {
        name: 'isInitialized',
        header: 'Initialized?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 150,
      },
      {
        name: 'isMigrated',
        header: 'Migrated?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 150,
      },
      {
        name: 'isActive',
        header: 'Active?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 110,
      },
    ];

  return (
    <DataTable 
      collection = {collection}
      dynamicQueryKey = {[collection]}
      options = {options}
      dataModel = {dataModel} 
    />
  )
};

export default table;