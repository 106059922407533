// Direct Imports - Protected Modules
import useAccessRequest from '../../../../core/features/visitor/hooks/use-access-request';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import LandingPageImg from '../../../assets/images/landing-page-img.png';

// Dynamic Imports - activate on case-by-case basis
import loadConfig from '../../../../core/utils/configLoader';
import loadModule from '../../../../core/utils/moduleLoader';

// Loading config dynamically to allow one-off customization
const config = loadConfig('general');

// Loading following components dynamically to allow one-off customization
loadModule('./features/visitor/components/content.css');


const Hero = () => {

  const { setEmail, accessRequested, handleAccessRequest } = useAccessRequest();

  return (
    <section className='section-container' id='hero'>
      <div className="section-content hero">
        {/* pulling a big image here to test css styles resize as expected */}
        <img src={LandingPageImg} alt="App Screenshot" />
        <div className='app-info'>
          <h3>Welcome to <strong>{config.brandName}</strong> </h3>
          <p><strong>{config.brandName}</strong> is a versatile journaling app designed to capture and organize all your thoughts and ideas in one secure place. With our intuitive platform, you can effortlessly take notes, record personal reflections, and save any insights you want to build your “second brain.” <strong>{config.brandName}</strong> helps you structure your thoughts, revisit insights, and explore your journey over time. Experience seamless organization and self-discovery with <strong>{config.brandName}</strong>, the essential tool for capturing your unique journey.</p>
        </div>
        <div className='call-for-action'>
          <form onSubmit={handleAccessRequest} className='access-request'>
            <p><strong>Access to {config.brandName} is currently by invitation only.</strong> Join our waitlist today, and we’ll notify you as soon as access is available. We appreciate your interest and look forward to welcoming you onboard soon!</p>
            {/* <label htmlFor="email" className="form-label">Email address</label> */}
            <div className='access-request-email'>
              <input 
                type="email" 
                className="form-control" 
                id="email" 
                placeholder="name@example.com"
                aria-describedby="emailHelp"
                required
                // autoComplete='off'
                onChange={(e) => setEmail( e.target.value)}
              ></input>
              { accessRequested ? 
                <button type="button" className="btn btn-success btn-md">
                  Access Requested
                </button>
                : 
                <button type="submit" className="btn btn-warning btn-md">
                  <FlashOnIcon />
                  Join Waitlist
                </button>
              }
            </div>
            {/* <div id="emailInfo" className="form-text">We'll never share your email with anyone else.</div> */}
          </form>
        </div>
      </div>
    </section>
  );
};

const VisitorContent = () => {
  return (
    <div className='visitor-content'>
      <Hero />
    </div>
  )
};

export default VisitorContent;