import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { DropdownObjects, DropdownValues } from './dropdowns';
import { capitalizeFirstLetter, printDebugInfo } from '../../utils/dataUtils';
import { transformSelectedObjBeforeSaving } from '../../utils/data-processing';

const formatDate = (date) => moment(date).startOf('day').format('YYYY-MM-DD');

export const ReadBooleanColumn = ({ row, defaultValue, columnName }) => {
  let current;
  if (!row.original._id || row.original._id === '') current = defaultValue; //case 1: creation, row.original._id doesn't exist yet
  else current = row._valuesCache[columnName]; //case 2: update, row exists
  const [checked, setChecked] = useState(current);

  useEffect(() => {
    row._valuesCache[columnName] = checked;
  }, [checked]);

  return (
    <Checkbox checked={checked}/>
  );
};

export const EditBooleanColumn = ({ row, defaultValue, columnName, header }) => {
  // no longer needed since we move to Modal approach
  
  let current;
  if (!row.original._id || row.original._id === '') current = defaultValue; //case 1: creation, row.original._id doesn't exist yet
  else current = row.original[columnName] ; //case 2: update, row exists
  const [checked, setChecked] = useState(current != null ? current : defaultValue); // ensure the current value is not null or undefined to prevent warning "Warning: A component is changing an uncontrolled input to be controlled." 

  useEffect(() => {
    row._valuesCache[columnName] = checked;
  }, [checked]);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setChecked(newValue);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} />}
      label={header || capitalizeFirstLetter(columnName)}
      checked={checked || false} 
      onChange={handleChange}
    />
  );
};

export const ReadObjectColumn = ({ row, columnName, entity }) => {
  const current = row._valuesCache[columnName]; //using _valuesCache and not original to get value updated without triggering page refresh
  return current ? current.value : "";
};

export const EditObjectColumn = ({ row, defaultValue, columnName, values }) => {
  const current = row.original[columnName];

  const [selectedObject, setSelectedObject] = useState(current);

  useEffect(() => {
    row._valuesCache[columnName] = selectedObject;
  }, [selectedObject]);

  const handleChange = (event) => {
    const selectedObjectId = event.target.value; 
    const selectedObject = values.find(value => value ? value._id === selectedObjectId : null);
    const transformedObject = transformSelectedObjBeforeSaving(selectedObject);
    setSelectedObject(transformedObject);
  };

  if (!row._valuesCache[columnName]) row._valuesCache[columnName] = defaultValue;

  return (
    <div>
      {/* <InputLabel htmlFor={`${columnName}-select`} sx={{ width: '100%' }}>{columnName}</InputLabel> */}
      <DropdownObjects 
        label={capitalizeFirstLetter(columnName)} 
        selected={selectedObject._id} 
        elements={values} 
        handleChange={handleChange} 
      />
    </div>
  );
};

export const ReadListColumn = ({ row, columnName }) => {
  if(row._valuesCache[columnName] && row._valuesCache[columnName]!=='null') return row._valuesCache[columnName];
  return ""
};

export const EditListColumn = ({ row, defaultValue, columnName, header, values }) => {
  let current;
  if (!row.original._id || row.original._id === '') current = defaultValue; //case 1: creation, row.original._id doesn't exist yet
  else current = row.original[columnName]; //case 2: update, row exists

  const [selectedValue, setSelectedValue] = useState(String(current));

  useEffect(() => {
    row._valuesCache[columnName] = selectedValue;
  }, [selectedValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    row._valuesCache[columnName]  = newValue;
    setSelectedValue(newValue);
  };

  if(!row._valuesCache[columnName]) row._valuesCache[columnName] = defaultValue;
  return (
    <DropdownValues 
      label={capitalizeFirstLetter(header)} 
      selected={selectedValue} 
      values={values} 
      handleChange={handleChange}
    />
  )
};


export const ReadDateColumn = ({ row, columnName}) => {
  
  if(row._valuesCache[columnName] && row._valuesCache[columnName]!=='null') { 
    return formatDate(row._valuesCache[columnName]);
  }
  return ""
};

export const EditDateColumn = ({ row, columnName, header }) => {
  
  const current = row.original[columnName];

  const [selectedDate, setSelectedDate] = useState(formatDate(current));

  useEffect(() => {
    row._valuesCache[columnName] = selectedDate;
  }, [selectedDate]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedDate(newValue);
  };

  return (
    <div class="d-flex flex-column">
      <label for="dateInput" style={{fontSize: '0.75rem'}}>{header}</label>
      <div class="d-flex align-items-start">
        <input 
          type="date" 
          id="dateInput" 
          name="selectedDate" 
          value={selectedDate} 
          onChange={handleChange} 
          class="form-control"
          style={{border: 'none', borderBottom: '1px solid black', borderRadius: '0'}}
        />
      </div>
    </div>

  );
};