import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import { updateUserParametersForSearch } from '../../utils/caching';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => {
  const { userParameters, setUserParameters } = useContext(UserContext);
  const [search, setSearch] = useState(userParameters.search || "");
  const [tempSearch, setTempSearch] = useState(search);
  const navigate = useNavigate();

  const handleSearchInputChange = (e) => {
    setTempSearch(e.target.value);
    if(e.target.value.length === 0) {
      setSearch("");
      updateUserParametersForSearch(setUserParameters, "");
      navigate('/app/home/');
    };
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearch(tempSearch);
    updateUserParametersForSearch(setUserParameters, tempSearch);
    navigate(`/app/search?query=${tempSearch}`);
  };

  useEffect(() => {
    setTempSearch(userParameters.search);
  }, [userParameters.search])

  return (
    <div className="search-bar">
      {/* <SearchIcon /> */}
      <form className="d-flex" role="search" onSubmit={handleSearchSubmit}>
        <input 
          className="form-control custom-input-field" 
          type="search"
          placeholder="Search.."
          aria-label="Search"
          value={tempSearch}
          onChange={handleSearchInputChange}
        />
      </form>
    </div>
  );
};

export default SearchBar;