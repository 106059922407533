import React, { useState } from 'react';
import useIsMobile from '../../hooks/use-is-mobile';
import './split-view-responsive.css';

const SplitViewResponsiveLayout = ({
  content,
  panel,
  maximizeContentWidth = false,
  isPanelFixedToRight = false,
  isPanelContrastEnabled = false,
  isPanelFoldable = false,
}) => {
  const [showPanel, setShowPanel] = useState(false);
  const isMobile = useIsMobile();
  
  if (!isMobile) {
    return (
      <div className={`split-view-responsive desktop ${isPanelFixedToRight ? 'position-panel-right' : ''}`}>
        <div className="split-view-responsive-container">
          <div className={`main-content ${maximizeContentWidth ? 'maximized' : ''}`}>
            {content}
          </div>
          <div className={`side-panel ${isPanelContrastEnabled && isPanelFixedToRight ? 'panel-contrasted' : ''}`}>
            {panel}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="split-view-responsive mobile">
      <div className="mobile-header">
        <div className="toggle-container">
          <button
            onClick={() => setShowPanel(false)}
            className={`toggle-button ${!showPanel ? 'active' : ''}`}
          >
            Content
          </button>
          <button
            onClick={() => setShowPanel(true)}
            className={`toggle-button ${showPanel ? 'active' : ''}`}
          >
            Properties
          </button>
        </div>
      </div>

      <div className="mobile-content">
        {showPanel ? panel : content}
      </div>
    </div>
  );
};

export default SplitViewResponsiveLayout;
