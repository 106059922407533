import './single-view.css'

const SingleViewLayout = ({
  content,
  maxWidth,
  isFullWidth = false,
}) => {
  return (
    <div 
      className={`single-view ${isFullWidth ? 'full-width' : ''}`} 
      style={!isFullWidth ? { '--custom-max-width': maxWidth } : {}}
    >
      {content}
    </div>
  );
};

export default SingleViewLayout;