import { useContext } from "react";
import { UserContext } from "../contexts/user-context";
import { logoutUser } from "../utils/auth";

const useLogout = () => {
  const { setIsLoading, setUser } = useContext(UserContext);

  const handleUserLogout = () => {
    logoutUser(setIsLoading, setUser);
  };

  return { handleUserLogout };
};

export default useLogout;