import axios from 'axios';
import { decryptCol, encryptCol, encryptObject } from './data-encryption';
import { searchObjectInCache, getObjectFromCache, printCache } from './caching';
import { printDebugInfo } from './dataUtils';
import { initializeAdminData } from "./data-initialization";
import { cacheUserData } from './data-initialization';

// Release 0.90: convert all entries and records to flex data model
export async function data_migration_client_side_release090(user){
  try{
    // MIGRATION INFOS
    const releaseName = "Release 0.90";
    const migrationDescription = "Goal of the migration: restructure entries and records with flexDataContainer, rename db fields, and rename collections";
    console.log(`${releaseName} - ${migrationDescription}`);
    console.log("Starting data migration");

    // OBJECTIVES
    // Rename core attributes in all collections
    // Restructure user-entries and user-records by nesting specific attributes within flexDataContainer
    // Duplicate admin-possible-values for current user
    // Reset all existing relations to use new format and new IDs since moving data to possible-values
    // Rename collections admin and non-admin
    // Remove old collections
    // Final collections should be adm-access-requests, possible-values, adm-tags, adm-habits, notes, entries, records
    // Leverage utility functions as much as possible to handle generic and reusable operations

    // PSEUDO CODE
    // 1. migrate collections (rename fields, delete fields, rename collection, delete old collections)
    // 2. create possible-values, tags and habits for current user
    // 3. cache user data so admin data can be accessed quickly
    // 4. transform note, entries and records including flexDataContainer and relation re-mapping

    // 1. migrate collections (rename fields, delete fields, rename collection, delete old collections)
    const mappingCore = {
      _id: "_id",
      __v: "__v",
      creationDate: "creationDate",
      createdBy: "createdBy",
      lastModificationDate: "lastModificationDate",
      lastModifiedBy: "lastModifiedBy",
      system: 'isSystem',
    };

    const mappingSpecific = {
      shortName: 'label',
      isTemplate: 'isTemplate',
      active: 'isActive',
      archived: 'isArchived',
      favorite: 'isFavorite',
      initialized: 'isInitialized',
      migrated: 'isMigrated',
      approved: 'isApproved',
      is_approved: 'isApproved',
    };

    const fieldsToRemove = [
      'encrypted',
      'deleted',
      'description',
    ];

    // no longer used because adminn data is imported manually during the release
    if (user.isAdministrator) {
      await transformAdminCollection('AccessRequestOld', 'AccessRequest', mappingCore, mappingSpecific, fieldsToRemove);
      await transformAdminCollection('UserOld', 'User', mappingCore, mappingSpecific, fieldsToRemove);
      await transformAdminCollection('HabitOld', 'Habit', mappingCore, mappingSpecific, fieldsToRemove);
      await transformAdminCollection('TagOld', 'Tag', mappingCore, mappingSpecific, fieldsToRemove);
      // TBD: drop user-data in habits and tags (isSystem = false)
    } else {
      // TBD: delete old admin data
      // 2. create all admin data possible-values, tags and habits for current user
      await initializeAdminData(user);

      // 3. cache user data
      await cacheUserData();
      
      // 4. migrate notes, entries and records including flexDataContainer and relation re-mapping
      await transformDataCollection('UserEntryOld', 'Entry', 'user-entries', 'entries', mappingCore, mappingSpecific, fieldsToRemove);
      await transformDataCollection('UserRecordOld', 'Record', 'user-records', 'records', mappingCore, mappingSpecific, fieldsToRemove);
    }

    console.log(`${releaseName} - ${migrationDescription}`);
    console.log("Finished data migration");

  } catch (error){
    console.error('Data Migration failed', error);
    throw error; 
  }
};

// UTILITY FUNCTIONS
// Instructions: keep all utility functions below as they may be used for future migrations

// TBD
// const copyCollection(sourceCollection, targetCollection)

const transformAdminCollection = async (oldModel, NewModel, mappingCore, mappingSpecific, fieldsToRemove) => {
  try {
    const response = await axios.get(`/api/data-migration/legacy/${oldModel}/`);
    const dbDocuments = response.data.documents;
    const documents = await decryptCol(dbDocuments);

    // transform documents and store in new array
    let newDocuments = [];
    for (const document of documents) {
      let newDocument = { ...document };
      Object.entries(newDocument).forEach(([key, value]) => {
        if (fieldsToRemove.includes(key)) {
           delete newDocument[key];
         }
         else if(mappingCore[key] && key !== mappingCore[key]) {
          // swap keys "remapping"
          const newKey = mappingCore[key];
          newDocument[newKey] = value;
         }
         else if (mappingSpecific[key] && key !== mappingSpecific[key]) {
          // swap keys "remapping"
          const newKey = mappingCore[key];
          newDocument[newKey] = value;
        }
       });
       newDocuments.push(newDocument);
    }
    console.log("Printing updated documents before db update: ");
    console.log(newDocuments);

    // create new documents in new collection
    let count = 0;
    for (const newDocument of newDocuments) {
      try {
        const encryptedNewDocument = encryptObject(newDocument);
        // using new route to grab the new version of the models
        await axios.delete(`/api/data-migration/${NewModel}/${encryptedNewDocument._id}`);
        await axios.post(`/api/data-migration/${NewModel}/`, encryptedNewDocument);
        count++;

      } catch (error) {
        console.error(`Error creating document: ${newDocument} for model: ${NewModel}: ${error.message}`);
      }
    };

    // delete old collection
    // await axios.post(`/api/system-admin/db/drop-collection/${oldModel}`); // not necessary anymore as we modify collections in place

    console.log(`Successfully migrated ${count} documents from model ${oldModel} to model ${NewModel}`);

  } catch (error) {
    console.error(`Error in function transformAdminCollection: : ${error.message}`);
  }
};

const transformDataCollection = async (
  oldModel,
  newModel,
  sourceCollection,
  targetCollection,
  mappingCore,
  mappingSpecific,
  fieldsToRemove
) => {
  try {
    // Fetching legacy documents
    const response = await axios.get(`/api/data-migration/legacy/${oldModel}/`);
    const dbDocuments = response.data.documents;
    const documents = await decryptCol(dbDocuments); console.log(documents.slice(-50))
    const cache = await printCache(); console.log(cache);
    // const templates = documents.filter((document) => document.isTemplate || document.isTemplate === "true"); console.log(templates);

    // Process each document individually
    for (const document of documents) {
      console.log("Printing original document before transformation: ");
      console.log(document)
      let newTargetCollection = targetCollection;
      let newTargetModel = newModel;
      let newDocument = { ...document };

      // Determine collection based on document type
      const type = newDocument.type?.name;
      let transformedType = type
      if (transformedType === "Journal") { 
        transformedType = "Personal Journal";
      } 

      if (type && ["Notes", "Reflection", "Thoughts"].includes(type)) {
        newTargetCollection = "notes";
        newTargetModel = "Note";
        transformedType = "Fleeting Note"
      }

      // Handle `type` field transformation
      if (newDocument.type instanceof Object) {
        // mapping old vs new type
        const newObj = await searchObjectInCache("possible-values", {
          fieldCollection: newTargetCollection,
          fieldName: "type",
          fieldValue: transformedType,
        });
        if (newObj) {
          newDocument.type = {
            _id: newObj._id,
            value: newObj.fieldValue,
            collection: "possible-values",
          };
        } else {
          console.log(`Could not find type for entry ${newDocument._id}`);
        }
      }

      // Handle `origin` and `template` field transformation
      const transformRelationField = async (fieldName) => {
        if (newDocument[fieldName] instanceof Object) {
          // const newObj = await getObjectFromCache(targetCollection, newDocument[fieldName]._id);
          const template = documents.find(template => template._id === newDocument[fieldName]._id)
          if (template) {
            newDocument[fieldName] = {
              _id: template._id,
              value: template.title,
              collection: newTargetCollection,
            };
          } else {
            newDocument[fieldName] = null;
            console.log(`Could not find ${fieldName} for entry ${newDocument._id}`);
          }
        } else {
          newDocument[fieldName] = null;
        }
      };

      await transformRelationField("origin");
      await transformRelationField("template");

      // Handle `tags` field transformation
      if (Array.isArray(newDocument.tags)) {
        newDocument.tags = await Promise.all(
          newDocument.tags.map(async (tag) => {
            const newObj = await searchObjectInCache("tags", { name: tag.name });
            if (newObj) {
              return {
                _id: newObj._id,
                value: newObj.name,
                collection: "tags",
              };
            } else {
              console.log(`Could not find tag for entry ${newDocument._id}`);
              return null;
            }
          })
        );
        newDocument.tags = newDocument.tags.filter((tag) => tag !== null);
      }

      // Handle `habits` field transformation
      if (Array.isArray(newDocument.habits)) {
        newDocument.habits = await Promise.all(
          newDocument.habits.map(async (habit) => {
            const newObj = await searchObjectInCache("habits", { name: habit[0].name });
            if (newObj) {
              return {
                _id: newObj._id,
                value: newObj.name,
                result: habit[1].value,
                collection: "habits",
              };
            } else {
              console.log(`Could not find habit for entry ${newDocument._id}`);
              return null;
            }
          })
        );
        newDocument.habits = newDocument.habits.filter((habit) => habit !== null);
      }

      // Apply core and specific mappings
      const restructuredDocument = {};

      Object.entries(newDocument).forEach(([key, value]) => {
        if (fieldsToRemove.includes(key)) {
          // Remove fields that need to be excluded
          return;
        } else if (mappingCore[key]) {
          // Apply core mappings
          restructuredDocument[mappingCore[key]] = value;
        } else if (mappingSpecific[key]) {
          // Apply specific mappings, nesting inside flexDataContainer
          if (!restructuredDocument.flexDataContainer) {
            restructuredDocument.flexDataContainer = {};
          }
          restructuredDocument.flexDataContainer[mappingSpecific[key]] = value;
        } else {
          // Keep the field as is, but nest inside flexDataContainer if required
          if (!restructuredDocument.flexDataContainer) {
            restructuredDocument.flexDataContainer = {};
          }
          restructuredDocument.flexDataContainer[key] = value;
        }
      });

      console.log("Printing restructured document before db update: ");
      console.log(restructuredDocument)

      // Encrypt and store the new document
      try {
        const encryptedNewDocument = encryptObject(restructuredDocument);
        await axios.post(`/api/data-migration/${newTargetModel}/`, encryptedNewDocument);
      } catch (error) {
        console.error(`Error creating document ${newDocument._id}: ${error.message}`);
      }
    }

    console.log(`Successfully migrated documents`);
  } catch (error) {
    console.error(`Error in transformDataCollection: ${error.message}`);
  }
};


// const transformDataCollection = async (oldModel, newModel, sourceCollection, targetCollection, mappingCore, mappingSpecific, fieldsToRemove) => {
//   try {
//     // using legacy route to grab the previous version of the models
//     const response = await axios.get(`/api/data-migration/legacy/${oldModel}/`);
//     const dbDocuments = response.data.documents;
//     const documents = await decryptCol(dbDocuments); console.log(documents)

//     for (const document of documents) {
//       let newTargetCollection = targetCollection;
//       let newTargetModel = newModel;
//       let newDocument = document;
//       console.log("Printing original document before transformation: ");
//       console.log(newDocument)

//       // move non journal entries to notes collection
//       const type = newDocument.type?.value;
//       if (type) {
//         if (type === "Notes" || type === "Reflection" || type === "Thoughts") {
//           newTargetCollection = "notes";
//           newTargetModel = "Note";
//         }
//       }

//       // handling type
//       if(newDocument.type instanceof Object){
//         let newObj = await searchObjectInCache("possible-values", {fieldCollection: newTargetCollection, fieldName: "type", fieldValue: newDocument.type.name});
//         if (!newObj) {
//           console.log(`Could not find type for entry ${newDocument._id} collection: ${newTargetCollection} with value: ${ newDocument.type.name}`);
//         } else {
//           let newRelation = {
//             _id: newObj._id,
//             value: newObj.fieldValue,
//             collection: "possible-values",
//           };
//           newDocument.type = newRelation;
//         }
//       };

//       // handling origin, template
//       if(newDocument.origin instanceof Object){
//         let newObj = await getObjectFromCache(sourceCollection, newDocument.origin._id); // using source collection because entries not migrated yet
//         if (!newObj) {
//           console.log(`Could not find origin for entry ${newDocument._id} collection: ${newTargetCollection} with id: ${newDocument.origin._id}`);
//         } else {
//           let newRelation = {
//             _id: newDocument.origin._id,
//             value: newDocument.origin.title,
//             collection: newTargetCollection,
//           };
//           newDocument.origin = newRelation;
//         }
//       };

//       if(newDocument.template instanceof Object){
//         let newObj = await getObjectFromCache(sourceCollection, newDocument.template._id); // using source collection because entries not migrated yet
//         if (!newObj) {
//           console.log(`Could not find template with id: ${newDocument.template._id}`);
//         } else {
//           let newRelation = {
//             _id: newDocument.template._id,
//             value: newDocument.template.title,
//             collection: newTargetCollection,
//           };
//           newDocument.template = newRelation;
//         }
//       };

//       // handling tags
//       if(newDocument.tags instanceof Array){
//         let newArray = newDocument.tags.map(async(tag) => {
//           let newObj = await searchObjectInCache('tags', {name: tag.name}) 
//           if (!newObj) {
//             console.log(`Could not find tag in cache with id: ${tag._id}`);
//             return null;
//           } else {
//             let newRelation = {
//               _id: newObj._id,
//               value: newObj.name,
//               collection: "tags",
//             };
//             return newRelation;
//           }
//         });
//         newDocument.tags = newArray;
//       };

//       // handling habits
//       if(newDocument.habits instanceof Array){
//         console.log(newDocument.habits); // check DM
//         let newArray = newDocument.habits.map(async(habit) => {
//           let newObj = await searchObjectInCache("habits", {name: habit[0].name});
//           if (!newObj) {
//             console.log(`Could not find habit in cache with name: ${habit[0].name}`);
//             return null;
//           } else {
//             let newRelation = {
//               _id: newObj._id,
//               value: newObj.name,
//               result: habit[1].value,
//               collection: "habits",
//             };
//             return newRelation;
//           }
//         });
//         newDocument.tags = newArray;
//       };
//       const restructuredDocument = newDocument;

//       Object.entries(restructuredDocument).forEach(([key, value]) => {
//         if (fieldsToRemove.includes(key)) {
//           delete restructuredDocument[key];
//         } else if (mappingCore[key] && key !== mappingCore[key]) {
//           // swap keys "remapping"
//           const newKey = mappingCore[key];
//           restructuredDocument[newKey] = value;
//         } else if (mappingSpecific[key] && key !== mappingSpecific[key]) {
//           // swap keys "remapping" and nest specific props within flexDataContainer 
//           const newKey = mappingCore[key];
//           if (!restructuredDocument["flexDataContainer"]){
//             restructuredDocument["flexDataContainer"] = {};
//           } 
//           restructuredDocument["flexDataContainer"][newKey] = value;
//         } else {
//           // key does not need to be renamed but prop should still be nested within flexDataContainer 
//           if (!restructuredDocument["flexDataContainer"]){
//             restructuredDocument["flexDataContainer"] = {};
//           }
//           restructuredDocument["flexDataContainer"][key] = value;
//         }
//       });
      
//       // Object.entries(newDocument).forEach(([key, value]) => {
//       //   if (fieldsToRemove.includes(key)) {
//       //      // Skip the current key if they need to be removed
//       //      return;
//       //    }
//       //    else if(mappingCore.hasOwnProperty(key)) {
//       //     // swap keys "remapping"
//       //     const newKey = mappingCore[key];
//       //     restructuredDocument[newKey] = value;
//       //    }
//       //    else if (mappingSpecific.hasOwnProperty(key)) {
//       //     // swap key and nest specific props within flexDataContainer 
//       //     const newKey = mappingSpecific[key];
//       //     restructuredDocument.flexDataContainer = {};
//       //     restructuredDocument.flexDataContainer[newKey] = value;
//       //   }
//       //   else {
//       //     // no change
//       //     restructuredDocument[key] = value;
//       //   }
//       // });

//       console.log("Printing new document before db update: ");
//       console.log(restructuredDocument)

//       try {
//         const encryptedNewDocument = encryptObject(restructuredDocument);
//         // using new route to grab the new version of the models
//         await axios.post(`/api/data-migration/${newTargetModel}/`, encryptedNewDocument);

//       } catch (error) {
//         console.error(`Error creating document: ${restructuredDocument} for model: ${newTargetModel}: ${error.message}`);
//       }     
       
//     }

//     // delete old collection
//     //await axios.post(`/api/system-admin/db/drop-collection/${oldModel}`);
    
//     console.log(`Successfully migrated documents`);

//   } catch (error) {
//     console.error(`Error in function transformDataCollection: : ${error.message}`);
//   }
// };

// deprecated
// const migrateEntriesV1 = async () => {
//   try {
//     const migrateCollection = async (collection, targetCollection) => {
      
//       // 1. get database documents (encrypted)
//       const response = await axios.get(`/api/data-migration/legacy/${collection}/`);
//       const dbDocuments = response.data.documents;
//       const documents = await decryptCol(dbDocuments); 

//       // 2. fix relations on original document
//       // relations: type, template, origin, tags, habits
//       for (const document of documents) {
//         // console.log(`Converting document ${document._id} on collection: ${collection}`);
        
//         // TBD: rempa admin-data to admin-possible-values, if value doesnt exist, create it as possible value
//         const prevType = document.type;
//         // const tempType = getPossibleValues(fieldCollection, fieldName, fieldValue);
//         const tempType = {};
//         if (prevType && collection !== 'admin-data') {
//           const newType = {
//             _id: tempType._id,
//             value: tempType.fieldValue,
//             collection: "admin-possible-values",
//           }
//           document.type = newType;
//         };

//         // TBD: rempa admin-data to admin-possible-values
//         const prevCategory = document.category
//         if (prevCategory) {
//           const newCategory = {
//             _id: prevCategory._id,
//             value: prevCategory.name,
//             collection: "admin-possible-values",
//           }
//           document.category = newCategory;
//         };

//         const prevTemplate = document.template
//         if (prevTemplate) {
//           const newTemplate = {
//             _id: prevTemplate._id,
//             value: prevTemplate.name,
//             collection: document.type.value === "user-entry" ? "entries" : "records",
//           }
//           document.template = newTemplate;
//         };

//         const prevOrigin = document.origin
//         if (prevOrigin) {
//           const newOrigin = {
//             _id: prevOrigin._id,
//             value: prevOrigin.name,
//             collection: document.type.value === "user-entry" ? "entries" : "records",
//           }
//           document.origin = newOrigin;
//         };

//         const prevTags = document.tags
//         if (prevTags && prevTags.length > 0) {
//           const newTags = prevTags.map((prevTag) => {
//             return {
//               _id: prevTag._id,
//               value: prevTag.name,
//               collection: "tags",
//             }
//           })
//           document.tags = newTags;
//         };

//         const prevHabits = document.habits
//         if (prevHabits && prevHabits.length > 1) {
//           const newHabits = prevHabits.map((prevHabit) => {
//             return {
//               _id: prevHabit[0]._id,
//               value: prevHabit[0].name,
//               result: prevHabit[1].value,
//               collection: "habits",
//             }
//           })
//           document.habits = newHabits;
//         };
//       };

//       // 3. convert documents with new format
//       const convertedDocuments = [];
//       for (const document of documents) {
//         let newDocument = {};
//         if (collection === "user-entries" || collection === "user-records") {
//           newDocument.flexDataContainer = {};
//         };

//         Object.entries(document).forEach(([key, value]) => {
//          if (fieldsToRemove.includes(key)) {
//             // Skip the current iteration if the key is in the fields to remove
//             return;
//           }
//           else if(mappingCore.hasOwnProperty(key)) {
//             // key is core and may need to be relabeled
//             const newKey = mappingCore[key];
//             newDocument[newKey] = value;
//           } 
//           else if (mappingSpecific.hasOwnProperty(key)) {
//             // key is specific and may need to be relabeled AND nested within flexDataContainer 
//             const newKey = mappingSpecific[key];
//             if (collection === "user-entries" || collection === "user-records") {
//               newDocument.flexDataContainer[newKey] = value;
//             } else {
//               newDocument[newKey] = value;
//             }
//           } else {
//             // key is neither core or specific, we keep as-is
//             newDocument[key] = value;
//           }
//         });
//         convertedDocuments.push(newDocument); 
//       };

//       console.log("Documents converted:");
//       console.log(convertedDocuments)

//       // 5. create new document in target collection
//       let count = 0;
//       for (const newDocument of Object.values(convertedDocuments)) {
//         try {
//           const encryptedNewDocument = encryptObject(newDocument);
//           await axios.post(`/api/data-migration/${targetCollection}/`, encryptedNewDocument);
//           count++;

//         } catch (error) {
//           console.error(`Error creating entry: ${newDocument} : ${error.message}`);
//         }
//       };
//       console.log(`Successfully migrated ${count} entries from collection ${collection} to collection ${targetCollection}`);
//     }
//     await migrateCollection('access-requests','adm-access-requests');
//     await migrateCollection('users','adm-users');
//     await migrateCollection('habits','adm-habits');
//     await migrateCollection('tags','adm-tags');
//     await migrateCollection('user-entries', 'entries');
//     await migrateCollection('user-records', 'records');

//   } catch(error) {
//     console.error('Failed to transform entries: ', error);
//   }
// }
// await migrateEntriesV1();