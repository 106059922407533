import React from 'react';
import './views.css'

const SplitView = ({left, right, constrasted, hideFooter, fixHeight}) => {

  return (
    <div className={`split-view ${hideFooter ? "hide-footer" : ""}`}>
      <div className={`split-view-left ${fixHeight ? "fixed-height" : ""}`}>
        {left}
      </div>
    
      <div className={`split-view-right ${constrasted ? "constrasted-background" : ""} ${fixHeight ? "fixed-height" : ""}`}>
        {right}
      </div>
    </div>
  );
};

export default SplitView;