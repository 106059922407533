import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select, InputLabel } from '@mui/material';

// Important:  Select component's value must exactly match one of the value props of its child MenuItem
export const DropdownObjects = ({ label, selected, elements, handleChange }) => {
  if (selected==null) selected = '';
  return (
    <FormControl variant="standard" sx={{ m: 0, minWidth: 120, width: '100%'}}>
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={selected}
        label="Select a value.."
        onChange={handleChange}
      >
        <MenuItem value='' style={{ fontStyle: 'italic'}} disabled>Select a Value</MenuItem>
        {elements ? 
          elements
          .filter((element) => element !== null && element !== undefined)
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((element) => (
            <MenuItem key={element._id} value={element._id}>
              {element.value}
            </MenuItem>
          ))
          : null
        }
        <MenuItem value='' style={{ color: 'red'}} >Clear Selection</MenuItem> 
      </Select>
    </FormControl>
  )
}

export const DropdownValues = ({ label, selected, values, handleChange }) => {
  return (
    <FormControl variant="standard" sx={{ m: 0, minWidth: 120, width: '100%'}}>
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={selected}
        label="Select a value.."
        onChange={handleChange}
      >
        <MenuItem value='' style={{ fontStyle: 'italic'}} disabled>Select a Value</MenuItem>
        {values && values.length > 0 ? 
          values.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))
        : null}
        {/* <MenuItem value='' style={{ color: 'red'}} >Clear Selection</MenuItem>  */}
      </Select>
    </FormControl>
  );
};
