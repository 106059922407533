import { useState, useEffect } from "react";
import loadConfig from "../../../utils/configLoader";

// Loading config dynamically to allow one-off customization
const config = loadConfig('general');

const useTypeWriter = ({ fullText, speed = config.agent.speed || 2 }) => {
  const [generatedText, setGeneratedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  
  // Configuration
  const config = {
    baseDelay: 50,
    delayVariance: 30,
    minChunkSize: 3,
    maxChunkSize: 12,
    pauseProbability: 0.15,
    pauseDuration: 500,
    pauseCharacters: ['.', '?', '!', ',', ';', ':'],
    punctuationPauses: {
      '.': 400,
      '?': 400,
      '!': 400,
      ',': 200,
      ';': 300,
      ':': 300
    }
  };

  // Helper function to get random number within range
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Helper function to get next chunk size
  const getNextChunkSize = (remainingText) => {
    // Increase chunk size based on speed
    const adjustedMaxSize = Math.ceil(config.maxChunkSize * Math.sqrt(speed));
    const size = getRandomInt(config.minChunkSize, adjustedMaxSize);
    
    // Don't exceed remaining fullText length
    return Math.min(size, remainingText.length);
  };

  // Helper function to calculate delay for next chunk
  const getNextDelay = (chunk) => {
    // Apply speed to base delay and variance
    let delay = (config.baseDelay + getRandomInt(-config.delayVariance, config.delayVariance)) / speed;
    
    // Check for punctuation marks - apply speed to punctuation pauses
    const lastChar = chunk[chunk.length - 1];
    if (config.pauseCharacters.includes(lastChar)) {
      delay += (config.punctuationPauses[lastChar] || 0) / speed;
    }
    
    // Random longer pause - apply speed to pause duration
    if (Math.random() < config.pauseProbability) {
      delay += config.pauseDuration / speed;
    }
    
    return delay;
  };

  useEffect(() => {
    if (!isTyping || !fullText) return;

    let remainingText = fullText.slice(generatedText.length);
    
    if (remainingText.length === 0) {
      setIsTyping(false);
      return;
    }

    // Get next chunk size
    const chunkSize = getNextChunkSize(remainingText);
    const nextChunk = remainingText.slice(0, chunkSize);
    
    // Calculate delay for next chunk
    const delay = getNextDelay(nextChunk);

    // Schedule next chunk
    const timeout = setTimeout(() => {
      setGeneratedText(prev => prev + nextChunk);
    }, delay);

    return () => clearTimeout(timeout);
  }, [generatedText, isTyping, fullText, speed]);

  const restart = () => {
    setGeneratedText('');
    setIsTyping(true);
  };

  return {
    generatedText,
    isTyping,
    restart
  };
};

export default useTypeWriter;