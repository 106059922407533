import SplitViewResponsiveLayout from '../../../layouts/view/split-view-responsive';
import Content from '../components/content';
import Properties from '../components/properties';
import Toolbar from '../components/toolbar';
import useFlexForm from '../hooks/use-flex-form';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const Spinner = loadModule('./components/loading/spinner.js');

const FlexFormMain = ({ collection }) => {
  
  // loading data, states and handlers only once to benefit all sub components
  const {
    id,
    formData,
    formProperties,
    loading,
    isSaving,
    types,
    templates,
    tags,
    handleTypeChange,
    handleGenericChange,
    handleBooleanChange,
    handleSingleSelectChange,
    handleMultiSelectChange,
    handleTemplateChange,
    saveForm,
  } = useFlexForm(collection);

  
  return (
    <div className="centered-content">
      {loading ? (
        <Spinner />
      ) : (
        <SplitViewResponsiveLayout 
          content={
            <Content
              formData={formData}
              handleGenericChange={handleGenericChange}
              saveForm={saveForm}
              isSaving={isSaving}
              collection={collection}
              toolbar={<Toolbar 
                formData={formData}
                saveForm={saveForm}
                collection={collection}
              />}
            />
          }
          panel={
            <Properties
              collection={collection}
              formProperties={formProperties}
              formData={formData}
              types={types}
              templates={templates}
              tags={tags}
              handleTypeChange={handleTypeChange}
              handleGenericChange={handleGenericChange}
              handleSingleSelectChange={handleSingleSelectChange}
              handleMultiSelectChange={handleMultiSelectChange}
              handleBooleanChange={handleBooleanChange}
              handleTemplateChange={handleTemplateChange}
              toolbar={<Toolbar 
                formData={formData}
                saveForm={saveForm}
                collection={collection}
              />}
            />
          }
          maximizeContentWidth = {true}
          isPanelFixedToRight = {true}
          isPanelContrastEnabled = {true}
          // isPanelFoldable={true}
        />
      )}
    </div>
  );
};

export default FlexFormMain;