import loadConfig from "./configLoader";

const config = loadConfig('general');

export const getTemplateData = (templates, templateId) => {
  try {
    if (templates.length > 0) {
      for (const template of templates) {
        if (template._id === templateId){
          return config.forms.fieldsToPropagateFromTemplate.reduce((result, fieldName) => {
            result[fieldName] = template[fieldName];
            return result;
          }, {});
        }
      }
    } else {
      return {};
    }
  } catch (error)
  {
    console.error(`Error retriving templates : ${error.message}`);
  }
}; 