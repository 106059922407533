import useSafeguard from '../../../hooks/use-safeguard';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ToolbarButton = loadModule('./components/toolbars/toolbar-button.js');

const SafeguardBtn = () => {

  const { isSafeguardOn, toggleSafeguard } = useSafeguard(); 

  return (
      <ToolbarButton label={"Activate/Deactivate Safeguard"} icon={!isSafeguardOn ? <GppBadIcon className='red-icon' /> : <GppGoodIcon />} onClick={toggleSafeguard}/>
  );
};

export default SafeguardBtn;