// Direct Imports - Protected Modules
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ThemeBtn from '../../features/app-toolbar/components/theme-btn';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import './header-visitor.css';

// Dynamic Imports - activate on case-by-case basis
import loadModule from '../../utils/moduleLoader';
const Branding = loadModule("./components/branding/branding.js");
const ThemeToggle = loadModule("./components/tools/theme-toggle.js"); // TBD: recreated this tool to mimic features/app-toolbar/components/theme-btn 

const NavbarLeft = () => {

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  return (
    <div className='visitor-navbar-left'>
      <div className='visitor-navbar-menu-icon'>
        <MenuIcon onClick={toggleDrawer(true)}/>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor={'left'} >
          {/* {<Sidebar handleClose={toggleDrawer(false)}/>} */}
        </Drawer>
      </div>
      <Branding />
    </div>
    
  )
};

const NavbarCenter = () => {
  return (
    <div className="visitor-navbar-center">
      {/* add content below like links etc. */}
    </div>
  );
};

const NavbarRight = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className='visitor-navbar-right'>
      <ThemeBtn />
      {pathname !== '/login' && (
        <button type="button" className="btn btn-primary" onClick={() => {navigate('/login')}}>Log in</button>
      )}
      {pathname !== '/signup' && (
        <button type="button" className="btn btn-light" onClick={() => {navigate('/signup')}}>Sign up</button> 
      )}
    </div>
  )
};

const Navbar = () => { 

  return (
    <div className='visitor-navbar'>
      <NavbarLeft />
      <NavbarCenter />
      <NavbarRight />
    </div>
  )
};

export default Navbar;