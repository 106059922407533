import loadModule from '../../../../core/utils/moduleLoader';
import Content from '../components/content';
import useLoadingIndicator from '../../../../core/hooks/use-loading-indicator';

// Loading following components dynamically to allow one-off customization
const Spinner = loadModule("./components/loading/spinner.js");
const SplitViewResponsiveLayout = loadModule("./layouts/view/split-view-responsive.js");
const SingleViewLayout = loadModule("./layouts/view/single-view.js");

const Main = () => {
  
  const isLoading = useLoadingIndicator();

  return (
    <div className="centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <SingleViewLayout 
          content={<Content />}
          maxWidth={"var(--view-narrow-width)"}
        />
      )}
    </div>
    
  );
};

export default Main;
