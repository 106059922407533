import React from "react";
import loadModule from "../../../utils/moduleLoader";

// Loading following components dynamically to allow one-off customization
const InfoCard = loadModule("./components/cards/field-value-card.js");

const UserAccountInfo = ({ data }) => {

  const title = 'User Account';
  const fieldsToInclude = ["username", "creationDate", "totalConnections"];
  const filteredData = Object.keys(data)
    .filter((key) => fieldsToInclude.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});


  return (
    <div className="user-main centered-content">
      <div>
        <InfoCard title={title} data={filteredData}/>
      </div>
    </div>

  );
};

export default UserAccountInfo;
