// Not used because loading all icons from MUI is not recommended
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/user-context';
import config from '../config';
import { hasAccessToFeature } from '../../../utils/user-access-control';
import * as icons from '@mui/icons-material';

import './main-menu.css';

const MainMenu = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const isMenuItemActive = (item) => {
    // Check if the current path matches or starts with the item's href
    if (location.pathname === item.href) return true; // Exact match
    if (
      location.pathname.startsWith(item.href) &&
      (location.pathname[item.href.length] === '/' || location.pathname[item.href.length] === undefined)
    )
      return true; // Partial match for dynamic paths (e.g., with ids)
    return false;
  };

  return (
    <div className="main-menu">
      {Object.entries(config).map(([category, items]) => {
        // Filter the items based on the user's access to features
        const accessibleItems = items.filter((item) => hasAccessToFeature(item.label, user));

        if (accessibleItems.length > 0) {
          return (
            <div className="menu-grouping" key={category}>
              {category && <div className="menu-category">{category}</div>}
              {accessibleItems.map((item) => {
                let MuiIconComponent = icons[item.icon];
                if (!MuiIconComponent) {
                  MuiIconComponent = icons['WarningAmberOutlined'];
                  console.warn(`Could not find corresponding MUI icons with name ${item.icon}`);
                }
                return (
                  <a
                    key={item.href} // Ensure the href is unique for each item
                    href={item.href}
                    onClick={item.onClick}
                    className={`menu-item-row ${isMenuItemActive(item) ? 'active' : ''}`} // Add 'active' class for active item
                    aria-label={item.label} // Accessibility for screen readers
                    title={item.label} // Tooltip for better UX
                  >
                    {MuiIconComponent && <MuiIconComponent className="menu-item-icon" />} {/* Render the icon if available */}
                    <span className="menu-item-label">{item.label}</span> {/* Render the label */}
                  </a>
                );
              })}
            </div>
          );
        }
        return null; // Skip rendering if no accessible items in the category
      })}
    </div>
  );
};

export default MainMenu;
