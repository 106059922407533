import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './views.css'

const TriView = ({top, bottomLeft, bottomRight}) => {

  return (
    <div className='tri-view-container'>
      <Row>
        <Col md={12} className='tri-view-top'>
          {top}
        </Col>
      </Row>
      <Row>
        <Col md={8} className='tri-view-bottom-left'>
          {bottomLeft}
        </Col>
        <Col md={4} className='tri-view-bottom-right'>
          {bottomRight}
        </Col>
      </Row>
    </div>
  );
};

export default TriView;