import React from 'react';
import { clearTempDirectory } from '../services/system-services';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');

const SystemDevTools = () => {

  return (
    <div>
      <ButtonActionRow 
        title={'Delete Temp files'}
        description={'Delete all files in /server/temp directory to save disk space.'}
        buttonName={'Reset'}
        handleAction={() => clearTempDirectory()}
        isLast={true}
      />
    </div>
  );
};

export default SystemDevTools;