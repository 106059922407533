import { useContext } from 'react';
import { UserContext } from '../../../contexts/user-context';
import { deleteUserFromDatabase } from '../services/user-services';
import { logoutUser } from '../../../utils/auth';

const useUserAdminActions = (user) => {
  const { setIsLoading, setUser } = useContext(UserContext);

  const deleteUserAccount = async () => {
    await deleteUserFromDatabase(user._id);
    alert("Your user account has been deleted. Click OK to log out, we hope to see you soon again.");
    await logoutUser(setIsLoading, setUser);
  };

  return {
    deleteUserAccount,
  };
};

export default useUserAdminActions;
