export const SampleText = `Today, focus on what truly matters. Prioritize your health—nourish your body, move, and rest. A strong body fuels a strong mind.

In relationships, be present. Listen deeply, love openly, and nurture bonds with those who uplift you. Life is richer when shared with others.

Manage your finances wisely. Spend intentionally, save diligently, and invest in your future. Financial freedom gives you space to live fully.

Honor your passions. Make time to explore and cultivate what excites you; these pursuits fuel joy and creativity in all you do.

In your career, commit to growth and excellence. Strive to bring value and purpose to your work, remembering that your journey is uniquely yours.

Embrace personal growth. Reflect, learn, and challenge yourself. Growth is continuous and the key to living a life you’re proud of.

Live today with intention, purpose, and gratitude.
`;