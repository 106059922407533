import { runServerPreMigration, runServerPostMigration } from '../services/system-services';
import { runClientMigration } from '../utils/data-migration';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');

const SystemReleaseManagement = () => {
  return (
    <div>
      <ButtonActionRow 
        title={'Client side migration'}
        description={'Method used after a release to manipulate plain-text data, hence the need to run client-side.'}
        buttonName={'Run'}
        handleAction={() => runClientMigration()}
      />
      <ButtonActionRow 
        title={'Server side pre-migration'}
        description={'Method used prior a release to stage the data server side'}
        buttonName={'Run'}
        handleAction={() => runServerPreMigration()}
      />
      <ButtonActionRow 
        title={'Server side post-migration'}
        description={'Method used after a release to stage the data server side'}
        buttonName={'Run'}
        handleAction={() => runServerPostMigration()}
        isLast={true}
      />
    </div>
  );
};

export default SystemReleaseManagement;