import SplitViewResponsiveLayout from '../layouts/view/split-view-responsive';

const Test = () => {
  return (
    <div className='page'>
      <SplitViewResponsiveLayout />
    </div>
  );
};

export default Test;