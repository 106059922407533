import React from 'react';
import "./toolbar.css";

const ToolbarButton = ({ label, icon, onClick }) => (
  <div className="toolbar-button" onClick={onClick}>
    {icon}
  </div>
);

export default ToolbarButton;
