import React from "react";
import { Card } from "react-bootstrap";

const WeatherCard = () => {
  const title = "Weather Overview";
  const weatherData = {
    location: "New York",
    temperature: "15°C",
    condition: "Cloudy",
    humidity: "78%",
    windSpeed: "10 km/h",
  };

  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <Card.Text><strong>Location:</strong> {weatherData.location}</Card.Text>
        <Card.Text><strong>Temperature:</strong> {weatherData.temperature}</Card.Text>
        <Card.Text><strong>Condition:</strong> {weatherData.condition}</Card.Text>
        <Card.Text><strong>Humidity:</strong> {weatherData.humidity}</Card.Text>
        <Card.Text><strong>Wind Speed:</strong> {weatherData.windSpeed}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default WeatherCard;
