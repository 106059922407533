import { clearUserData } from '../../../utils/dataCleanup';
import useUserAdminActions from '../hooks/use-user-admin-actions';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const ButtonActionRow = loadModule('./components/administration/button-action-row.js');

const UserAdminForm = ({ user }) => {

  const { deleteUserAccount } = useUserAdminActions(user);

  return (
    <div>
      <ButtonActionRow 
        title={'Reset user data'}
        description={'Delete all user data and restore default parameters.'}
        buttonName={'Reset'}
        handleAction={() => clearUserData()}
      />
      <ButtonActionRow 
        title={'Delete My Account'}
        titleColor={'red'}
        description={'Delete user account and all associated data permanently.'}
        buttonName={'Delete'}
        requestConfirmation={true}
        confirmationMessage={"By clicking \"Confirm\", your user account and all associated data will be permanently deleted."}
        handleAction={() => deleteUserAccount()}
        isLast={true}
      />
    </div>
  );
};

export default UserAdminForm;