import { Card } from 'react-bootstrap';
import './stats-card.css';

const StatsCard = ({title, stats}) => {
  return (
    <Card className="mb-4 transparent-card">
      <Card.Body>
        <Card.Title>
          {title}
        </Card.Title>
        <div>
          {stats && stats.sort((a, b) => a.label.localeCompare(b.label)).map((stat, index) => (
            <div key={index} className="stat-item">
              <div className="stat-label-container">
                <span className="stat-label">
                  {stat.label}
                </span>
              </div>
              <div>
                <span className="stat-number">
                  {stat.number.toLocaleString()} items
                </span>
              </div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default StatsCard;
