import { useState } from 'react';
import { Card } from 'react-bootstrap';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./foldable-card.css"

const FoldableCard = ({ title, defaultFolded, children }) => {

  const [folded, setFolded] = useState(defaultFolded);
  
  const expandOrCollapseCard = (event) => {
    event.stopPropagation(); 
    setFolded((prev) => !prev);
  };

  return (
    <Card className={`mb-4 foldable-card ${!folded ? "foldable-card-expanded" : undefined}`}>
      <Card.Body>
        <div className='foldable-card-header'>
          <Card.Title className='foldable-card-title'>{title}</Card.Title>
          <div className="foldable-card-expend-tool" onClick={expandOrCollapseCard}>
            {folded && <ExpandMoreIcon />}
            {!folded && <ExpandLessIcon />}
          </div>
        </div>
        {children}
      </Card.Body>
    </Card>
  )
};

export default FoldableCard;