import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./tools.css"

const ExpandOrCollapseCardTool = ({ folded, setFolded }) => {

  const handleClick = (event) => {
    event.stopPropagation(); // stop event from propagating and triggering onClick on parent's component
    setFolded((prev) => !prev);
  };

  return (
    <div className="clickable-tool-small" onClick={(event) => handleClick(event)}>
      {folded && <ExpandMoreIcon />}
      {!folded && <ExpandLessIcon />}
    </div>
  )
};

export default ExpandOrCollapseCardTool;