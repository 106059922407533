// AdminData.js
// Import libraries
import React from 'react';

// Custom Components
import { DataTable } from '../../mui-tables/mui-data-table';


const AdminDataTable = () => {
    const collection = 'admin-data';
    const options = {
      enableGrouping: true,
      groupingColumns: ['type'],
      enablePagination: true,
      sorting: [
        {
          id: 'type',
          desc: true,
        },
        {
          id: 'order',
          desc: false,
        },
        {
          id: 'name',
          desc: false,
        },
      ]
    };
    const dataModel = [
      {
        name: 'type',
        type: 'list',
        colWidth: 180,
        entity: 'admin-data',
        // values: config.adminData.types.map((data) => data.value),
        defaultValue: '',
      },
      {
        name: 'name',
        type: 'text',
        colWidth: 180,
      },
      {
        name: 'order',
        type: 'text',
        colWidth: 110,
      },
      {
        name: 'color',
        type: 'color',
        colWidth: 110,
      },
      // {
      //   name: 'icon',
      //   type: 'text',
      //   colWidth: 80,
      // },
      {
        name: 'isFavorite',
        header: 'Favorite?',
        type: 'boolean',
        defaultValue: false,
        colWidth: 120,
      },
      // {
      //   name: 'category',
      //   type: 'object',
      //   colWidth: 180,
      //   entity: 'admin-data',
      //   values: [null, ...getCollectionCategories('tag-category')],
      //   defaultValue: '',
      // },
      {
        name: 'isActive',
        header: 'Active?',
        type: 'boolean',
        defaultValue: true,
        colWidth: 110,
      },
    ];

  return (
    <DataTable 
      collection = {collection}
      dynamicQueryKey = {[collection]}
      options = {options}
      dataModel = {dataModel} 
    />
  )
};

export default AdminDataTable;