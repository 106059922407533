import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

const DataForm = ({ fields, onSubmit, disableSave }) => {
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.id]: field.value || '' }), {})
  );

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({ ...formData, [id]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="row d-flex align-items-end">
          {fields.map((field) => (
            <div key={field.id} className="col-md-6 mb-3">
              {field.type === 'checkbox' ? (
                <div>
                  <Checkbox
                    id={field.id}
                    checked={formData[field.id] || false}
                    disabled={field.disabled}
                    onChange={handleChange}
                    sx={{
                      '&.MuiCheckbox-root': {
                        marginLeft: '-9px',
                        color: 'var(--color-text-primary)',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                      '&.Mui-checked': {
                        color: 'var(--color-text-primary)',
                      },
                      '&.Mui-disabled': {
                        color: 'var(--color-disabled-text)',
                      },
                    }}
                  />
                  <label 
                    className="form-check-label" 
                    htmlFor={field.id}
                    // style={{
                    //   color: field.disabled ? 'gray' : 'var(--color-disabled-text)',
                    // }}
                  >
                    {field.label}
                  </label>
                </div>
              ) : field.type === 'select' ? (
                <div>
                  <label htmlFor={field.id} className="form-label">
                    {field.label}
                  </label>
                  <select
                    id={field.id}
                    className="form-select custom-input-field"
                    value={formData[field.id]}
                    onChange={handleChange}
                  >
                    {field.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>
                  <label htmlFor={field.id} className="form-label">
                    {field.label}
                  </label>
                  <input
                    type={field.type}
                    className="form-control custom-input-field"
                    id={field.id}
                    value={formData[field.id]}
                    onChange={handleChange}
                    disabled={field.disabled}
                  />
                </div>
              )}
            </div>
          ))}
          {!disableSave && 
            <div className="col-12 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          }
        </div>
      </form>
    </div>
  );
};

export default DataForm;
