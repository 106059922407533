import ViewContentLayout from '../../../layouts/view/view-content-layout';
import DataImportSection from './data-import';
import DataExportSection from './data-export';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const FoldableCard = loadModule('./components/cards/foldable-card.js');

const Content = () => {

  return (
    <ViewContentLayout 
        title={"Import & Export"}
        subtitle={"Import and export all your data from here"}>
        <div className='view-content'>
          <FoldableCard title={'Data Export'} expandable={true}>
            <DataExportSection />
          </FoldableCard>
          <FoldableCard title={'Data Import'} expandable={true}>
            <DataImportSection />
          </FoldableCard>
        </div>
    </ViewContentLayout>
  );
};

export default Content;