// TBD: test
import React from "react";

const NewWindow = async () => {

  const newWindow = window.open('', 'newWindow', 'width=600,height=400');
  
  // Ensure newWindow is focused
  if (newWindow) {
    newWindow.focus();
  }
  
  const handleWindowClose = () => {
    console.log('New window has been closed');
  };

  if (newWindow) {
    // Add an event listener for the window's beforeunload event
    newWindow.addEventListener('beforeunload', handleWindowClose);
  }

  setTimeout(() => {
    console.log('This message will be displayed after 3 seconds');
    console.log(newWindow);
    if (newWindow) {
      newWindow.removeEventListener('beforeunload', handleWindowClose);
      newWindow.close();
    }
  }, 3000);
};

export default NewWindow;