import ViewContentLayout from '../../../layouts/view/view-content-layout';
import UserStats from '../../user-stats/components/user-stats';
import UserAccountInfo from './user-account-info';

const Panel = ({ data }) => {

  return (
    <ViewContentLayout 
      // title={"⚙️ Utility Panel"}
      // subtitle={"All your user settings"}
    >
      <UserAccountInfo data={data}/>
      <UserStats />
    </ViewContentLayout>
  );
};

export default Panel;