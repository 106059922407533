// dataCleanup.js
import axios from "axios"


export const clearUserData = async (displayAlert = true) => {
  try {
    await axios.post(`/api/user-admin/clear-user-data`).catch(console.error);
    if (displayAlert) alert('All user data cleared successfully!');
  } catch (error) {
    console.error('Error clearing user data: ', error);
    alert('Error clearing user data. Please try again.');
  }
};