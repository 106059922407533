import React from "react";
import loadModule from "../../../../core/utils/moduleLoader";
import useSystemStats from "../../../hooks/use-system-stats";

// Loading following components dynamically to allow one-off customization
const Spinner = loadModule("./components/loading/spinner.js");
const StatsCard = loadModule("./components/stats/stats-card.js");

const SystemStats = () => {

  const title = 'Collections';
  const { systemStats, isLoading } = useSystemStats();

  return (
    <div className="user-main centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <StatsCard title={title} stats={systemStats}/>
      )}
    </div>

  );
};

export default SystemStats;
