import { useState } from "react";

const useFileUpload = () => {

  const [importedFile, setImportedFile] = useState(null);
  
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setImportedFile(file);
  };

  return {
    importedFile,
    handleFileUpload,
  };
};

export default useFileUpload;