import React from 'react';
import Tab from './tab';
import './tabbar.css';

const TabBar = ({ tabNames, selectedTab, onChange }) => (
  <nav className="tab-bar-container">
    <ul className="tab-bar">
      {tabNames.map((tab, index) => (
        <Tab
          key={index}
          tabName={tab}
          isSelected={selectedTab === tab}
          onChange={onChange}
        />
      ))}
    </ul>
  </nav>
);

export default TabBar;
