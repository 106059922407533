import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import CardContent from '../../../components/cards/card-content';
import EntryDropdown from './entry-dropdown-menu';
import { getEntryTitle } from '../utils/get-entry-title';
import "./data-entry-card.css";

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const OpenPageInNewTabTool = loadModule("./components/tools/open-page-in-new-tab.js");
const OpenLinkInNewTabTool = loadModule("./components/tools/open-link-in-new-tab.js");
const ExpandOrCollapseCardTool = loadModule("./components/tools/expand-or-collapse-card.js");

const DataEntryCard = (props) => {

  const [folded, setFolded] = useState(true);
  const url = `/app/${props.object.collection}/edit/${props.object._id}`;
  const navigate = useNavigate();

  // const activeTags = props.object.tags?.filter((tag) => getDataFromCache('tags', tag)?.isActive);
  const activeTags = props.object.tags;

  const handleCardClick = () => {
    navigate(url);
  };

  const handleTagClick = (event, tagName) => {
    event.stopPropagation();
    navigate(`/app/search?tag=${tagName}`);
  }

  return (
    <Card className='data-entry-card mb-4' onClick={handleCardClick}>
      <Card.Body>
        <div className='data-entry-card-header'>
          <div className='data-entry-card-top-row'>
            <div className='data-entry-card-date'>
              {moment(props.object.date).format('dddd, MMMM D, YYYY')}
            </div>
            <div className="data-entry-card-toolbar">
              {/* <ExpandOrCollapseCardTool folded={folded} setFolded={setFolded} /> */}
              <OpenPageInNewTabTool url={url} />
              <EntryDropdown collection={props.object.collection} id={props.object._id} onDeletion={props.onDeletion}/>
            </div>
          </div> 
          <div className='data-entry-card-title'>{getEntryTitle(props.object)}</div>
          {props.type ?
            <div className='data-entry-card-type'>
            {/* <div className='data-entry-card-type' style={{backgroundColor: props.type.color ? props.type.color : '#e6e6e6'}}> */}
              <p>{props.type.value}</p>
            </div>
            : null
          }
        </div>
        
        <CardContent content={props.object.content} folded={folded}/>

        <div className='data-entry-card-footer'>
          <div className='tags-and-url'>
            {(activeTags) ? (
              <div className="row user-entry-card-tags">
                <p className="text-muted">
                  {activeTags.map((tag, index) => ( 
                    <span
                      key={index}
                      onClick={(event) => handleTagClick(event, tag.value)}
                      className="tag-link user-entry-card-footer-text"
                    >
                      #{tag.value}{' '}
                    </span>
                    ))}
                </p>
              </div>
            ) : null} 

            {props.object.url ? (
              <OpenLinkInNewTabTool url={props.object.url} displayUrl={true}/>  
            ) : (
              <div>{null}</div>
            )} 

          </div>

          <div className="data-entry-card-toolbar">
            <ExpandOrCollapseCardTool folded={folded} setFolded={setFolded} />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
};

export default DataEntryCard;