import SplitViewResponsiveLayout from '../../../layouts/view/split-view-responsive';
import Content from '../components/content';
import Panel from '../components/panel';
import Toolbar from '../components/toolbar';
import useLoadingIndicator from '../../../../core/hooks/use-loading-indicator';
import configView from '../config';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../../utils/moduleLoader';
const Spinner = loadModule('./components/loading/spinner.js');

const Main = () => {
  
  const isLoading = useLoadingIndicator();

  return (
    <div className="centered-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <SplitViewResponsiveLayout 
          content={<Content viewId={configView.viewId} viewName={configView.viewName} viewDesc={configView.viewDesc} viewFilters={configView.viewFilters} toolbar={<Toolbar />} />}
          contentToolbar= {<Toolbar />} 
          panel={<Panel />}
          panelToolbar= {null} 
          maximizeContentWidth={false}
          isPanelFixedToRight={false}
          isPanelContrastEnabled={false}
          isPanelFoldable={false}
        />
      )}
    </div>
    
  );
};

export default Main;