import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import HomeIcon from '@mui/icons-material/Home';
import './header.css';

// Dynamic Imports - Core Components That Can Be Customized
import loadModule from '../../utils/moduleLoader';
const Split3Col = loadModule('./components/grids/Split3Col.js');
const ToolbarButton = loadModule('./components/toolbars/toolbar-button.js');
const SearchBar = loadModule('./components/search/search-bar.js');
const AppToolbar = loadModule('./features/app-toolbar/components/app-toolbar.js');

const HeaderLeft = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  return (
    <div className='header-left'>
        <ToolbarButton icon={<MenuIcon />} onClick={toggleDrawer(true)}/>
        <ToolbarButton icon={<HomeIcon />} onClick={() => navigate('/app/home/')}/>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor={'left'} >
          {<Sidebar handleClose={toggleDrawer(false)}/>}
        </Drawer>
    </div>
    
  )
};

const HeaderRight = () => {

  return (
    <div className="header-right">
      <SearchBar />
      <AppToolbar />
    </div>
  )
};

const Header = () => { 

  return (
    <div className='header'>
      <Split3Col
        left={<HeaderLeft />}
        center={null} 
        right={<HeaderRight />} 
      />
    </div>
  )
};

export default Header;