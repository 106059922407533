import ViewHeader from "../../components/views/view-header";

const ViewContentLayout = ({ title, subtitle, toolbar, toolbarOnMobileOnly, children}) => {
  return (
    <div className='view-content-layout'>
      <ViewHeader 
        title={title} 
        subtitle={subtitle} 
        toolbar={toolbar} 
        toolbarOnMobileOnly={toolbarOnMobileOnly}
      />
      {children}
    </div>
  );
}

export default ViewContentLayout;