// TBD
// 1. Make this component generic
// 2. move to components library
// 3. use hook to modify context based on Selection

import { useContext } from 'react';
import { UserContext } from '../../../../core/contexts/user-context';

const ButtonGroupSelector = () => {

  const { userParameters, setUserParameters } = useContext(UserContext);

  const handleFontSizeChange = (value, event) => {
    // Stop the event from propagating to parent elements
    event.stopPropagation();
    
    setUserParameters((prevParams) => ({
      ...prevParams,
      appearance: {
        ...prevParams.appearance,
        fontSize: value,
      },
    }));
  };

  const isSelected = (size) => userParameters.appearance.fontSize === size;

  const values = {
    S: 'small',
    M: 'medium',
    L: 'large',
  };

  return (
    <div className="btn-group btn-group-sm" role="group" aria-label="Small button group">
      {Object.entries(values).map(([key, label]) => (
        <button
          key={key}
          type="button"
          className={`btn ${isSelected(label) ? 'btn-secondary' : 'btn-outline-secondary'}`}
          onClick={(event) => handleFontSizeChange(label, event)}
        >
          {key}
        </button>
      ))}
    </div>

  );
};

export default ButtonGroupSelector;